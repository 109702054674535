import React, { useEffect, useState } from "react"
import {
  setBreadcrumbItems,
} from "../../store/actions"
import { connect } from "react-redux"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import {
  createLockerAPI,
  getLockerByIdAPI,
  updateLockerAPI,
} from "service/service"

import { useParams, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

const CreateLocker = props => {
  const { id } = useParams()
  document.title = id ? "Update Locker Category | Shreesh - Locker Management Syatem" : "Create Locker Category | Shreesh - Locker Management Syatem"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: id ? "Update Locker Category" : "Create Locker Category", link: "#" },
  ]

  const [lockerName, setLockerName] = useState("")
  const [lockerDesc, setLockerDesc] = useState("")
  const [lockerSize, setLockerSize] = useState("")
  const [updateLocker, setUpdateLocker] = useState([])
  const [selectedValue, setSelectedValue] = useState("")
  const [selectCat, setSelectCat] = useState("")
  const [lockerDeposit, setLockerDeposit] = useState("")
  const [status, setStatus] = useState(true)
  const history = useNavigate()
  // let lockerCategory = document.getElementById('lockerCat').value;

  // const value = { lockerName, lockerDesc, lockerDeposit, lockerSize }
  // const updateValue = { id, lockerName, lockerDesc, lockerDeposit, lockerSize }

  const handleName = e => {
    setLockerName(e.target.value)
  }
  const handleDesc = e => {
    setLockerDesc(e.target.value)
  }
  const handleDeposit = e => {
    setLockerDeposit(e.target.value)
  }
  const handleSize = e => {
    setLockerSize(e.target.value)
  }
  const handleStatus = e => {
    setStatus(!status)
  }

  const handleDisplaylockerUpdate = async id => {
    try {
      const res = await getLockerByIdAPI(id)
      setUpdateLocker(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetFildName = () => {
    if (id) {
      // document.getElementById("name").value = updateLocker.name
      // document.getElementById("desc").value = updateLocker.description
      // document.getElementById("size").value = updateLocker.lockerSize
      // document.getElementById("deposit").value = updateLocker.lockerDeposit
      setLockerName(updateLocker.name);
      setLockerDeposit(updateLocker.lockerDeposit);
      setLockerDesc(updateLocker.description);
      setLockerSize(updateLocker.lockerSize);
      setSelectCat(updateLocker.lockerCategory)
      setSelectedValue(updateLocker.lockerCategory)
      setStatus(updateLocker.status)
    }
  }

  const handleSubmit = async () => {
    if (id) {
      try {
        let lockerName = document.getElementById("name").value
        let lockerDesc = document.getElementById("desc").value
        let lockerDeposit = document.getElementById("deposit").value
        let lockerSize = document.getElementById("size").value
        let lockerCategory = document.getElementById("lockerCat").value
        const res = await updateLockerAPI(
          id,
          lockerName,
          lockerDesc,
          lockerDeposit,
          lockerSize,
          lockerCategory,
          status
        )

        if (res.status === "1" || res.status === 1) {
          handleClear()
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500,
          })
          history("/lockerlist")
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {

      try {
        let lockerCategory = document.getElementById("lockerCat").value
        const res = await createLockerAPI(
          lockerName,
          lockerDesc,
          lockerDeposit,
          lockerSize,
          lockerCategory,
          status
        )
        localStorage.setItem("createLocker", res.status)
        handleClear()
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500,
          })
          history("/lockerlist")
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleClear = async () => {
    setLockerName("");
    setLockerDeposit("");
    setLockerDesc("");
    setLockerSize("");
    setSelectedValue("");
    setStatus(true)
  }

  const handleSelectChange = e => {
    const value = e.target.value
    // console.log("Category - ", value);
    setSelectedValue(value)
  }

  useEffect(() => {
    props.setBreadcrumbItems(id ? "Update Locker Category" : "Create Locker Category", breadcrumbItems)
  }, [window.location.href])

  useEffect(() => {
    handleClear()
  }, [window.location.href])

  useEffect(() => {
    if (id) {
      handleDisplaylockerUpdate(id)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleSetFildName();
    //eslint-disable-next-line
  }, [updateLocker])


  const handleCancle = async () => {
    history("/lockerlist")
  }

  return (
    <>
      <div>

        <Row className="mt-3">
          <Col xl={6} lg={8}>
            <div className="d-flex justify-content-end mb-2">
              <Button
                type="button"
                className={id ? "btn btn-primary w-md ms-3" : "d-none"}

                onClick={handleCancle}
              >
                Go Back
              </Button>
            </div>
            <Card>
              <CardBody>
                {/* <CardTitle>Create Locker</CardTitle> */}

                <div className="mb-3">
                  <Label
                    className=" col-form-label"
                  >
                    Locker Category*
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      id="name"
                      type="text"
                      name="name"
                      value={lockerName}
                      required
                      placeholder="Enter Locker Category"
                      onChange={handleName}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <Label
                    className=" col-form-label"
                  >
                    Locker Description
                  </Label>
                  <div>
                    <textarea
                      className="form-control"
                      id="desc"
                      type="text"
                      rows={4}
                      value={lockerDesc}
                      name="description"
                      placeholder="Enter Locker Description"

                      onChange={handleDesc}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <Label
                    className=" col-form-label"
                  >
                    Locker Deposit (Refundable)*
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      id="deposit"
                      type="number"
                      name="deposit"
                      value={lockerDeposit}
                      required
                      placeholder="Enter Deposit"
                      onChange={handleDeposit}
                    />
                  </div>
                </div>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className=" col-form-label"
                      >
                        Locker Size*
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          id="size"
                          type="text"
                          value={lockerSize}
                          name="lockerSize"
                          placeholder="i.e. 3 * 4"
                          required
                          onChange={handleSize}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="py-1">
                      <Label
                        className="col-form-label"
                      >
                        Select Size*
                      </Label>
                      <div>
                        <select
                          className="form-control"
                          value={selectedValue}
                          id="lockerCat"
                          onChange={handleSelectChange}
                        >
                          <option value="">--Please choose an option--</option>
                          <option
                            value="Extra Small"
                            selected={selectCat === "Extra Small"}
                          >
                            XSM (Extra Small)
                          </option>
                          <option
                            value="Small"
                            selected={selectCat === "Small"}
                          >
                            SM (Small)
                          </option>
                          <option
                            value="Medium"
                            selected={selectCat === "Medium"}
                          >
                            M (Medium)
                          </option>
                          <option
                            value="Large"
                            selected={selectCat === "Large"}
                          >
                            L (Large)
                          </option>
                          <option
                            value="Extra Large"
                            selected={selectCat === "Extra Large"}
                          >
                            XL (Extra Large)
                          </option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="py-1">
                  <Label
                    className=" col-form-label"
                  >
                    Status
                  </Label>
                  <div>
                    <Input
                      type="radio"
                      name="status"
                      id="active"
                      checked={status === true}
                      value="true"
                      onClick={() => handleStatus()}
                      style={{ transform: "scale(1.3)" }}
                    />
                    &nbsp;&nbsp;Active&nbsp;&nbsp;
                    <Input
                      type="radio"
                      name="status"
                      id="inactive"
                      checked={status === false}
                      value="false"
                      onClick={() => handleStatus()}
                      style={{ transform: "scale(1.3)" }}
                    />
                    &nbsp;&nbsp;Inactive
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-md"
                    onClick={handleSubmit}
                  >
                    {id ? "Update Locker" : "Create Locker"}
                  </button>
                  <Button
                    type="button"
                    className="btn btn-danger w-md ms-3"

                    onClick={id ? handleCancle : handleClear}
                  >
                    {id ? "Cancel" : "Clear"}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CreateLocker)
