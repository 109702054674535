import React, { Suspense, useEffect, useState } from "react"
import {
  setBreadcrumbItems,
} from "../../store/actions"
import { connect } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  createStaffAPI,
  getRoleList,
  getStaffByIdAPI,
  removeStaffImg,
  searchRoleAPI,
  updateStaffAPI,
  uploadNomineeImages,
  uploadStaffImages,
} from "service/service"

import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import user1 from "../../assets/images/users/user-1.jpg"
import dummy from "../../assets/images/users/dummy.jpg"
import Loading from "components/Common/Loading"
import ImageViewer from "ImageViewer"

const CreateStaff = props => {
  const { id } = useParams()
  const [roles, setRoles] = useState([])
  const [userRoleId, setUserRoleId] = useState()
  const [isRefresh, setIsRefresh] = useState(false)
  const [username, setUsername] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [gender, setGender] = useState(true)
  const [address, setAddress] = useState("")
  const [password, setpassword] = useState("")
  const [filepath, setFilepath] = useState()
  // const [staffId, setStaffId] = useState()
  const [image, setImage] = useState("")
  const [kyc, setKyc] = useState("")
  const [filepathkyc, setFilepathkyc] = useState()
  const [imageId, setImageId] = useState("")
  const [status, setStatus] = useState(true)
  const re = /^[A-Za-z\s]+$/;
  const regex = /^[0-9\b]+$/;

  const history = useNavigate()


  document.title = id ? "Update Staff | Shreesh Securities" : "Create Staff | Shreesh Securities"

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: id ? "Update Staff" : "Create Staff", link: "#" },
  ]

  const handleusername = e => {
    setUsername(e.target.value)
  }
  const handleLastName = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setLastName(value);
    }
  }
  const handleFirstName = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setFirstName(value);
    }
    // setFirstName(e.target.value)
  }
  const handleEmail = e => {
    setEmail(e.target.value)
  }
  const handleMobile = e => {
    const limit = 10
    // const { value } = e.target;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobile(e.target.value.slice(0, limit))
    }
  }
  const handleGender = e => {
    setGender(!gender)
  }
  const handleStatus = e => {
    setStatus(!status)
  }
  const handleAddress = e => {
    setAddress(e.target.value)
  }
  const handlePassword = e => {
    setpassword(e.target.value)
  }

  const handleDisplayRole = async () => {
    try {
      const res = await getRoleList(100, 1)
      setRoles(res.data)
      // setUserRoleId(document.getElementById("roleName").value)
      // if (id) {
      //   const res = await searchRoleAPI()
      //   setRoles(res.data)
      //   setUserRoleId(document.getElementById("roleName").value)
      // } else {
      //   const res = await searchRoleAPI()
      //   setRoles(res.data)
      //   setUserRoleId(document.getElementById("roleName").value)
      // }

    } catch (error) {
      console.log(error)
    }
  }

  const handleSelect = e => {
    const value = e.target.value
    setUserRoleId(value)
  }

  useEffect(() => {
    handleDisplayRole();
    //eslint-disable-next-line
  }, [userRoleId])

  const [isVisible, setVisible] = useState(false)
  const toggle = () => {
    setVisible(!isVisible)
  }

  const handleChild = () => {
    setTimeout(() => {
      setIsRefresh(!isRefresh)
    }, 1000)
  }

  const handleClear = () => {
    setFirstName("");
    setLastName("");
    setUsername("");
    setpassword("");
    setEmail("");
    setMobile("");
    setAddress("");
    setFilepathkyc("");
    setKyc("");
    setRoles("");
    setGender(true);
    // document.getElementById("username").value = ""
    // document.getElementById("password").value = ""
    // document.getElementById("firstName").value = ""
    // document.getElementById("lastName").value = ""
    // document.getElementById("email").value = ""
    // document.getElementById("mobile").value = ""
    // document.getElementById("address").value = ""
  }
  // const clear = () => {
  //   document.getElementById("image").value = ""
  // }
  const handleClean = () => {
    handleChild()
    handleClear()
  }

  const handleSetValue = async () => {
    if (id) {
      try {
        const res = await getStaffByIdAPI(id)
        const data = res.data
        // console.log(res.data);
        setUsername(data.login.username)
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setEmail(data.email)
        setMobile(data.mobile)
        setAddress(data.address)
        setUserRoleId(data.login.userRoleId);
        setGender(data.gender)
        setImage(data.image)
        setKyc(data.adharCard)
        setImageId(data.id)
        setStatus(data.status)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const uploadStaffimg = async e => {
    e.preventDefault()
    setFilepath(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]

    const res = await uploadStaffImages(imageId, file)
    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
      setImage(res.data.image)
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
      setFilepath("")
      setImage("")
      document.getElementById('profileImage').value = null
    }
  }

  const uploadStaffkyc = async e => {
    e.preventDefault()
    setFilepathkyc(URL.createObjectURL(e.target.files[0]))
    const file_name = e.target.files[0]
    const res = await uploadNomineeImages(file_name)
    setKyc(res.data)

    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
  }

  const removeStaffProfile = async e => {
    const staffId = imageId
    const imagePath = image
    try {
      const res = await removeStaffImg(staffId, imagePath)

      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
        setImage("")
        setFilepath("")
        // clear()
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const removeStaffKyc = async e => {
    if (id) {
      const staffId = imageId
      const imagePath = kyc
      try {
        const res = await removeStaffImg(staffId, imagePath)

        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          setKyc("")
          // clear()
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    else {
      setKyc("");
      setFilepathkyc("");
    }
  }

  const handleCancle = async () => {
    history("/stafflist")
  }

  const handleSubmit = async () => {
    if (id) {
      const firstName = document.getElementById("firstName").value
      const lastName = document.getElementById("lastName").value
      const email = document.getElementById("email").value
      const mobile = document.getElementById("mobile").value
      const address = document.getElementById("address").value
      const username = document.getElementById("username").value
      const password = document.getElementById("password").value

      try {
        const res = await updateStaffAPI(
          id,
          firstName,
          lastName,
          email,
          mobile,
          image,
          gender,
          address,
          kyc,
          username,
          password,
          userRoleId,
          // status
        )
        console.log(res.data);
        if (res.status === "1" || res.status === 1) {
          history("/stafflist")
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
        handleClean()
      } catch (error) {
        console.log(error)
      }
    } else {

      try {
        const res = await createStaffAPI(
          firstName,
          lastName,
          email,
          mobile,
          image,
          gender,
          address,
          kyc,
          username,
          password,
          userRoleId,
          // status
        )
        localStorage.setItem("staff_id", res.data._id)
        // setStaffId(res.data._id)

        if (res.status === "1" || res.status === 1) {
          // const img = await uploadStaffImage();
          history("/stafflist")
          // history("/uploadimg")
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
        handleClean()
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    props.setBreadcrumbItems(id ? "Update Staff" : "Create Staff", breadcrumbItems);
    //eslint-disable-next-line
  }, [window.location.href])

  useEffect(() => {
    id && handleSetValue()
    //eslint-disable-next-line
  }, [userRoleId])
  useEffect(() => {
    handleClear()
  }, [window.location.href])

  return (
    <Suspense fallback={<Loading />}>
      <>
        <div className="mt-3">
          <div className="d-flex justify-content-end mb-2">
            <Button
              type="button"
              className={id ? "btn btn-primary w-md ms-3" : "d-none"}

              onClick={handleCancle}
            >
              Go Back
            </Button>
          </div>
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col md={6} className="mt-3">
                    <CardTitle>
                      <b>Staff Personal Details</b>{" "}
                    </CardTitle>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        First Name*
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={handleFirstName}
                          value={firstName}
                          placeholder="Enter First Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Last Name*
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          name="lastName"
                          placeholder="Enter Last Name"
                          id="lastName"
                          onChange={handleLastName}
                          value={lastName}
                          required
                        />
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Email*
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Enter Email"
                          id="email"
                          onChange={handleEmail}
                          value={email}
                          required
                        />
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Mobile-No*
                      </Label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          name="mobile"
                          id="mobile"
                          min={10}
                          placeholder="Enter Mobile-No"
                          pattern="^[0-9\b]+$"
                          onChange={handleMobile}
                          value={mobile}
                          required
                        />
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Address*
                      </Label>
                      <div>
                        <textarea
                          className="form-control"
                          type="text"
                          rows={4}
                          placeholder="Enter Address"
                          name="address"
                          id="address"
                          onChange={handleAddress}
                          value={address}
                          required
                        />
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Gender
                      </Label>
                      <div>
                        <Input
                          type="radio"
                          name="gender"
                          id="male"
                          checked={gender === true}
                          value="true"
                          onChange={e => { }}
                          onClick={() => handleGender()}
                          style={{ transform: "scale(1.3)" }}
                        />
                        &nbsp;&nbsp;Male&nbsp;&nbsp;
                        <Input
                          type="radio"
                          name="gender"
                          id="female"
                          checked={gender === false}
                          value="false"
                          onChange={e => { }}
                          onClick={() => handleGender()}
                          style={{ transform: "scale(1.3)" }}
                        />
                        &nbsp;&nbsp;Female
                      </div>
                    </div>
                  </Col>

                  <Col md={6} className="mt-3">

                    <CardTitle>
                      <b>Staff Login</b>
                    </CardTitle>

                    <div>
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Select User Role*
                      </Label>
                      <div>
                        <select
                          className="form-control"
                          id="roleName"
                          value={userRoleId}
                          onChange={handleSelect}
                        >
                          <option>-- SELECT --</option>
                          {roles &&
                            roles.length > 0 &&
                            roles.map((data, index) => {
                              return (
                                <option key={index} value={data.id}>
                                  {data.name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        User Name*
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          name="username"
                          placeholder="Enter User Name"
                          id="username"
                          onChange={handleusername}
                          value={username}
                          required
                        />
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Password*
                      </Label>
                      <div className="d-flex position-relative">
                        <Input
                          className="form-control"
                          type={!isVisible ? "password" : "text"}
                          name="password"
                          placeholder="Enter Password"
                          id="password"
                          onChange={handlePassword}
                          value={password}
                          required
                        />
                        <span
                          className="icon align-self-center"
                          style={{
                            position: "absolute",
                            right: "10px",
                            cursor: "pointer",
                          }}
                          onClick={toggle}
                        >
                          {isVisible ? (
                            <i className="fas fa-eye" style={{ color: "gray" }}></i>
                          ) : (
                            <i
                              className="fas fa-eye-slash"
                              style={{ color: "gray" }}
                            ></i>
                          )}
                        </span>
                      </div>
                    </div>



                    {/* <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Status
                      </Label>
                      <div>
                        <Input
                          type="radio"
                          name="status"
                          id="active"
                          checked={status === true}
                          value="true"
                          onClick={() => handleStatus()}
                        />
                        &nbsp;Active&nbsp;&nbsp;
                        <Input
                          type="radio"
                          name="status"
                          id="inactive"
                          checked={status === false}
                          value="false"
                          onClick={() => handleStatus()}
                        />
                        &nbsp;Inactive
                      </div>
                    </div> */}

                    <Row>
                      {id && (
                        <>
                          <Col lg={6}>
                            <div className="mt-3">
                              <Label
                                htmlFor="example-text-input"
                                className=" col-form-label"
                              >
                                Staff Profile Photo
                              </Label>
                              <div className="mt-1 ">
                                {!image ? (
                                  <div>
                                    <Input
                                      type="file"
                                      name="profileImage"
                                      id="profileImage"
                                      required
                                      onChange={uploadStaffimg}
                                    />
                                    <img
                                      src={filepath ? filepath : user1}
                                      alt="staff-profile"
                                      style={{ width: "8rem", height: "7rem" }}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <ImageViewer
                                      path={image}
                                      style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                                    >
                                      {" "}
                                    </ImageViewer>
                                    <i
                                      className="fas fa-trash-alt"
                                      style={{
                                        position: "absolute",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        marginTop: "8px",
                                        color: "red",
                                        fontSize: "larger"
                                      }}
                                      onClick={removeStaffProfile}
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                      <Col lg={6}>
                        <div className="mt-3">
                          <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Staff KYC
                          </Label>
                          <div className="mt-1 ">
                            {!kyc ? (
                              <div>
                                <Input
                                  type="file"
                                  name="adharCard"
                                  id="adharCard"
                                  required
                                  onChange={uploadStaffkyc}
                                />
                                <img
                                  src={filepathkyc ? filepathkyc : dummy}
                                  alt="staff-profile"
                                  className="mt-1"
                                  style={{ width: "8rem", height: "6rem" }}
                                />
                              </div>
                            ) : (
                              <div>
                                {/* <img
                                  crossOrigin="anonymous"
                                  id="adharCard"
                                  src={kyc && "http://64.227.143.181:3000/" + kyc}
                                  alt=""
                                  style={{ width: "8rem", height: "5rem" }}
                                />
                                
                                <i
                                  className="fas fa-trash-alt"
                                  style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    marginTop: "8px",
                                  }}
                                  onClick={removeStaffKyc}
                                ></i> */}
                                <ImageViewer
                                  path={kyc}
                                  style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                                >
                                  {" "}
                                </ImageViewer>
                                <i
                                  className="fas fa-trash-alt"
                                  style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    marginTop: "8px",
                                    color: "red",
                                    fontSize: "larger"
                                  }}
                                  onClick={removeStaffKyc}
                                ></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <div className="d-flex justify-content-center">
                    <Button
                      type="button"
                      className="btn btn-primary w-md"
                      onClick={handleSubmit}
                    >
                      {id ? "Update Staff" : "Create Staff"}
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-danger w-md ms-3"

                      onClick={id ? handleCancle : handleClear}
                    >
                      {id ? "Cancel" : "Clear"}
                    </Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </>
    </Suspense>
  )
}

export default connect(null, { setBreadcrumbItems })(CreateStaff)
