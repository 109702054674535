import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import lockerNumber from "./lockerNo.json"

import {
  assignLockerToCustomerAPI,
  getCustomerByIdAPI,
  // getCustomerList,
  getCustomerSubHolderAPI,
  getLockerByIdAPI,
  getOccupiedLocker,
  getRentByIdAPI,
  getRentList,
  getSearchCustomerList,
  getSearchLockerList,
} from "service/service"
import Select from "react-select"
import { gst } from "api/apiClient"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
// import { useSelector } from "react-redux"
// import { assignLockerToCustomer } from "service/backendRoutes"

const CustomerLocker = props => {
  document.title = "Customer Locker Assign | Shreesh - Locker Management Syatem"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Customer Locker Assign", link: "#" },
  ]

  const re = /^[A-Za-z\s]+$/;
  const [locker, setLocker] = useState([])
  const [rent, setRent] = useState([])
  const [secondaryCustomer, setSecondaryCustomer] = useState([])
  const [selectedLockerNoValue, setSelectedLockerNoValue] = useState("")
  const [instruction, setInstruction] = useState("")
  const [details, setDetails] = useState("")
  const [lockerKey, setLockerKey] = useState("")
  // const [entryFees, setEntryFees] = useState("")
  const [userLocker, setUserLocker] = useState()
  const [userRent, setUserRent] = useState("")
  const [lockerDetails, setLockerDetails] = useState()
  const [rentName, setRentName] = useState()
  const [rentDetails, setRentDetails] = useState()
  const [operatingMandate, setOperatingMandate] = useState()
  const [occupied, setOccupied] = useState([])
  const [additionalLockCharge, setAdditionalLockCharge] = useState("")

  const [lockerDeposit, setLockerDeposit] = useState(0)

  const [customer, setCustomer] = useState("")
  const [userCustomer, setUserCustomer] = useState("")
  const [subHolderDetails, setSubHolderDetails] = useState([])
  const [primaryHolders, setPrimaryHolders] = useState([])
  const [assignPrimary, setAssignPrimary] = useState("")
  const [subHolderId, setSubHolderId] = useState("")

  const history = useNavigate()

  const handleDisplaylockerList = async e => {
    try {
      let temp = [];
      const res = await getSearchLockerList()
      // setLocker(res.data)
      res?.data.length > 0 && res?.data.map((data) => {
        if (data.status === true) {
          temp.push(data)
        }
        // console.log(temp);
        setLocker(temp)
      })
    } catch (error) {
      console.log(error);
    }
  }

  const handleDisplayrentList = async () => {
    try {
      const res = await getRentList()
      setRent(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  // const handleDisplayCustomerList = async () => {
  //   try {
  //     await getCustomerList()
  //     // setCustomer(res.data)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleSelectChangeLockerNo = e => {
    const value = e.value
    setSelectedLockerNoValue(value)
  }

  const handleInstruction = e => {
    const value = e.target.value
    setInstruction(value)
  }

  const handleDetails = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setDetails(value);
    }
  }

  const handleLockerKey = e => {
    const value = e.target.value
    setLockerKey(value)
  }

  const handleMandate = e => {
    const value = e.target.value
    setOperatingMandate(value)
  }

  const totalCharge = additionalLockCharge + (additionalLockCharge * gst) / 100

  const handleSubmit = async (e, i) => {
    e.preventDefault()

    // let subHolderIds = assignPrimary.holders
    // let primary = assignPrimary?.primaryHolder?.id ? assignPrimary?.primaryHolder?.id : userCustomer
    let primary = userCustomer

    // console.log(
    //   "primaryCustomerId = " + primary,
    //   "userLockerId = " + userLocker,
    //   "userRentId = ", userRent,
    //   "lockerKey = " + lockerKey,
    //   "selectedLockerNoValue = " + selectedLockerNoValue,
    //   "additionalLockCharge = " + totalCharge,
    //   "instruction = " + instruction,
    //   "details = " + details,
    //   "Deposit = " + lockerDeposit,
    //   "entryFees = " + totalRent,
    //   "GST = " + gst,
    //   "primary = " + primary,
    //   "(subHolderId) = " + (subHolderId) ? (subHolderId) : "",
    //   "operatingMandate = " + operatingMandate
    // );

    const res = await assignLockerToCustomerAPI(
      primary,
      userLocker,
      userRent,
      lockerKey,
      selectedLockerNoValue,
      totalCharge,
      lockerDeposit,
      instruction,
      details,
      totalRent,
      gst,
      primary,
      // JSON.parse(subHolderId),
      (subHolderId) ? JSON.parse(subHolderId) : "",
      operatingMandate
    )
    if (res?.status === "1" || res?.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
      history("/assignedLockerList")
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
  }

  const handleSelect = e => {
    const value = e.value
    setUserLocker(value)
    setRentName([])
    setRentDetails([])
    setUserRent('')
  }

  const handleSelect2 = e => {
    const value = e.value
    setUserRent(value)
  }

  const primatyCust = async () => {
    let id = localStorage.getItem("customerID")
    await getCustomerByIdAPI(id)
  }

  const secondaryCust = async () => {
    let temp = JSON.parse(localStorage.getItem("subHolderId"))
    // temp &&
    //   temp.length > 0 &&
    //   temp.map(async data => {
    const res = await getCustomerByIdAPI(temp)
    // console.log(res.data)
    setSecondaryCustomer(prev => [...prev, res.data])
    // })
  }
  const displayLockerById = async () => {
    const id = userLocker
    const res = await getLockerByIdAPI(id)
    setLockerDetails(res?.data)

    // locker Deposit
    // const lockCat = res?.data
    // if (lockCat?.name === "B") {
    //   setLockerDeposit(25000)
    // }
    // else if (lockCat?.name === "C") {
    //   setLockerDeposit(32000)
    // }
    // else if (lockCat?.name === "F") {
    //   setLockerDeposit(75000)
    // }
    // else if (lockCat?.name === "L") {
    //   setLockerDeposit(120000)
    // }
    // else if (lockCat?.name === "XL") {
    //   setLockerDeposit(160000)
    // }


    // locker rent
    const rentData = res.data
    // setRentDetails(res.data.rents)
    const response = await getOccupiedLocker()
    setOccupied(response.data)
    const temp = []
    // console.log(res.data && res.data.rents)
    rentData.rents &&
      rentData.rents.length > 0 &&
      rentData.rents.map(data => {
        if (data.status === true) {
          // setRentDetails(data)
          temp.push({ value: data.id, label: data.name })
        }
      })
    setRentName([temp])
  }

  const getRentById = async () => {
    try {
      const res = await getRentByIdAPI(userRent)
      setRentDetails(res?.data)
      if (res?.data.minimumDurationDay === 3) {
        setLockerDeposit(0)
      } else {
        if (lockerDetails?.name === "B") {
          setLockerDeposit(25000)
        }
        else if (lockerDetails?.name === "C") {
          setLockerDeposit(32000)
        }
        else if (lockerDetails?.name === "F") {
          setLockerDeposit(75000)
        }
        else if (lockerDetails?.name === "L") {
          setLockerDeposit(120000)
        }
        else if (lockerDetails?.name === "XL") {
          setLockerDeposit(160000)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const displayCustomer = async e => {
    let temp = []
    const res = await getSearchCustomerList()
    res?.data.map((item) => {
      item.kycStatus === 'Verified' && temp.push(item)
    })
    setCustomer(temp)
  }

  const handleSelectCustomer = e => {
    const value = e.value
    setUserCustomer(value)
    const checkBox = document.getElementById('checkbox')
    if (checkBox !== null) {
      document.getElementById('checkbox').checked = false
    }
    // console.log("setUserCustomer = ", e.value);
  }

  const customerOptions = [
    customer &&
    // customer.length > 0 &&
    customer.map(data => {
      return { value: data.id, label: data.fullName }
    }),
  ]

  const displayHolders = async e => {
    const res = await getCustomerSubHolderAPI(userCustomer)
    const temp = []
    const hTemp = []
    const nameTemp = []
    const response = res.data
    if (res.status === "1" || res.status === 1) {
      response.map(data => temp.push(data))
      response.map(data => hTemp.push(data.holders))
      setPrimaryHolders(temp)

      hTemp &&
        hTemp.length > 0 &&
        hTemp.map((data, index) => {
          // console.log(data);
          data &&
            data.length > 0 &&
            data.map(item => {
              nameTemp.push({ id: index, name: item.fullName })
            })
        })
      setSubHolderDetails(nameTemp)
    } else {
      setPrimaryHolders("")
      setSubHolderDetails("")
    }
  }

  const handleChange = (event, index) => {
    let temp = []
    if (event.target.checked) {
      // console.log("✅ Checkbox is checked", event.target.value)
      const checked = event.target.value
      primaryHolders.map((data, index) => {
        if (index == checked) {
          // console.log("data", data)
          data.holders.map(item => {
            temp.push(item.id)
          })
          setAssignPrimary(data)
          setSubHolderId(JSON.stringify(temp))
        }
      })
    } else {
      // console.log("⛔️ Checkbox is NOT checked")
      setAssignPrimary("")
      setSubHolderId("")
    }
  }

  const handleAdditionalLock = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked) {
      console.log("checked = ", e.target.checked);
      setAdditionalLockCharge(4000)
    } else {
      setAdditionalLockCharge("")
    }
  }
  // console.log("item", subHolderId)
  // console.log("primary", assignPrimary?.primaryHolder?.id)

  useEffect(() => {
    handleDisplaylockerList()
    handleDisplayrentList()
    // handleDisplayCustomerList()
    primatyCust()
    secondaryCust()
    displayCustomer()
  }, [""])

  useEffect(() => {
    displayLockerById()
    getRentById()
    filterLockerNo()
    //eslint-disable-next-line
  }, [userLocker, userRent])

  useEffect(() => {
    displayHolders()
    //eslint-disable-next-line
  }, [userCustomer, ""])

  useEffect(() => {
    props.setBreadcrumbItems("Customer Locker Assign", breadcrumbItems)
  })

  const options = [
    locker.map(data => {
      return { value: data.id, label: data.name }
    }),
  ]

  const filterLockerNo = () => {
    let temp = []
    let tempLocker = []
    occupied && occupied.length > 0 &&
      occupied.map((item) => {
        tempLocker.push(item.lockerNo)
      })
    // console.log(tempLocker);
    lockerDetails &&
      lockerNumber &&
      lockerNumber.map(data => {
        if (data.lockerNo.includes(lockerDetails.name)) {
          if (!(tempLocker.includes(data.lockerNo)))
            temp.push({ value: data.lockerNo, label: data.lockerNo })
        }
      })
    return temp
  }

  // const rentTotal =
  //   rentDetails?.rentPrice * rentDetails?.minimumDurationDay +
  //   (rentDetails?.rentPrice * rentDetails?.minimumDurationDay * gst) / 100

  let entryFees = rentDetails?.minimumDurationDay === 3 ? 0 : 2100

  const rentTotal =
    rentDetails?.rentPrice +
    (rentDetails?.rentPrice * gst) / 100
  // const totalRent = Number(entryFees) + (Number(entryFees) * gst) / 100
  const totalRent = entryFees + (entryFees * gst) / 100
  const finalRent = Math.round(totalRent + rentTotal)
  const lockGst = Number(additionalLockCharge) + (Number(additionalLockCharge) * gst) / 100
  // const totalAmount = Math.round(lockerDetails?.lockerDeposit + finalRent + lockGst)
  const totalAmount = Math.round(lockerDeposit + finalRent + lockGst)


  return (
    <>
      <div className="d-flex justify-content-start">
        <Row className="mt-5 w-100">
          <Card>
            <CardBody>
              <Form>
                <CardTitle className="fs-4 text-center">
                  <b>Customer Locker Assign</b>
                </CardTitle>
                <Row>
                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Select Locker*
                    </Label>
                    {options &&
                      options.map(data => (
                        <Select
                          options={data}
                          onChange={handleSelect}
                          isSearchable={true}
                        />
                      ))}
                  </div>
                </Row>

                {userLocker ? (
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <CardTitle className="fs-4">
                            <b>Locker Details</b>
                          </CardTitle>
                          <hr />
                          <Row className="fw-bold view-table">
                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Locker Name</h6>
                              <p>{lockerDetails?.name}</p>
                            </Col>
                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Locker Size</h6>
                              <p>{lockerDetails?.lockerSize}</p>
                            </Col>
                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Locker Category</h6>
                              <p>{lockerDetails?.lockerCategory}</p>
                            </Col>
                            {/* <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">
                                Locker Deposit (Refundable)
                              </h6>
                              <p>{lockerDetails?.lockerDeposit}</p>
                            </Col> */}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row>
                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Select Rent*
                    </Label>
                    <div>
                      {/* <select className="form-control" id="LockerName"></select> */}
                      {/* <Input value={rentName} /> */}
                      {rentName &&
                        rentName.map(data => (
                          <Select
                            options={data}
                            onChange={handleSelect2}
                            isSearchable={true}
                            placeholder={data.label}
                          />
                        ))}
                    </div>
                  </div>
                </Row>

                {userRent ? (
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <CardTitle className="fs-4">
                            <b>Rent Details</b>
                          </CardTitle>
                          <hr />
                          <Row className="fw-bold view-table">
                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Plan Name</h6>
                              <p>{rentDetails?.name}</p>
                            </Col>

                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Rent Price</h6>
                              <p>{rentDetails?.rentPrice}</p>
                            </Col>

                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Duration Day</h6>
                              <p>{rentDetails?.minimumDurationDay}</p>
                            </Col>
                            {/* <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Rent Price</h6>
                              <p>
                                {rentDetails?.rentPrice *
                                  rentDetails?.minimumDurationDay}
                              </p>
                            </Col> */}

                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">GST (18%)</h6>
                              <p>
                                {(rentDetails?.rentPrice *
                                  gst) /
                                  100}
                              </p>
                            </Col>
                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Total Rent</h6>
                              <p>{rentTotal}</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row>
                  <Col lg={6}>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Locker No*
                      </Label>
                      <div>
                        {lockerDetails && (
                          <Select
                            options={filterLockerNo()}
                            onChange={handleSelectChangeLockerNo}
                            isSearchable={true}
                          />
                        )}
                      </div>
                    </div>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Locker Key*
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          name="customerLockerKey"
                          id="customerLockerKey"
                          placeholder="Enter Customer Locker Key"
                          required
                          value={lockerKey}
                          onChange={handleLockerKey}
                        />
                      </div>
                    </div>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Instructions*
                      </Label>
                      <div>
                        <textarea
                          className="form-control"
                          type="text"
                          rows={4}
                          name="Instructions"
                          placeholder="Enter Instruction"

                          value={instruction}
                          onChange={handleInstruction}
                        />
                      </div>
                    </div>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Reference By
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          name="referenceBy"
                          id="referenceBy"
                          placeholder="Enter Reference By"

                          value={details}
                          onChange={handleDetails}
                        />
                      </div>
                    </div>
                    {/* Locker Deposit start */}
                    {
                      rentDetails?.minimumDurationDay === 3 ?
                        <div className="py-1">
                          <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Deposit (Refundable)*
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="text"
                              name="lockerDeposit"
                              id="lockerDeposit"
                              placeholder="Enter Locker Deposit"
                              value={lockerDeposit}
                            />
                          </div>
                        </div>
                        :
                        <div className="py-1">
                          <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Deposit (Refundable)*
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="text"
                              name="lockerDeposit"
                              id="lockerDeposit"
                              placeholder="Enter Locker Deposit"
                              value={lockerDeposit}
                            />
                          </div>
                        </div>
                    }
                    {/* Locker Deposit end */}
                    {
                      rentDetails?.minimumDurationDay === 3 ?
                        <div className="py-1">
                          <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Entry Fees
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="number"
                              name="entryFees"
                              id="entryFees"
                              placeholder="Enter Entry Fees"
                              required
                              // onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                              value={totalRent}
                            // onChange={handleEntryFees}
                            />
                          </div>
                        </div>
                        : <div className="py-1">
                          <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Entry Fees (2100 + GST 18%)*
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="number"
                              name="entryFees"
                              id="entryFees"
                              placeholder="Enter Entry Fees"
                              required
                              // onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                              value={totalRent}
                            // onChange={handleEntryFees}
                            />
                          </div>
                        </div>}

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Operate Mandate
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          name="operatingMandate"
                          id="operatingMandate"
                          placeholder="Operate Mandate"

                          value={operatingMandate}
                          onChange={handleMandate}
                        />
                      </div>
                    </div>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Additional Lock Charge 4000 + GST (18%)
                      </Label>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          // value={index}
                          onChange={handleAdditionalLock}
                          style={{ cursor: "pointer", width: "15px" }}
                        />
                        <span style={{ marginLeft: "1rem" }}>If you required <b>Additional Lock</b> please select checkbox</span>
                        {/* <Input
                          className="form-control"
                          type="number"
                          name="additionalLock"
                          id="additionalLock"
                          placeholder="Additional Lock Charge"
                          onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                          // required
                          value={additionalLockCharge}
                          onChange={handleLockCharge}
                        /> */}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Select Customer*
                      </Label>
                      {customerOptions &&
                        customerOptions.map(data => (
                          <Select
                            options={data}
                            onChange={handleSelectCustomer}
                            isSearchable={true}
                            id="check"
                          />
                        ))}
                    </div>

                    <div className="py-1 table-responsive mt-3 receipt-table text-center">
                      <table>
                        <tr>
                          <th>
                            Select
                          </th>
                          <th>
                            Customer Name
                          </th>
                          <th>
                            Sub-Holder Name
                          </th>
                        </tr>
                        {primaryHolders &&
                          primaryHolders.length > 0 &&
                          primaryHolders.map((data, index) => {
                            return (
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="checkbox"
                                    value={index}
                                    onChange={handleChange}
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                                <td >
                                  {data.primaryHolder.fullName}
                                </td>

                                <td>
                                  {subHolderDetails &&
                                    subHolderDetails.length > 0 &&
                                    subHolderDetails.map(
                                      (data, i) =>
                                        data.id === index &&
                                        data.name +
                                        `${subHolderDetails.filter(
                                          item => item.id === i
                                        ).length -
                                          (1 % 2) !==
                                          0
                                          ? " | "
                                          : " "
                                        }`
                                    )}
                                </td>
                              </tr>
                            )
                          })}
                      </table>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                 
                        <hr />
                        <Row className="fw-bold view-table">
                          <Col xl={6} lg={6} md={6} sm={12}>
                            <h6 className="fw-bold">Final Rent</h6>
                            {/* <p>
                              {totalRent} + {rentTotal} = {finalRent}
                            </p> */}
                            {/* <p>
                              {lockGst} + {totalAmount}
                            </p> */}
                            <p>{finalRent}</p>
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={12}>
                            <div className="totalAmount">
                              <h6 className="fw-bold">Total payable amount</h6>
                              <p>{totalAmount}</p>
                            </div>
                          </Col>
                        </Row>
                     
                  </Col>
                </Row>
                
                <center>
                  <div className="mt-3 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Assign Locker to Customer
                    </button>
                  </div>
                </center>
              </Form>
            </CardBody>
          </Card>
        </Row>
      </div>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CustomerLocker)
