export const authLoginRoute = "Admin/getAuthTokens"
export const userProfileRoute = "Admin/getUserById"
export const uploadUserImage = "Admin/uploadUserImage"
export const removeUserImage = "Admin/removeUserImage"
export const changePasswordRoute = "Admin/changePassword"

export const companyProfileRoute = "Admin/getCompanyDetailsById"
export const uploadCompanyImage = "Admin/uploadCompanyImage"
export const removeCompanyImage = "Admin/removeCompanyImage"
export const updateCompanyDetailsRoute = "Admin/updateCompanyDetails"

// ---------ROLE---------
export const createRoleRoute = "Role/createUserRole"
export const displayRoleRoute = "Role/getUserRolesByPg"
export const deleteRoleRoute = "Role/deleteUserRoles"
export const updateRoleRoute = "Role/updateUserRole"
export const roleCountRoute = "Role/countUserRole"
export const searchRoleRoute = "Role/searchUserRole"

// ---------RIGHTS---------
export const createRightsRoute = "Right/createUserRight"
export const displayRightsRoute = "Right/getUserRight"
export const deleteRightsRoute = "Right/deleteUserRights"
export const updateRightsRoute = "Right/updateUserRight"
export const rightsCountRoute = "Right/countUserRight"

// ---------USER RIGHTS---------
export const getUserRights = "Right/getUserRightByPg"

// ---------Role To Right---------
export const displayRoleToRightsRoute = "RoleToRight/getUserRoleToRight"
export const updateRoleToRightsRoute = "RoleToRight/assignRightsToRole"

// ---------STAFF---------
export const searchStaffRoute = "Staff/searchStaffs"
export const StaffListRoute = "Staff/getStaffByPg"
export const deleteStaffRoute = "Staff/deleteStaffById"
export const UpdateStaffRoute = "Staff/updateStaffById"
export const createStaffRoute = "Staff/createStaff"
export const uploadStaffImgRoute = "Staff/uploadStaffImage"
export const removeStaffImgRoute = "Staff/removeStaffImage"
export const getStaffById = "Staff/getStaffById"
export const staffCountRoute = "Staff/countStaff"
export const deactiveStaffRoute = "Staff/inactiveStaffById"
export const ActiveStaffRoute = "Staff/activeStaffById"

// ---------LOCKER---------
export const searchLockerRoute = "Locker/searchLockers"
export const createLockerRoute = "Locker/createLocker"
export const lockerListRoute = "Locker/getLockersByPg"
export const DeactiveLockerRoute = "Locker/deactiveLockerById"
export const ActiveLockerRoute = "Locker/activateLockerById"
export const lockerByIdRoute = "Locker/getLockerById"
export const updateLockerRoute = "Locker/updateLockerById"
export const lockerCountRoute = "Locker/getLockersCount"

// ---------CUSTOMER---------

export const customerCountRoute = "Customer/getCustomersCount"
export const searchCustomerRoute = "Customer/searchCustomers"
export const UploadImageRoute = "Customer/uploadCustomerFiles"
export const CustomerListRoute = "Customer/getCustomersByPg"
export const createCustomerRoute = "Customer/createCustomer"
export const getCustomerByIdRoute = "Customer/getCustomerById"
export const addCustomerAddressRoute = "Customer/addCustomerAddress"
export const updateCustomerAddressRoute = "Customer/updateCustomerAddress"
export const getCustomerAddressRoute = "Customer/listCustomerAddresses"
export const addCustomerNomineeRoute = "Customer/addCustomerNominee"
export const updateCustomerNomineeRoute = "Customer/updateCustomerNominee"
export const updateCustomerRoute = "Customer/updateCustomerById"
export const DeactiveCustomerRoute = "Customer/deactiveCustomerById"
export const ActiveCustomerRoute = "Customer/activateCustomerById"
// export const addCustomerKycRoute = "Customer/addCustomerKYCDocument"
export const getCustomerKycRoute = "Customer/listCustomerKYCDocuments"
export const deleteCustomerKycRoute = "Customer/deleteCustomerKYCDocument"
export const deleteCustomerAddressRoute = "Customer/deleteCustomerAddress"
export const getExpiringLockersRoute = "Customer/getExpiringLockers"
export const uploadCustomerImage = "Customer/uploadCustomerImage"
export const removeCustomerImage = "Customer/removeCustomerImage"
export const renewLockerRoute = "Customer/renewCustomerLocker"
export const changeLockerPlanRoute = "Customer/changeCustomerLockerPlan"
export const kycByIdRoute = "Customer/listCustomerKYCDocuments"
export const UpdateCustomerKycStatusRoute = "Customer/updateCustomerKYCStatusById"
export const occupiedLockerRoute = "Customer/getOccupiedLockers"
export const addCustKycRoute = "Customer/addCustomerKYCDocument"
export const updateCustKycRoute = "Customer/updateCustomerKYCDocument"
export const assignLockerToCustomer = "Customer/assignLockerToCustomer"
export const getOccupiedLockerByIdRoute = "Customer/getCustomerLockerDetails"
export const getOccupiedLockerRoute = "Customer/getOccupiedLockers"
export const occupiedLockerDeactivateRoute = "Customer/deactivateCustomerLocker"
export const occupiedLockerActivateRoute = "Customer/activateCustomerLocker"
export const occupiedLockerRemoveRoute = "Customer/removeCustomerLocker"
export const occupiedLockerBreakRoute = "Customer/breakCustomerLocker"
export const getLockerHistoryRoute = "Customer/getLockerHistory"
export const searchCustomerLockersByLockerNo = "Customer/searchCustomerLockersByLockerNo"



export const listCustomerNomineeRoute = "Customer/listCustomerNominees"
export const deleteCustomerNomineeRoute = "Customer/deleteCustomerNominee"



export const updateLockerTransactionByIdRoute = "Customer/updateLockerTransactionById"



export const getLockerTransactionByIdRoute = "Customer/getCustomerLockerTransactions"

export const addAdditionalLockOnLockerRoute = "Customer/addAdditionalLockOnLocker"

export const registerCustomerFingerPrint = "Customer/registerCustomerFingerPrint"
export const fetchCustomerFingerPrint = "Customer/fetchCustomerFingerPrint"
export const accessCustomerLockerRoute = "Customer/accessCustomerLocker"

export const manualAccessLockerRoute = "Customer/manuallyAccessCustomerLocker"

export const closeCustLockerRoute = "Customer/CloseCustomerLockerWithPenalty"

export const sendMessageRoute = "Customer/inviteCustomers"
export const registerInvitedCustRoute = "Customer/registerInvitedCustomer"

// ---------RENT---------
export const searchRentRoute = "Rent/searchRents"
export const RentListRoute = "Rent/getRentsByPg"
export const DeactiveRentRoute = "Rent/deactiveRentById"
export const ActiveRentRoute = "Rent/activateRentById"
export const UpdateRentRoute = "Rent/updateRentById"
export const CreateRentRoute = "Rent/createRent"
export const getRentById = "Rent/getRentById"
export const rentCountRoute = "Rent/getRentsCount"
export const refRoute = "Admin/getAccessTokenByRefreshToken"

//-----------------REPORT--------------------
export const displayLockersCountRoute = "Report/getLockersCount"
export const displayCustomersCountRoute = "Report/getCustomersCount"
export const displayLockerAccessCountRoute = "Report/getTotalLockerAccessHistoryCounts"
export const displayDailyLockerAccessCountRoute = "Report/getDailyLockerAccessHistoryCounts"

// ---------------Transaction------------
export const createTransactionRoute = "Transaction/createTransaction"
export const searchTransactionRoute = "Transaction/searchTransactions"
export const TransactionListRoute = "Transaction/getTransactionsByPg"
export const transactionCountRoute = "Transaction/getTransactionsCount"
export const getTransactionByIdRoute = "Transaction/getTransactionById"

// ---------------SubHolder------------
export const addSubHolderRoute = "Customer/storeHolders"
export const getSubHolderRoute = "Customer/fetchHolders"

// --------------- Reports ------------
export const getCustomerReportRoute = "Report/listCustomers"
export const getTransactionReportRoute = "Report/getTransactionReport"
export const getLockerReportRoute = "Report/getLockerAccessReport"
export const getUnOperatedLockerReportRoute = "Report/getUnOperatedLockerReport"


