import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row, Label, Form } from "reactstrap";
import UserRights from "components/UserRights/UserRights";

import { createRightsAPI, updateRightsAPI } from "service/service";
import Swal from "sweetalert2"
import { useSelector } from "react-redux";

const Rights = (props) => {
    document.title = "Rights | Shreesh Securities";
    const breadcrumbItems = [
        { title: "Locker", link: "/dashboard" },
        { title: "Rights", link: "#" },
    ]
    useEffect(() => {
        props.setBreadcrumbItems('Rights', breadcrumbItems);
    })

    const re = /^[A-Za-z\s]+$/;
    const [rightsName, setRightsName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [userStatus, setUserStatus] = useState(true);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [childId, setChildId] = useState('');

    const userRights = useSelector((state) => state.Profile.userRights);


    const handleName = (e) => {
        const { value } = e.target;
        if (value === "" || re.test(value)) {
            setRightsName(value);
        }
        // setRightsName(e.target.value);
    }

    const handleDisplayName = (e) => {
        const { value } = e.target;
        if (value === "" || re.test(value)) {
            setDisplayName(value);
        }
        // setDisplayName(e.target.value);
    }

    // const handleStatus = (e) => {
    //     setUserStatus(e.target.value);
    // }

    // const value = { rightsName, displayName, userStatus };
    const handleSubmit = async () => {

        try {
            const res = await createRightsAPI(rightsName, displayName, userStatus);
            localStorage.setItem('createRights', res.status);
            handleClean();
            if (res.status === '1' || res.status === 1) {
                Swal.fire({
                    title: res.message,
                    icon: "success",

                })
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",

                })
            }
        }
        catch (error) {
            throw (error)
        }
    };
    const handleClean = () => {
        handleChild();
        handleClear();
    }
    const handleClear = () => {
        setRightsName('')
        setDisplayName('')
        setIsUpdate(false);
    };
    const handleChild = () => {
        setTimeout(() => {
            setIsRefresh(!isRefresh);
        }, 100);
    }

    const handleUpdate = async () => {
        try {
            if (rightsName.length > 0 && displayName.length > 0) {
                const res = await updateRightsAPI(childId, rightsName, displayName, userStatus);
                res.status === '1' ? setIsUpdate(false) : setIsUpdate(true)
                handleClean();
                if (res.status === '1' || res.status === 1) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                    })
                } else {
                    Swal.fire({
                        title: res.message,
                        icon: "error",
                    })
                }
            }
            else {
                return false;
            }
            // localStorage.setItem('createRole', res.status);
        }
        catch (error) {
            throw (error)
        }
    };
    const handleUpdateChild = (data) => {
        setChildId(data.id);
        setIsUpdate(data.isUpdateChild);
    };

    const handleChildClick = (name,displayName) => {
        setRightsName(name)
        setDisplayName(displayName)
      };
    return (
        <>
            <div className="mt-3">
                <div>
                    <Card>
                        <CardBody>
                            <Row>
                                {userRights.user_right?.write && <Col xl={3}>
                                    <Card style={{ boxShadow: 'none' }}>
                                        <CardBody >
                                            <CardTitle><b>UserRight Details</b> </CardTitle>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                            >
                                                <div>
                                                    <Label htmlFor="example-text-input" className=" col-form-label" >
                                                        Right Name*
                                                    </Label>
                                                    <div>
                                                        <input className="form-control" type="text" name="name" placeholder="Enter Right Name" id="userName" value={rightsName} onChange={handleName} required />
                                                    </div>

                                                </div>
                                                <div>
                                                    <Label htmlFor="example-text-input" className=" col-form-label" >
                                                        Display Name*
                                                    </Label>
                                                    <div>
                                                        <input className="form-control" type="text" name="displayName" placeholder="Enter Display Name" id="displayName" value={displayName} onChange={handleDisplayName} required />
                                                    </div>

                                                </div>
                                                <div className="mt-3">
                                                    <button className="role-save custom-btn" onClick={isUpdate ? handleUpdate : handleSubmit}>{!isUpdate ? "Save" : "Update"}</button>
                                                    <button className="role-clear custom-btn" onClick={handleClear}>Clear</button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>}
                                {/* <Col xl={9}>
                                    <Card style={{ boxShadow: 'none' }}>
                                        <CardBody>
                                            <CardTitle><b>User Rights Lists</b></CardTitle>
                                            <div>
                                                <button className="role-delete custom-btn">Delete</button>
                                              
                                            </div>
                                            <div className="table-responsive mt-3">
                                                <Table responsive className="table mb-0 table-hover">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="row">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="customSwitchsizelg"
                                                                />
                                                            </th>
                                                            <th>Action</th>
                                                            <th>User Role</th>
                                                            <th>Last Modified</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-hover">
                                                        {Rights_data.map((item, i) => 
                                                            <tr key={i}>
                                                                <th scope="row">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                    />
                                                                </th>
                                                                <td className="d-flex gap-2">
                                                                    <div> <i className="fas fa-file-signature"></i></div>
                                                                    <div> <i className="fas fa-grip-lines-vertical"></i></div>
                                                                    <div> <i className="fas fa-trash"></i></div>
                                                                </td>
                                                                <td>{item.rightname}</td>
                                                                <td>{item.date}</td>

                                                            </tr>
                                                        )}


                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col> */}
                                <UserRights refresh={isRefresh} dataRecived={handleUpdateChild} onChildClick={handleChildClick} />
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    )
}

// export default Rights
export default connect(null, { setBreadcrumbItems })(Rights);
