import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Input } from "reactstrap"
// import logo from '../../assets/images/logo-dark.png'
import logo from "../../assets/images/logo-sm.png"
import html2pdf from "html2pdf.js/dist/html2pdf.min"
import moment from "moment";
import * as ReactDOMServer from 'react-dom/server';
import { convert } from 'currency-in-words';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions"
import {
  getLockerOccupiedList,
  getOccupiedLocker,
  getOccupiedLockerByIdAPI,
} from "service/service"
import FeatherIcon from "feather-icons-react"
import { gst } from "api/apiClient"

const LockerList = props => {
  const [locker, setLocker] = useState([])
  const [isRefresh, setIsRefresh] = useState(false)
  // const userRights = useSelector(state => state.Profile.userRights)
  const [page, pagechange] = useState(0)
  const [rowperpage, rowperpagechange] = useState(10)
  const history = useNavigate()

  document.title = "Assigned Locker List | Shreesh - Locker Management System"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Assigned Locker List", link: "#" },
  ]
  useEffect(() => {
    props.setBreadcrumbItems("Assigned Locker List", breadcrumbItems)
  })

  const handlechangepage = (event, newpage) => {
    pagechange(newpage)
  }
  const handleRowsPerPage = event => {
    rowperpagechange(+event.target.value)
    pagechange(0)
  }

  const handleDisplayCustomerOccupiedList = async () => {
    try {
      const res = await getLockerOccupiedList()
      setLocker(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCustomerView = async i => {
    try {
      history(`/viewAssignedLocker/${i}`)
    } catch (error) {
      console.log(error);
    }
  }

  // const [receiptLocker, setReceiptLocker] = useState("");

  const handleGeneratePdf = async (i) => {
    const response = await getOccupiedLockerByIdAPI(i);
    setIsRefresh(!isRefresh)
    // setReceiptLocker(response.data.locker);
    console.log(response.data, response.data.locker);
    await downloadPdf(response.data, response.data.locker);
  }

  function generateReceiptNum() {
    var date = Date.now();
    // let date = (dd + Math.random()).substring(1, 4)
    let date22 = Math.floor(Math.random() * date)
    let dd = date22.toString()
    // console.log(dd)
    // console.log(dd.substr(1, 4));
    let dateFormate = dd.substr(1, 3)

    return { dateFormate }
  }

  const downloadPdf = async (data, lockerData) => {
    let { dateFormate } = generateReceiptNum()
    const receiptData = data;
    const receiptLocker = lockerData;

    // console.log("mobile ", customerDetails.mobile);
    let mst = receiptData?.mobile.toString()
    // console.log("mobile mst ", mst);
    let msr = mst.substr(3, 3)

    // const rateGst = ((receiptLocker.lockerRate * gst) / 100);
    const rateGst = (receiptLocker.rentPrice + receiptLocker.rentPrice * gst / 100);
    const totalAmount = receiptLocker.entryFees + receiptLocker.lockerDeposit + rateGst + receiptLocker.penalty + receiptLocker.breakCharge + receiptLocker.additionalLockCharge;
    // console.log("entryFees = ", receiptLocker.entryFees);
    // console.log("lockerDeposit = ", receiptLocker.lockerDeposit);
    // console.log("rateGst = ", rateGst);
    // console.log("penalty = ", receiptLocker.penalty);
    // console.log("breakCharge = ", receiptLocker.breakCharge);
    // console.log("additionalLockCharge = ", receiptLocker.additionalLockCharge);
    // console.log("totalAmount = ", totalAmount);
    const entrygst = receiptLocker?.entryFees / 1.18 * gst / 100
    const rentgst = receiptLocker?.rentPrice * gst / 100
    const addlockgst = receiptLocker?.additionalLockCharge / 1.18 * gst / 100
    const breakgst = receiptLocker?.breakCharge / 1.18 * gst / 100
    const penaltygst = receiptLocker?.penalty / 1.18 * gst / 100
    // const totalGST = ((receiptLocker.entryFees * gst) / 100) + rateGst
    const totalGST = entrygst + rentgst + addlockgst + breakgst + penaltygst
    // const totalNetAmount = totalAmount + totalGST

    const printElement = (<div id="htmlElementId">
      <div className="container pt-5" style={{ padding: "40px" }}>
        <div style={{ border: "4px double black" }}>
          <div className="m-0" style={{ borderBottom: "1px solid black" }}>
            <div className="row align-items-center">
              <div className="col-2 d-flex justify-content-center">
                <img
                  src={logo}
                  alt=""
                  style={{ height: "4rem", paddingLeft: "5px" }}
                />
              </div>
              <div className="col-10 py-2 text-center">
                <span
                  className="fw-bold p-0 m-0"
                  style={{ fontSize: "20px" }}
                >
                  SHREESH SECURITIES
                </span>{" "}
                <br />
                <span style={{ fontSize: "9px", marginTop: "-20px" }}>
                  Managed By : Shreesh Design Studio
                </span>
                <br />
                <span className="text-center ">
                  "Shreesh" 4th - 5th Floor, Dr.Dastur Marg, Off Dr.Yagnik
                  Road, B/h D.H. College,
                  <br /> Rajkot - 360001, Gujrat, INDIA
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="table-responsive">
              <table className="table receipt-table">
                <thead>
                  <tr>
                    <td colSpan="4">
                      <div className="">
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Name : </span>
                          <span id="name" value={receiptData?.fullName}>{receiptData.fullName}</span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Mobile : </span>
                          <span>{receiptData?.mobile}</span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Locker No : </span>
                          <span>{receiptLocker?.customerLockerNo}</span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Plan Start Date : </span>
                          {/* <p>{receiptLocker.planStartDate}</p> */}
                          <span>
                            {moment(receiptLocker?.planStartDate).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Plan End Date : </span>
                          {/* <p>{receiptLocker.planEndDate}</p> */}
                          <span>
                            {moment(receiptLocker?.planEndDate?.split('T')[0]).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td colSpan="2" className="align-top">
                      <div>
                        <div className="d-flex gap-2 lh-1">
                          <p className="fw-bold">Date :</p>
                          {/* <p>{currentDate}</p> */}
                          <p>{moment().format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="d-flex flex-wrap">
                          <p className="fw-bold">Receipt No : </p>
                          {/* <p>ASS_{receiptData?._id.substr(receiptData?._id.length - 4)}</p> */}
                          <p>ASS_{receiptLocker?.customerLockerNo}{msr}{dateFormate}</p>
                        </div>

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Title</th>
                    <th>HSN Code</th>
                    <th>Amount</th>
                    <th>GST (18%)</th>
                    <th>Net Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    receiptLocker?.entryFees === 0 ? "" :
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Entry Fees</td>
                        <td>997212</td>
                        <td>{receiptLocker?.entryFees / 1.18}</td>
                        {/* <td>{(receiptLocker?.entryFees * gst) / 100}</td> */}
                        <td>{receiptLocker?.entryFees / 1.18 * gst / 100}</td>
                        <td>{receiptLocker?.entryFees}</td>
                      </tr>
                  }
                  {
                    receiptLocker.lockerDeposit === 0 ? "" :
                      <tr className="receipt-border-none" >
                        <td>{srno++}</td>
                        <td>Locker Deposit (Refundable)</td>
                        <td>-</td>
                        <td>{receiptLocker?.lockerDeposit}</td>
                        <td>-</td>
                        <td>{receiptLocker?.lockerDeposit}</td>
                      </tr>
                  }
                  {
                    receiptLocker?.rentPrice === 0 ? "" :
                      <tr className="receipt-border-none" >
                        <td>{srno++}</td>
                        <td>Rate</td>
                        <td>997212</td>
                        <td>{receiptLocker?.rentPrice}</td>
                        {/* <td>{rateGst} */}
                        {/* {((receiptLocker.lockerRate * receiptLocker.rentDurationDays * gst) / 100)} */}
                        {/* </td> */}
                        <td>{receiptLocker?.rentPrice * gst / 100}</td>
                        <td>{receiptLocker?.rentPrice + (receiptLocker?.rentPrice * gst / 100)}</td>
                      </tr>
                  }
                  {
                    receiptLocker?.penalty === 0 ? "" :
                      <tr className="receipt-border-none" >
                        <td>{srno++}</td>
                        <td>Late Fees</td>
                        <td>997212</td>
                        <td>{receiptLocker?.penalty / 1.18}</td>
                        <td>{receiptLocker?.penalty / 1.18 * gst / 100}</td>
                        <td>{receiptLocker?.penalty}</td>
                      </tr>
                  }
                  {
                    receiptLocker?.additionalLockCharge === 0 ? "" :
                      <tr className="receipt-border-none" >
                        <td>{srno++}</td>
                        <td>Additional Lock Charge</td>
                        <td>997212</td>
                        <td>{receiptLocker?.additionalLockCharge / 1.18}</td>
                        <td>{receiptLocker?.additionalLockCharge / 1.18 * gst / 100}</td>
                        <td>{receiptLocker?.additionalLockCharge}</td>
                      </tr>
                  }
                  {
                    receiptLocker?.breakCharge === 0 ? "" :
                      <tr className="receipt-border-none" >
                        <td>{srno++}</td>
                        <td>Break Charge</td>
                        <td>997212</td>
                        <td>{receiptLocker?.breakCharge / 1.18}</td>
                        <td>{receiptLocker?.breakCharge / 1.18 * gst / 100}</td>
                        <td>{receiptLocker?.breakCharge}</td>
                      </tr>
                  }
                  <tr>
                    <td colSpan={4}>
                      <div className="row">
                        <div className="col-8">
                          <span className="fw-bold">GSTIN No. :</span>{" "}
                          24AEXFS7939H1Z5
                        </div>
                        <div className="col-4 text-end">
                          {/* <span className="fw-bold">Total&nbsp;:&nbsp; </span>{totalAmount} */}
                          <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }

                        </div>
                      </div>
                    </td>
                    <td>{totalGST}</td>
                    {/* <td>{totalNetAmount} </td> */}
                    <td>{totalAmount} </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <div className="col-12">
                        <span className="fw-bold">Bank Name :</span> SHREE DHARTI CO-OP. BANK LTD.
                      </div>
                      <div className="row">

                        <div className="col-7">
                          <span className="fw-bold">Bank A/c. No :</span>{" "}
                          442005101000462
                        </div>
                        <div className="col-5">
                          <span className="fw-bold">IFSC Code :</span>{" "}
                          IBKL01642SD
                        </div>
                      </div>

                    </td>
                    <td colSpan={2}></td>
                  </tr>
                  <tr>
                    <th
                      colSpan="4"
                      className="text-capitalize"
                    >
                      Total GST : {convert(`${parseInt(totalGST)}`)}{" "}
                      <br />
                      {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                      Bill Amount : {convert(`${parseInt(totalAmount)}`)}{" "}
                    </th>
                    <th
                      colSpan="2"
                      className=""
                    >
                      {/* Grand Total : {parseInt(totalNetAmount)} */}
                      Grand Total : {parseInt(totalAmount)}

                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-8 ">
              <div className="ps-2">
                <h6>Term & Condition</h6>
                <ul style={{ fontSize: '10px' }}>
                  <li className="fst-italic">
                    "Break open change RS. 11000/- + GST change extra."
                  </li>
                  <li className="fst-italic">
                    "Late fees per month Rs.250/- + GST change extra from next month."
                  </li>
                  <li className="fst-italic">
                    "Additional lock available on request with charge of Rs.4000/- + GST charge extra."
                  </li>
                  <li className="fst-italic">
                    "Cheque deposits in A/C are subject to clearing."
                  </li>
                  <li className="fst-italic">
                    "Your given deposit is valid for 9 years."
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-4 position-relative">
              <h6>For, Shreesh Securities </h6>
              <p className="fst-italic mt-4 position-absolute bottom-0 ">(Authorised Signatory) </p>
            </div>
          </div>
        </div>
      </div>
    </div>)
    // console.log(printElement);
    html2pdf().from(ReactDOMServer.renderToStaticMarkup(printElement)).set({ filename: `${receiptData?.fullName.split(' ').join('_')}_Assigned_Locker.pdf` }).save();
  }


  let srno = 1;

  const handleSearch = async e => {
    let value = e.target.value
    const res = await getOccupiedLocker(value)
    setLocker(res.data)
  }

  useEffect(() => {
    handleDisplayCustomerOccupiedList();
  }, [isRefresh, rowperpage])

  return (
    <>
      <div
        className="input-group"
        style={{
          width: "30%",
          position: "relative",
          right: 0,
          marginRight: 0,
          marginLeft: "auto",
        }}
      >
        <span
          className="fa fa-search mb-3 input-group-text"
          id="basic-addon1"
          style={{ height: "auto" }}
        ></span>
        <Input
          type="search"
          className="mb-3 d-flex form-control"
          onChange={handleSearch}
        />
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Customer Name
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Locker Name
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Locker No
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Plan Name
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Assign Date
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Expiry Date
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Locker Status
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Receipt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locker &&
                  locker
                    .slice(page * rowperpage, page * rowperpage + rowperpage)
                    // .slice()
                    // .reverse()
                    .map((row, index) => (
                      <TableRow key={index} className="tablerow">
                        <TableCell onClick={() => handleCustomerView(row.customerLockerId)}>{row.customerName}</TableCell>
                        <TableCell onClick={() => handleCustomerView(row.customerLockerId)}>{row.lockerName}</TableCell>
                        <TableCell onClick={() => handleCustomerView(row.customerLockerId)}>{row.lockerNo}</TableCell>
                        <TableCell onClick={() => handleCustomerView(row.customerLockerId)}>{row.planName}</TableCell>
                        <TableCell onClick={() => handleCustomerView(row.customerLockerId)}>{row.occupiedDate}</TableCell>
                        <TableCell onClick={() => handleCustomerView(row.customerLockerId)}>{row.expiringDate}</TableCell>
                        <TableCell onClick={() => handleCustomerView(row.customerLockerId)}>{row.lockerStatus}</TableCell>
                        <TableCell>
                          <Tooltip title="Download">
                            {/* <IconButton onClick={convertToPdf}> */}
                            <IconButton
                              onClick={() => handleGeneratePdf(row.customerLockerId)}
                            >
                              <FeatherIcon icon="download" width="18" />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="View">
                            <IconButton
                              onClick={() => handleCustomerView(row.customerLockerId)}
                            >
                              <FeatherIcon icon="eye" width="18" />
                            </IconButton>
                          </Tooltip> */}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              rowsPerPage={rowperpage}
              page={page}
              count={locker.length}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            ></TablePagination>
          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}
export default connect(null, { setBreadcrumbItems })(LockerList)
