import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, Input } from "reactstrap"

import { activeRentAPI, deactiveRentAPI, getRentCountAPI, getRentList, searchRent } from "service/service";
import Swal from "sweetalert2"
import { useNavigate } from 'react-router-dom';
// import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'


const Rentlist = (props) => {

  const history = useNavigate();
  const [rent, setRent] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  // const userRights = useSelector((state) => state.Profile.userRights);
  const [count, setRentCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);

  // const handleSelectChange = e => {
  //   const value = parseInt(e.target.value) || 1;
  //   setPerPage(value)
  //   setPageNo(1);
  // }

  const handlechangepage = (event, newpage) => {
    // console.log("newpage", newpage);
    setPageNo(newpage + 1);
    // setCurrentPage(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    setPerPage(+event.target.value)
    setPageNo(1);
  }

  document.title = "Rent List | Shreesh Securities";
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Rent", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Rent', breadcrumbItems);
  })

  const handleDisplayrentList = async () => {
    try {
      const res = await getRentList(pageNo, perPage);
      setRent(res.data);

    }
    catch (error) {
      console.log(error);
    }
  };

  const getRentCount = async () => {
    try {
      const res = await getRentCountAPI();
      // console.log("res", res);
      if (res.status === '1' || res.status === 1) {
        setRentCount(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getCounts() {
      await getRentCount();
    }
    getCounts();
  }, [])

  const handleDeactiveRent = async (i) => {
    try {
      const res = await deactiveRentAPI(i);
      setIsRefresh(!isRefresh);

      if (res.status === '1' || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (index) => {
    try {
      history(`/updateRent/${index}`)
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleActiveRent = async (i) => {
    try {
      // const activeid = rent[i].id;
      const res = await activeRentAPI(i);
      setIsRefresh(!isRefresh);
      if (res.status === '1' || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",

        })
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleUpdateRent = async (i) => {
    try {
      history(`/viewRent/${i}`)
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (e) => {
    let value = e.target.value;
    const res = await searchRent(value);
    setRent(res.data);
  };

  useEffect(() => {
    handleDisplayrentList();
    // eslint-disable-next-line
  }, [isRefresh, perPage, pageNo])

  return (<>
    <div className="d-flex justify-content-end position-relative">
      <div className="input-group w-25 searchBox">
        <span className="fa fa-search mb-3 input-group-text" id="basic-addon1" style={{ height: 'auto' }}></span>
        <Input type="search" className="mb-3 d-flex form-control" onChange={handleSearch} />
      </div>
    </div>


    <Card>
      <CardBody>
        <TableContainer>
          <Table>
            <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
              <TableRow>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Plan Name</TableCell>
                {/* <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Description</TableCell> */}
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Rent Price</TableCell>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Minimum Charge</TableCell>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Duration In Days</TableCell>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rent && rent.map((row) => (

                <TableRow key={row._id} className="tablerow" >
                  <TableCell onClick={() => handleUpdateRent(row._id)}>{row.name}</TableCell>
                  {/* <TableCell onClick={() => handleUpdateRent(row._id)}>{row.description}</TableCell> */}
                  <TableCell onClick={() => handleUpdateRent(row._id)}>{row.rentPrice}</TableCell>
                  <TableCell onClick={() => handleUpdateRent(row._id)}>{row.minimumCharge}</TableCell>
                  <TableCell onClick={() => handleUpdateRent(row._id)}>{row.minimumDurationDay}</TableCell>
                  <TableCell >
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div
                        className="edit-btn"
                        onClick={() => handleEdit(row._id)}
                      >
                        Edit
                      </div>
                      {
                        row.status ?
                          <div
                            className="active-btn"
                            onClick={() => handleDeactiveRent(row._id)}
                          >
                            Active
                          </div>
                          :
                          <div
                            className="deactive-btn"
                            onClick={() => handleActiveRent(row._id)}
                          >
                            Inactive
                          </div>
                      }
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 25]}
            rowsPerPage={perPage}
            page={pageNo - 1}
            count={count}
            component="div"
            onPageChange={handlechangepage}
            onRowsPerPageChange={handleRowsPerPage}
          >
          </TablePagination>


        </TableContainer>
      </CardBody>
    </Card>
  </>
  )
}

export default connect(null, { setBreadcrumbItems })(Rentlist);