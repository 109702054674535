import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  addCustomerAddressAPI,
  addCustomerKycAPI,
  deleteCustomerKycAPI,
  getCustomerAddressAPI,
  getCustomerByIdAPI,
  getCustomerKycAPI,
  removeCustomerImg,
  updateCustomerAddressAPI,
  updateCustomerKycAPI,
  uploadCustomerImg,
} from "service/service"
import {
  addCustomerAddress,
  addCustomerKyc,
  removeCustomerImgImage,
} from "store/actions"
import Swal from "sweetalert2"
import user1 from "../../assets/images/users/user-1.jpg"
import dummy from "../../assets/images/users/dummy.jpg"
import ImageViewer from "ImageViewer"
import Webcam from "react-webcam"

// const FACING_MODE_USER = "user";

// const videoConstraints = {
//   width: 120,
//   height: 100,
//   // facingMode: "user",
//   // facingMode: 'user'
//   facingMode: { exact: "user" }

// }

// const videoConstraints = {
//   facingMode: FACING_MODE_USER
// };

const CustomerAddress = ({ onButtonClick }) => {
  const dispatch = useDispatch()

  const [customerId, setcustomerId] = useState("")
  const [addressType, setAddressType] = useState("1")
  const [fullAddress, setFullAddress] = useState("")
  const [filePath, setFilePath] = useState("")
  const [photo, setPhoto] = useState("")
  const [oldKyc, setOldKyc] = useState("")
  const [isDeleted, setIsDeleted] = useState(0)
  const [documentType, setdocumentType] = useState("")
  const [docDetail, setdocDetail] = useState("")
  const [kycId, setKycId] = useState("")
  const [isKyc, setIsKyc] = useState(false)
  const [isAddress, setIsAddress] = useState(false)
  const [isNext, setIsNext] = useState(false);

  let isDisplay = JSON.parse(localStorage.getItem("customerS2"))

  let addressId = localStorage.getItem("customerAddressId");

  const handleFullAddress = e => {
    handleSetFild()
    setFullAddress(e.target.value)
  }

  const handleAddressType = e => {
    handleSetFild()
    setAddressType(e.target.value)
  }

  const value = {
    addressType,
    fullAddress,
  }

  const handleSetFild = () => {
    const address = document.getElementById('fullAddress').value;
    let data = JSON.parse(localStorage.getItem("customerAddress"))
    let image = localStorage.getItem("Customer_Image")
    if ((address.length > 0 || data?.fullAddress.length > 0) && image) {
      setIsNext(true)
    }
    else {
      setIsNext(false)
    }
    setAddressType(data?.addressType)
    setFullAddress(data?.fullAddress)
    setPhoto(image)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    let oldAdd = localStorage.getItem("customerAddress")
    let addressId = localStorage.getItem("customerAddressId")
    if (oldAdd && oldAdd !== "undefined" && addressId !== "undefined") {
      try {
        const res = await getCustomerByIdAPI(customerId)
        let data = res.data
        const resAdd = await updateCustomerAddressAPI(
          // JSON.parse(addressId),
          addressId,
          addressType,
          data.fullName,
          data.mobile,
          fullAddress
        )

        if (resAdd.status === "1" || resAdd.status === 1) {
          // setAddress(resAdd.status)
          setIsAddress(true)
          dispatch(addCustomerAddress(resAdd.data))
          Swal.fire({
            title: resAdd.message,
            icon: "success",
            timer: 1500
          })
          localStorage.setItem("customerAddress", JSON.stringify(value))
        } else {
          Swal.fire({
            title: resAdd.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await getCustomerByIdAPI(customerId)
        let data = res.data
        const resAdd = await addCustomerAddressAPI(
          customerId,
          addressType,
          data.fullName,
          data.mobile,
          fullAddress
        )

        if (resAdd.status === "1" || resAdd.status === 1) {
          // setAddress(resAdd.status)
          setIsAddress(true)
          dispatch(addCustomerAddress(resAdd.data))
          Swal.fire({
            title: resAdd.message,
            icon: "success",
            timer: 1500
          })
          localStorage.setItem("customerAddress", JSON.stringify(value))
          localStorage.setItem("customerAddressId", JSON.stringify(res.data.id))
          localStorage.setItem("customerS2", true)
        } else {
          Swal.fire({
            title: resAdd.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const uploadCustomerImage = async e => {
    e.preventDefault()
    handleSetFild()
    setFilePath(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    const cid = customerId
    const response = await uploadCustomerImg(cid, file)
    if (response.status === 1 || response.status === "1") {
      setPhoto(response.data.photo)
      localStorage.setItem("Customer_Image", response.data.photo)
      Swal.fire({
        title: response.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: response.message,
        icon: "error",
      })
    }
  }

  const handledocDetail = e => {
    setdocDetail(e.target.value)
  }

  const handleSelect = e => {
    const value = e.target.value
    setdocumentType(value)
  }

  const [image, setImage] = useState("")
  const [imageView, setImageView] = useState("")

  const handleImage = (e) => {
    e.preventDefault()
    setImage(e.target.files[0])
    setImageView(URL.createObjectURL(e.target.files[0]))
    console.log(image);
  }

  const handleSubmitKyc = async (e, i) => {
    e.preventDefault()
    if (kycId) {
      try {
        const res = await updateCustomerKycAPI(
          kycId,
          image,
          documentType,
          docDetail
        )

        handleGetKyc(localStorage.getItem("customerID"))
        handleClear()
        if (res.status === "1" || res.status === 1) {
          setIsKyc(true)
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) { }
    } else {
      const res = await addCustomerKycAPI(
        customerId,
        image,
        documentType,
        docDetail
      )
      dispatch(addCustomerKyc(customerId, image, documentType, docDetail))
      handleGetKyc(localStorage.getItem("customerID"))
      handleClear()
      if (res.status === "1" || res.status === 1) {
        setIsKyc(true)
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
        localStorage.setItem("customerS2", true)
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    }
  }

  const handleClear = () => {
    document.getElementById('kycImage').value = null
    setdocumentType("")
    setdocDetail("")
    setImage(null)
    setImageView("")
  }

  const handleNext = e => {
    e.preventDefault()

    // if ((kyc === 1 || kyc === "1") && (address === 1 || address === "1")) {
    let isSub = localStorage.getItem("customerS3")

    onButtonClick(isSub === "0" ? "CustomerNominee" : "CustomerSubholder")
    // onButtonClick("CustomerSubholder")
    // }
  }

  const removeImage = async e => {

    // const customerId = customerId
    const imagePath = photo
    const response = await removeCustomerImg(customerId, imagePath)
    dispatch(removeCustomerImgImage(customerId, imagePath))
    if (response.status === "1") {
      localStorage.removeItem("Customer_Image")
      Swal.fire({
        title: response.message,
        icon: "success",
        timer: 1500
      })
      setPhoto("");
      setFilePath("")
    } else {
      Swal.fire({
        title: response.message,
        icon: "error",
      })
    }
  }

  const handleGetAddress = async id => {
    await getCustomerAddressAPI(id)
    // setOldAddress(res?.data)
  }

  const handleGetKyc = async id => {
    const res = await getCustomerKycAPI(id)
    setOldKyc(res?.data)
    handleSetFild()
    res?.data.length <= 0 && setIsNext(false)
  }

  const handleDeleteOldKyc = async i => {
    const res = await deleteCustomerKycAPI(oldKyc[i].id)
    localStorage.removeItem("customerAddress")
    res.status === "1" && handleGetKyc(localStorage.getItem("customerID"))
    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
    setIsDeleted(2)
  }

  const [status, setStatus] = useState(1) // 0: no show, 1: show yes, 2: show no.

  const radioHandler = (status) => {
    setStatus(status);
  };

  const [camImage, setCamImage] = useState("")
  const webcamRef = useRef(null)

  // const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    // const imageSrc = window.URL.createObjectURL(new Blob(webcamRef.current.getScreenshot()))

    setCamImage(imageSrc)
    // var blob = dataURItoBlob(imageSrc)
    // setFacingMode(FACING_MODE_USER);
    // console.log("blob", blob);
  }, [webcamRef, setCamImage])

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }


  const uploadCustCameraImage = async e => {
    e.preventDefault()
    const file = dataURItoBlob(camImage)
    const cid = customerId

    console.log("cam - ", camImage);
    // console.log("cam - ", customerId);
    const response = await uploadCustomerImg(cid, file)
    if (response.status === 1 || response.status === "1") {
      setPhoto(response.data.photo)
      setCamImage("")
      setStatus(1)
      localStorage.setItem("Customer_Image", response.data.photo)
      Swal.fire({
        title: response.message,
        icon: "success",
      })
    } else {
      Swal.fire({
        title: response.message,
        icon: "error",
      })
    }
  }


  useEffect(() => {
    setcustomerId(localStorage.getItem("customerID"))
    handleGetAddress(localStorage.getItem("customerID"))
    handleGetKyc(localStorage.getItem("customerID"))
  }, [isDeleted])

  useEffect(() => {
    handleSetFild()
  }, [isDisplay])

  useEffect(() => {
    handleSetFild()
  }, [])

  return (
    <div>
      <div className="d-flex justify-content-center">
        <Row className="mt-5 w-100" style={{ maxWidth: "50rem" }}>
          <Col>
            <Card>
              <CardBody>
                <Form>
                  <CardTitle className="text-center fs-4">
                    <b>Primary Customer Address and Photo</b>{" "}
                  </CardTitle>
                  <Row>
                    <Col lg={6}>
                      <div className="py-1">
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Address*
                        </Label>
                        <div>
                          <textarea
                            className="form-control"
                            type="text"
                            rows={4}
                            placeholder="Enter Address"
                            name="fullAddress"
                            id="fullAddress"
                            value={fullAddress}
                            required
                            onChange={handleFullAddress}
                          />
                        </div>
                      </div>
                      <div className="py-1">
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Address-Type
                        </Label>
                        <div className="mx-2">
                          <div
                            className="active"
                            value={addressType}
                            id="addressType"
                          >
                            <Input
                              type="radio"
                              name="addresstype"
                              value="1"
                              defaultChecked={addressType === 1 || addressType === "1"}
                              onChange={handleAddressType}
                              style={{ transform: "scale(1.3)" }}
                            />
                            &nbsp;Home&nbsp;&nbsp;
                            <Input
                              type="radio"
                              name="addresstype"
                              value="2"
                              defaultChecked={addressType === 2 || addressType === "2"}
                              onChange={handleAddressType}
                              style={{ transform: "scale(1.3)" }}
                            />
                            &nbsp;Office
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="py-1">
                        <div className="d-flex">
                          <div>
                            <input type="radio" name="release" checked={status === 1} onClick={(e) => radioHandler(1)} style={{ margin: "8px 6px" }} /><span>Photo</span>
                          </div>
                          <div style={{ marginLeft: "5rem" }}>
                            <input type="radio" name="release" checked={status === 2} onClick={(e) => radioHandler(2)} style={{ margin: "8px 6px" }} /><span>Camera</span>
                          </div>
                        </div>
                        {status === 1 && <div className="py-1">
                          {/* <Label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Photo
                          </Label> */}
                          <div className="py-1">
                            {!photo ? (
                              <div>
                                <Input
                                  type="file"
                                  name="doc"
                                  id="doc"
                                  required
                                  onChange={uploadCustomerImage}
                                />
                                <img
                                  src={filePath ? filePath : user1}
                                  alt="Customer Profile"
                                  style={{ width: "8rem", height: "7rem" }}
                                />
                              </div>
                            ) : (
                              <div className="mt-1">
                                <ImageViewer
                                  path={photo}
                                  style={{ width: "8rem", height: "7rem" }}
                                />
                                <i
                                  className="fas fa-trash-alt"
                                  style={{
                                    position: "relative",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "red",
                                    fontSize: "larger"
                                  }}
                                  onClick={removeImage}
                                ></i>
                              </div>
                            )}
                          </div>
                        </div>}
                        {status === 2 && <div>
                          <div className="webcam-container py-1" style={{ textAlign: "center" }}>
                            <div className="webcam-img">
                              {camImage == "" ? (
                                <Webcam
                                  audio={false}
                                  // height={200}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  // width={220}
                                  width={120}
                                  height={100}
                                // videoConstraints={videoConstraints}
                                />
                              ) : (
                                <img src={camImage} alt="" />
                              )}
                            </div>
                            <div style={{ marginTop: "1rem" }}>
                              {camImage != "" ? (
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    setCamImage("")
                                  }}
                                  className="webcam-btn"
                                >
                                  Retake Image
                                </button>
                              ) : (
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    capture()
                                  }}
                                  className="webcam-btn"
                                >
                                  Capture
                                </button>
                              )}
                            </div>
                          </div>
                        </div>}
                      </div>
                      {/* <div className="py-1">
                        <Label
                          htmlFor="example-text-input"
                          className="col-form-label"
                        >
                          Photo
                        </Label>
                        <div>
                          {!photo ? (
                            <div>
                              <Input
                                type="file"
                                name="doc"
                                id="doc"
                                required
                                onChange={uploadCustomerImage}
                              />
                              <img
                                src={filePath ? filePath : user1}
                                alt="Custome Profile"
                                style={{ width: "8rem", height: "7rem" }}
                              />
                            </div>
                          ) : (
                            <div className="mt-1">
                              <ImageViewer
                                path={photo}
                                style={{ width: "8rem", height: "7rem" }}
                              />
                              <i
                                className="fas fa-trash-alt"
                                style={{
                                  position: "relative",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={removeImage}
                              ></i>
                            </div>
                          )}
                        </div>
                      </div> */}
                    </Col>
                  </Row>

                  <div className="mt-3 d-flex" style={{ justifyContent: "space-between" }}>
                    <button
                      type="submit"
                      className="btn btn-primary w-md "
                      onClick={handleSubmit}
                    >
                      {addressId ? "Update" : "Save"}
                    </button>
                    {status === 2 &&
                      <button
                        type="submit"
                        className="btn btn-primary w-md "
                        onClick={uploadCustCameraImage}
                      >
                        Upload Image
                      </button>
                    }
                  </div>

                  <hr />
                  <CardTitle className="text-center fs-4 mt-4">
                    <b>Primary Customer KYC</b>{" "}
                  </CardTitle>

                  <Row>
                    <Col md={5}>
                      <div>
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Select Document Type*
                        </Label>
                        <div>
                          <select
                            className="form-control"
                            id="DocName"
                            value={documentType}
                            onChange={handleSelect}
                          >
                            <option>-- SELECT --</option>
                            <option value="Aadhar Card">Aadhar Card</option>
                            <option value="Pan card">Pan card</option>
                            <option value="Voter Id Card">Voter Id Card</option>
                            <option value="Driving License">
                              Driving License
                            </option>
                            <option value="Passport">Passport</option>
                            <option value="Physical Form">Physical Form</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div>
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Document Detail*
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="text"
                            id="DocDetail"
                            placeholder="XXXXXXXXXXXX"
                            name="docDetail"
                            value={docDetail}
                            onChange={handledocDetail}
                            required
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Image*
                    </Label>

                    <div>
                      {/* {image ? (<>
                        <ImageViewer
                          path={image}
                          style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}
                        >
                          {" "}
                        </ImageViewer>
                        <i
                          className="fas fa-trash-alt"
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={() => removeKycImage()}
                        ></i>
                      </>) : ( */}

                      <div>
                        <Input
                          type="file"
                          name="doc"
                          required
                          id="kycImage"
                          onChange={handleImage}
                          multiple
                        />
                        <img
                          src={imageView ? imageView : dummy}
                          alt=""
                          style={{ width: "8rem", height: "5rem" }}
                        />
                      </div>
                      {/* )} */}

                    </div>
                  </div>

                  <Row>
                    <Col lg={12}>
                      <div className="py-1 table-responsive mt-3">
                        <table
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Document Type
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Document Number
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          {oldKyc &&
                            oldKyc.length > 0 &&
                            oldKyc.map((data, index) => {
                              return (
                                <tbody key={index}>
                                  <tr >
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      {data.documentType}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {data.docDetail}{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {/* <span>
                                        <i
                                          className="fa fa-edit"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleUpdateOldKyc(index)
                                          }
                                        ></i>
                                      </span> */}
                                      <span>
                                        <i
                                          className="fas fa-trash-alt"
                                          style={{
                                            cursor: "pointer", color: "red",
                                            fontSize: "larger"
                                          }}
                                          onClick={() =>
                                            handleDeleteOldKyc(index)
                                          }
                                        ></i>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}

                        </table>
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-3 d-flex justify-content-between">
                    <div className="">
                      <button
                        type="submit"
                        className="btn btn-primary w-md "
                        onClick={handleSubmitKyc}
                      >
                        {kycId ? "Update" : "Save"}
                      </button>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        className="btn btn-primary w-md "
                        onClick={handleNext}
                        disabled={!isNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CustomerAddress
