import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import {
  addAdditionalLockOnLockerAPI,
  assignLockerActiveAPI,
  assignLockerBreakAPI,
  assignLockerDeactiveAPI,
  assignLockerRemoveAPI,
  closeCustLockerAPI,
  getLockerHistoryAPI,
  getLockerTransactionByIdAPI,
  getOccupiedLocker,
  getOccupiedLockerByIdAPI,
} from "service/service"
import { Link, useNavigate } from "react-router-dom"
import Swal from "sweetalert2";

import { Tab, Tabs, Typography, useMediaQuery } from "@mui/material"
import { gst } from "api/apiClient"
import Select from "react-select"

import moment from 'moment-timezone';
import ImageViewer from "ImageViewer"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(swal);

const OccupiedLockerAction = props => {
  document.title =
    "Assigned Locker Action | Shreesh Securities"

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    // { title: "Assigned Locker List", link: "/assignedLockerList" },
    { title: "Assigned Locker Action", link: "#" },
  ]

  const [customerDetails, setCustomerDetails] = useState([])
  const [lockerDetails, setLockerDetails] = useState([])
  const [holderDetails, setHolderDetails] = useState([])
  const [historyLocker, setLockerHistory] = useState([])
  const [lockerTransaction, setLockerTransaction] = useState([])
  const [calculatedPenalty, setCalculatedPenalty] = useState(0);

  const searchString = useState("")[0];
  const [Lockers, setLockers] = useState([]);
  const [selectedLocker, setSelectedLocker] = useState("");
  const [lockerId, setLockerId] = useState("");
  const smallScreen = useMediaQuery("(max-width: 600px)")
  const [value, setValue] = useState(0);


  const history = useNavigate()

  const handleDisplayOldLockerList = async () => {
    try {
      const res = await getOccupiedLocker()
      // console.log(res.data);
      setLockers(res.data)
    } catch (error) {
      throw error
    }
  }

  const LockerOptions = [
    Lockers &&
    Lockers.map(data => {
      return { value: data.customerLockerId, label: data.lockerNo }
    }),
  ];

  const handleSelectLocker = (e) => {
    setSelectedLocker(e.value)
  }

  const handleDisplaylockerUpdate = async () => {
    try {
      const res = await getOccupiedLockerByIdAPI(selectedLocker)
      setCustomerDetails(res?.data)
      setLockerDetails(res?.data.locker)
      setHolderDetails(res?.data?.locker?.holders)

      const cuslockerdata = res?.data.locker;

      // const startDate = new Date("2024-03-21")
      // const lastDate = new Date("2027-03-21")
      const startDate = new Date(cuslockerdata?.planStartDate)
      const lastDate = new Date(cuslockerdata?.planEndDate)

      // year
      const differenceForYear = lastDate - startDate;
      console.log("differenceForYear = ", differenceForYear);
      const countDays = Math.ceil(differenceForYear / (1000 * 60 * 60 * 24));
      console.log("countDays = ", countDays);

      if (countDays > 900) {
        // const currentDateObject = new Date("2027-04-21");
        const currentDateObject = new Date();
        // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
        const differenceInMilliseconds = currentDateObject - lastDate;
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        console.log("differenceInDays = ", differenceInDays);
        const weeksPastDue = Math.floor(differenceInDays / 7);
        console.log("weeksPastDue = ", weeksPastDue);
        const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
        console.log("moreThanOneMonth = ", moreThanOneMonth);

        let penalty = 0;
        if (moreThanOneMonth) {
          // penalty = (weeksPastDue * 1000);
          // let yearGst = penalty + (penalty * gst) / 100
          // setCalculatedPenalty(yearGst)
          // console.log("Penalty Year = ", yearGst);
          if (cuslockerdata.lockerName === "B") {
            // console.log("innn", cuslockerdata.lockerName);
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "C") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "F") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "L") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "XL") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
        }
      }
      else if (countDays > 600) {
        // const currentDateObject = new Date("2026-04-21");
        const currentDateObject = new Date();
        // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
        const differenceInMilliseconds = currentDateObject - lastDate;
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        console.log("differenceInDays = ", differenceInDays);
        const weeksPastDue = Math.floor(differenceInDays / 7);
        console.log("weeksPastDue = ", weeksPastDue);
        const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
        console.log("moreThanOneMonth = ", moreThanOneMonth);

        let penalty = 0;
        if (moreThanOneMonth) {
          // penalty = (weeksPastDue * 1000);
          // let yearGst = penalty + (penalty * gst) / 100
          // setCalculatedPenalty(yearGst)
          // console.log("Penalty Year = ", yearGst);
          if (cuslockerdata.lockerName === "B") {
            // console.log("innn", cuslockerdata.lockerName);
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "C") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "F") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "L") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "XL") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
        }
      }
      else if (countDays > 300) {
        // const currentDateObject = new Date("2027-04-21");
        const currentDateObject = new Date();
        // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
        const differenceInMilliseconds = currentDateObject - lastDate;
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        console.log("differenceInDays = ", differenceInDays);
        const weeksPastDue = Math.floor(differenceInDays / 7);
        console.log("weeksPastDue = ", weeksPastDue);
        const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
        console.log("moreThanOneMonth = ", moreThanOneMonth);

        let penalty = 0;
        if (moreThanOneMonth) {
          // penalty = (weeksPastDue * 1000);
          // let yearGst = penalty + (penalty * gst) / 100
          // setCalculatedPenalty(yearGst)
          // console.log("Penalty Year = ", yearGst);
          if (cuslockerdata.lockerName === "B") {
            // console.log("innn", cuslockerdata.lockerName);
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "C") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "F") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "L") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
          else if (cuslockerdata.lockerName === "XL") {
            penalty = (weeksPastDue * 250);
            let yearGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(yearGst)
            console.log("Penalty Year = ", yearGst);
          }
        }
      } else {
        setCalculatedPenalty(0)
      }

      // month
      // const currentDateObject = new Date("2024-03-30");
      const currentDateObject = new Date();
      // console.log("currentDateObject = ", currentDateObject.getDate());
      const differenceInMilliseconds = currentDateObject - lastDate;
      console.log("differenceInMilliseconds = ", differenceInMilliseconds);
      const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      console.log("differenceInDays = ", differenceInDays);
      if (differenceInDays >= 0) {
        if (cuslockerdata.lockerName === "B") {
          let penalty = 0
          penalty = (500 * differenceInDays)
          // penalty = (penalty * 100) / 18
          let dayGst = penalty + (penalty * gst) / 100
          setCalculatedPenalty(dayGst)
          console.log("Penalty Days = ", dayGst);
        }
        else if (cuslockerdata.lockerName === "C") {
          let penalty = 0
          penalty = (750 * differenceInDays)
          // penalty = (penalty * 100) / 18
          let dayGst = penalty + (penalty * gst) / 100
          setCalculatedPenalty(dayGst)
          console.log("Penalty Days = ", dayGst);
        }
        else if (cuslockerdata.lockerName === "F") {
          let penalty = 0
          penalty = (1000 * differenceInDays)
          // penalty = (penalty * 100) / 18
          let dayGst = penalty + (penalty * gst) / 100
          setCalculatedPenalty(dayGst)
          console.log("Penalty Days = ", dayGst);
        }
        else if (cuslockerdata.lockerName === "L") {
          let penalty = 0
          penalty = (1500 * differenceInDays)
          // penalty = (penalty * 100) / 18
          let dayGst = penalty + (penalty * gst) / 100
          setCalculatedPenalty(dayGst)
          console.log("Penalty Days = ", dayGst);
        }
        else if (cuslockerdata.lockerName === "XL") {
          let penalty = 0
          penalty = (2500 * differenceInDays)
          // penalty = (penalty * 100) / 18
          let dayGst = penalty + (penalty * gst) / 100
          setCalculatedPenalty(dayGst)
          console.log("Penalty Days = ", dayGst);
        }
      } else {
        setCalculatedPenalty(0)
        console.log("penalty == ", calculatedPenalty);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const getLockerHistory = async () => {
    try {
      const res = await getLockerHistoryAPI(lockerDetails?.customerLockerNo)
      setLockerHistory(res.data)
    } catch (error) {

    }
  }

  const getLockerTransaction = async () => {
    try {
      const res = await getLockerTransactionByIdAPI(lockerDetails?.customerLockerId)
      setLockerTransaction(res.data)
    } catch (error) {

    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Reason",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      const response = await assignLockerDeactiveAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }

  const handleSubmitUnfreeze = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Reason",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      const response = await assignLockerActiveAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }

  }

  const handleSubmitRemove = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Enter Reason for Remove Locker",
      title: `Your Pending Late Fees = ${calculatedPenalty}`,
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      console.log(lockerDetails.customerLockerId,
        reasons,
        calculatedPenalty);
      const response = await assignLockerRemoveAPI(
        lockerDetails.customerLockerId,
        reasons,
        calculatedPenalty
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }

  let changePwSwal = {
    title: 'Break Locker',
    focusConfirm: false,
    html: `
      <label>Your Locker Break Charge: 11000 + GST(18%) = ${11000 + (11000 * gst) / 100}</label>
      <input class="swal2-input" id="breakFee" type="number" value=${11000 + (11000 * gst) / 100} hidden/><br />
      <label>Reason : </label>
      <input class="ps-1" id="reason" type="text" placeholder="Enter reason " /><br />
    `,
    type: 'warning',
    showCancelButton: true,
    // cancelButtonColor: 'grey',
    // confirmButtonText: 'Ok',

    preConfirm: () => ({
      reason: document.getElementById('reason').value,
      breakFee: document.getElementById('breakFee').value,
    })
  };
  const handleSubmitBreak = async e => {
    e.preventDefault()
    const swalval = await MySwal.fire(changePwSwal);
    console.log("swal ", swalval);
    // console.log("swal ", swalval.value.breakFee);
    let v = swalval && swalval.value || swalval.dismiss;
    // console.log(v);
    if (v.reason) {
      console.log(lockerDetails.customerLockerId,
        v.reason,
        v.breakFee);
      const response = await assignLockerBreakAPI(
        lockerDetails.customerLockerId,
        v.reason,
        v.breakFee
      )
      // console.log("break locker");
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    } else if (v === 'cancel') {
    }
    // else {
    // }
  }
  const handleBreakActive = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Reason",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      const response = await assignLockerActiveAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: "Locker Activated Successfully",
          icon: "success",
          timer: 1500
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }

  }

  const handleAdditionalLock = async (e) => {
    e.preventDefault()
    const inputValue = 4000 + 4000 * gst / 100;
    // const penalty = lockerDetails?.penalty;
    const { value: reasons } = await Swal.fire({
      title: "For Additional Lock you have to pay 4000 + GST (18%)",
      inputLabel: `Additional Lock Charge = ${inputValue}`,
      html: `<label>Additional Lock Charge = ${inputValue}</label>`,
      showCancelButton: true,
    })
    if (reasons) {
      // Swal.fire(reasons);
      console.log(lockerDetails.customerLockerId,
        inputValue);
      const response = await addAdditionalLockOnLockerAPI(
        lockerDetails.customerLockerId,
        inputValue
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }

  const handleCloseLocker = async (e) => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Enter Reason for Close Locker",
      title: `Your Pending Late Fees = ${calculatedPenalty}`,
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      console.log(lockerDetails.customerLockerId,
        reasons,
        calculatedPenalty);
      const response = await closeCustLockerAPI(
        lockerDetails.customerLockerId,
        reasons,
        calculatedPenalty
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }

  let startDate = lockerDetails?.planStartDate?.split('T')[0]
  let endDate = lockerDetails?.planEndDate?.split('T')[0]

  useEffect(() => {
    props.setBreadcrumbItems("Assigned Locker Action", breadcrumbItems)
  })

  useEffect(() => {
    handleDisplayOldLockerList()
  }, [])

  useEffect(() => {
    handleDisplaylockerUpdate()
  }, [selectedLocker]);

  return (
    <div>
      <Row>
        <Col>
          <div className="d-flex justify-content-end">
            <div className="goback">
              <Link to="/assignedLockerList" className="">
                Go Back
              </Link>
            </div>
          </div>
          <Card style={{ height: selectedLocker ? "" : "20rem" }}>
            <CardBody>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Select Locker No
                </Label>
                <div>
                  {LockerOptions && LockerOptions.length ?
                    LockerOptions.map((data, i) => (
                      <Select
                        // key={`customer_locker_${i}`}
                        maxMenuHeight={250}
                        isSearchable={true}
                        defaultValue={selectedLocker}
                        onChange={handleSelectLocker}
                        options={data}
                      />
                    )) : ""
                  }
                </div>
              </div>


              {selectedLocker ?
                <div className="mt-4">
                  <Tabs
                    value={value}
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={(event, newValue) => {
                      setValue(newValue)
                    }}
                    style={{ borderBottom: "1px solid gray" }}
                    orientation={smallScreen ? "vertical" : "horizontal"}
                    classes={{
                      flexContainer: "flex-wrap sm:flex-nowrap",
                    }}
                    className="tabs"
                  >
                    <Tab className="top-tab" label="Locker Details" style={{ fontWeight: "600" }} />
                    <Tab className="top-tab" label="Locker Action" style={{ fontWeight: "600" }} />
                  </Tabs>

                  {value === 0 && (
                    <Typography className="tabs-content">
                      <Row>
                        {/* Locker Details */}
                        <Card className="mt-3">
                          <CardBody>
                            <Row className="fw-bold view-table">
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Locker Category</h6>
                                <p>{lockerDetails?.lockerName}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Locker Category</h6>
                                <p>{lockerDetails?.lockerCategory}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Locker No</h6>
                                <p>{lockerDetails?.customerLockerNo}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Locker Key</h6>
                                <p>{lockerDetails?.customerLockerKey}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Locker Size</h6>
                                <p>{lockerDetails?.lockerSize}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Plan Name</h6>
                                <p>{lockerDetails?.rentName}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Plan Duration Days</h6>
                                <p>{lockerDetails?.rentDurationDays}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Plan Start Date</h6>
                                <p>{moment(startDate).format("DD-MM-YYYY")}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Plan End Date</h6>
                                <p>{moment(endDate).format("DD-MM-YYYY")}</p>
                              </Col>

                              {lockerDetails?.referenceBy &&
                                <Col xl={3} lg={4} md={6} sm={12}>
                                  <h6 className="fw-bold">Reference By</h6>
                                  <p>{lockerDetails?.referenceBy}</p>
                                </Col>
                              }

                              {lockerDetails?.operatingMandate &&
                                <Col xl={3} lg={4} md={6} sm={12}>
                                  <h6 className="fw-bold">Operating Mandate</h6>
                                  <p>{lockerDetails?.operatingMandate}</p>
                                </Col>
                              }

                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Locker Status</h6>
                                <p>{lockerDetails?.lockerStatus}</p>
                              </Col>

                              {/* {
                        lockerDetails?.reason &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Reason</h6>
                          <p>{lockerDetails?.reason}</p>
                        </Col>
                      } */}

                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Status</h6>
                                {lockerDetails?.status ? (
                                  <div className="active-btn">Active</div>
                                ) : (
                                  <div className="deactive-btn">Inactive</div>
                                )}
                              </Col>
                            </Row>
                            <hr />
                            <Row className="fw-bold view-table">

                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Deposit (Refundable)</h6>
                                <p>{lockerDetails?.lockerDeposit}</p>
                              </Col>

                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Entry Fees (GST 18%)</h6>
                                <p>{lockerDetails?.entryFees}</p>
                              </Col>

                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Locker Rate (GST 18%)</h6>
                                {/* <p>{lockerDetails?.lockerRate + (lockerDetails?.lockerRate * gst) / 100}</p> */}
                                <p>{lockerDetails?.rentPrice + (lockerDetails?.rentPrice * gst) / 100}</p>
                              </Col>

                              {lockerDetails?.penalty > 0 &&
                                <Col xl={3} lg={4} md={6} sm={12}>
                                  <h6 className="fw-bold">Late Fees (GST 18%)</h6>
                                  <p>{lockerDetails?.penalty}</p>
                                </Col>
                              }

                              {lockerDetails?.breakCharge > 0 &&
                                <Col xl={3} lg={4} md={6} sm={12}>
                                  <h6 className="fw-bold">Break Charge (GST 18%)</h6>
                                  <p>{lockerDetails?.breakCharge}</p>
                                </Col>
                              }

                              {
                                lockerDetails?.additionalLockCharge > 0 &&
                                <Col xl={3} lg={4} md={6} sm={12}>
                                  <h6 className="fw-bold">Additional Lock Charge (GST 18%)</h6>
                                  <p>{lockerDetails?.additionalLockCharge}</p>
                                </Col>
                              }



                              {lockerDetails?.rentMinimumCharges && (

                                <Col xl={3} lg={4} md={6} sm={12}>
                                  <h6 className="fw-bold">Rent Minimum Charges</h6>
                                  <p>{lockerDetails?.rentMinimumCharges}</p>
                                </Col>
                              )}


                            </Row>
                          </CardBody>
                        </Card>

                        {/* Primary Holder */}
                        <Card>
                          <CardBody>
                            <CardTitle className="fs-4">
                              <b>Primary Holder</b>
                            </CardTitle>
                            <hr />
                            <Row className="fw-bold view-table" style={{}}>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold ">Name</h6>
                                <p>{customerDetails?.fullName}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Occupation</h6>
                                <p>{customerDetails?.occupation}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Email</h6>
                                <p>{customerDetails?.email}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Mobile</h6>
                                <p>{customerDetails?.mobile}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Telephone</h6>
                                <p>{customerDetails?.telephone}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Photo</h6>
                                <p><ImageViewer path={customerDetails?.photo} style={{ width: "5rem", height: "4rem", cursor: "pointer" }} /></p>
                              </Col>
                              {/* <Col xl={3} lg={4} md={6} sm={12}>
                    <h6 className="fw-bold">Primary Holder</h6>
                    {customerDetails?.isPrimaryHolder ? (
                      <div className="active-btn">Yes</div>
                    ) : (
                      <div className="deactive-btn">No</div>
                    )}
                  </Col> */}
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">KYC Status</h6>
                                <p>{customerDetails?.kycStatus}</p>
                              </Col>
                              <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Status</h6>

                                {customerDetails?.status ? (
                                  <div className="active-btn">Active</div>
                                ) : (
                                  <div className="deactive-btn">Inactive</div>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>

                        {/* Sub Holder */}
                        {holderDetails?.length > 0 &&
                          <Card>
                            <CardBody>
                              <CardTitle className="fs-4">
                                <b>Sub Holders</b>
                              </CardTitle>
                              {/* <hr /> */}
                              {holderDetails &&
                                holderDetails?.length > 0 &&
                                holderDetails.map((data, i) => (
                                  <>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mt-2 text-capitalize"
                                        style={{ backgroundColor: 'rgb(243 243 243)' }}
                                      >
                                        <Typography className="fw-bold">{data?.fullName}</Typography>
                                      </AccordionSummary>

                                      <AccordionDetails style={{ borderTop: "1px solid rgb(171 165 165)" }}>
                                        <Typography>
                                          <Row className="fw-bold view-table" style={{}}>
                                            <Col xl={3} lg={4} md={6} sm={12}>
                                              <h6 className="fw-bold ">Name</h6>
                                              <p>{data.fullName}</p>
                                            </Col>
                                            <Col xl={3} lg={4} md={6} sm={12}>
                                              <h6 className="fw-bold">Occupation</h6>
                                              <p>{data.occupation}</p>
                                            </Col>
                                            <Col xl={3} lg={4} md={6} sm={12}>
                                              <h6 className="fw-bold">Email</h6>
                                              <p>{data.email}</p>
                                            </Col>
                                            <Col xl={3} lg={4} md={6} sm={12}>
                                              <h6 className="fw-bold">Mobile</h6>
                                              <p>{data.mobile}</p>
                                            </Col>
                                            <Col xl={3} lg={4} md={6} sm={12}>
                                              <h6 className="fw-bold">Telephone</h6>
                                              <p>{data.telephone}</p>
                                            </Col>
                                            {/* <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Primary Holder</h6>
                                      {data.isPrimaryHolder ? (
                                        <div className="active-btn">Yes</div>
                                      ) : (
                                        <div className="deactive-btn">No</div>
                                      )}
                                    </Col> */}
                                            <Col xl={3} lg={4} md={6} sm={12}>
                                              <h6 className="fw-bold">KYC Status</h6>
                                              <p>{data.kycStatus}</p>
                                            </Col>
                                            <Col xl={3} lg={4} md={6} sm={12}>
                                              <h6 className="fw-bold">Status</h6>

                                              {data.status ? (
                                                <div className="active-btn">Active</div>
                                              ) : (
                                                <div className="deactive-btn">Inactive</div>
                                              )}
                                            </Col>
                                          </Row>
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  </>))}
                            </CardBody>
                          </Card>
                        }
                      </Row>
                    </Typography>
                  )}

                  {value === 1 && (
                    <Typography className="tabs-content">
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="serviceBox" onClick={handleSubmitRemove}>
                              <div class="service-icon">
                                <i class="mdi mdi-locker"></i>
                              </div>
                              <h3 class="title">Unassign Locker '{lockerDetails?.customerLockerNo}'</h3>
                              <p class="description">
                              </p>
                            </div>
                          </div>

                          {
                            lockerDetails.lockerStatus === "Freezed" ? "" : lockerDetails.lockerStatus === "Expired" ? "" :
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                {
                                  lockerDetails.lockerStatus === "Breaked" ? (
                                    <div class="serviceBox" onClick={handleBreakActive}>
                                      <div class="service-icon">
                                        <i class="mdi mdi-lock-off" aria-hidden="true"></i>
                                      </div>
                                      <h3 class="title">Active Locker '{lockerDetails?.customerLockerNo}'</h3>
                                      <p class="description">
                                      </p>
                                    </div>
                                  ) : (
                                    <div class="serviceBox" onClick={handleSubmitBreak}>
                                      <div class="service-icon">
                                        <i class="mdi mdi-lock-off" aria-hidden="true"></i>
                                      </div>
                                      <h3 class="title">Break Locker '{lockerDetails?.customerLockerNo}'</h3>
                                      <p class="description">
                                      </p>
                                    </div>
                                  )
                                }
                              </div>
                          }

                          {
                            lockerDetails?.lockerStatus === "Breaked" ? "" : lockerDetails.lockerStatus === "Expired" ? "" : (
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                {lockerDetails.status ? (
                                  <div class="serviceBox" onClick={handleSubmit}>
                                    <div class="service-icon">
                                      <i class="fa fa-snowflake" aria-hidden="true"></i>
                                    </div>
                                    <h3 class="title">Freeze Locker '{lockerDetails?.customerLockerNo}'</h3>
                                    <p class="description">
                                    </p>
                                  </div>
                                ) : (
                                  <div class="serviceBox" onClick={handleSubmitUnfreeze}>
                                    {" "}
                                    <div class="service-icon">
                                      <i class="fa fa-snowflake" aria-hidden="true"></i>
                                    </div>
                                    <h3 class="title">

                                      Unfreeze Locker '{lockerDetails?.customerLockerNo}'</h3>
                                    <p class="description">
                                    </p>
                                  </div>
                                )}
                              </div>
                            )
                          }


                          <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="serviceBox" onClick={handleAdditionalLock}>
                              <div class="service-icon">
                                <i class="fa fa-lock"></i>
                              </div>
                              <h3 class="title">Add Extra Lock</h3>
                              <p class="description">
                              </p>
                            </div>
                          </div>

                          {lockerDetails?.lockerStatus === "Expired" ?
                            <div class="col-lg-3 col-md-6 col-sm-12">
                              <div class="serviceBox" onClick={handleCloseLocker}>
                                <div class="service-icon">
                                  <i class="mdi mdi-locker"></i>
                                </div>
                                <h3 class="title">Close Locker '{lockerDetails?.customerLockerNo}'</h3>
                                <p class="description">
                                </p>
                              </div>
                            </div>
                            : ""}
                        </div>
                      </div>
                    </Typography>
                  )}
                </div>
                : ""}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(OccupiedLockerAction)


