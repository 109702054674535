import React from "react"
import { Navigate } from "react-router-dom"

import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Extra Pages
import PagesTimeline from "../pages/Extra Pages/pages-timeline"
import PagesInvoice from "../pages/Extra Pages/pages-invoice"
import PagesDirectory from "../pages/Extra Pages/pages-directory"
import PagesBlank from "../pages/Extra Pages/pages-blank"
import Pages404 from "../pages/Extra Pages/pages-404"
import Pages500 from "../pages/Extra Pages/pages-500"
import Role from "pages/Role-rights/role"
import Rights from "pages/Role-rights/rights"
import TransactionsReport from "pages/Reports/transactionsReport"
import StaffReport from "pages/Reports/staffReport"
import LockersReport from "pages/Reports/lockersReport"
import CreateCustomer from "pages/CustomerManagement/createCustomer"
import CreateLocker from "pages/LokerManagement/createLocker"
import Rentlist from "pages/RentManagement/rentlist"
import CreateRent from "pages/RentManagement/createRent"
import CreateStaff from "pages/StaffManagement/createStaff"
import ChangePassword from "pages/Authentication/changePassword"
import Lockerlist from "pages/LokerManagement/lockerlist"
import Customerlist from "pages/CustomerManagement/customerlist"
import Stafflist from "pages/StaffManagement/stafflist"
import CompanyProfile from "pages/Authentication/CompanyProfile"
import CustomerAddress from "pages/CustomerManagement/CustomerAddress"
import CustomerKYC from "pages/CustomerManagement/CustomerKYC"
import CustomerLocker from "pages/CustomerManagement/CustomerLocker"
import CustomerNominee from "pages/CustomerManagement/CustomerNominee"
import CustomerCreationSteps from "pages/CustomerManagement/CustomerCreationSteps"
import RoleToRight from "pages/Role-rights/RoleToRight"
import Index from "pages/CustomerManagement"
import LockerDetails from "pages/LokerManagement/LockerDetails"
import StaffDetails from "pages/StaffManagement/StaffDetails"
import RentDetails from "pages/RentManagement/RentDetails"

import TransactionList from "pages/Transation/transactionList"
import LockerExpire from "pages/LokerManagement/LockerExpire"
import RenewLocker from "pages/LokerManagement/RenewLocker"

import DynamicAddHolders from "pages/CustomerManagement/DynamicAddHolders"
import CustomerDetails from "pages/CustomerManagement/CustomerDetails"
import ChangeLockerPlan from "pages/LokerManagement/ChangeLockerPlan"
import KycUpdate from "pages/CustomerManagement/KycUpdate"
import LockerList from "pages/CustomerManagement/LockerList"
import OccupiedLockerDetails from "pages/CustomerManagement/OccupiedLockerDetails"
import CustomerDetailsTest from "pages/Biometric/customerDetailsTest"
import TransactionDetails from "pages/Transation/transactionDetails"
import LoginBio from "pages/Biometric/LoginBio"
import LoginPayError from "pages/Biometric/LoginPayError"
import Login3rdParty from "pages/Biometric/Login3rdParty"
import LockerFreezed from "pages/Biometric/LockerFreezed"
import CustomerReport from "pages/Reports/CustomerReport"
import LockerOperation from "pages/Reports/LockerOperation"
import TotalLockersReport from "pages/Reports/TotalLockersReport"
import UnOperatedLocker from "pages/Reports/UnOperatedLocker"
import FetchFingerPrint from "pages/Biometric/fetchFingerPrint"
import AuthenticateFingerPrint from "pages/Biometric/authenticateFingerPrint"
import UpdateCustomer from "pages/CustomerManagement/UpdateCustomer"
// import CustomerRegistration from "pages/CustomerRegistration"
import SendMessage from "pages/SMSRegistration/sendMessage"
import CustomerKycList from "pages/CustomerManagement/customerKycList"
import OccupiedLockerAction from "pages/CustomerManagement/occupiedLockerAction"

// const Dashboard = lazy(() => import("../pages/Dashboard/index"))

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/companyprofile", component: <CompanyProfile /> },
  { path: "/smsRegistration", component: <SendMessage /> },


  { path: "/role", component: <Role /> },
  { path: "/rights", component: <Rights /> },

  { path: "/transactionReport", component: <TransactionsReport /> },
  { path: "/staffreport", component: <StaffReport /> },
  { path: "/lockersreport", component: <LockersReport /> },
  { path: "/lockerReport", component: <LockerOperation /> },
  { path: "/customerreport", component: <CreateCustomer /> },

  { path: "/totalLockersReport", component: <TotalLockersReport /> },
  { path: "/customersReport", component: <CustomerReport /> },
  { path: "/unOperatedLockerReport", component: <UnOperatedLocker /> },

  { path: "/createlocker", component: <CreateLocker /> },

  { path: "/transactionList", component: <TransactionList /> },


  { path: "/rentlist", component: <Rentlist /> },
  { path: "/createrent", component: <CreateRent /> },
  { path: "/updateRent/:id", component: <CreateRent /> },
  { path: "/viewRent/:id", component: <RentDetails /> },

  { path: "/createstaff", component: <CreateStaff /> },
  { path: "/updateStaff/:id", component: <CreateStaff /> },
  { path: "/viewStaff/:id", component: <StaffDetails /> },

  { path: "/changepassword", component: <ChangePassword /> },
  { path: "/lockerlist", component: <Lockerlist /> },
  { path: "/lockerExpire", component: <LockerExpire /> },
  { path: "/lockerRenew", component: <RenewLocker /> },
  { path: "/lockerRenew/:id", component: <RenewLocker /> },

  { path: "/customerlist", component: <Customerlist /> },

  { path: "/customerKyc", component: <CustomerKycList /> },

  { path: "/assignedLockerAction", component: <OccupiedLockerAction /> },

  { path: "/stafflist", component: <Stafflist /> },
  { path: "/roletoright", component: <RoleToRight /> },

  { path: "/customerIndex", component: <Index /> },
  { path: "/customerCreationStep", component: <CustomerCreationSteps /> },
  { path: "/customeraddress", component: <CustomerAddress /> },
  { path: "/customerKYC", component: <CustomerKYC /> },
  { path: "/customerLocker", component: <CustomerLocker /> },
  { path: "/customerNominee", component: <CustomerNominee /> },

  { path: "/kycupdate/:id", component: <KycUpdate /> },
  { path: "/viewCustomer/:id", component: <CustomerDetails /> },
  { path: "/updateCustomer/:id", component: <UpdateCustomer /> },
  { path: "/updateCustomer/:id", component: <UpdateCustomer /> },

  { path: "/updateLocker/:id", component: <CreateLocker /> },
  { path: "/viewLocker/:id", component: <LockerDetails /> },
  { path: "/dynamictab", component: <DynamicAddHolders /> },

  { path: "/customerDetails", component: <CustomerDetails /> },


  { path: "/changeLockerPlan", component: <ChangeLockerPlan /> },
  { path: "/changeLockerPlan/:id", component: <ChangeLockerPlan /> },
  { path: "/assignedLockerList", component: <LockerList /> },
  { path: "/viewAssignedLocker/:id", component: <OccupiedLockerDetails /> },

  // { path: "/viewAssignedLocker/:customerLockerId", component: <OccupiedLockerDetails /> },

  { path: "/viewTransaction/:id", component: <TransactionDetails /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },

  // //Extra Pages
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-invoice", component: <PagesInvoice /> },
  { path: "/pages-directory", component: <PagesDirectory /> },
  { path: "/pages-blank", component: <PagesBlank /> },

  // Biometric pages

  { path: "/loginBio", component: <LoginBio /> },
  { path: "/loginPayError", component: <LoginPayError /> },
  { path: "/loginError", component: <Login3rdParty /> },
  { path: "/lockerFreezed", component: <LockerFreezed /> },
  { path: "/customerDetails/:customerLockerId", component: <CustomerDetailsTest /> },
  { path: "/accessCustomerLockerDetails", component: <CustomerDetailsTest /> },
  { path: "/fetchFingerPrint", component: <FetchFingerPrint /> },
  { path: "/customerAuthenticate", component: <AuthenticateFingerPrint /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  // { path: "/customerRegistration", component: <CustomerRegistration /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { userRoutes, authRoutes }
