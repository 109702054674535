import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import DataTable from "react-data-table-component"
import { getUnOperatedLockerReportAPI } from "service/service"
import { ExportToExcel } from "./ExportToExcel"

const UnOperatedLocker = props => {
  document.title =
    "UnOperated Locker Report | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "UnOperated Locker report", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Lockers Report", breadcrumbItems)
  })

  const [lockerList, setLockerList] = useState([])
  const [locker, setLocker] = useState([])

  const displayUnOperatedLockerReport = async () => {
    const response = await getUnOperatedLockerReportAPI()
    // console.log("Response - ", response);
    if (response.status === 1 || response.status === "1") {
      setLockerList(response?.data)
      const postData = response.data
      const customHeadings = postData.map((item, index) => ({
        "Sr. NO.": index + 1,
        "Customer Name": item.primaryHolder,
        "Customer Mobile": item.primaryHolderMobile,
        "Locker No": item.lockerNo,
        "Locker Category": item.lockerCategory,
      }))

      setLocker(customHeadings)
    } else {
      setLockerList([])
    }
  }

  const [columns, setColumns] = useState([])
  const [pending, setPending] = useState(true)

  // const columns = [
  //   {
  //     name: "Customer Name",
  //     selector: row => row.primaryHolder,
  //   },
  //   {
  //     name: "Mobile",
  //     selector: row => row.primaryHolderMobile,
  //   },
  //   {
  //     name: "Locker No",
  //     selector: row => row.lockerNo,
  //   },
  //   {
  //     name: "Locker Category",
  //     selector: row => row.lockerCategory,
  //   },
  // ]

  const customStyles = {
    headCells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for head cells
        // paddingRight: "8px",
        fontSize: "15px",
      },
    },
  }

  useEffect(() => {
    displayUnOperatedLockerReport()
    //eslint-disable-next-line
  }, [""])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: "Customer Name",
          selector: row => row.primaryHolder,
        },
        {
          name: "Mobile",
          selector: row => row.primaryHolderMobile,
        },
        {
          name: "Locker No",
          selector: row => row.lockerNo,
        },
        {
          name: "Locker Category",
          selector: row => row.lockerCategory,
        },
      ])
      setPending(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      {/* Locker Not Accessed in past 3 months report  */}
      <Card className="mt-3">
        <CardBody>
          <CardTitle
            className="fs-4 fw-bolder d-flex "
            style={{
              borderBottom: "1px solid #e3d0d0",
              justifyContent: "space-between",
            }}
          >
            Locker Not Accessed In Past 3 Months
            <ExportToExcel apiData={locker} fileName={"LockerNotAccessedList"} />
          </CardTitle>
          <Row>
            <Col>
              <DataTable
                className="mt-3"
                columns={columns}
                data={lockerList}
                fixedHeader
                pagination
                customStyles={customStyles}
                progressPending={pending}
                highlightOnHover
              ></DataTable>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(UnOperatedLocker)
