import React, { Suspense, useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { getStaffByIdAPI } from "service/service"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import ImageViewer from "ImageViewer"
import dummy from "../../assets/images/users/dummy.jpg"
import Loading from "components/Common/Loading"

const StaffDetails = props => {
  document.title = "Staff Details | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Staff List", link: "/stafflist" },
    { title: "Staff Details", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Staff Details", breadcrumbItems)
  })

  const { id } = useParams()
  const [staffDetails, setStaffDetails] = useState([])
  console.log(staffDetails);
  const handleViewStaffDetails = async id => {
    try {
      const res = await getStaffByIdAPI(id)
      setStaffDetails(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (id) {
      handleViewStaffDetails(id)
    }
    //eslint-disable-next-line
  }, [])
  return (
    <Suspense fallback={<Loading />}>
      <>
        <div>
          <Row>
            <Col>
              <div className="d-flex justify-content-end">
                <div className="goback">
                  <Link to="/stafflist" className="">
                    Go Back
                  </Link>
                </div>
              </div>
              <Card>
                <CardBody>
                  <CardTitle className="fs-4">
                    <b>View Staff Details</b>
                  </CardTitle>
                  <hr />
                  <Row className="fw-bold view-table" style={{}}>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold ">First Name</h6>
                      <p>{staffDetails.firstName}</p>
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Last Name</h6>
                      <p>{staffDetails.lastName}</p>
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Email</h6>
                      <p>{staffDetails.email}</p>
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Mobile No</h6>
                      <p>{staffDetails.mobile}</p>
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Address</h6>
                      <p>{staffDetails.address}</p>
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Gender</h6>

                      {staffDetails.gender && staffDetails.gender === true ? (
                        <p>Male</p>
                      ) : (
                        <p>Female</p>
                      )}
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Staff KYC</h6>
                      {staffDetails?.adharCard ? (
                        <ImageViewer
                          path={staffDetails?.adharCard}
                          style={{ width: "4rem", height: "2.5rem", cursor: 'pointer' }}
                        >
                          {" "}
                        </ImageViewer>
                      ) : (
                        <ImageViewer
                          path={dummy}
                          style={{ width: "4rem", height: "2.5rem", cursor: 'pointer' }}
                        >
                          {" "}
                        </ImageViewer>
                      )}

                      {/* <ImageViewer
                    path={staffDetails.adharCard}
                    style={{ width: "3rem", height: "2rem",cursor:'pointer' }}
                  >
                    {" "}
                  </ImageViewer> */}
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Staff Profile Photo</h6>
                      {/* <ImageViewer
                    path={staffDetails.image}
                    style={{ width: "3rem", height: "2rem",cursor:'pointer' }}
                  >
                    {" "}
                  </ImageViewer> */}
                      {staffDetails?.image ? (
                        <ImageViewer
                          path={staffDetails?.image}
                          style={{ width: "3rem", height: "2rem", cursor: 'pointer' }}
                        >
                          {" "}
                        </ImageViewer>
                      ) : (
                        <ImageViewer
                          path={dummy}
                          style={{ width: "3rem", height: "2rem", cursor: 'pointer' }}
                        >
                          {" "}
                        </ImageViewer>
                      )}
                    </Col>
                    {/* <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Status</h6>

                      {staffDetails.status === true ? (
                        <div className="active-btn">Active</div>
                      ) : (
                        <div className="deactive-btn">Inactive</div>
                      )}
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </Suspense>
  )
}

export default connect(null, { setBreadcrumbItems })(StaffDetails)
