import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import FeatherIcon from "feather-icons-react"
import { IconButton, Tooltip } from "@mui/material"
import Swal from "sweetalert2"
import { sendMessageAPI } from "service/service"

const SendMessage = props => {
  document.title = "Send Message | Shreesh Securities"

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Send Message", link: "#" },
  ]

  const [mobile, setMobile] = useState("")
  const [selectedData, setSelectedData] = useState([]);

  const addItem = () => {
    if (mobile) {
      setSelectedData(selectedData, selectedData.push(mobile));
      setMobile("")
    } else {
      Swal.fire({
        title: "Please enter mobile no.",
        icon: "info"
      })
    }
  }

  const deleteItem = (e) => {
    const name = e;
    setSelectedData(
      selectedData.filter(function (item) {
        return item !== name;
      })
    );
    Swal.fire({
      title: `${name} has been removed`,
      icon: "success",
      timer: 1500
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedData.length == 0) {
      Swal.fire({
        title: "Please enter mobile no.",
        icon: "info"
      })
    } else {
      console.log("Mobile", selectedData);
      try {
        let url = "http://surl.li/sejnl"
        let message = `Dear Customer, Please click the given link ${url} and fill up the form for registration. SHREESH LOCKER`
        const response = await sendMessageAPI(selectedData, message)
        if (response.status === "1" || response.status === 1) {
          Swal.fire({
            title: response.message,
            icon: "success",
            timer: 1500
          })
          setSelectedData([])
        } else {
          Swal.fire({
            title: response.message,
            icon: "error"
          })
        }
        // console.log(selectedData,message);
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    props.setBreadcrumbItems("Send Message", breadcrumbItems)
  })

  return (
    <div>
      <Card className="mt-3">
        <CardBody>
          <div className="py-1">
            <Label htmlFor="example-text-input" className=" col-form-label">
              Please enter customer mobile number*
            </Label>
            <div className=" position-relative">
              <div className="input-group w-50 searchBox">
                <Input
                  className="form-control"
                  type="number"
                  name="mobile"
                  id="mobile"
                  min={10}
                  placeholder="Enter Mobile No"
                  onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                  onChange={e => {
                    setMobile(e.target.value.slice(0, 10))
                  }}
                  value={mobile}
                  required
                />
                <Tooltip title="Add">
                  <IconButton
                    onClick={() => addItem()}
                  >
                    <FeatherIcon icon="plus-square" width="25" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>

          {selectedData.length > 0 ? (
            <Row>
              <Col lg={6}>
                <div className="py-1 table-responsive mt-3">
                  <table
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Mobile No
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    {selectedData &&
                      selectedData?.length > 0 &&
                      selectedData?.map((data, index) => {
                        return (
                          <tbody key={index}>
                            <tr >
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                {data}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                <span>
                                  <i
                                    className="fas fa-trash-alt"
                                    style={{ cursor: "pointer", color: "red" }}
                                    onClick={() =>
                                      deleteItem(data)
                                    }
                                  ></i>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })}

                  </table>
                </div>
              </Col>
            </Row>
          ) : ""}

          <div className="py-5">

            <Button
              type="button"
              className="btn btn-primary w-md"
              onClick={handleSubmit}
            >
              Send Message
            </Button>
          </div>
        </CardBody>
      </Card>
    </div >
  )
}

export default connect(null, { setBreadcrumbItems })(SendMessage)
