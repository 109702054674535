import React, { Fragment } from "react"
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    errorData: {
      error: "",
      info: "",
    },
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // console.log("errorInfo", errorInfo.componentStack)
    this.setState(preState => {
      return {
        errorData: {
          error: error.message,
          info: errorInfo.componentStack,
        },
      }
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <h1>{this.state.errorData.error}</h1>
          <p>{this.state.errorData.info}</p>
        </Fragment>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
