import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import Miniwidget from "./Miniwidget"
import LatestTransactions from "./latest-transactions"
import {
  setBreadcrumbItems,
} from "../../store/actions"
import Lockers from "./lockers"
import {
  displayCustomersCount,
  displayDailyLockerAccessCountAPI,
  displayLockerAccessCountAPI,
} from "service/service"

const Dashboard = props => {
  // const [totallocker, setTotallocker] = useState()
  const [transaction, setTransaction] = useState()
  const [totalCustomer, setTotalCustomer] = useState()
  const [dailyTransaction, setDailyTransaction] = useState()

  document.title = "Dashboard | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Dashboard", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)
  })

  const customersCountD = async () => {
    try {
      const response = await displayCustomersCount()
      setTotalCustomer(response.data)

    } catch (error) {
      console.log(error);
    }
  }
  const transactionCountD = async () => {
    try {
      const response = await displayLockerAccessCountAPI()
      setTransaction(response.data)

    } catch (error) {
      console.log(error);
    }
  }
  const dailyTransactionCount = async () => {
    try {
      const response = await displayDailyLockerAccessCountAPI()
      setDailyTransaction(response?.data)

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // lockersCountD()
    customersCountD()
    transactionCountD()
    dailyTransactionCount()
  }, [])

  const reports = [
    {
      title: "Cabinet",
      iconClass: "locker-multiple",
      total: 287,

    },
    {
      title: "Customers",
      iconClass: "account-group",
      total: totalCustomer,

    },
    {
      title: "Daily Locker Access",
      iconClass: "bank",
      total: dailyTransaction,

    },
    {
      title: "All Locker Access",
      iconClass: "bank",
      total: transaction,

    },
  ]

  return (

    <React.Fragment>
      <Miniwidget reports={reports} />

      <Row>
        <Col>
          <Lockers />
        </Col>
      </Row>
      <Row>
        <Col xl="12">
          <LatestTransactions />
        </Col>
      </Row>
    </React.Fragment>

  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
