import React, { useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"

import {
  setBreadcrumbItems,
} from "../../store/actions"

import { connect } from "react-redux"

import { getCustomerLockerList } from "service/service"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import { useNavigate } from "react-router-dom"

const LockerExpire = props => {

  const [locker, setLocker] = useState([])
  const [isRefresh, setIsRefresh] = useState(false)
  // const userRights = useSelector(state => state.Profile.userRights)
  const [page, pagechange] = useState(0)
  const [rowperpage, rowperpagechange] = useState(10)

  const [dayValue, setDayValue] = useState(30)

  const history = useNavigate()
  // console.log("locker",locker);
  document.title = "Locker Expire List | Shreesh - Locker Management System"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Locker Expire List", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Locker Expire List", breadcrumbItems)
  })

  const handleDisplayLockerList = async () => {
    try {
      let temp = [];
      const res = await getCustomerLockerList(dayValue)
      res.data && res.data.length &&
        res.data.map((data) => {
          if(data.lockerStatus!=="Removed"){
            temp.push({
              "customerLockerId": data.customerLockerId,
              "customerEmail": data.customerEmail,
              "customerMobile": data.customerMobile,
              "customerName": data.customerName,
              "expiringDate": data.expiringDate,
              "lockerCategory": data.lockerCategory,
              "lockerId": data.lockerId,
              "lockerKey": data.lockerKey,
              "lockerName": data.lockerName,
              "lockerNo": data.lockerNo,
              "lockerStatus": data.lockerStatus,
              "planName": data.planName,
              "rentId": data.rentId,
            })
          }
        })
      setLocker(temp)
    } catch (error) {
      console.log(error)
    }
  }
  const handlechangepage = (event, newpage) => {
    pagechange(newpage)
  }
  const handleRowsPerPage = event => {
    rowperpagechange(+event.target.value)
    pagechange(0)
  }
  const handleUpdateLocker = async (i) => {
    // console.log("id", i);
    try {
      // localStorage.setItem("renewCustomerLockerPlanId", i)
      history(`/lockerRenew/${i}`)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeLockerPlan = async (i) => {
    try {
      // localStorage.setItem("changeCustomerLockerPlanId", i)
      history(`/changeLockerPlan/${i}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelect = async (e) => {
    // const value = e.target.value
    // setDayValue(value)
    let value = e.target.value
    setDayValue(value)
    console.log(value);
    getCustomerLockerList(value)
    // const res = await getCustomerLockerList(value)
    // setLocker(res.data)
  }

  useEffect(() => {
    handleDisplayLockerList()
  }, [isRefresh, dayValue])
  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "10px" }}
      >
        <select
          className="form-control"
          id="DocName"
          value={dayValue}
          onChange={handleSelect}
          style={{ width: "auto" }}
        >
          <option>-- SELECT --</option>
          <option value="1" >1</option>
          <option value="2" >2</option>
          <option value="7" >7</option>
          <option value="15" >15</option>
          <option value="30" selected>30</option>
          <option value="60">60</option>
          <option value="90">90</option>
          <option value="365">365</option>
          <option value="730">730</option>
          <option value="1050">1050</option>
        </select>
      </div>

      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow>
                  <TableCell className="fs-5 fw-bold" style={{ color: "white" }}>
                    Customer Name
                  </TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: "white" }}>
                    Email
                  </TableCell>

                  <TableCell className="fs-5 fw-bold" style={{ color: "white" }}>
                    Locker No
                  </TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: "white" }}>
                    Plan Name
                  </TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: "white" }}>
                    Expiring Date
                  </TableCell>
                  <TableCell className="fs-5 fw-bold" style={{ color: "white" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locker &&
                  locker.lockerStatus !== "Removed" && locker
                    .slice(page * rowperpage, page * rowperpage + rowperpage)
                    .map((row, index) => (
                      <TableRow key={row._id}>
                        <TableCell>{row.customerName}</TableCell>
                        <TableCell>{row.customerEmail}</TableCell>
                        <TableCell>{row.lockerNo}</TableCell>
                        <TableCell>{row.planName}</TableCell>
                        <TableCell>{row.expiringDate}</TableCell>
                        <TableCell style={{ display: "grid", gap: "5px" }}>
                          {/* {userRights?.locker?.write && */}
                          <div
                            className="edit-btn"
                            onClick={() => handleUpdateLocker(row.customerLockerId)}
                          >
                            Renew Locker
                          </div>
                          {/* } */}
                          {/* {userRights?.locker?.write && */}
                          <div
                            className="edit-btn"
                            onClick={() => handleChangeLockerPlan(row.customerLockerId)}
                          >
                            Change Locker Plan
                          </div>
                          {/* } */}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[8, 10, 20, 25]}
              rowsPerPage={rowperpage}
              page={page}
              count={locker.length}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            ></TablePagination>
          </TableContainer>
        </CardBody>
      </Card>

    </>
  )
}

export default connect(null, { setBreadcrumbItems })(LockerExpire)
