import React, { useEffect, useState } from "react"
import {
  setBreadcrumbItems,
} from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, Input } from "reactstrap"

import {
  activeCustomerAPI,
  deactiveCustomerAPI,
  getCustomerCountAPI,
  getCustomerList,
  searchCustomer,
} from "service/service"
import Swal from "sweetalert2"
// import { useSelector } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
// import { HTML5_FMT } from "moment"

const Customerlist = props => {

  const [customer, setcustomer] = useState([])
  const [isRefresh, setIsRefresh] = useState(false)
  // const userRights = useSelector(state => state.Profile.userRights)
  const [count, setRentCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const history = useNavigate()

  document.title = "Customer List | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Customer List", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Customer List", breadcrumbItems)
  })

  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    setPerPage(+event.target.value)
    setPageNo(1);
  }

  const handleDisplayCustomerList = async () => {
    try {
      const res = await getCustomerList(perPage, pageNo)
      setcustomer(res.data)

    } catch (error) {
      console.log(error);
    }
  }
  const getRentCount = async () => {
    try {
      const res = await getCustomerCountAPI();
      // console.log("res", res);
      if (res.status === '1' || res.status === 1) {
        setRentCount(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getCounts() {
      await getRentCount();
    }
    getCounts();
  }, [])

  const handleDeactiveCustomer = async i => {
    try {
      // const deactiveid = customer[i].id
      const res = await deactiveCustomerAPI(i)
      setIsRefresh(!isRefresh)

      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleActiveCustomer = async i => {
    try {
      // const activeid = customer[i].id
      const res = await activeCustomerAPI(i)
      setIsRefresh(!isRefresh)

      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  // const handleKycUpdate = async i => {
  //   try {
  //     history(`/kycupdate/${i}`)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  const handleCustomerUpdate = i => {
    try {
      history(`/updateCustomer/${i}`)
    } catch (error) {
      console.log(error);
    }
  }
  const handleCustomerView = async i => {
    try {
      history(`/viewCustomer/${i}`)
    } catch (error) {
      console.log(error);
    }
  }
  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchCustomer(value)
    setcustomer(res.data)
  }

  useEffect(() => {
    handleDisplayCustomerList();
    // eslint-disable-next-line
  }, [isRefresh, perPage, pageNo])
  return (
    <>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-2 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-2 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow>
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    Full Name
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    Mobile No
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    KYC Status
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer ? (
                  customer.map((row, index) => (
                    <TableRow key={row._id} className="tablerow">
                      <TableCell onClick={() => handleCustomerView(row._id)}>
                        {row.fullName}
                      </TableCell>
                      <TableCell onClick={() => handleCustomerView(row._id)}>
                        {row.mobile}
                      </TableCell>
                      <TableCell onClick={() => handleCustomerView(row._id)} style={{ color: row.kycStatus === "Verified" ? "green" : "red" }}>
                        {row.kycStatus}
                      </TableCell>
                      <TableCell >
                      <div style={{ display: "flex", gap: "5px" }}>
                        {row.status ? (
                          <div
                            className="active-btn"
                            onClick={() => handleDeactiveCustomer(row._id)}
                          >
                            Active
                          </div>
                        ) : (
                          <div
                            className="deactive-btn"
                            onClick={() => handleActiveCustomer(row._id)}
                          >
                            Inactive
                          </div>
                        )}

                        {/* <div
                          className="edit-btn"
                          onClick={() => handleKycUpdate(row._id)}
                        >
                          KYC
                        </div> */}
                        <div
                          className="edit-btn"
                          onClick={() => handleCustomerUpdate(row._id)}
                        >
                          Edit
                        </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <h5>Data not found</h5>
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              rowsPerPage={perPage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            />
          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Customerlist)
