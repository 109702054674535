import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import Container from "@mui/material/Container"
import { useEffect, useState } from 'react';
import CustomerSubholder from './CustomerSubholder';
import { addCustomerSubHolderAPI, getCustomerSubHolderAPI } from 'service/service';

const ControlledAccordions = ({ onButtonClick }) => {
  let subHolders = JSON.parse(localStorage.getItem("subHolderId")) || [];
  const [isAdd, setIsAdd] = useState(false)
  const [clickedValue, setClickedValue] = useState(false)

  const [accordions, setAccordions] = useState(
    subHolders.map((_, index) => `Holder ${index + 1}`)
  );
  const addAccordion = () => {
    setIsAdd(true);
    const newAccordion = `Holder ${accordions.length + 1}`;
    setAccordions((prevAccordions) => [...prevAccordions, newAccordion]);
  };
  const compareArray = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }
    return array1.every((element, index) => element === array2[index]);
  };

  const handleNext = async () => {
    let main = []
    const oldHolders = await getCustomerSubHolderAPI(localStorage.getItem('customerID'));
    oldHolders && oldHolders.data.length > 0 && oldHolders.data.map((data) => {
      let temp = []
      data.holders.length > 0 && data.holders.map((item) => {
        temp.push(item.id)
      })
      main.push(temp)
    })
    if (main.length > 0) {
      if (main.some((subArray) => compareArray(subArray, JSON.parse(localStorage.getItem('subHolderId')))))
        onButtonClick("CustomerNominee")
      else {
        await addCustomerSubHolderAPI(localStorage.getItem('customerID'), JSON.parse(localStorage.getItem('subHolderId')));
        onButtonClick("CustomerNominee")
      }
    }
    else {
      await addCustomerSubHolderAPI(localStorage.getItem('customerID'), JSON.parse(localStorage.getItem('subHolderId')));
      onButtonClick("CustomerNominee")
    }
  }
  const handleChildClick = (value) => {
    setClickedValue(value);
  };
  useEffect(() => {
    subHolders.length === 0 && addAccordion()
    subHolders.length > 0 && setClickedValue(true)
  }, [])
  return (
    <div className="d-flex justify-content-center">
      <Row className="w-100" style={{ maxWidth: "50rem" }}>
        <Col>
          <Card className="mt-5">
            <CardBody>
              <CardTitle className="text-center fs-4">
                <b>Customer Sub Holders Details</b>{" "}
              </CardTitle>

              <Container>
                {
                  isAdd ?
                    accordions && accordions.length > 0 &&
                    accordions.map((data, i) => {
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            style={{ background: "rgb(248 247 251)" }}
                          >
                            <Typography>{data}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CustomerSubholder id={subHolders[i]} count={i} onClickFunc={handleChildClick} />
                          </AccordionDetails>
                        </Accordion>
                      )
                    })
                    :
                    subHolders && subHolders.length > 0 &&
                    subHolders.map((data, i) => {
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            style={{ background: "rgb(248 247 251)" }}
                          >
                            <Typography>Holder {i + 1}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CustomerSubholder id={data} count={i} />
                          </AccordionDetails>
                        </Accordion>
                      )
                    })
                }

                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary w-md"
                    onClick={addAccordion}
                  >
                    {`+ Add Holder`}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary w-md"
                    style={{ marginLeft: "20px" }}
                    disabled={!clickedValue}
                    onClick={handleNext}
                  >
                    {`Next`}
                  </button>
                </div>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default ControlledAccordions
