import {
  LOGIN_USER,
  LOGOUT_USER,
  DISPLAY_ROLE,
  DISPLAY_RIGHTS,
  DISPLAY_ROLE_TO_RIGHTS,
  UPDATE_ROLE_TO_RIGHTS,
  ADD_CUSTOMER_ADDRESS,
  ADD_CUSTOMER_KYC,
  REMOVE_CUSTOMER_IMG,

  // LOGIN_SUCCESS,
  // LOGOUT_USER_SUCCESS,
  // API_ERROR,
  // SOCIAL_LOGIN,
  // CREATE_ROLE,
  // DELETE_ROLE,
  // STAFF_LIST,
  // LOCKER_LIST,
  // CUSTOMER_LIST,
  // RENT_LIST,
  // USER_PROFILE,
  // CHANGE_PASSWORD,
  // DELETE_STAFF,
  // ACTIVE_LOCKER,
  // DEACTIVE_LOCKER,
  // ACTIVE_CUSTOMER,
  // DEACTIVE_CUSTOMER,
  // ACTIVE_RENT,
  // DEACTIVE_RENT,
  // UPDATE_ROLE,
  // UPDATE_LOCKER,
  // UPDATE_STAFF,
  // UPDATE_CUSTOMER,
  // UPDATE_RENT,
  // COMPANY_PROFILE,
  // CREATE_RIGHTS,
  // DELETE_RIGHTS,
  // UPDATE_RIGHTS,
  // CREATE_LOCKER,
  // UPLOAD_COMPANY_IMG,
  // CREATE_STAFF,
  // CREATE_RENT,
  // DISPLAY_LOCKER,
  // UPLOAD_STAFF_IMG,
  // GET_LOCKER_BY_ID,
  // UPDATE_COMPANY_DETAILS,
  // REMOVE_COMPANY_IMG,
  // UPLOAD_USER_IMG,
  // REMOVE_USER_IMG,
  // REMOVE_STAFF_IMG,
  // GET_STAFF_BY_ID,
  // CREATE_CUSTOMER,
  // ADD_CUSTOMER_NOMINEE,
  // TRANSACTION_LIST,
  // ASSIGN_LOCKER_TO_CUSTOMER,
  // CUSTOMER_EXPIRINIG_LOCKER_LIST,
  // OCCUPIED_LOCKER,
  // CUSTOMER_NOMINEE_IMAGE,
  // LOCKERS_COUNT,
  // CUSTOMERS_COUNT,
  // TRANSCTIONS_COUNT,
  // CREATE_TRANSCTIONS,
  // CHANGE_LOCKER_PLAN,
  // RENEW_LOCKER_PLAN,
  // OCCUPIED_LOCKER_LIST,
  // ACTIVE_CUSTOMER_LOCKER,
  // UPLOAD_CUSTOMER_IMG

} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const logoutUser = (user, history) => {
  return {
    type: LOGOUT_USER,
    payload: { user, history },
  }
}

export const displayRole = (roles, history) => {
  return {
    type: DISPLAY_ROLE,
    payload: { roles, history },
  };
};

// --------------- ROLE TO RIGHT---------------
export const displayRoleToRights = (userRoletoRight, history) => {
  return {
    type: DISPLAY_ROLE_TO_RIGHTS,
    payload: { userRoletoRight, history },
  };
};
export const updateRoleToRights = (updateRoletoRight, history) => {
  return {
    type: UPDATE_ROLE_TO_RIGHTS,
    payload: { updateRoletoRight, history },
  };
};

export const displayRights = (roles, history) => {
  return {
    type: DISPLAY_RIGHTS,
    payload: { roles, history },
  };
};

export const addCustomerAddress = (customerAddress, history) => {
  return {
    type: ADD_CUSTOMER_ADDRESS,
    payload: { customerAddress, history },
  };
};

export const addCustomerKyc = (customerKyc, history) => {
  return {
    type: ADD_CUSTOMER_KYC,
    payload: { customerKyc, history },
  };
};

export const removeCustomerImgImage = (userCustomer, history) => {
  return {
    type: REMOVE_CUSTOMER_IMG,
    payload: { userCustomer, history },
  }
}

// export const loginSuccess = user => {
//   return {
//     type: LOGIN_SUCCESS,
//     payload: user,
//   }
// }

// export const userProfile = (profile, history) => {
//   return {
//     type: CHANGE_PASSWORD,
//     payload: { profile, history },
//   }
// };
// export const companyProfile = (profile, history) => {
//   return {
//     type: COMPANY_PROFILE,
//     payload: { profile, history },
//   }
// };
// export const uploadCompanyImg = (profile, history) => {
//   return {
//     type: UPLOAD_COMPANY_IMG,
//     payload: { profile, history },
//   }
// };
// export const removeCompanyImage = (profile, history) => {
//   return {
//     type: REMOVE_COMPANY_IMG,
//     payload: { profile, history },
//   }
// };
// export const updateCompanyDetail = (profile, history) => {
//   return {
//     type: UPDATE_COMPANY_DETAILS,
//     payload: { profile, history },
//   }
// };
// export const uploadUserImg = (profile, history) => {
//   return {
//     type: UPLOAD_USER_IMG,
//     payload: { profile, history },
//   }
// };
// export const removeUserImage = (profile, history) => {
//   return {
//     type: REMOVE_USER_IMG,
//     payload: { profile, history },
//   }
// };


// export const changePassword = (pass, history) => {
//   return {
//     type: USER_PROFILE,
//     payload: { pass, history },
//   };
// };

// export const logoutUserSuccess = () => {
//   return {
//     type: LOGOUT_USER_SUCCESS,
//     payload: {},
//   }
// }

// export const apiError = error => {
//   return {
//     type: API_ERROR,
//     payload: error,
//   }
// }

// export const socialLogin = (type, history) => {
//   return {
//     type: SOCIAL_LOGIN,
//     payload: { type, history },
//   }
// }

// --------------- ROLE ---------------
// export const createRole = (userRole, history) => {
//   return {
//     type: CREATE_ROLE,
//     payload: { userRole, history },
//   };
// };



// export const deleteRole = (role, history) => {
//   return {
//     type: DELETE_ROLE,
//     payload: { role, history },
//   };
// };

// export const updateRole = (userRole, history) => {
//   return {
//     type: UPDATE_ROLE,
//     payload: { userRole, history },
//   };
// };



// --------------- RIGHTS ---------------
// export const createRights = (userRole, history) => {
//   return {
//     type: CREATE_RIGHTS,
//     payload: { userRole, history },
//   };
// };



// export const deleteRights = (role, history) => {
//   return {
//     type: DELETE_RIGHTS,
//     payload: { role, history },
//   };
// };

// export const updateRights = (userRole, history) => {
//   return {
//     type: UPDATE_RIGHTS,
//     payload: { userRole, history },
//   };
// };

// ----------------- STAFF -----------------
// export const createStaff = (userStaff, history) => {
//   return {
//     type: CREATE_STAFF,
//     payload: { userStaff, history },
//   };
// };
// export const staffList = (staff, history) => {
//   return {
//     type: STAFF_LIST,
//     payload: { staff, history },
//   };
// };
// export const deleteStaff = (staff, history) => {
//   return {
//     type: DELETE_STAFF,
//     payload: { staff, history },
//   };
// };
// export const updateStaff = (userStaff, history) => {
//   return {
//     type: UPDATE_STAFF,
//     payload: { userStaff, history },
//   };
// };
// export const getStaffById = (staff, history) => {
//   return {
//     type: GET_STAFF_BY_ID,
//     payload: { staff, history },
//   };
// };
// export const uploadStaffImg = (uploadimg, history) => {
//   return {
//     type: UPLOAD_STAFF_IMG,
//     payload: { uploadimg, history },
//   };
// };
// export const removeStaffImg = (uploadimg, history) => {
//   return {
//     type: REMOVE_STAFF_IMG,
//     payload: { uploadimg, history },
//   };
// };

// --------------- LOCKER ---------------
// export const createLocker = (lockerDetails, history) => {
//   return {
//     type: CREATE_LOCKER,
//     payload: { lockerDetails, history },
//   };
// };
// export const assignLockerToCustomer = (assignLockerDetails) => {
//   return {
//     type: ASSIGN_LOCKER_TO_CUSTOMER,
//     payload: { assignLockerDetails },
//   };
// };
// export const lockerList = (locker, history) => {
//   return {
//     type: LOCKER_LIST,
//     payload: { locker, history },
//   };
// };
// export const activeLocker = (locker, history) => {
//   return {
//     type: ACTIVE_LOCKER,
//     payload: { locker, history },
//   };
// };
// export const deactiveLocker = (locker, history) => {
//   return {
//     type: DEACTIVE_LOCKER,
//     payload: { locker, history },
//   };
// };
// export const updateLocker = (userLocker, history) => {
//   return {
//     type: UPDATE_LOCKER,
//     payload: { userLocker, history },
//   };
// };
// export const displayLocker = (displayLocker, history) => {
//   return {
//     type: DISPLAY_LOCKER,
//     payload: { displayLocker, history },
//   };
// };
// export const getLockerById = (locker, history) => {
//   return {
//     type: GET_LOCKER_BY_ID,
//     payload: { locker, history },
//   };
// };

// --------------- CUSTOMER ---------------
// export const customerNomineeImage = (nomineeId, history) => {
//   return {
//     type: CUSTOMER_NOMINEE_IMAGE,
//     payload: { nomineeId, history },
//   };
// };
// export const createCustomer = (customer, history) => {
//   return {
//     type: CREATE_CUSTOMER,
//     payload: { customer, history },
//   };
// };

// export const addCustomerNominee = (customerNominee, history) => {
//   return {
//     type: ADD_CUSTOMER_NOMINEE,
//     payload: { customerNominee, history },
//   };
// };

// export const customerList = (customer, history) => {
//   return {
//     type: CUSTOMER_LIST,
//     payload: { customer, history },
//   };
// };
// export const customerExpiringLockerList = (customer, history) => {
//   return {
//     type: CUSTOMER_EXPIRINIG_LOCKER_LIST,
//     payload: { customer, history },
//   };
// };
// export const activeCustomer = (customer, history) => {
//   return {
//     type: ACTIVE_CUSTOMER,
//     payload: { customer, history },
//   };
// };
// export const deactiveCustomer = (customer, history) => {
//   return {
//     type: DEACTIVE_CUSTOMER,
//     payload: { customer, history },
//   };
// };
// export const updateCustomer = (userCustomer, history) => {
//   return {
//     type: UPDATE_CUSTOMER,
//     payload: { userCustomer, history },
//   };
// };

// export const uploadCustomerImgImage = (userCustomer, history) => {
//   return {
//     type: UPLOAD_CUSTOMER_IMG,
//     payload: { userCustomer, history },
//   }
// }

// export const activeCustomerLocker = (customer, history) => {
//   return {
//     type: ACTIVE_CUSTOMER_LOCKER,
//     payload: { customer, history },
//   }
// }

// export const changeLockerPlanA = (userCustomer, history) => {
//   return {
//     type: CHANGE_LOCKER_PLAN,
//     payload: { userCustomer, history },
//   }
// }
// export const renewLockerA = (renewLockerPlan, history) => {
//   return {
//     type: RENEW_LOCKER_PLAN,
//     payload: { renewLockerPlan, history },
//   }
// }
// export const lockerOccupiedList = (occupiedLocker, history) => {
//   return {
//     type: OCCUPIED_LOCKER_LIST,
//     payload: { occupiedLocker, history },
//   }
// }

// --------------- RENT ---------------
// export const createRent = (rent, history) => {
//   return {
//     type: CREATE_RENT,
//     payload: { rent, history },
//   };
// };
// export const rentList = (rent, history) => {
//   return {
//     type: RENT_LIST,
//     payload: { rent, history },
//   };
// };
// export const activeRent = (rent, history) => {
//   return {
//     type: ACTIVE_RENT,
//     payload: { rent, history },
//   };
// };
// export const deactiveRent = (rent, history) => {
//   return {
//     type: DEACTIVE_RENT,
//     payload: { rent, history },
//   };
// };

// export const updateRent = (userRent, history) => {
//   return {
//     type: UPDATE_RENT,
//     payload: { userRent, history },
//   };
// };
// export const createTransactions = (transaction, history) => {
//   return {
//     type: CREATE_TRANSCTIONS,
//     payload: { transaction, history },
//   };
// };
// export const transactiontList = (transaction, history) => {
//   return {
//     type: TRANSACTION_LIST,
//     payload: { transaction, history },
//   };
// };

// export const getOccupiedLockerList = (lockerList, history) => {
//   return {
//     type: OCCUPIED_LOCKER,
//     payload: { lockerList, history },
//   }
// }

// export const lockersCount = (lockers, history) => {
//   return {
//     type: LOCKERS_COUNT,
//     payload: { lockers, history },
//   }
// }
// export const customerCount = (customer, history) => {
//   return {
//     type: CUSTOMERS_COUNT,
//     payload: { customer, history },
//   }
// }
// export const TransactionCount = (transaction, history) => {
//   return {
//     type: TRANSCTIONS_COUNT,
//     payload: { transaction, history },
//   }
// }