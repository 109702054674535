import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import Swal from "sweetalert2"

const LockerFreezed = props => {
  document.title = "Authentication | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Authentication", link: "#" },
  ]
  useEffect(() => {
    props.setBreadcrumbItems("Authentication", breadcrumbItems)
  })

  return (
    <div>
      <Card>
        <CardBody>
          {/* {
            Swal.fire({
            title: "Login Successfully",
            icon: "success",
          })} */}
          <div style={{ textAlign: "center" }}>
            {/* <button type="submit" onClick={login}>
              Login
            </button> */}
            <i
              className="checkmark"
              style={{
                color: "	#FF0000",
                fontSize: "100px",
                lineHeight: "200px",
                marginLeft: "-15px",
              }}
            >
              ?
            </i>
            <h2>Your Locker is Freezed</h2>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(LockerFreezed)
