import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"

import { getCustomerReportAPI } from "service/service"
import DataTable from "react-data-table-component"
import { Card, CardBody, CardTitle } from "reactstrap"
import { ExportToExcel } from "./ExportToExcel"

const CustomerReport = props => {
  document.title = "Customer Report | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Customer report", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Customer Report", breadcrumbItems)
  })

  const [customerList, setCustomerList] = useState([])
  const [customer, setCustomer] = useState([])

  const displayCustomerReport = async () => {
    const response = await getCustomerReportAPI()
    // console.log("Response - ", response);
    if (response.status === 1 || response.status === "1") {
      setCustomerList(response.data)
      const postData = response.data
      const customHeadings = postData.map((item, index) => ({
        "Sr. NO.": index + 1,
        "Customer Name": item.fullName,
        "Customer Email": item.email,
        "Customer Occupation": item.occupation,
        "Customer Mobile No": item.mobile,
        "Customer Telephone": item.mobile,
        "Customer KYC Status": item.kycStatus,
      }))

      setCustomer(customHeadings)
    } else {
      setCustomerList([])
    }
  }

  const [columns, setColumns] = useState([])
  const [pending, setPending] = useState(true)

  const customStyles = {
    // rows: {
    //   style: {
    //     minHeight: "72px", // override the row height
    //   },
    // },
    headCells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for head cells
        // paddingRight: "8px",
        fontSize: "15px",
      },
    },
    // cells: {
    //   style: {
    //     paddingLeft: "8px", // override the cell padding for data cells
    //     paddingRight: "8px",
    //   },
    // },
  }

  useEffect(() => {
    displayCustomerReport()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: "Name",
          selector: row => row.fullName,

        },
        {
          name: "Email",
          selector: row => row.email,
        },
        {
          name: "Occupation",
          selector: row => row.occupation,
        },
        {
          name: "Mobile",
          selector: row => row.mobile,
        },
        {
          name: "Telephone",
          selector: row => row.telephone,
        },
        {
          name: "KYC Status",
          selector: row => row.kycStatus,
        },
      ])
      setPending(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <Card className="mt-3">
        <CardBody>
          <CardTitle
            className="fs-4 fw-bolder d-flex "
            style={{
              borderBottom: "1px solid #e3d0d0",
              justifyContent: "space-between",
            }}
          >
            {" "}
            Customers Report
            <ExportToExcel apiData={customer} fileName={"CustomersList"} />
          </CardTitle>
          
          <DataTable
            className="mt-3"
            columns={columns}
            data={customerList}
            fixedHeader
            pagination
            customStyles={customStyles}
            progressPending={pending}
            highlightOnHover
          ></DataTable>
        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CustomerReport)
