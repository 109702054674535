import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, Col, Row, Label, Input, Button } from "reactstrap"
import {
  createRentAPI,
  getRentByIdAPI,
  getSearchLockerList,
  updateRentByIdAPI,
} from "service/service"
import { useNavigate, useParams } from "react-router-dom"

import Swal from "sweetalert2"
import Select from "react-select"

const CreateRent = props => {
  const { id } = useParams()
  document.title = id ? "Update Rent | Shreesh Securities" : "Create Rent | Shreesh Securities"

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: id ? "Update Rent" : "Create Rent", link: "#" },
  ]

  const re = /^[A-Za-z0-9\s]+$/;
  const regex = /^[0-9\b]+$/;
  const [locker, setLocker] = useState([])
  const [updateLocker, setUpdateLocker] = useState([])
  const [userLocker, setUserLocker] = useState("")
  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")
  const [rentPrice, setRentPrice] = useState("")
  const [minCharge, setMinCharge] = useState("")
  const [minDay, setMinDay] = useState("")
  // const [status, setStatus] = useState(true)
  const history = useNavigate();

  const handleDisplaylockerList = async () => {
    try {
      if (id) {
        let temp = []
        const res = await getSearchLockerList(userLocker)
        // setLocker(res.data)
        res?.data.length > 0 && res?.data.map((data) => {
          if (data.status === true) {
            temp.push(data)
          }
          // console.log(temp);
          setLocker(temp)
        })
      } else {
        let temp = [];
        const res = await getSearchLockerList()
        res?.data.length > 0 && res?.data.map((data) => {
          if (data.status === true) {
            temp.push(data)
          }
          // console.log(temp);
          setLocker(temp)
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelect = e => {
    const value = e.value
    // console.log(value);
    setUserLocker(value)
  }


  const handleChangeName = e => {
    const { value } = e.target;
    // if (value === "" || re.test(value)) {
    setName(value);
    // }
    // setName(e.target.value)
  }

  const handleChangeDesc = e => {
    setDesc(e.target.value)
  }

  const handleChangeRentPrice = e => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setRentPrice(e.target.value)
    }
  }

  const handleChangeMinCharge = e => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMinCharge(e.target.value)
    }
  }

  const handleChangeMinDay = e => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMinDay(e.target.value)
    }
  }

  // const handleStatus = e => {
  //   setStatus(!status)
  // }

  const handleSubmit = async () => {
    if (id) {
      let name = document.getElementById("name").value
      let desc = document.getElementById("desc").value
      let rentPrice = document.getElementById("rentPrice").value
      let minCharge = document.getElementById("minCharge").value
      let minDay = document.getElementById("minDay").value

      try {
        const res = await updateRentByIdAPI(
          id,
          userLocker,
          name,
          desc,
          rentPrice,
          minCharge,
          minDay,
          // status
        )
        handleDisplaylockerList()
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500,
          })
          history("/rentlist")
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await createRentAPI(
          userLocker,
          name,
          desc,
          rentPrice,
          minCharge,
          minDay,
          // status
        )
        setLocker(res.data)
        handleDisplaylockerList()
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500,
          })
          history("/rentlist")
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleUpdateRentValue = async () => {
    if (id) {
      const res = await getRentByIdAPI(id)
      setUpdateLocker(res.data)
      setUserLocker(res.data.lockerId)
    }
  }


  // console.log(updateLocker);
  const handleValue = () => {
    if (id) {
      // document.getElementById("LockerName").value = updateLocker.name
      // document.getElementById("name").value = updateLocker.name
      // document.getElementById("desc").value = updateLocker.description
      // document.getElementById("rentPrice").value = updateLocker.rentPrice
      // document.getElementById("minCharge").value = updateLocker.minimumCharge
      // document.getElementById("minDay").value = updateLocker.minimumDurationDay
      setLocker(updateLocker.locker)
      setName(updateLocker.name);
      setDesc(updateLocker.description);
      setRentPrice(updateLocker.rentPrice);
      setMinCharge(updateLocker.minimumCharge);
      setMinDay(updateLocker.minimumDurationDay);
      // setStatus(updateLocker.status)
    }
  }

  const handleClear = async () => {
    setName("");
    setDesc("");
    setRentPrice("");
    setMinCharge("");
    setMinDay("");
    setUserLocker("")
  }

  useEffect(() => {
    props.setBreadcrumbItems(id ? "Update Rent" : "Create Rent", breadcrumbItems)
  }, [window.location.href])

  useEffect(() => {
    handleClear()
  }, [window.location.href])

  useEffect(() => {
    handleDisplaylockerList()
    handleValue();
    //eslint-disable-next-line
  }, [userLocker])

  useEffect(() => {
    handleUpdateRentValue();
    //eslint-disable-next-line
  }, [id])

  const options = [
    locker &&
    locker.length > 0 &&
    locker.map(data => {
      return { value: data.id, label: data.name }
    }),
  ]

  const handleCancle = async () => {
    history("/rentlist")
  }

  return (
    <div>
      <Row className="mt-3">
        <Col xl={6} lg={8}>
          <div className="d-flex justify-content-end mb-2">
            <Button
              type="button"
              className={id ? "btn btn-primary w-md ms-3" : "d-none"}

              onClick={handleCancle}
            >
              Go Back
            </Button>
          </div>
          {/* <CardTitle>Create Customer</CardTitle> */}
          <Card >
            <CardBody>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Select Locker Category*
                </Label>
                <div>
                  {id ? (
                    <select
                      className="form-control"
                      id="LockerName"
                      // onChange={handleSelect}
                      value={updateLocker.id}
                    >
                      {updateLocker && (
                        <option value={updateLocker?.locker?.id} selected>
                          {updateLocker?.locker && updateLocker?.locker?.name}
                        </option>
                      )}
                    </select>

                  ) : (
                    <div>
                      {options &&
                        options.map((data, i) => (
                          <Select
                            key={i}
                            options={data}
                            onChange={handleSelect}
                            value={data.id}
                          />
                        ))}

                    </div>
                  )}
                </div>
              </div>

              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Plan Name*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    required
                    onChange={handleChangeName}
                    value={name}
                  />
                </div>
              </div>

              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Description
                </Label>
                <Input
                  type="textarea"
                  placeholder="Enter Description"
                  name="description"
                  id="desc"
                  rows="3"
                  value={desc}
                  onChange={handleChangeDesc}
                />
              </div>

              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Rent Price*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    id="rentPrice"
                    name="rentPrice"
                    placeholder="Enter Rent Price"
                    required
                    pattern="^[0-9\b]+$"
                    value={rentPrice}
                    onChange={handleChangeRentPrice}
                  />
                </div>
              </div>

              <Row>
                <Col md={6}>
                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Minimum Charge*
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="text"
                        name="minimumCharge"
                        id="minCharge"
                        placeholder="Enter Minimum Charge"
                        required
                        pattern="^[0-9\b]+$"
                        value={minCharge}
                        onChange={handleChangeMinCharge}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Minimum Duration Day*
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="text"
                        id="minDay"
                        name="minimumDurationDay"
                        placeholder="Enter Minimum Duration Day"
                        required
                        pattern="^[0-9\b]+$"
                        value={minDay}
                        onChange={handleChangeMinDay}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {/* <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Status
                </Label>
                <div>
                  <Input
                    type="radio"
                    name="status"
                    id="active"
                    checked={status === true}
                    value="true"
                    onClick={() => handleStatus()}
                  />
                  &nbsp;Active&nbsp;&nbsp;
                  <Input
                    type="radio"
                    name="status"
                    id="inactive"
                    checked={status === false}
                    value="false"
                    onClick={() => handleStatus()}
                  />
                  &nbsp;Inactive
                </div>
              </div> */}

              <div className="mt-4">
                <Button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={() => handleSubmit()}
                >
                  {id ? "Update Rent" : "Create Rent"}
                </Button>
                <Button
                  type="button"
                  className="btn btn-danger w-md ms-3"

                  onClick={id ? handleCancle : handleClear}
                >
                  {id ? "Cancel" : "Clear"}
                </Button>
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(CreateRent)
