import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link, useLocation } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const SidebarContent = props => {
  const ref = useRef()
  const userRights = useSelector(state => state.Profile.userRights)
  const location = useLocation();


  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    // activeMenu()
    // }, [activeMenu])
  }, [])

  function tToggle() {
    var body = document.body;
    // body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li className={userRights.dashboard?.display ? "d-block" : "d-none"}>
              <Link to="/dashboard" onClick={() => {tToggle()}} className={`${location.pathname === "/dashboard" ? "mm-active" : ""}`}>
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li className={userRights.staff?.display ? "d-block" : "d-none"}>
              <Link className="has-arrow waves-effect" >
                <i className="fas fa-users"></i>
                <span>{props.t("Staff Management")}</span>
              </Link>
              <ul className="sub-menu">
                {userRights.staff?.write && (
                  <li>
                    <Link to="/createstaff" onClick={() => {tToggle()}} className={`${location.pathname === "/createstaff" ? "waves-effect mm-active" : ""}`}>{props.t("Create Staff")}</Link>
                  </li>
                )}
                {userRights.staff?.read && (
                  <li>
                    <Link to="/stafflist" onClick={() => {tToggle()}} className={`${location.pathname === "/stafflist" ? "waves-effect mm-active" : ""}`}>{props.t("Staff List")}</Link>
                  </li>
                )}
              </ul>
            </li>

            <li className={userRights.locker?.display ? "d-block" : "d-none"}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="mdi mdi-locker-multiple"></i>
                <span>{props.t("Locker Management")}</span>
              </Link>
              <ul className="sub-menu">
                {userRights.locker?.write && (
                  <li>
                    <Link to="/createlocker" onClick={() => {tToggle()}} className={`${location.pathname === "/createlocker" ? "waves-effect mm-active" : ""}`}>{props.t("Create Locker")}</Link>
                  </li>
                )}
                {userRights.locker?.read && (
                  <li>
                    <Link to="/lockerlist" onClick={() => {tToggle()}} className={`${location.pathname === "/lockerlist" ? " mm-active" : ""}`}>{props.t("Locker List")} </Link>
                  </li>
                )}
              </ul>
            </li>

            <li className={userRights.lockerRent?.display ? "d-block" : "d-none"}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="mdi mdi-bank"></i>
                <span>{props.t("Rent Management")}</span>
              </Link>
              <ul className="sub-menu">
                {userRights.lockerRent?.write && (
                  <li>
                    <Link to="/createrent" onClick={() => {tToggle()}} className={`${location.pathname === "/createrent" ? "waves-effect mm-active" : ""}`}>{props.t("Create Rent")}</Link>
                  </li>
                )}
                {userRights.lockerRent?.read && (
                  <li>
                    <Link to="/rentlist" onClick={() => {tToggle()}} className={`${location.pathname === "/rentlist" ? "waves-effect mm-active" : ""}`}>{props.t("Rent List")} </Link>
                  </li>
                )}
              </ul>
            </li>

            <li className={userRights.customer?.display ? "d-block" : "d-none"}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="fas fa-user-friends"></i>
                <span>{props.t("Customer Management")}</span>
              </Link>
              <ul className="sub-menu">
                {userRights.customer?.write && (
                  <li>
                    <Link to="/customerIndex" onClick={() => {tToggle()}} className={`${location.pathname === "/customerIndex" ? "waves-effect mm-active" : ""}`}>
                      {props.t("Create Customer")}
                    </Link>
                  </li>
                )}

                {userRights.customer?.read && (
                  <li>
                    <Link to="/customerlist" onClick={() => {tToggle()}} className={`${location.pathname === "/customerlist" ? "waves-effect mm-active" : ""}`}>{props.t("Customer List")} </Link>
                  </li>
                )}
                {userRights.customerKyc?.display && (
                  <li>
                    <Link to="/customerKyc" onClick={() => {tToggle()}} className={`${location.pathname === "/customerKyc" ? "waves-effect mm-active" : ""}`}>{props.t("Customer KYC")} </Link>
                  </li>
                )}
                {userRights.customer?.write && (
                  <li>
                    <Link to="/CustomerLocker" onClick={() => {tToggle()}} className={`${location.pathname === "/CustomerLocker" ? "waves-effect mm-active" : ""}`}>{props.t("Locker Assign")}</Link>
                  </li>
                )}
                {userRights.customer?.read && (
                  <li>
                    <Link to="/assignedLockerList" onClick={() => {tToggle()}} className={`${location.pathname === "/assignedLockerList" ? "waves-effect mm-active" : ""}`}>
                      {props.t("Assigned Locker List")}{" "}
                    </Link>
                  </li>
                )}
                {userRights.customerLockerAction?.display && (
                  <li>
                    <Link to="/assignedLockerAction" onClick={() => {tToggle()}} className={`${location.pathname === "/assignedLockerAction" ? "waves-effect mm-active" : ""}`}>
                      {props.t("Assigned Locker Action")}{" "}
                    </Link>
                  </li>
                )}
                {userRights.customer?.read && (
                  <li>
                    <Link to="/lockerExpire" onClick={() => {tToggle()}} className={`${location.pathname === "/lockerExpire" ? "waves-effect mm-active" : ""}`}>{props.t("Locker Expire")} </Link>
                  </li>
                )}

              </ul>
            </li>

            <li className={userRights.transaction?.display ? "d-block" : "d-none"}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="mdi mdi-currency-inr"></i>
                <span>{props.t("Transaction Management")}</span>
              </Link>
              <ul className="sub-menu">

                {userRights.transaction?.read && (
                  <li>
                    <Link to="/transactionList" onClick={() => {tToggle()}} className={`${location.pathname === "/transactionList" ? "waves-effect mm-active" : ""}`}>
                      {props.t("Transaction List")}{" "}
                    </Link>
                  </li>
                )}
              </ul>
            </li>

            <li className={userRights.settings?.display ? "d-block" : "d-none"}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="fas fa-cog"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                {/* {userRights.user_role?.display && ( */}
                <li className={userRights.user_role?.display ? "d-block" : "d-none"}>
                  <Link to="/role" onClick={() => {tToggle()}} className={`${location.pathname === "/role" ? "waves-effect mm-active" : ""}`}>{props.t("Create Role")}</Link>
                </li>
                {/* )} */}
                {/* {userRights.user_right?.display && ( */}
                <li className={userRights.user_right?.display ? "d-block" : "d-none"}>
                  <Link to="/rights" onClick={() => {tToggle()}} className={`${location.pathname === "/rights" ? "waves-effect mm-active" : ""}`}>{props.t("Create Rights")}</Link>
                </li>
                {/* )} */}
                {/* {userRights.role_to_right?.display && ( */}
                <li className={userRights.role_to_right?.display ? "d-block" : "d-none"}>
                  <Link to="/roletoright" onClick={() => {tToggle()}} className={`${location.pathname === "/roletoright" ? "waves-effect mm-active" : ""}`}>{props.t("Role To Right")} </Link>
                </li>
                {/* )} */}
              </ul>
            </li>

            <li className={userRights.report?.display ? "d-block" : "d-none"}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="fas fa-clipboard-list"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/totalLockersReport" onClick={() => {tToggle()}} className={`${location.pathname === "/totalLockersReport" ? "waves-effect mm-active" : ""}`}>
                    {props.t("Total Lockers Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/customersReport" onClick={() => {tToggle()}} className={`${location.pathname === "/customersReport" ? "waves-effect mm-active" : ""}`}>
                    {props.t("Customers Report")}
                  </Link>
                </li>

                <li>
                  <Link to="/transactionReport" onClick={() => {tToggle()}} className={`${location.pathname === "/transationReport" ? "waves-effect mm-active" : ""}`}>
                    {props.t("Transactions Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/lockersReport" onClick={() => {tToggle()}} className={`${location.pathname === "/lockersReport" ? "waves-effect mm-active" : ""}`}>{props.t("Lockers Access Report")}</Link>
                </li>
                <li>
                  <Link to="/unOperatedLockerReport" onClick={() => {tToggle()}} className={`${location.pathname === "/unOperatedLockerReport" ? "waves-effect mm-active" : ""}`}>{props.t("UnOperated Lockers Report")}</Link>
                </li>
              </ul>
            </li>

            <li className={userRights.biometric?.display ? "d-block" : "d-none"}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="fas fa-fingerprint"></i>
                <span>{props.t("Biometric")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/fetchFingerPrint" onClick={() => {tToggle()}} className={`${location.pathname === "/fetchFingerPrint" ? "waves-effect mm-active" : ""}`}>
                    {props.t("Customer Register FingerPrint")}
                  </Link>
                </li>
                <li>
                  <Link to="/customerAuthenticate" onClick={() => {tToggle()}} className={`${location.pathname === "/customerAuthenticate" ? "waves-effect mm-active" : ""}`}>
                    {props.t("Customer Authenticate")}
                  </Link>
                </li>
              </ul>
            </li>

            <li className={userRights.SMSregistration?.display ? "d-block" : "d-none"}>
              <Link to="/smsRegistration" onClick={() => {tToggle()}} className={`${location.pathname === "/smsRegistration" ? "mm-active" : ""}`}>
                <i className="mdi mdi mdi-comment-text"></i>
                <span>{props.t("SMS Registration")}</span>
              </Link>
            </li>
          </ul>

        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
