import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import user1 from "../../assets/images/users/user-1.jpg";
import moment from "moment"
import {
  changeLockerPlanAPI,
  getLockerByIdAPI,
  getOccupiedLocker,
  getOccupiedLockerByIdAPI,
  getRentByIdAPI,
  getRentList,
  getSearchLockerList,
} from "service/service"
import Tab from '@mui/material/Tab';


import Select from "react-select"
import { gst } from "api/apiClient"
import Swal from "sweetalert2"
import { useNavigate, useParams } from "react-router-dom"
import ImageViewer from "ImageViewer"
import { Tabs, useMediaQuery } from "@mui/material";
import AddNewLockerPlan from "./AddNewLockerPlan";
// import { useSelector } from "react-redux"
// import { assignLockerToCustomer } from "service/backendRoutes"

const CustomerLocker = props => {
  document.title = "Change Locker Plan | Shreesh - Locker Management Syatem"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Locker Expire", link: "/lockerExpire" },
    { title: "Change Locker Plan", link: "#" },
  ]


  const re = /^[A-Za-z\s]+$/;
  const [locker, setLocker] = useState([])
  const [rent, setRent] = useState([])
  const { id } = useParams();
  const [cusLocker, setCusLocker] = useState("");
  const [customer, setCustomer] = useState("");
  const [penalty, setPenalty] = useState(0);
  const [planDate, setPlanDate] = useState("")
  // const [selectedLockerNoValue, setSelectedLockerNoValue] = useState("")
  // const [lockerKey, setLockerKey] = useState("")
  // const [entryFees, setEntryFees] = useState("")
  // const [userLocker, setUserLocker] = useState()
  const [userRent, setUserRent] = useState("")
  const [lockerDetails, setLockerDetails] = useState()
  const [rentName, setRentName] = useState()
  const [rentDetails, setRentDetails] = useState()
  const [occupied, setOccupied] = useState([])

  const smallScreen = useMediaQuery("(max-width: 600px)")
  const [value, setValue] = React.useState(0);


  const history = useNavigate()

  const handleDisplaylockerList = async e => {
    try {
      const res = await getSearchLockerList()
      setLocker(res.data)
    } catch (error) {
      console.log(error);
    }
  }


  const handleDisplayrentList = async () => {
    try {
      const res = await getRentList()
      setRent(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  // const handleSelectChangeLockerNo = e => {
  //   const value = e.value
  //   setSelectedLockerNoValue(value)
  // }

  // const handleSelect = e => {
  //   const value = e.value
  //   setUserLocker(value)
  //   setRentName([])
  //   setRentDetails([])
  //   setUserRent('')
  // }

  const handleSelect2 = e => {
    const value = e.value
    setUserRent(value)
  }

  const displayLockerById = async () => {
    // const id = userLocker
    const id = cusLocker?.lockerId
    const res = await getLockerByIdAPI(id)
    setLockerDetails(res.data)
    const rentData = res.data

    const response = await getOccupiedLocker()
    setOccupied(response.data)
    const temp = []

    rentData.rents &&
      rentData.rents.length > 0 &&
      rentData.rents.map(data => {
        if (data.status === true) {
          // setRentDetails(data)
          temp.push({ value: data.id, label: data.name })
        }
      })
    setRentName([temp])
  }

  const getRentById = async () => {
    try {
      const res = await getRentByIdAPI(userRent)
      setRentDetails(res?.data)
    } catch (error) {
      throw (error)
    }
  }



  // const options = [
  //   locker.map(data => {
  //     return { value: data.id, label: data.name }
  //   }),
  // ]

  // const filterLockerNo = () => {
  //   let temp = []
  //   let tempLocker = []
  //   occupied && occupied.length > 0 &&
  //     occupied.map((item) => {
  //       tempLocker.push(item.lockerNo)
  //     })
  //   // console.log(tempLocker);
  //   lockerDetails &&
  //     lockerNumber &&
  //     lockerNumber.map(data => {
  //       if (data.lockerNo.includes(lockerDetails.name)) {
  //         if (!(tempLocker.includes(data.lockerNo)))
  //           temp.push({ value: data.lockerNo, label: data.lockerNo })
  //       }
  //     })
  //   return temp
  // }

  const [calculatedPenalty, setCalculatedPenalty] = useState(0);

  // const rentTotal =
  //   rentDetails?.rentPrice * rentDetails?.minimumDurationDay +
  //   (rentDetails?.rentPrice * rentDetails?.minimumDurationDay * gst) / 100
  const rentTotal =
    (rentDetails?.rentPrice + (rentDetails?.rentPrice * gst) / 100)
  // const totalRent = Number(entryFees) + (Number(entryFees) * gst) / 100
  const finalRent = Math.round(rentTotal)
  // const totalAmount = lockerDetails?.lockerDeposit + finalRent + calculatedPenalty
  const totalAmount = finalRent + calculatedPenalty

  // const occupiedLockerCustomer = async () => {
  //   try {
  //     if (id) {
  //       const res = await getOccupiedLockerByIdAPI(id);
  //       // console.log(res.data.locker);
  //       setCustomer(res.data)
  //       setCusLocker(res.data.locker);

  //       // console.log(cuslockerdata);
  //     }
  //   } catch (error) {

  //   }
  // }

  const occupiedLockerCustomer = async () => {
    try {
      if (id) {
        const res = await getOccupiedLockerByIdAPI(id);
        setCustomer(res.data)
        // console.log("locker ID = ", res.data.locker.lockerId);
        setCusLocker(res.data.locker);
        const cuslockerdata = res.data.locker;

        // cuslockerdata.lockerName = "XL"

        // const startDate = new Date("2024-04-02")
        // const lastDate = new Date("2024-05-01")
        const startDate = new Date(cuslockerdata?.planStartDate)
        const lastDate = new Date(cuslockerdata?.planEndDate)

        // year
        const differenceForYear = lastDate - startDate;
        console.log("differenceForYear = ", differenceForYear);
        const countDays = Math.ceil(differenceForYear / (1000 * 60 * 60 * 24));
        console.log("countDays = ", countDays);

        if (countDays > 900) {
          // const currentDateObject = new Date("2027-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
          }
        }
        else if (countDays > 600) {
          // const currentDateObject = new Date("2026-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
          }
        }
        else if (countDays > 300) {
          // const currentDateObject = new Date("2027-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
          }
        } else {
          setCalculatedPenalty(0)
        }

        // month
        // const currentDateObject = new Date("2024-05-02");
        const currentDateObject = new Date();
        // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
        const differenceInMilliseconds = currentDateObject - lastDate;
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        console.log("differenceInDays = ", differenceInDays);
        if (differenceInDays >= 0) {
          // let penalty = 0
          // penalty = (500 * differenceInDays)
          // // penalty = (penalty * 100) / 18
          // let dayGst = penalty + (penalty * gst) / 100
          // setCalculatedPenalty(dayGst)
          // console.log("Penalty Days = ", dayGst);
          if (cuslockerdata.lockerName === "B") {
            let penalty = 0
            penalty = (500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "C") {
            let penalty = 0
            penalty = (750 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "F") {
            let penalty = 0
            penalty = (1000 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "L") {
            let penalty = 0
            penalty = (1500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "XL") {
            let penalty = 0
            penalty = (2500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
        }
        // setRentId(cuslockerdata.rentId)
        // setCusLockerNo(cuslockerdata.customerLockerNo)
        // setCusRentName(cuslockerdata.rentName)
        // getRant(res.data.locker.rentId)
      } else {
        setCalculatedPenalty(0)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    // console.log(
    //   "customerLockerId = ", cusLocker?.customerLockerId + "\n ",
    //   "rentId = ", userRent + "\n ",
    //   "customerLockerKey = ", cusLocker?.customerLockerKey + "\n ",
    //   "customerLockerNo = ", cusLocker?.customerLockerNo + "\n ",
    //   "planStartDate = ", planDate + "\n ",
    //   // "entryFees = ", totalRent + "\n ",
    //   "penalty = ", calculatedPenalty + "\n ",
    //   "gst = ", gst + "\n ",);
    try {
      e.preventDefault();
      const response = await changeLockerPlanAPI(
        cusLocker?.customerLockerId,
        userRent,
        cusLocker?.customerLockerKey,
        cusLocker?.customerLockerNo,
        planDate,
        // totalRent,
        // penalty
        calculatedPenalty,
        gst
      )

      if (response.status === "1" || response.status === 1) {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/lockerExpire")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    props.setBreadcrumbItems("Change Locker Plan", breadcrumbItems)
  })

  useEffect(() => {
    handleDisplaylockerList()
    handleDisplayrentList()
  }, [""])

  useEffect(() => {
    displayLockerById()
    getRentById()
    // filterLockerNo()
    //eslint-disable-next-line
  }, [userRent, cusLocker])

  useEffect(() => {
    occupiedLockerCustomer();
  }, [])

  // console.log("locker Id = ", cusLocker.lockerId);

  return (
    <>

      <Card className="mt-3">
        <CardBody>
          <CardTitle className="fs-4">
            <b>Customer Old Plan Details</b>
          </CardTitle>
          <hr />
          <Row>
            <Col lg={2} className="position-relative">
              <h6 className="fw-bold ">Photo</h6>
              <div className="mt-1">
                {customer.photo ?
                  <ImageViewer
                    path={customer.photo}
                    style={{ width: "8rem", height: "7rem", cursor: 'pointer' }}
                  />
                  :
                  <ImageViewer
                    path={user1}
                    style={{ width: "8rem", height: "7rem", cursor: 'pointer' }}
                  />
                }
              </div>
            </Col>
            <Col lg={10}>
              <Row>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Customer Name</h6>
                  <p>{customer.fullName}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Email</h6>
                  <p>{customer.email}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Locker No</h6>
                  <p>{cusLocker.customerLockerNo}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Locker Key</h6>
                  <p>{cusLocker.customerLockerKey}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Plan Name</h6>
                  <p>{cusLocker.rentName}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Plan Start Date</h6>
                  <p> {moment(cusLocker.planStartDate).format(
                    "DD/MM/YYYY"
                  )}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Plan End Date</h6>
                  <p> {moment(cusLocker.planEndDate).format(
                    "DD/MM/YYYY"
                  )}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Locker Status</h6>
                  <p>{cusLocker.lockerStatus}</p>
                </Col>

              </Row>
            </Col>

          </Row>

        </CardBody>
      </Card>

      <Tabs
        value={value}
        textColor="primary"
        indicatorColor="primary"
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        style={{ borderBottom: "1px solid gray" }}
        orientation={smallScreen ? "vertical" : "horizontal"}
        classes={{
          flexContainer: "flex-wrap sm:flex-nowrap",
        }}
        className="tabs"
      >
        <Tab className="top-tab" label="Change Existing Locker Plan" style={{ fontWeight: "600" }} />
        <Tab className="top-tab" label="Change Locker Category & Plan" style={{ fontWeight: "600" }} />

        {/* <Tab className="top-tab" label="Locker Action" style={{ fontWeight: "600" }} /> */}

      </Tabs>
      {value === 0 && (
        <div className="d-flex justify-content-start">
          <Row className=" w-100">
            <Col lg={8}>
              <Card>
                <CardBody>
                  <Form>
                    {/* <CardTitle className="fs-4 text-center">
                      <b>Change Locker Plan</b>
                    </CardTitle> */}
                    <Row>
                      <div className="py-1">
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Locker Category
                        </Label>
                        <select
                          className="form-control"
                          id="LockerName"
                          // onChange={handleSelect}
                          value={cusLocker?.lockerId}
                        >
                          {cusLocker && (
                            <option value={cusLocker?.lockerId} selected>
                              {cusLocker && cusLocker?.lockerName}
                            </option>
                          )}
                        </select>
                        {/* {options &&
                        options.map(data => (
                          <Select
                            options={data}
                            onChange={handleSelect}
                            isSearchable={true}
                          />
                        ))} */}
                      </div>
                    </Row>
                    {cusLocker ? (
                      <div className="pt-2">
                        <h5> <b>Locker Details</b> </h5>
                        <hr />
                        <Row className="fw-bold view-table">
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Locker Name</h6>
                            <p>{lockerDetails?.name}</p>
                          </Col>
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Locker Size</h6>
                            <p>{lockerDetails?.lockerSize}</p>
                          </Col>
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Locker Category</h6>
                            <p>{lockerDetails?.lockerCategory}</p>
                          </Col>
                          {/* <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">
                            Locker Deposit (Refundable)
                          </h6>
                          <p>{lockerDetails?.lockerDeposit}</p>
                        </Col> */}
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}


                    <Row>
                      <div className="py-1">
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Select Rent*
                        </Label>
                        <div>
                          {/* <select className="form-control" id="LockerName"></select> */}
                          {/* <Input value={rentName} /> */}
                          {rentName &&
                            rentName.map(data => (
                              <Select
                                options={data}
                                onChange={handleSelect2}
                                isSearchable={true}
                                placeholder={data.label}
                              />
                            ))}
                        </div>
                      </div>
                    </Row>

                    {userRent ? (
                      <Row className="mt-2">
                        <Col lg={12}>

                          <CardTitle className="fs-5">
                            <b>Rent Details</b>
                          </CardTitle>
                          <hr />
                          <Row className="fw-bold view-table">
                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Rent Name</h6>
                              <p>{rentDetails?.name}</p>
                            </Col>

                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Rent Price</h6>
                              <p>{rentDetails?.rentPrice}</p>
                            </Col>

                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Duration Day</h6>
                              <p>{rentDetails?.minimumDurationDay}</p>
                            </Col>
                            {/* <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Rent Price</h6>
                                <p>
                                  {rentDetails?.rentPrice *
                                    rentDetails?.minimumDurationDay}
                                </p>
                              </Col> */}

                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">GST (18%)</h6>
                              <p>
                                {(rentDetails?.rentPrice * gst) / 100}
                              </p>
                            </Col>
                            <Col xl={3} lg={4} md={6} sm={12}>
                              <h6 className="fw-bold">Total Rent</h6>
                              <p>{rentTotal}</p>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Locker No
                      </Label>
                      <div>
                        <Input type="text" value={cusLocker?.customerLockerNo} disabled />
                        {/* {lockerDetails && (
                        <Select
                          options={filterLockerNo()}
                          onChange={handleSelectChangeLockerNo}
                          isSearchable={true}
                        />
                      )} */}
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Locker Key
                      </Label>
                      <div>
                        <Input type="text" value={cusLocker?.customerLockerKey} disabled />
                        {/* <Input
                        className="form-control"
                        type="text"
                        name="customerLockerKey"
                        id="customerLockerKey"
                        placeholder="Enter Customer Locker Key"
                        required
                        value={lockerKey}
                        onChange={e => {
                          setLockerKey(e.target.value)
                        }}
                      /> */}
                      </div>
                    </div>

                    <div className="py-1">
                      <Label htmlFor="example-text-input" className=" col-form-label">
                        Plan Start Date*
                      </Label>
                      <div>

                        <Input
                          className="form-control"
                          id="planStartDate"
                          type="date"
                          name="planStartDate"
                          required
                          placeholder="Select Plan Start Date"
                          pattern="\d{2}-\d{2}-\d{4}"
                          value={planDate}
                          onChange={e => {
                            setPlanDate(e.target.value)
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Entry Fees
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="entryFees"
                        id="entryFees"
                        placeholder="Enter Entry Fees"
                        required
                        onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                        value={entryFees}
                        onChange={e => {
                          setEntryFees(e.target.value)
                        }}
                      />
                    </div>
                  </div> */}

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Late Fees
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="number"
                          name="panalty"
                          id="panalty"
                          placeholder="Enter Late Fees"
                          required
                          disabled
                          // onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                          value={calculatedPenalty}
                        // onChange={e => {
                        //   setPenalty(e.target.value)
                        // }}
                        />
                      </div>
                    </div>

                    <hr />
                    <Row className="fw-bold view-table">
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <h6 className="fw-bold">Final Rent</h6>
                        {/* <p>
                        {totalRent} + {rentTotal} = {finalRent}
                      </p> */}
                        <p>{finalRent}</p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="totalAmount">
                          <h6 className="fw-bold">Total payable amount</h6>
                          <p>{totalAmount}</p>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-4 d-flex gap-2">
                      <button
                        type="submit"
                        className="btn btn-primary w-md"
                        style={{ display: "flex", justifyContent: "center" }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary w-md"
                        style={{ display: "flex", justifyContent: "center" }}
                        onClick={() => { history("/lockerExpire") }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {value === 1 && (
        <AddNewLockerPlan />
      )}
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CustomerLocker)
