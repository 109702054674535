import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  setBreadcrumbItems,
} from "../../store/actions"
import { connect } from "react-redux"
import { CardTitle, Col, Form, Input, Label, Row } from "reactstrap"
import {
  createCustomerAPI,
  getCustomerByIdAPI,
  getSearchCustomerList,
  removeCustomerImg,
  updateCustomerAPI,
  uploadCustomerImg,
} from "service/service"
import Swal from "sweetalert2"
import Select from "react-select"
import CustomerSubHolderKYC from "./CustomerSubHolderKYC"
import ImageViewer from "ImageViewer"
import user1 from "../../assets/images/users/user-1.jpg"
// import dummy from "../../assets/images/users/dummy.jpg"
import Webcam from "react-webcam"

const CustomerSubholder = ({ id, count, onClickFunc }) => {

  document.title = "Create Customer Subholder | Shreesh Securities"
  const [fullName, setFullName] = useState("")
  const [occupation, setOccupation] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [telephone, setTelephone] = useState("");
  const [oldCustomerId, setOldCustomerId] = useState("")
  const [oldCustomer, setOldCustomer] = useState("")
  const [subId, setSubId] = useState()
  const [isSave, setIsSave] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false)
  const re = /^[A-Za-z\s]+$/;
  const primaryCustomerId = localStorage.getItem('customerID')

  const handleFullName = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setFullName(value);
    }
  }

  const handleOccupation = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setOccupation(value);
    }
    // setOccupation(e.target.value)
  }

  const handleEmail = e => {
    setEmail(e.target.value)
  }

  const handleMobileNo = e => {
    setMobile(e.target.value.slice(0, 10))
  }

  const handleTelephoneNo = e => {
    setTelephone(e.target.value)
  }

  const handleSelectOldCustId = e => {
    const value = e.value
    // console.log(value);
    setOldCustomerId(value)
  }

  const handleSetFildOld = async id => {
    const res = await getCustomerByIdAPI(id)
    setFullName(res?.data?.fullName)
    setOccupation(res?.data?.occupation)
    setEmail(res?.data?.email)
    setMobile(res?.data?.mobile)
    setTelephone(res?.data?.telephone)
    // let image = localStorage.getItem("SubHolder_Image")
    let image = res?.data?.photo
    setPhoto(image)
  }

  const handleDisplayOldCustomerList = async () => {
    try {
      let temp = []
      const res = await getSearchCustomerList()
      res.data && res.data.map((item) => {
        item.id !== primaryCustomerId && temp.push(item)
      })
      setOldCustomer(temp)
    } catch (error) {
      console.log(error);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (oldCustomerId) {
      try {
        const res = await updateCustomerAPI(
          oldCustomerId,
          fullName,
          occupation,
          email,
          mobile,
          telephone
        )
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          setSubId(res.data.id)
          setIsSave(!isSave)
          onClickFunc(true)
          let data = [JSON.parse(localStorage.getItem("subHolderId"))]
          if (data[0] !== null) {
            let temp = JSON.parse(localStorage.getItem("subHolderId"))
            temp[count] = res.data.id
            const arrayWithoutDuplicates = temp.filter((value, index, self) => {
              return self.indexOf(value) === index
            })
            localStorage.setItem(
              "subHolderId",
              JSON.stringify(arrayWithoutDuplicates)
            )
            // await addCustomerSubHolderAPI(primaryCustomerId, arrayWithoutDuplicates);
          } else {
            localStorage.setItem("subHolderId", JSON.stringify([res.data.id]))
            // await addCustomerSubHolderAPI(primaryCustomerId, [res.data.id]);
          }
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await createCustomerAPI(
          fullName,
          occupation,
          email,
          mobile,
          telephone
        )
        setIsRefresh(!isRefresh)
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          setSubId(res.data.id)
          onClickFunc(true)
          let data = [JSON.parse(localStorage.getItem("subHolderId"))]
          if (data[0] !== null) {
            let temp = JSON.parse(localStorage.getItem("subHolderId"))
            temp.push(res.data.id)
            const arrayWithoutDuplicates = temp.filter((value, index, self) => {
              return self.indexOf(value) === index
            })
            localStorage.setItem(
              "subHolderId",
              JSON.stringify(arrayWithoutDuplicates)
            )
            // await addCustomerSubHolderAPI(primaryCustomerId, arrayWithoutDuplicates);
          } else {
            localStorage.setItem("subHolderId", JSON.stringify([res.data.id]))
            // await addCustomerSubHolderAPI(primaryCustomerId, [res.data.id]);
          }
          localStorage.setItem("customerS1", true)
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const getSubData = async () => {
    if (subId) {
      await getCustomerByIdAPI(subId)
    }
  }

  const customerOptions = [
    oldCustomer &&
    oldCustomer.map(data => {
      return { value: data.id, label: data.fullName }
    }),
  ]

  useEffect(() => {
    handleDisplayOldCustomerList();
    handleSetFildOld(oldCustomerId);
    //eslint-disable-next-line
  }, [oldCustomerId])

  useEffect(() => {
    handleSetFildOld(id);
  }, [id])

  useEffect(() => {
    getSubData();
    // eslint-disable-next-line
  }, [subId])


  const [status, setStatus] = useState(1) // 0: no show, 1: show yes, 2: show no.

  const radioHandler = (status) => {
    setStatus(status);
  };

  const [camImage, setCamImage] = useState("");
  const [photo, setPhoto] = useState("");
  const [filePath, setFilePath] = useState("")

  const webcamRef = useRef(null)

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setCamImage(imageSrc)
  }, [webcamRef, setCamImage])

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }


  const uploadCustCameraImage = async e => {
    e.preventDefault()
    const file = dataURItoBlob(camImage)
    const cid = id

    console.log("cam - ", camImage);
    // console.log("cam - ", customerId);
    const response = await uploadCustomerImg(cid, file)
    if (response.status === 1 || response.status === "1") {
      setPhoto(response.data.photo)
      setCamImage("")
      setStatus(1)
      // localStorage.setItem("SubHolder_Image", response.data.photo)
      Swal.fire({
        title: response.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: response.message,
        icon: "error",
      })
    }
  }

  // const uploadCustomerImage = async e => {
  //   e.preventDefault()
  //   handleSetFildOld(id)
  //   setFilePath(URL.createObjectURL(e.target.files[0]))
  //   const file = e.target.files[0]
  //   const cid = id
  //   const response = await uploadCustomerImg(cid, file)
  //   if (response.status === 1 || response.status === "1") {
  //     setPhoto(response.data.photo)
  //     Swal.fire({
  //       title: response.message,
  //       icon: "success",
  //       timer: 1500
  //     })
  //   } else {
  //     Swal.fire({
  //       title: response.message,
  //       icon: "error",
  //     })
  //   }
  // }

  const uploadCustomerImage = async e => {
    e.preventDefault()
    console.log(JSON.parse(localStorage.getItem('subHolderId'))[count]);
    handleSetFildOld(JSON.parse(localStorage.getItem('subHolderId'))[count])
    setFilePath(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    const cid = JSON.parse(localStorage.getItem('subHolderId'))[count]
    const response = await uploadCustomerImg(cid, file)
    if (response.status === 1 || response.status === "1") {
      setPhoto(response.data.photo)
      // localStorage.setItem("SubHolder_Image", response.data.photo)
      Swal.fire({
        title: response.message,
        icon: "success",
      })
    } else {
      Swal.fire({
        title: response.message,
        icon: "error",
      })
    }
  }

  const removeImage = async e => {

    // const customerId = customerId
    const imagePath = photo
    const cid = JSON.parse(localStorage.getItem('subHolderId'))[count]
    const response = await removeCustomerImg(cid, imagePath)
    // dispatch(removeCustomerImgImage(customerId, imagePath))
    if (response.status === "1") {
      // localStorage.removeItem("SubHolder_Image")
      Swal.fire({
        title: response.message,
        icon: "success",
        timer: 1500
      })
      setPhoto("");
      setFilePath("")
    } else {
      Swal.fire({
        title: response.message,
        icon: "error",
      })
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="mt-4 w-100" style={{ maxWidth: "50rem" }}>
          <Col>
            <Form>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Select Old Customer
                </Label>
                <div>
                  {customerOptions.map(data => (
                    <Select
                      options={data}
                      value={data.label}
                      onChange={handleSelectOldCustId}
                      placeholder={fullName}
                    />
                  ))}
                </div>
              </div>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Customer Name*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Enter Customer Name"
                    required
                    onChange={handleFullName}
                    value={fullName}
                  />
                </div>
              </div>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Occupation*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    name="occupation"
                    id="occupation"
                    placeholder="Enter Occupation"
                    required
                    onChange={handleOccupation}
                    value={occupation}
                  />
                </div>
              </div>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Email*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email"
                    required
                    onChange={handleEmail}
                    value={email}
                  />
                </div>
              </div>

              <Row>
                <Col lg={6}>
                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Mobile-No*
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="mobile"
                        id="mobile"
                        min={10}
                        placeholder="Enter Mobile-No"
                        onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                        onChange={handleMobileNo}
                        value={mobile}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Telephone-No
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="telephone"
                        id="telephone"
                        placeholder="Enter Telephone-No"
                        onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                        onChange={handleTelephoneNo}
                        value={telephone}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="mt-5 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
              <hr />
              <div>
                <CardTitle className="text-center fs-4 mt-4">
                  <b>Customer Sub Holder Photo</b>{" "}
                </CardTitle>
                <div>
                  <div className="py-1">
                    <div className="d-flex">
                      <div>
                        <input type="radio" name="release" checked={status === 1} onClick={(e) => radioHandler(1)} style={{ margin: "8px 6px", transform: "scale(1.3)" }} /><span>Photo</span>
                      </div>
                      <div style={{ marginLeft: "5rem" }}>
                        <input type="radio" name="release" checked={status === 2} onClick={(e) => radioHandler(2)} style={{ margin: "8px 6px", transform: "scale(1.3)" }} /><span>Camera</span>
                      </div>
                    </div>
                    {status === 1 && <div className="py-1">

                      <div className="py-1">
                        {!photo ? (
                          <div>
                            <Input
                              type="file"
                              name="doc"
                              id="doc"
                              required
                              onChange={uploadCustomerImage}
                            />
                            <img
                              src={filePath ? filePath : user1}
                              alt="Customer Profile"
                              style={{ width: "8rem", height: "7rem" }}
                            />
                          </div>
                        ) : (
                          <div className="mt-1">
                            <ImageViewer
                              path={photo}
                              style={{ width: "8rem", height: "7rem" }}
                            />
                            <i
                              className="fas fa-trash-alt"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                marginLeft: "10px",
                                color: "red",
                                fontSize: "larger"
                              }}
                              onClick={removeImage}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>}
                    {status === 2 && <div>
                      <div className="webcam-container py-1" style={{ textAlign: "center" }}>
                        <div className="webcam-img">
                          {camImage == "" ? (
                            <Webcam
                              audio={false}
                              // height={200}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              // width={220}
                              width={120}
                              height={100}
                            // videoConstraints={videoConstraints}
                            />
                          ) : (
                            <img src={camImage} alt="" />
                          )}
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                          {camImage != "" ? (
                            <button
                              onClick={e => {
                                e.preventDefault()
                                setCamImage("")
                              }}
                              className="webcam-btn"
                            >
                              Retake Image
                            </button>
                          ) : (
                            <button
                              onClick={e => {
                                e.preventDefault()
                                capture()
                              }}
                              className="webcam-btn"
                            >
                              Capture
                            </button>
                          )}
                        </div>
                      </div>
                    </div>}
                  </div>
                  {status === 2 &&
                    <div className="mt-3 d-flex" style={{ justifyContent: "space-between" }}>
                      <button
                        type="submit"
                        className="btn btn-primary w-md "
                        onClick={uploadCustCameraImage}
                      >
                        Upload Image
                      </button>
                    </div>
                  }
                </div>
              </div>
              <hr />
              <CustomerSubHolderKYC id={id} count={count} oldCustomerId={oldCustomerId} isSave={isSave} />
            </Form>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CustomerSubholder)
