import React, { Suspense, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-dark.png"
import { useDispatch } from "react-redux"

import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"

// actions
import { loginUser } from "../../store/actions"
import { authLogin } from "service/service"
import Loading from "components/Common/Loading"

const Login = props => {
  document.title = "Login | Shreesh Securities"

  const dispatch = useDispatch()
  const [userNameValue, setUserNameValue] = useState("")
  const [passwordValue, setPasswordValue] = useState("")
  const [userGroupValue, setUserGroupValue] = useState("")
  const [status, setStatus] = useState(0)
  const navigate = useNavigate()

  // const handleuserGroup = (e) => {
  //   setuserGroup(!userGroup);
  // }
  const [isVisible, setVisible] = useState(false)
  const toggle = () => {
    setVisible(!isVisible)
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: userNameValue || "",
      password: passwordValue || "",
      userGroupValue: userGroupValue || "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
      userGroupValue: Yup.string().required("Please Enter Your User Group"),
    }),
  })

  const handleOnchangeEmail = e => {
    setUserNameValue(e.target.value)
  }
  const handleOnchangepwd = e => {
    setPasswordValue(e.target.value)
  }
  const handleOnchangeUserGroup = e => {
    const value = e.target.value
    setUserGroupValue(value)
  }
  const handleSubmit = async () => {
    try {
      // const res = await authLogin("dhruvi", "123456", "admin_staff")
      const res = await authLogin(userNameValue, passwordValue, userGroupValue)

      if (res && res?.status === "1") {
        dispatch(loginUser(res.data, props.router.navigate))
        const data = res.data


        localStorage.setItem("isLoggedIn", true)
        localStorage.setItem("loginUserId", data._id)
        localStorage.setItem("accessToken", data.accessToken)
        localStorage.setItem("refreshToken", data.refreshToken)
        localStorage.setItem("username", data.username)
        localStorage.setItem("expiryTime", data.expiryTime)
        localStorage.setItem("secretKey", data.secretKey)
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500,
        })
        navigate("/dashboard")
      } else {
        // console.log("error", res.message)
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log("error", error)
      // throw error
    }
  }

  return (
    <React.Fragment>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="30"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Shreesh.
                    </p>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label htmlFor="username">Username</Label>
                        <Input
                          name="userName"
                          className="form-control"
                          placeholder="Enter User name"
                          type="text"
                          onChange={handleOnchangeEmail}
                          onBlur={validation.handleBlur}
                          value={userNameValue}
                          invalid={
                            validation.touched.userName &&
                              validation.errors.userName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.userName &&
                          validation.errors.userName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.userName}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">Password</Label>
                        <div className="d-flex position-relative">
                          <Input
                            name="password"
                            type={!isVisible ? "password" : "text"}
                            placeholder="Enter Password"
                            onChange={handleOnchangepwd}
                            onBlur={validation.handleBlur}
                            value={passwordValue}
                            invalid={
                              validation.touched.password &&
                                validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <span
                            className="icon align-self-center"
                            style={{
                              position: "absolute",
                              right: "10px",
                              cursor: "pointer",
                            }}
                            onClick={toggle}
                          >
                            {isVisible ? (
                              <i
                                className="fas fa-eye"
                                style={{ color: "gray" }}
                              ></i>
                            ) : (
                              <i
                                className="fas fa-eye-slash"
                                style={{ color: "gray" }}
                              ></i>
                            )}
                          </span>
                        </div>
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userGroup">Usergroup</Label>
                        {/* <Input
                          name="userGroup"
                          className="form-control"
                          placeholder="Enter UserGroup"
                          type="text"
                          onChange={handleOnchangeUserGroup}
                          onBlur={validation.handleBlur}
                          value={validation.values.userGroup || ""}
                          invalid={
                            validation.touched.userGroup &&
                              validation.errors.userGroup
                              ? true
                              : false
                          }
                        />
                        {validation.touched.userGroup &&
                          validation.errors.userGroup ? (
                          <FormFeedback type="invalid">
                            {validation.errors.userGroup}
                          </FormFeedback>
                        ) : null} */}
                        <select
                          className="form-control"
                          id="userGroup"
                          value={validation.values.userGroupValue || ""}
                          onChange={handleOnchangeUserGroup}
                        >
                          <option value="">---SELECT---</option>
                          <option value="admin">Admin</option>
                          <option value="admin_staff">Admin_Staff</option>
                          {/* <option value="Cherry">Cherry</option> */}
                        </select>
                      </div>
                      <Row className="mb-3 mt-4">
                        {/*
                        <div className="col-6">
                          <div className="form-check d-flex gap-4">
                            <div>
                              <Input
                                type="radio" name='userGroup' id='admin' checked={userGroup === 'admin'} value={userGroup} onChange={() => setuserGroup('admin')}
                              />
                              <Label>Admin</Label>
                            </div>
                            <div>
                              <Input
                                type="radio" name='userGroup' id='admin_staff' checked={userGroup === 'admin_staff'} value={userGroup} onClick={() => setuserGroup('admin_staff')}
                              /><Label>Staff</Label>
                            </div>
                          </div>
                        </div>*/}
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </Row>
                      {/* <Row className="form-group mb-0">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock"></i> Forgot your
                              password?
                            </Link>
                          </Row> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                {/* <p>Don't have an account ? <Link to="/register" className="text-primary"> Signup Now </Link></p>
                © {new Date().getFullYear()} Lexa <span className="d-none d-sm-inline-block"> - Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand.</span> */}
                Shreesh © 2024 - Locker Management System. All Rights
                Reserved
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
