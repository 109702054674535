import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import user1 from "../../assets/images/users/user-1.jpg";
import moment from "moment"
import {
  changeLockerPlanAPI,
  getLockerByIdAPI,
  getOccupiedLocker,
  getOccupiedLockerByIdAPI,
  getRentByIdAPI,
  getRentList,
  getSearchLockerList,
} from "service/service"

import lockerNumber from "../CustomerManagement/lockerNo.json"

import Select from "react-select"
import { gst } from "api/apiClient"
import Swal from "sweetalert2"
import { useNavigate, useParams } from "react-router-dom"
import ImageViewer from "ImageViewer"
// import { useSelector } from "react-redux"
// import { assignLockerToCustomer } from "service/backendRoutes"

const CustomerLocker = props => {
  document.title = "Change Locker Plan | Shreesh - Locker Management Syatem"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Locker Expire", link: "/lockerExpire" },
    { title: "Change Locker Plan", link: "#" },
  ]


  const re = /^[A-Za-z\s]+$/;
  const [locker, setLocker] = useState([])
  const [rent, setRent] = useState([])
  const { id } = useParams();
  const [cusLocker, setCusLocker] = useState("");
  const [customer, setCustomer] = useState("");
  const [penalty, setPenalty] = useState(0);
  const [planDate, setPlanDate] = useState("")
  const [selectedLockerNoValue, setSelectedLockerNoValue] = useState("")
  const [lockerKey, setLockerKey] = useState("")
  // const [entryFees, setEntryFees] = useState("")
  const [userLocker, setUserLocker] = useState()
  const [userRent, setUserRent] = useState("")
  const [lockerDetails, setLockerDetails] = useState()
  const [rentName, setRentName] = useState()
  const [rentDetails, setRentDetails] = useState()
  const [occupied, setOccupied] = useState([]);
  const [calculatedPenalty, setCalculatedPenalty] = useState(0);

  const [lockerDeposit, setLockerDeposit] = useState(0)


  const history = useNavigate()

  const handleDisplaylockerList = async e => {
    try {
      const res = await getSearchLockerList()
      setLocker(res.data)
    } catch (error) {
      console.log(error);
    }
  }


  const handleDisplayrentList = async () => {
    try {
      const res = await getRentList()
      setRent(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectChangeLockerNo = e => {
    const value = e.value
    setSelectedLockerNoValue(value)
  }

  const handleSelect = e => {
    const value = e.value
    setUserLocker(value)
    setRentName([])
    setRentDetails([])
    setUserRent('')
  }

  const handleSelect2 = e => {
    const value = e.value
    setUserRent(value)
  }

  const displayLockerById = async () => {
    const id = userLocker
    // const id = cusLocker?.lockerId
    const res = await getLockerByIdAPI(id)
    setLockerDetails(res.data)
    const rentData = res.data

    const response = await getOccupiedLocker()
    setOccupied(response.data)
    const temp = []

    rentData.rents &&
      rentData.rents.length > 0 &&
      rentData.rents.map(data => {
        if (data.status === true) {
          // setRentDetails(data)
          temp.push({ value: data.id, label: data.name })
        }
      })
    setRentName([temp])
  }

  const getRentById = async () => {
    try {
      const res = await getRentByIdAPI(userRent)
      setRentDetails(res?.data);

      if (res?.data.minimumDurationDay === 3) {
        setLockerDeposit(0)
      } else {

        if (lockerDetails?.name === "B") {
          setLockerDeposit(25000)
        }
        else if (lockerDetails?.name === "C") {
          setLockerDeposit(32000)
        }
        else if (lockerDetails?.name === "F") {
          setLockerDeposit(75000)
        }
        else if (lockerDetails?.name === "L") {
          setLockerDeposit(120000)
        }
        else if (lockerDetails?.name === "XL") {
          setLockerDeposit(160000)
        } else {
          setLockerDeposit(0)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const options = [
    locker.map(data => {
      return { value: data.id, label: data.name }
    }),
  ]

  const filterLockerNo = () => {
    let temp = []
    let tempLocker = []
    occupied && occupied.length > 0 &&
      occupied.map((item) => {
        tempLocker.push(item.lockerNo)
      })
    // console.log(tempLocker);
    lockerDetails &&
      lockerNumber &&
      lockerNumber.map(data => {
        if (data.lockerNo.includes(lockerDetails.name)) {
          if (!(tempLocker.includes(data.lockerNo)))
            temp.push({ value: data.lockerNo, label: data.lockerNo })
        }
      })
    return temp
  }



  // const occupiedLockerCustomer = async () => {
  //   try {
  //     if (id) {
  //       const res = await getOccupiedLockerByIdAPI(id);
  //       // console.log(res.data.locker);
  //       setCustomer(res.data)
  //       setCusLocker(res.data.locker);

  //       // console.log(cuslockerdata);
  //     }
  //   } catch (error) {

  //   }
  // }

  const occupiedLockerCustomer = async () => {
    try {
      if (id) {
        const res = await getOccupiedLockerByIdAPI(id);
        setCustomer(res.data)
        // console.log("locker ID = ", res.data.locker.lockerId);
        setCusLocker(res.data.locker);
        const cuslockerdata = res.data.locker;

        // cuslockerdata.lockerName = "XL"

        // const startDate = new Date("2024-04-02")
        // const lastDate = new Date("2024-05-01")
        const startDate = new Date(cuslockerdata?.planStartDate)
        const lastDate = new Date(cuslockerdata?.planEndDate)

        // year
        const differenceForYear = lastDate - startDate;
        console.log("differenceForYear = ", differenceForYear);
        const countDays = Math.ceil(differenceForYear / (1000 * 60 * 60 * 24));
        console.log("countDays = ", countDays);

        if (countDays > 900) {
          // const currentDateObject = new Date("2027-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          // console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          // console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          // console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
          }
        }
        else if (countDays > 600) {
          // const currentDateObject = new Date("2026-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          // console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          // console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          // console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
          }
        }
        else if (countDays > 300) {
          // const currentDateObject = new Date("2027-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          // console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          // console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          // console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              // console.log("Penalty Year = ", yearGst);
            }
          }
        } else {
          setCalculatedPenalty(0)
        }

        // month
        // const currentDateObject = new Date("2024-05-02");
        const currentDateObject = new Date();
        // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
        const differenceInMilliseconds = currentDateObject - lastDate;
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        // console.log("differenceInDays = ", differenceInDays);
        if (differenceInDays >= 0) {
          // let penalty = 0
          // penalty = (500 * differenceInDays)
          // // penalty = (penalty * 100) / 18
          // let dayGst = penalty + (penalty * gst) / 100
          // setCalculatedPenalty(dayGst)
          // console.log("Penalty Days = ", dayGst);
          if (cuslockerdata.lockerName === "B") {
            let penalty = 0
            penalty = (500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            // console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "C") {
            let penalty = 0
            penalty = (750 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            // console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "F") {
            let penalty = 0
            penalty = (1000 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            // console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "L") {
            let penalty = 0
            penalty = (1500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            // console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "XL") {
            let penalty = 0
            penalty = (2500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            // console.log("Penalty Days = ", dayGst);
          }
        }
        // setRentId(cuslockerdata.rentId)
        // setCusLockerNo(cuslockerdata.customerLockerNo)
        // setCusRentName(cuslockerdata.rentName)
        // getRant(res.data.locker.rentId)
      } else {
        setCalculatedPenalty(0)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // console.log(
    //   "customerLockerId = ", cusLocker?.customerLockerId + "\n ",
    //   "rentId = ", userRent + "\n ",
    //   "customerLockerKey = ", lockerKey + "\n ",
    //   // "customerLockerKey = ", cusLocker?.customerLockerKey + "\n ",
    //   // "customerLockerNo = ", cusLocker?.customerLockerNo + "\n ",
    //   "customerLockerNo = ", selectedLockerNoValue + "\n ",
    //   "planStartDate = ", planDate + "\n ",
    //   // "entryFees = ", totalRent + "\n ",
    //   "penalty = ", calculatedPenalty + "\n ",
    //   "gst = ", gst + "\n ",);

    try {
      e.preventDefault();
      const response = await changeLockerPlanAPI(
        cusLocker?.customerLockerId,
        // userLocker,
        userRent,
        // cusLocker?.customerLockerKey,
        // cusLocker?.customerLockerNo,
        lockerKey,
        selectedLockerNoValue,
        planDate,
        // totalRent,
        // penalty
        calculatedPenalty,
        gst
      )

      if (response.status === "1" || response.status === 1) {
        Swal.fire({
          title: response.message,
          icon: "success",
          timer: 1500
        })
        history("/lockerExpire")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    props.setBreadcrumbItems("Change Locker Plan", breadcrumbItems)
  })

  useEffect(() => {
    handleDisplaylockerList()
    handleDisplayrentList()
  }, [""])

  useEffect(() => {
    displayLockerById()
    getRentById()
    // filterLockerNo()
    //eslint-disable-next-line
  }, [userRent, userLocker])

  useEffect(() => {
    occupiedLockerCustomer();
  }, [])

  // console.log("locker Id = ", cusLocker.lockerId);

  let depositCount = lockerDeposit - cusLocker?.lockerDeposit

  const rentTotal = (rentDetails?.rentPrice + (rentDetails?.rentPrice * gst) / 100)
  const finalRent = Math.round(rentTotal)
  // const totalAmount = finalRent + calculatedPenalty
  const [totalAmount, setTotalAmount] = useState(0)
  const finalAmount = () => {

    if (depositCount > 0) {
      const amount = finalRent + calculatedPenalty + Math.abs(depositCount)
      setTotalAmount(amount)
      // return amount;
    }
    else {
      const amount = finalRent + calculatedPenalty - Math.abs(depositCount)
      setTotalAmount(amount)
      // return amount;
    }
  }
  useEffect(() => {
    if (userRent) {
      finalAmount()
    }
  }, [finalRent])
  // console.log("Deposit = ", depositCount);

  return (
    <>

      {/* <Card className="mt-3">
        <CardBody>
          <CardTitle className="fs-4">
            <b>Customer Old Plan Details</b>
          </CardTitle>
          <hr />
          <Row>
            <Col lg={2} className="position-relative">
              <h6 className="fw-bold ">Photo</h6>
              <div className="mt-1">
                {customer.photo ?
                  <ImageViewer
                    path={customer.photo}
                    style={{ width: "8rem", height: "7rem", cursor: 'pointer' }}
                  />
                  :
                  <ImageViewer
                    path={user1}
                    style={{ width: "8rem", height: "7rem", cursor: 'pointer' }}
                  />
                }
              </div>
            </Col>
            <Col lg={10}>
              <Row>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Customer Name</h6>
                  <p>{customer.fullName}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Email</h6>
                  <p>{customer.email}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Locker No</h6>
                  <p>{cusLocker.customerLockerNo}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Locker Key</h6>
                  <p>{cusLocker.customerLockerKey}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Plan Name</h6>
                  <p>{cusLocker.rentName}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Plan Start Date</h6>
                  <p> {moment(cusLocker.planStartDate).format(
                    "DD/MM/YYYY"
                  )}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Plan End Date</h6>
                  <p> {moment(cusLocker.planEndDate).format(
                    "DD/MM/YYYY"
                  )}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Locker Status</h6>
                  <p>{cusLocker.lockerStatus}</p>
                </Col>

              </Row>
            </Col>

          </Row>

        </CardBody>
      </Card> */}


      <div className="d-flex justify-content-start">
        <Row className=" w-100">
          <Col lg={8}>
            <Card>
              <CardBody>
                <Form>
                  {/* <CardTitle className="fs-4 text-center">
                    <b>Change Locker Plan</b>
                  </CardTitle> */}
                  <Row>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Locker Category
                      </Label>
                      {/* <select
                        className="form-control"
                        id="LockerName"
                        // onChange={handleSelect}
                        value={cusLocker?.lockerId}
                      >
                        {cusLocker && (
                          <option value={cusLocker?.lockerId} selected>
                            {cusLocker && cusLocker?.lockerName}
                          </option>
                        )}
                      </select> */}
                      {options &&
                        options.map(data => (
                          <Select
                            options={data}
                            onChange={handleSelect}
                            isSearchable={true}
                          />
                        ))}
                    </div>
                  </Row>
                  {userLocker ? (
                    <div className="pt-2">
                      <h5> <b>Locker Details</b> </h5>
                      <hr />
                      <Row className="fw-bold view-table">
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Locker Name</h6>
                          <p>{lockerDetails?.name}</p>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Locker Size</h6>
                          <p>{lockerDetails?.lockerSize}</p>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Locker Category</h6>
                          <p>{lockerDetails?.lockerCategory}</p>
                        </Col>
                        {/* <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">
                            Locker Deposit (Refundable)
                          </h6>
                          <p>{lockerDetails?.lockerDeposit}</p>
                        </Col> */}
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}


                  <Row>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Select Rent*
                      </Label>
                      <div>
                        {/* <select className="form-control" id="LockerName"></select> */}
                        {/* <Input value={rentName} /> */}
                        {rentName &&
                          rentName.map(data => (
                            <Select
                              options={data}
                              onChange={handleSelect2}
                              isSearchable={true}
                              placeholder={data.label}
                            />
                          ))}
                      </div>
                    </div>
                  </Row>

                  {userRent ? (
                    <Row className="mt-2">
                      <Col lg={12}>

                        <CardTitle className="fs-5">
                          <b>Rent Details</b>
                        </CardTitle>
                        <hr />
                        <Row className="fw-bold view-table">
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Rent Name</h6>
                            <p>{rentDetails?.name}</p>
                          </Col>

                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Rent Price</h6>
                            <p>{rentDetails?.rentPrice}</p>
                          </Col>

                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Duration Day</h6>
                            <p>{rentDetails?.minimumDurationDay}</p>
                          </Col>
                          {/* <Col xl={3} lg={4} md={6} sm={12}>
                                <h6 className="fw-bold">Rent Price</h6>
                                <p>
                                  {rentDetails?.rentPrice *
                                    rentDetails?.minimumDurationDay}
                                </p>
                              </Col> */}

                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">GST (18%)</h6>
                            <p>
                              {(rentDetails?.rentPrice * gst) / 100}
                            </p>
                          </Col>
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Total Rent</h6>
                            <p>{rentTotal}</p>
                          </Col>
                        </Row>

                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Locker No
                    </Label>
                    <div>
                      {/* <Input type="text" value={cusLocker?.customerLockerNo} disabled /> */}
                      {lockerDetails && (
                        <Select
                          options={filterLockerNo()}
                          onChange={handleSelectChangeLockerNo}
                          isSearchable={true}
                        />
                      )}
                    </div>
                  </div>

                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Locker Key
                    </Label>
                    <div>
                      {/* <Input type="text" value={cusLocker?.customerLockerKey} disabled /> */}
                      <Input
                        className="form-control"
                        type="text"
                        name="customerLockerKey"
                        id="customerLockerKey"
                        placeholder="Enter Locker Key"
                        required
                        value={lockerKey}
                        onChange={e => {
                          setLockerKey(e.target.value)
                        }}
                      />
                    </div>
                  </div>

                  <div className="py-1">
                    <Label htmlFor="example-text-input" className=" col-form-label">
                      Plan Start Date*
                    </Label>
                    <div>

                      <Input
                        className="form-control"
                        id="planStartDate"
                        type="date"
                        name="planStartDate"
                        required
                        placeholder="Select Plan Start Date"
                        pattern="\d{2}-\d{2}-\d{4}"
                        value={planDate}
                        onChange={e => {
                          setPlanDate(e.target.value)
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Entry Fees
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="entryFees"
                        id="entryFees"
                        placeholder="Enter Entry Fees"
                        required
                        onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                        value={entryFees}
                        onChange={e => {
                          setEntryFees(e.target.value)
                        }}
                      />
                    </div>
                  </div> */}

                  <div className="py-1">
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Late Fees
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="number"
                        name="panalty"
                        id="panalty"
                        placeholder="Enter Late Fees"
                        required
                        disabled
                        // onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                        value={calculatedPenalty}
                      // onChange={e => {
                      //   setPenalty(e.target.value)
                      // }}
                      />
                    </div>
                  </div>

                  <div className="py-1">
                    {userRent && <>
                      {depositCount && depositCount > 0 ?
                        <p> You have to paid deposit = {depositCount} </p> :
                        <p> You have to refund deposit = {Math.abs(depositCount)} </p>
                      }
                    </>
                    }
                  </div>


                  {/* <Row className="fw-bold view-table">
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <h6 className="fw-bold">Final Rent</h6>
                     
                      <p>{finalRent}</p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="totalAmount">
                        <h6 className="fw-bold">Total payable amount</h6>
                        <p>{totalAmount}</p>
                      </div>
                    </Col>
                  </Row> */}


                  {/* <hr /> */}
                  <div className="table-responsive changelockerplan">
                    <table>
                      <tr>
                        <td><h6 className="fw-bold">Final Rent</h6></td>
                        <td><p>{finalRent}</p></td>
                      </tr>
                      {userRent &&
                        <tr>
                          <td><h6 className="fw-bold">
                            {depositCount && depositCount > 0 ?
                              " You have to paid deposit" :
                              "You have to refund deposit"
                            }
                          </h6></td>
                          <td>
                            {depositCount && depositCount > 0 ?
                              <p>{depositCount} </p> :
                              <p> {Math.abs(depositCount)} </p>
                            }
                          </td>
                        </tr>
                      }
                      {userRent &&
                        <tr>
                          <td><h6 className="fw-bold">
                            {totalAmount && totalAmount > 0 ?
                              " Total payable amount" :
                              "Total refundable Amount"
                            }
                          </h6></td>
                          <td><p>
                            {/* {depositCount && depositCount > 0 ?
                                (totalAmount + (Math.abs(depositCount)))
                                :
                                (totalAmount - (Math.abs(depositCount)))
                              } */}
                            {Math.abs(totalAmount)}
                          </p></td>
                        </tr>
                      }
                    </table>
                  </div>


                  <div className="mt-4 d-flex gap-2">
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary w-md"
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={() => { history("/lockerExpire") }}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div >
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CustomerLocker)
