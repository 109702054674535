import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, Col, Input, Row } from "reactstrap"

import { activeStaffAPI, deactiveStaffAPI, getStaffCountAPI, getStaffList, searchStaff } from "service/service"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"

const Stafflist = props => {

  const history = useNavigate()
  const [staff, setStaff] = useState([])
  const userRights = useSelector(state => state.Profile.userRights)
  const [count, setRentCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isRefresh, setIsRefresh] = useState(false)
  document.title = "Staff List | Shreesh Securities"

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Staff List", link: "#" },
  ]

  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    setPerPage(+event.target.value)
    setPageNo(1);
  }

  useEffect(() => {
    props.setBreadcrumbItems("Staff List", breadcrumbItems)
  })

  const handleDisplayStaffList = async () => {
    try {
      const res = await getStaffList(perPage, pageNo)
      setStaff(res.data)

    } catch (error) {
      console.log(error);
    }
  }

  const getRentCount = async () => {
    try {
      const res = await getStaffCountAPI();
      // console.log("res", res);
      if (res.status === '1' || res.status === 1) {
        setRentCount(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getCounts() {
      await getRentCount();
    }
    getCounts();
  }, [])

  const handleUpdate = index => {
    try {
      history(`/updateStaff/${index}`)
    } catch (error) {
      console.log(error);
    }
  }

  const handleViewStaff = async i => {
    try {
      history(`/viewStaff/${i}`)
    } catch (error) {
      console.log(error);
    }
  }

  // const handleDeleteStaff = async i => {
  //   try {
  //     // const idValue = staff[i].id
  //     // const statusValue = staff[i].status

  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then(result => {
  //       if (result.isConfirmed) {
  //         // deleteStaffAPI(idValue, statusValue)
  //         deleteStaffAPI(i)
  //         setIsRefresh(!isRefresh)


  //         Swal.fire({
  //           title: "Staff Deleted Successfully",
  //           icon: "success",
  //         })

  //       }
  //     })
  //   } catch (error) {
  //     throw error
  //   }
  // }

  const handleDeactiveStaff = async i => {
    // console.log(i);
    try {
      // const deactiveid = locker[i].id
      const res = await deactiveStaffAPI(i)
      setIsRefresh(!isRefresh)
      console.log(res.data);
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleActiveStaff = async i => {
    try {
      // const activeid = locker[i].id
      const res = await activeStaffAPI(i)
      setIsRefresh(!isRefresh)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchStaff(value)
    setStaff(res.data)
  }

  useEffect(() => {
    handleDisplayStaffList()
    //eslint-disable-next-line
  }, [isRefresh, perPage, pageNo])

  return (
    <>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-3 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-3 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    First Name
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Mobile No
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Address
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staff &&
                  staff.map((row, index) => (
                    <TableRow key={row._id} className="tablerow">
                      <TableCell onClick={() => handleViewStaff(row._id)}>
                        {row.firstName}
                      </TableCell>
                      <TableCell onClick={() => handleViewStaff(row._id)}>
                        {row.lastName}
                      </TableCell>
                      <TableCell onClick={() => handleViewStaff(row._id)}>
                        {row.email}
                      </TableCell>
                      <TableCell onClick={() => handleViewStaff(row._id)}>
                        {row.mobile}
                      </TableCell>
                      <TableCell onClick={() => handleViewStaff(row._id)}>
                        {row.address}
                      </TableCell>
                      <TableCell
                        style={{
                          display: "flex",
                          gap: "5px",
                          zIndex: "99999",
                        }}
                      >
                        {userRights && userRights?.staff?.write && (
                          <div
                            className="edit-btn"
                            onClick={() => handleUpdate(row._id)}
                          >
                            Edit
                          </div>
                        )}
                        {/* {userRights && userRights?.staff?.delete && (
                          <div
                            className="deactive-btn"
                            onClick={() => handleDeleteStaff(row._id)}
                          >
                            Delete
                          </div>
                        )} */}
                        {userRights && userRights?.staff?.write && row.status ? (
                          <div
                            className="active-btn"
                            onClick={() => handleDeactiveStaff(row._id)}
                          >
                            Active
                          </div>
                        ) : (
                          <div
                            className="deactive-btn"
                            onClick={() => handleActiveStaff(row._id)}
                          >
                            Inactive
                          </div>
                        )}
                        {/* {userRights && userRights?.staff?.read &&
                        <div
                          className="edit-btn"
                          onClick={() => handleViewStaff(index)}
                        >
                          View
                        </div>
                      } */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              rowsPerPage={perPage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            />

          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Stafflist)
