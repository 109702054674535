import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row, TabContent } from "reactstrap"
import {
  accessCustomerLockerAPI,
  addAdditionalLockOnLockerAPI,
  assignLockerActiveAPI,
  assignLockerBreakAPI,
  assignLockerDeactiveAPI,
  assignLockerRemoveAPI,
  getLockerHistoryAPI,
  getLockerTransactionByIdAPI,
  getOccupiedLockerByIdAPI,
} from "service/service"
import { Link, useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { gst } from "api/apiClient"
import moment from "moment"
import FeatherIcon from "feather-icons-react"
import ImageViewer from "ImageViewer"
import * as ReactDOMServer from 'react-dom/server';
import { html2pdf } from "html2pdf.js/dist/html2pdf.min"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const OccupiedLockerDetails = props => {
  document.title =
    "Assigned Locker Details | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    // { title: "Assigned Locker List", link: "/assignedLockerList" },
    { title: "Assigned Locker Details", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Assigned Locker Details", breadcrumbItems)
  })

  const [customerDetails, setCustomerDetails] = useState([])
  const [lockerDetails, setLockerDetails] = useState([])
  const [holderDetails, setHolderDetails] = useState([])
  const [reason, setReason] = useState("")
  const [historyLocker, setLockerHistory] = useState([])
  const [lockerTransaction, setLockerTransaction] = useState([])
  const [transactionDetail, setTransactionDetail] = useState("")
  const smallScreen = useMediaQuery("(max-width: 600px)")
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useNavigate()

  const { customerLockerId } = useParams();

  const handleDisplaylockerUpdate = async (customerLockerId) => {
    try {
      const res = await getOccupiedLockerByIdAPI(customerLockerId)
      setCustomerDetails(res.data)
      setLockerDetails(res.data.locker)
      // const lockerNo = res.data.locker.customerLockerNo
      // const response = await getLockerHistoryAPI(lockerNo)
      // setLockerHistory(response.data)
      setHolderDetails(res.data.locker.holders)
      // await downloadPdf(res.data, res.data.locker);
    } catch (error) {
      throw error
    }
  }

  const getLockerHistory = async () => {
    try {
      const res = await getLockerHistoryAPI(lockerDetails?.customerLockerNo)
      setLockerHistory(res.data)
    } catch (error) {

    }
  }

  const getLockerTransaction = async () => {
    try {
      const res = await getLockerTransactionByIdAPI(lockerDetails?.customerLockerId)
      setLockerTransaction(res.data)
    } catch (error) {

    }
  }

  const handleGeneratePdf = async (item) => {
    // console.log("iiiiiiiii = ", item);
    setTransactionDetail(item)
    // await downloadPdf(item);
  }

  let srno = 1;

  // const downloadPdf = async (data, lockerData, transactionData) => {
  //   const receiptData = customerDetails;
  //   const receiptLocker = lockerDetails;
  //   const receiptTransction = transactionData;

  //   const printElement = (<div id="htmlElementId">
  //     <div className="container pt-5" style={{ padding: "10px" }}>
  //       <div style={{ border: "4px double black" }}>
  //         <div className="m-0" style={{ borderBottom: "1px solid black" }}>
  //           <div className="row align-items-center">
  //             <div className="col-2 d-flex justify-content-center">
  //               <img
  //                 // src={logo}
  //                 alt=""
  //                 style={{ height: "4rem", paddingLeft: "5px" }}
  //               />
  //             </div>
  //             <div className="col-10 py-2 text-center">
  //               <span
  //                 className="fw-bold p-0 m-0"
  //                 style={{ fontSize: "20px" }}
  //               >
  //                 SHREESH SECURITIES
  //               </span>{" "}
  //               <br />
  //               <span style={{ fontSize: "9px", marginTop: "-20px" }}>
  //                 Managed By : Shreesh Design Studio
  //               </span>
  //               <br />
  //               <span className="text-center ">
  //                 "Shreesh" 4th - 5th Floor, Dr.Dastur Marg, Off Dr.Yagnik
  //                 Road, B/h D.H. College,
  //                 <br /> Rajkot - 360001, Gujrat, INDIA
  //               </span>
  //             </div>
  //           </div>
  //         </div>
  //         <div>
  //           <div className="table-responsive">
  //             <table className="table table-bordered border border-dark">
  //               <thead>
  //                 <tr>
  //                   <td colSpan="4">
  //                     <div>
  //                       <div className="d-flex gap-2 lh-1">
  //                         <p className="fw-bold">Name : </p>
  //                         <p id="name" value={receiptData?.fullName}>{receiptData.fullName}</p>
  //                       </div>
  //                       <div className="d-flex gap-2 lh-1">
  //                         <p className="fw-bold">Mobile : </p>
  //                         <p>{receiptData?.mobile}</p>
  //                       </div>
  //                       <div className="d-flex gap-2 lh-1">
  //                         <p className="fw-bold">Locker No : </p>
  //                         <p>{receiptLocker?.customerLockerNo}</p>
  //                       </div>
  //                       <div className="d-flex gap-2 lh-1">
  //                         <p className="fw-bold">Plan Start Date : </p>
  //                         {/* <p>{receiptLocker.planStartDate}</p> */}
  //                         <p>
  //                           {moment(receiptLocker?.planStartDate).format(
  //                             "DD/MM/YYYY"
  //                           )}
  //                         </p>
  //                       </div>
  //                       <div className="d-flex gap-2 lh-1">
  //                         <p className="fw-bold">Plan End Date : </p>
  //                         {/* <p>{receiptLocker.planEndDate}</p> */}
  //                         <p>
  //                           {moment(receiptLocker?.planEndDate).format(
  //                             "DD/MM/YYYY"
  //                           )}
  //                         </p>
  //                       </div>
  //                     </div>
  //                   </td>
  //                   <td colSpan="2" className="align-top">
  //                     <div>
  //                       <div className="d-flex gap-2 lh-1">
  //                         <p className="fw-bold">Receipt No :</p>
  //                         <p>SS001</p>
  //                       </div>
  //                       <div className="d-flex gap-2 lh-1">
  //                         <p className="fw-bold">Date :</p>
  //                         {/* <p>{currentDate}</p> */}
  //                         <p>{moment().format("DD/MM/YYYY")}</p>
  //                       </div>
  //                     </div>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <th className="border border-dark">Sr. No.</th>
  //                   <th className="border border-dark">Title</th>
  //                   <th className="border border-dark">HSN Code</th>
  //                   <th className="border border-dark">Amount</th>
  //                   <th className="border border-dark">GST (18%)</th>
  //                   <th className="border border-dark">Net Amount</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {
  //                   receiptLocker?.entryFees === 0 ? "" :
  //                     <tr style={{ border: "none" }}>
  //                       <td>{srno++}</td>
  //                       <td>Entry Fees</td>
  //                       <td>24334</td>
  //                       <td>{receiptLocker?.entryFees}</td>
  //                       <td>{(receiptLocker?.entryFees * gst) / 100}</td>
  //                       <td>{(receiptLocker?.entryFees) + ((receiptLocker?.entryFees * gst) / 100)}</td>
  //                     </tr>
  //                 }
  //                 {
  //                   receiptLocker.lockerDeposit === 0 ? "" :
  //                     <tr style={{ border: "none" }}>
  //                       {/* <td>{srno++}</td> */}
  //                       <td>Locker Deposit</td>
  //                       <td>24334</td>
  //                       <td>{receiptLocker?.lockerDeposit}</td>
  //                       <td>0</td>
  //                       <td>{receiptLocker?.lockerDeposit}</td>
  //                     </tr>
  //                 }
  //                 {
  //                   receiptLocker?.lockerRate === 0 ? "" :
  //                     <tr style={{ border: "none" }}>
  //                       <td>{srno++}</td>
  //                       <td>Rate</td>
  //                       <td>24334</td>
  //                       <td>{receiptLocker?.lockerRate}</td>
  //                       <td>

  //                         {/* {((receiptLocker.lockerRate * receiptLocker.rentDurationDays * gst) / 100)} */}
  //                       </td>
  //                       <td>{receiptLocker?.lockerRate + ((receiptLocker?.lockerRate * gst) / 100)}</td>
  //                     </tr>
  //                 }
  //                 {
  //                   receiptLocker?.penalty === 0 ? "" :
  //                     <tr style={{ border: "none" }}>
  //                       <td>{srno++}</td>
  //                       <td>Penalty</td>
  //                       <td>24334</td>
  //                       <td>{receiptLocker?.penalty}</td>
  //                       <td>0</td>
  //                       <td>{receiptLocker?.penalty}</td>
  //                     </tr>
  //                 }
  //                 <tr>
  //                   <td colSpan={4} className="border border-dark">
  //                     <div className="row">
  //                       <div className="col-8">
  //                         <span className="fw-bold">GSTIN No. :</span>{" "}
  //                         24AAGFO3003K1ZK
  //                       </div>
  //                       <div className="col-4 text-end">
  //                         <span className="fw-bold">Total&nbsp;:&nbsp; </span>

  //                       </div>
  //                     </div>
  //                   </td>
  //                   <td className="border border-dark"></td>
  //                   <td className="border border-dark"></td>
  //                 </tr>
  //                 <tr>
  //                   <td colSpan="4" className="border border-dark">
  //                     <div className="row">
  //                       <div className="col-6">
  //                         <span className="fw-bold">Bank Name :</span> ICICI
  //                         Bank
  //                       </div>
  //                       <div className="col-6">
  //                         <span className="fw-bold">IFSC Code :</span>{" "}
  //                         ICIC0001234
  //                       </div>
  //                     </div>
  //                     <span className="fw-bold">Bank A/c. No :</span>{" "}
  //                     624805501234
  //                   </td>
  //                   <td colSpan={2}></td>
  //                 </tr>
  //                 <tr>
  //                   <th
  //                     colSpan="4"
  //                     className="border border-dark border-start-0 border-end-1 text-capitalize"
  //                   >
  //                     {/* Total GST : {convert(`${parseInt(totalGST)}`)}{" "}
  //                     <br />
  //                     Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
  //                   </th>
  //                   <th
  //                     colSpan="2"
  //                     className=" border border-dark border-start-0 border-end-0 text-center"
  //                   >
  //                     Grand Total :111

  //                   </th>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //         <div className="row">
  //           <div className="col-8 ">
  //             <div className="ps-2">
  //               <h6>Term & Condition</h6>
  //               <ul style={{ fontSize: '12px' }}>
  //                 <li className="fst-italic">
  //                   "Break open change RS. 11000/- + GST change extra."
  //                 </li>
  //                 <li className="fst-italic">
  //                   "Late fees per month Rs.250/- + GST change extra from next month."
  //                 </li>
  //                 <li className="fst-italic">
  //                   "Additional lock available on request with charge of Rs.4000/- + GST charge extra."
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //           <div className="col-4 position-relative">
  //             <h6>For, Shreesh Securities </h6>
  //             <p className="fst-italic mt-4 position-absolute bottom-0 ">(Authorised Signatory) </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>)
  //   console.log(printElement);
  //   // html2pdf().from(printElement).save();
  // }

  const handleSubmit = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Reason",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      const response = await assignLockerDeactiveAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }

  const handleSubmitUnfreeze = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Reason",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      const response = await assignLockerActiveAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }

  }

  const handleSubmitRemove = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Reason",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      const response = await assignLockerRemoveAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }

  const handleSubmitBreak = async e => {
    e.preventDefault()
    const { value: reasons } = await Swal.fire({
      input: "text",
      inputLabel: "Reason",
      // inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
    })
    if (reasons) {
      const response = await assignLockerBreakAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }

  const handleAdditionalLock = async (e) => {
    e.preventDefault()
    const inputValue = 4000 + 4000 * gst / 100;
    // const penalty = lockerDetails?.penalty;
    const { value: reasons } = await Swal.fire({
      title: "For Additional Lock you have to pay 4000 + GST (18%)",
      // title: "For Additional Lock you have to pay 4000 + GST (18%) and If your late fees is pending",
      input: "text",
      inputLabel: `Additional Lock Charge = ${inputValue}`,
      inputValue,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      }
    })
    if (reasons) {
      // Swal.fire(reasons);
      console.log(lockerDetails.customerLockerId,
        reasons);
      const response = await addAdditionalLockOnLockerAPI(
        lockerDetails.customerLockerId,
        reasons
      )
      if (response.status === 1 || response.status === "1") {
        Swal.fire({
          title: response.message,
          icon: "success",
        })
        history("/assignedLockerList")
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
        })
      }
    }
  }



  const [customerDetail, setCustomerDetail] = useState();
  const [customerLockerDetail, setCustomerLockerDetail] = useState();

  let accessCharge = 2000 + 2000 * 18 / 100
  let iddd = JSON.parse(localStorage.getItem("customerInfo"))
  console.log(iddd._id, customerLockerId);

  const handleAccessCustomerDetail = async () => {
    console.log("Access");
    try {
      const cust = JSON.parse(localStorage.getItem("customerInfo"))
      // const custId = JSON.parse(localStorage.getItem('AccessCustID'))[0].customerId
      const custId = cust._id
      const res = await accessCustomerLockerAPI(customerLockerId, custId);
      console.log(res);
      // setCustomerDetail(res.data);
      // setCustomerLockerDetail(res.data.locker)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (customerLockerId) {
      handleAccessCustomerDetail()
    }
  }, [""]);

  useEffect(() => {
    if (customerLockerId) {
      handleDisplaylockerUpdate(customerLockerId)
    }
  }, [""])

  return (
    <div>
      <Row>
        <Col>
          <div className="d-flex justify-content-end">
            <div className="goback">
              <Link to="/assignedLockerList" className="">
                Go Back
              </Link>
            </div>
          </div>

          <Tabs
            value={value}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
            style={{ borderBottom: "1px solid gray" }}
            orientation={smallScreen ? "vertical" : "horizontal"}
            classes={{
              flexContainer: "flex-wrap sm:flex-nowrap",
            }}
            className="tabs"
          >
            <Tab className="top-tab" label="Locker Details" style={{ fontWeight: "600" }} />
            <Tab className="top-tab" label="Locker History" style={{ fontWeight: "600" }} onClick={getLockerHistory} />
            <Tab className="top-tab" label="Locker Transaction & Receipt" style={{ fontWeight: "600" }} onClick={getLockerTransaction} />
            <Tab className="top-tab" label="Locker Action" style={{ fontWeight: "600" }} />
            {/* <Tab className="top-tab" label="Locker E" style={{ fontWeight: "600" }} /> */}
          </Tabs>

          {value === 0 && (
            <Typography className="tabs-content">
              <Row>
                <Card className="mt-3">
                  <CardBody>
                    <Row className="fw-bold view-table">
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Category</h6>
                        <p>{lockerDetails?.lockerName}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Key</h6>
                        <p>{lockerDetails?.customerLockerKey}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker No</h6>
                        <p>{lockerDetails?.customerLockerNo}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Entry Fees (GST 18%)</h6>
                        <p>{Math.round(lockerDetails?.entryFees + (lockerDetails?.entryFees * gst / 100))}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Deposit</h6>
                        <p>{lockerDetails?.lockerDeposit}</p>
                      </Col>

                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Rate (GST 18%)</h6>
                        <p>{Math.round(lockerDetails?.lockerRate + (lockerDetails?.lockerRate * gst / 100))}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Size</h6>
                        <p>{lockerDetails?.lockerSize}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Status</h6>
                        <p>{lockerDetails?.lockerStatus}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Late Fees</h6>
                        <p>{lockerDetails?.penalty}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Reference By</h6>
                        <p>{lockerDetails?.referenceBy}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Operating Mandate</h6>
                        <p>{lockerDetails?.operatingMandate}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Plan Name</h6>
                        <p>{lockerDetails?.rentName}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Rent Duration Days</h6>
                        <p>{lockerDetails?.rentDurationDays}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Plan Start Date</h6>
                        <p>{moment(lockerDetails?.planStartDate).format("DD-MM-YYYY")}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Plan End Date</h6>
                        <p>{moment(lockerDetails?.planEndDate).format("DD-MM-YYYY")}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Additional Lock Charge (GST 18%)</h6>
                        {/* <p>{lockerDetails?.additionalLockCharge} + {lockerDetails?.additionalLockCharge * gst / 100} = {lockerDetails?.additionalLockCharge + lockerDetails?.additionalLockCharge * gst / 100}</p> */}
                        <p>{Math.round(lockerDetails?.additionalLockCharge + lockerDetails?.additionalLockCharge * gst / 100)}</p>
                      </Col>

                      {/* <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Rent Minimum Charges</h6>
                        <p>{lockerDetails?.rentMinimumCharges}</p>
                      </Col> */}

                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Status</h6>
                        {lockerDetails?.status ? (
                          <div className="active-btn">Active</div>
                        ) : (
                          <div className="deactive-btn">Inactive</div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* Primary Holder */}
                <Card>
                  <CardBody>
                    <CardTitle className="fs-4">
                      <b>Primary Holder</b>
                    </CardTitle>
                    <hr />
                    <Row className="fw-bold view-table" style={{}}>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold ">Name</h6>
                        <p>{customerDetails?.fullName}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Occupation</h6>
                        <p>{customerDetails?.occupation}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Email</h6>
                        <p>{customerDetails?.email}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Mobile</h6>
                        <p>{customerDetails?.mobile}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Telephone</h6>
                        <p>{customerDetails?.telephone}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Photo</h6>
                        <p><ImageViewer path={customerDetails?.photo} style={{ width: "5rem", height: "4rem", cursor: "pointer" }} /></p>
                      </Col>
                      {/* <Col xl={3} lg={4} md={6} sm={12}>
                    <h6 className="fw-bold">Primary Holder</h6>
                    {customerDetails?.isPrimaryHolder ? (
                      <div className="active-btn">Yes</div>
                    ) : (
                      <div className="deactive-btn">No</div>
                    )}
                  </Col> */}
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">KYC Status</h6>
                        <p>{customerDetails?.kycStatus}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Status</h6>

                        {customerDetails?.status ? (
                          <div className="active-btn">Active</div>
                        ) : (
                          <div className="deactive-btn">Inactive</div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* Sub Holder */}
                {/* Sub Holder */}
                {holderDetails?.length > 0 &&
                  <Card>
                    <CardBody>
                      <CardTitle className="fs-4">
                        <b>Sub Holders</b>
                      </CardTitle>
                      {/* <hr /> */}
                      {holderDetails &&
                        holderDetails?.length > 0 &&
                        holderDetails.map((data, i) => (
                          <>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="mt-2 text-capitalize"
                                style={{ backgroundColor: 'rgb(243 243 243)' }}
                              >
                                <Typography className="fw-bold">{data?.fullName}</Typography>
                              </AccordionSummary>

                              <AccordionDetails style={{ borderTop: "1px solid rgb(171 165 165)" }}>
                                <Typography>
                                  <Row className="fw-bold view-table" style={{}}>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold ">Name</h6>
                                      <p>{data.fullName}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Occupation</h6>
                                      <p>{data.occupation}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Email</h6>
                                      <p>{data.email}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Mobile</h6>
                                      <p>{data.mobile}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Telephone</h6>
                                      <p>{data.telephone}</p>
                                    </Col>
                                    {/* <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Primary Holder</h6>
                                      {data.isPrimaryHolder ? (
                                        <div className="active-btn">Yes</div>
                                      ) : (
                                        <div className="deactive-btn">No</div>
                                      )}
                                    </Col> */}
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">KYC Status</h6>
                                      <p>{data.kycStatus}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Status</h6>

                                      {data.status ? (
                                        <div className="active-btn">Active</div>
                                      ) : (
                                        <div className="deactive-btn">Inactive</div>
                                      )}
                                    </Col>
                                  </Row>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </>))}
                    </CardBody>
                  </Card>
                }

              </Row>
            </Typography>
          )}
          {value === 1 && (
            <Typography className="tabs-content">
              <Row >
                <TableContainer>
                  <Table>
                    <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                      <TableRow>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          No.
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Action
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Access Charge
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Action Performed By
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Action Performed At
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Remarks
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyLocker &&
                        historyLocker
                          .map((row, index) => (
                            <TableRow key={index} className="tablerow">
                              <TableCell >{index + 1}</TableCell>
                              <TableCell >{row.action}</TableCell>
                              <TableCell >{row.accessCharge}</TableCell>
                              <TableCell >{row.actionPerformedBy}</TableCell>
                              <TableCell >{row.actionPerformedAt}</TableCell>
                              <TableCell >{row.remarks}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              </Row>
            </Typography>
          )}
          {value === 2 && (
            <Typography className="tabs-content">
              <Row >
                <TableContainer>
                  <Table>
                    <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                      <TableRow>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          No.
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Details
                        </TableCell>

                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Transaction For
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Time
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Receipt
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lockerTransaction &&
                        lockerTransaction
                          .map((row, index) => (
                            <TableRow key={index} className="tablerow">
                              <TableCell >{index + 1}</TableCell>
                              <TableCell >{row.transactionType}</TableCell>
                              <TableCell >{row.transactionAmount}</TableCell>
                              <TableCell >{row.transactionDetails}</TableCell>
                              <TableCell >{row.transactionFor}</TableCell>
                              {/* <TableCell >{row.transactionDate}</TableCell> */}
                              <TableCell >{moment(row.transactionDate).format("DD-MM-YYYY")}</TableCell>
                              <TableCell >{moment(row.transactionDate).format("hh:mm:ss")}</TableCell>
                              <TableCell >
                                <Tooltip title="Download">
                                  <IconButton
                                    onClick={() => handleGeneratePdf(row)}
                                  >
                                    <FeatherIcon icon="download" width="18" />
                                  </IconButton>
                                </Tooltip></TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              </Row>
            </Typography>
          )}
          {value === 3 && (
            <Typography className="tabs-content">
              <div class="container">
                <div class="row">
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="serviceBox" onClick={handleSubmitRemove}>
                      <div class="service-icon">
                        <i class="mdi mdi-locker"></i>
                      </div>
                      <h3 class="title">Unassign Locker '{lockerDetails?.customerLockerNo}'</h3>
                      <p class="description">
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    {lockerDetails.lockerStatus === "Breaked" ? (
                      <div class="serviceBox" onClick={handleSubmitUnfreeze}>
                        <div class="service-icon">
                          <i class="mdi mdi-lock-off" aria-hidden="true"></i>
                        </div>
                        <h3 class="title">Active Locker '{lockerDetails?.customerLockerNo}'</h3>
                        <p class="description">
                        </p>
                      </div>
                    ) : (
                      <div class="serviceBox" onClick={handleSubmitBreak}>
                        <div class="service-icon">
                          <i class="mdi mdi-lock-off" aria-hidden="true"></i>
                        </div>
                        <h3 class="title">Break Locker '{lockerDetails?.customerLockerNo}'</h3>
                        <p class="description">
                        </p>
                      </div>
                    )}

                  </div>

                  <div class="col-lg-3 col-md-6 col-sm-12">
                    {lockerDetails.status ? (
                      <div class="serviceBox" onClick={handleSubmit}>
                        <div class="service-icon">
                          <i class="fa fa-snowflake" aria-hidden="true"></i>
                        </div>
                        <h3 class="title">Freeze Locker '{lockerDetails?.customerLockerNo}'</h3>
                        <p class="description">
                        </p>
                      </div>
                    ) : (
                      <div class="serviceBox" onClick={handleSubmitUnfreeze}>
                        {" "}
                        <div class="service-icon">
                          <i class="fa fa-snowflake" aria-hidden="true"></i>
                        </div>
                        <h3 class="title">

                          Unfreeze Locker '{lockerDetails?.customerLockerNo}'</h3>
                        <p class="description">
                        </p>
                      </div>
                    )}

                  </div>

                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="serviceBox" onClick={handleAdditionalLock}>
                      <div class="service-icon">
                        <i class="fa fa-lock"></i>
                      </div>
                      <h3 class="title">Add Extra Lock</h3>
                      <p class="description">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Typography>
          )}
        </Col>

      </Row>

    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(OccupiedLockerDetails)


