import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap"
import {
  getKYCByIdAPI,
  getLockerHistoryAPI,
  getLockerTransactionByIdAPI,
  getOccupiedLockerByIdAPI,
  updateLockerTransactionByIdAPI,
} from "service/service"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2";

import Tab from '@mui/material/Tab';
import logo from "../../assets/images/logo-sm.png"
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { gst } from "api/apiClient"

import moment from 'moment-timezone';
import FeatherIcon from "feather-icons-react"
import ImageViewer from "ImageViewer"
import * as ReactDOMServer from 'react-dom/server';
import html2pdf from "html2pdf.js/dist/html2pdf.min"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// import swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'
import { convert } from "currency-in-words"
// const MySwal = withReactContent(swal);
// import logo from "../../assets/images/logo-sm.png"

const OccupiedLockerDetails = props => {
  document.title =
    "Assigned Locker Details | Shreesh Securities"

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Assigned Locker List", link: "/assignedLockerList" },
    { title: "Assigned Locker Details", link: "#" },
  ]

  const { id } = useParams()
  const [customerDetails, setCustomerDetails] = useState([])
  const [lockerDetails, setLockerDetails] = useState([])
  const [holderDetails, setHolderDetails] = useState([])
  // const [reason, setReason] = useState("")
  const [historyLocker, setLockerHistory] = useState([])
  const [lockerTransaction, setLockerTransaction] = useState([])
  const [transactionDetail, setTransactionDetail] = useState("")
  const smallScreen = useMediaQuery("(max-width: 600px)")
  const [value, setValue] = React.useState(0);

  const [customerKyc, setCustomerKyc] = useState([]);
  const [custKycDetail, setCustKycDetail] = useState("");

  const handleDisplaylockerUpdate = async id => {
    try {
      const res = await getOccupiedLockerByIdAPI(id)
      setCustomerDetails(res.data)
      setLockerDetails(res.data.locker)
      const custKyc = await getKYCByIdAPI(res.data.locker.customerId)
      setCustomerKyc(custKyc.data)
      // const lockerNo = res.data.locker.customerLockerNo
      // const response = await getLockerHistoryAPI(lockerNo)
      // setLockerHistory(response.data)
      setHolderDetails(res.data.locker.holders)
      // await downloadPdf(res.data, res.data.locker);
      // await getCustomerKyc()

    } catch (error) {
      console.log(error);
    }
  }

  const kycDetail = () => {
    const temp = ""
    customerKyc.length > 0 && customerKyc.map(data => {
      // console.log(data.docDetail === "Aadhar Card" ? data.docDetail : "data.docDetail");
      if (data.documentType === "Pan card") {
        console.log(data.docDetail);
        setCustKycDetail(data.docDetail)
      }
    })
  }

  const getLockerHistory = async () => {
    try {
      const res = await getLockerHistoryAPI(lockerDetails?.customerLockerNo)
      setLockerHistory(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const getLockerTransaction = async () => {
    kycDetail()
    try {
      const res = await getLockerTransactionByIdAPI(lockerDetails?.customerLockerId)
      setLockerTransaction(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleGeneratePdf = async (item) => {
    // console.log("iiiiiiiii = ", item);
    setTransactionDetail(item)
    await downloadPdf(item);
  }

  let srno = 1;

  // function getCurrentTimestamp() {
  //   const date = Date.now()
  //   const randomNum = Math.random() * date
  //   const formattedRandomNum = Math.floor(randomNum)
  //   console.log("formattedRandomNum - ", formattedRandomNum)

  //   // return Date.now()
  // }

  // function random10to6th_concatToTimestamp() {
  //   // return new Date().getTime() + '' + Math.round(Math.random() * Math.pow(10, 6));
  //   return Math.random() * Math.pow(10, 6);

  // }

  function generateReceiptNum() {
    var date = Date.now();
    // let date = (dd + Math.random()).substring(1, 4)
    let date22 = Math.floor(Math.random() * date)
    let dd = date22.toString()
    // console.log(dd)
    // console.log(dd.substr(1, 4));
    let dateFormate = dd.substr(1, 3)

    return { dateFormate }
  }

  const downloadPdf = async (transactionData) => {
    let { dateFormate } = generateReceiptNum()
    const receiptData = customerDetails;
    const receiptLocker = lockerDetails;

    // console.log("mobile ", customerDetails.mobile);
    let mst = customerDetails?.mobile.toString()
    // console.log("mobile mst ", mst);
    let msr = mst.substr(3, 3)
    // console.log("mobile msr ", msr);
    // console.log(lockerDetails.customerLockerNo, dateFormate, msr);
    const receiptTransaction = transactionData;
    // const rateGst = ((receiptLocker.lockerRate * gst) / 100);
    // const totalAmount = (receiptLocker.entryFees) + (receiptLocker.lockerDeposit) + (receiptLocker.lockerRate) + (receiptLocker.penalty);
    // const totalGST = ((receiptLocker.entryFees * gst) / 100) + rateGst
    // const totalNetAmount = totalAmount + totalGST
    let totalAllocateGST = receiptTransaction?.transactionFor === "Locker Allocated" ? receiptLocker?.entryFees / 1.18 * gst / 100 + receiptLocker?.lockerRate * gst / 100 : ""

    let totalAddLockGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    let totalLockerRenewedWithPenaltyGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    let totalLockerRenewedWithoutPenaltyGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    let totalCustomerLockerPlanChangedWithPenaltyGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    let totalCustomerLockerPlanChangedWithoutPenaltyGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    let totalLockerPenaltyChargedGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    let totalLockerBreakChargedGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    let totalLockerAccessChargedGST = receiptTransaction?.transactionAmount / 1.18 * gst / 100
    //   const printElement = (<div id="htmlElementId">
    //     <div className="container pt-5" style={{ padding: "10px" }}>
    //       <div style={{ border: "4px double black" }}>
    //         <div className="m-0" style={{ borderBottom: "1px solid black" }}>
    //           <div className="row align-items-center">
    //             <div className="col-2 d-flex justify-content-center">
    //               <img
    //                 src={logo}
    //                 alt=""
    //                 style={{ height: "4rem", paddingLeft: "5px" }}
    //               />
    //             </div>
    //             <div className="col-10 py-2 text-center">
    //               <span
    //                 className="fw-bold p-0 m-0"
    //                 style={{ fontSize: "20px" }}
    //               >
    //                 SHREESH SECURITIES
    //               </span>{" "}
    //               <br />
    //               <span style={{ fontSize: "9px", marginTop: "-20px" }}>
    //                 Managed By : Shreesh Design Studio
    //               </span>
    //               <br />
    //               <span className="text-center ">
    //                 "Shreesh" 4th - 5th Floor, Dr.Dastur Marg, Off Dr.Yagnik
    //                 Road, B/h D.H. College,
    //                 <br /> Rajkot - 360001, Gujrat, INDIA
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //         <div>
    //           <div className="table-responsive receipt-table">
    //             <table className="table ">
    //               <thead>
    //                 <tr>
    //                   <td colSpan="5">
    //                     <div>
    //                       <div className="d-flex gap-2 lh-1">
    //                         <p className="fw-bold">Name : </p>
    //                         <p id="name" value={receiptData?.fullName}>{receiptData.fullName}</p>
    //                       </div>
    //                       <div className="d-flex gap-2 lh-1">
    //                         <p className="fw-bold">Mobile : </p>
    //                         <p>{receiptData?.mobile}</p>
    //                       </div>
    //                       <div className="d-flex gap-2 lh-1">
    //                         <p className="fw-bold">Locker No : </p>
    //                         <p>{receiptLocker?.customerLockerNo}</p>
    //                       </div>
    //                       <div className="d-flex gap-2 lh-1">
    //                         <p className="fw-bold">Plan Start Date : </p>
    //                         {/* <p>{receiptLocker.planStartDate}</p> */}
    //                         <p>
    //                           {moment(receiptLocker?.planStartDate).format(
    //                             "DD/MM/YYYY"
    //                           )}
    //                         </p>
    //                       </div>
    //                       <div className="d-flex gap-2 lh-1">
    //                         <p className="fw-bold">Plan End Date : </p>
    //                         {/* <p>{receiptLocker.planEndDate}</p> */}
    //                         <p>
    //                           {moment(receiptLocker?.planEndDate?.split('T')[0]).format(
    //                             "DD/MM/YYYY"
    //                           )}
    //                         </p>
    //                       </div>
    //                     </div>
    //                   </td>
    //                   <td colSpan="2" className="align-top">
    //                     <div>
    //                       <div className="d-flex gap-2 lh-1">
    //                         <p className="fw-bold">Date :</p>
    //                         {/* <p>{currentDate}</p> */}
    //                         <p>{moment().format("DD/MM/YYYY")}</p>
    //                       </div>
    //                       <div className="d-flex flex-wrap">
    //                         <p className="fw-bold">Receipt No :</p>
    //                         <p>TR_{lockerDetails?.customerLockerNo}{msr}{dateFormate}</p>
    //                       </div>

    //                     </div>
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <th >Sr. No.</th>
    //                   <th >Transaction Type</th>
    //                   <th >Transaction Mode</th>
    //                   {/* <th >HSN Code</th> */}
    //                   <th >Transaction For</th>
    //                   {/* <th >GST (18%)</th> */}
    //                   <th >Net Amount</th>
    //                   <th >Date</th>
    //                   <th >Time</th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 <tr style={{ border: "none" }}>
    //                   <td>{srno++}</td>
    //                   <td>{receiptTransaction?.transactionType}</td>
    //                   <td>{receiptTransaction?.modeOfPayment}</td>
    //                   {/* <td>24334</td> */}
    //                   <td>{receiptTransaction?.transactionFor}</td>
    //                   <td>{receiptTransaction?.transactionAmount}</td>
    //                   <td>{moment(receiptTransaction?.transactionDate).format("DD/MM/YYYY")}</td>
    //                   <td>{moment(receiptTransaction?.transactionDate).format("hh:mm:ss")}</td>
    //                 </tr>
    //                 <tr>
    //                   <td colSpan={5} >
    //                     <div className="row">
    //                       <div className="col-8">
    //                         <span className="fw-bold">GSTIN No. :</span>{" "}
    //                         24AEXFS7939H1Z5
    //                       </div>
    //                       <div className="col-4 text-end">
    //                         <span className="fw-bold">Total&nbsp;:&nbsp; </span>{receiptTransaction?.transactionAmount}

    //                       </div>
    //                     </div>
    //                   </td>
    //                   <td colSpan={2}>{""}</td>
    //                   {/* <td >{""}</td> */}
    //                   {/* <td >{totalGST}</td>
    //                   <td >{totalNetAmount} </td> */}
    //                 </tr>
    //                 <tr>
    //                   <td colSpan="5" >
    //                     <div className="row">
    //                       <div className="col-8">
    //                         <span className="fw-bold">Bank Name :</span> SHREE DHARTI CO-OP. BANK LTD.
    //                       </div>
    //                       <div className="col-4">
    //                         <span className="fw-bold">IFSC Code :</span>{" "}
    //                         IBKL01642SD
    //                       </div>
    //                     </div>
    //                     <span className="fw-bold">Bank A/c. No :</span>{" "}
    //                     442005101000462
    //                   </td>
    //                   <td colSpan={2}></td>
    //                 </tr>
    //                 <tr>
    //                   <th
    //                     colSpan="5"
    //                     className="  text-capitalize"
    //                   >
    //                     {/* Total GST : {convert(`${parseInt(totalGST)}`)}{" "} */}
    //                     {/* <br /> */}
    //                     Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
    //                   </th>
    //                   <th
    //                     colSpan="2"
    //                     className="   text-center"
    //                   >
    //                     Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

    //                   </th>
    //                 </tr>
    //               </tbody>
    //             </table>
    //           </div>
    //         </div>
    //         <div className="row">
    //           <div className="col-8 ">
    //             <div className="ps-2">
    //               <h6>Term & Condition</h6>
    //               <ul style={{ fontSize: '12px' }}>
    //                 <li className="fst-italic">
    //                   "Break open change RS. 11000/- + GST change extra."
    //                 </li>
    //                 <li className="fst-italic">
    //                   "Late fees per month Rs.250/- + GST change extra from next month."
    //                 </li>
    //                 <li className="fst-italic">
    //                   "Additional lock available on request with charge of Rs.4000/- + GST charge extra."
    //                 </li>
    //                 <li className="fst-italic">
    //                   "Cheque deposits in A/C are subject to clearing."
    //                 </li>
    //                 <li className="fst-italic">
    //                   "Your given deposit is valid for 9 years."
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //           <div className="col-4 position-relative">
    //             <h6>For, Shreesh Securities </h6>
    //             <p className="fst-italic mt-4 position-absolute bottom-0 ">(Authorised Signatory) </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>)
    //   // console.log(printElement);
    //   html2pdf().from(ReactDOMServer.renderToStaticMarkup(printElement)).set({ filename: 'Transaction Receipt.pdf' }).save();
    // }
    const printElement = (<div id="htmlElementId">
      <div className="container pt-5" style={{ padding: "30px" }}>
        <div style={{ border: "4px double black" }}>
          <div className="m-0" style={{ borderBottom: "1px solid black" }}>
            <div className="row align-items-center">
              <div className="col-2 d-flex justify-content-center">
                <img
                  src={logo}
                  alt=""
                  style={{ height: "4rem", paddingLeft: "5px" }}
                />
              </div>
              <div className="col-8 py-2 text-center">
                <span
                  className="fw-bold p-0 m-0"
                  style={{ fontSize: "20px" }}
                >
                  SHREESH SECURITIES
                </span>{" "}
                <br />
                <span style={{ fontSize: "9px", marginTop: "-20px" }}>
                  Managed By : Shreesh Design Studio
                </span>
                <br />
                <span className="text-center ">
                  "Shreesh" 4th - 5th Floor, Dr.Dastur Marg, Off Dr.Yagnik
                  Road, B/h D.H. College,
                  <br /> Rajkot - 360001, Gujrat, INDIA
                </span>
              </div>
              <div className="col-2" style={{ marginLeft: "-1.5rem" }}>
                {receiptTransaction?.transactionFor === "Locker Allocated" ?
                  <h3>Locker Rent</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Locker Deposit Received" ?
                  <h3>Locker Deposit</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Locker Deposit Refunded" ?
                  <h3>Locker Deposit Refunded</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Additioinal Lock Charge" ?
                  <h3>Additional Lock</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Locker Renewed With Penalty" ?
                  <h3>Locker Renewed With Penalty</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Locker Renewed Without Penalty" ?
                  <h3>Locker Renewed Without Penalty</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Customer Locker Plan Changed With Penalty" ?
                  <h3>Locker Plan Changed With Penalty</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Customer Locker Plan Changed Without Penalty" ?
                  <h3>Locker Plan Changed Without Penalty</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Locker Penalty Charged" ?
                  <h3>Locker Penalty Charge</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Locker Break Charged" ?
                  <h3>Locker Break Charge</h3> : ""
                }
                {receiptTransaction?.transactionFor === "Locker Access Charged" ?
                  <h3>Locker Access Charge</h3> : ""
                }
              </div>
            </div>
          </div>
          <div>
            <div className="table-responsive">
              <table className="table receipt-table">
                <thead>
                  <tr>
                    <td colSpan="6">
                      <div className="">
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Name : </span>
                          <span id="name" value={receiptData?.fullName}>{receiptData.fullName}</span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Mobile : </span>
                          <span>{receiptData?.mobile}</span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">PAN Card No : </span>
                          <span>{custKycDetail}</span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Locker No : </span>
                          <span>{receiptLocker?.customerLockerNo}</span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Plan Start Date : </span>
                          {/* <p>{receiptLocker.planStartDate}</p> */}
                          <span>
                            {moment(receiptLocker?.planStartDate).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </div>
                        <div className="d-flex gap-2 lh-1 py-2">
                          <span className="fw-bold">Plan End Date : </span>
                          {/* <p>{receiptLocker.planEndDate}</p> */}
                          <span>
                            {moment(receiptLocker?.planEndDate?.split('T')[0]).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td colSpan="2" className="align-top">
                      <div>
                        <div className="d-flex gap-2 lh-1">
                          <p className="fw-bold">Date :</p>
                          {/* <p>{currentDate}</p> */}
                          <p>{moment().format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="d-flex flex-wrap">
                          <p className="fw-bold">Receipt No : </p>
                          {/* <p>ASS_{receiptData?._id.substr(receiptData?._id.length - 4)}</p> */}
                          <p>ASS_{receiptLocker?.customerLockerNo}{msr}{dateFormate}</p>
                        </div>

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Title</th>
                    <th>HSN Code</th>
                    <th>Mode</th>
                    <th>Information</th>
                    <th>Amount</th>
                    <th>GST (18%)</th>
                    <th>Net Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    receiptTransaction?.transactionFor === "Locker Allocated" ?
                      <>
                        <tr className="receipt-border-none">
                          <td>{srno++}</td>
                          <td>Entry Fees</td>
                          <td>997212</td>
                          <td>{receiptTransaction?.modeOfPayment}</td>
                          <td>{receiptTransaction?.paymentDescription}</td>
                          <td>{receiptLocker?.entryFees / 1.18}</td>
                          <td>{receiptLocker?.entryFees / 1.18 * gst / 100}</td>
                          <td>{receiptLocker?.entryFees}</td>
                        </tr>
                        <tr className="receipt-border-none">
                          <td>{srno++}</td>
                          <td>Locker Rate</td>
                          <td>997212</td>
                          <td>{receiptTransaction?.modeOfPayment}</td>
                          <td>{receiptTransaction?.paymentDescription}</td>
                          <td>{receiptLocker?.lockerRate}</td>
                          <td>{receiptLocker?.lockerRate * gst / 100}</td>
                          <td>{receiptLocker?.lockerRate + (receiptLocker?.lockerRate * gst / 100)}</td>
                        </tr>
                      </> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Deposit Received" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Deposit (Refundable)</td>
                        <td>-</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                        <td>-</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Deposit Refunded" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Deposit (Refundable)</td>
                        <td>-</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                        <td>-</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }

                  {
                    receiptTransaction?.transactionFor === "Additioinal Lock Charge" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Additional Lock</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Renewed With Penalty" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Locker Renewed With Penalty</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Renewed Without Penalty" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Locker Renewed Without Penalty</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Customer Locker Plan Changed With Penalty" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Locker Plan Changed With Penalty</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Customer Locker Plan Changed Without Penalty" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Locker Plan Changed Without Penalty</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Penalty Charged" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Locker Penalty Charged</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Break Charged" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Locker Break Charged</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Access Charged" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>Locker Access Charged</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }

                  {/* {
                    receiptTransaction?.transactionFor !== "Locker Allocated" || receiptTransaction?.transactionFor !== "Locker Deposit Received" || receiptTransaction?.transactionFor !== "Locker Deposit Refunded" || receiptTransaction?.transactionFor !== "Additioinal Lock Charge" ?
                      <tr className="receipt-border-none">
                        <td>{srno++}</td>
                        <td>{receiptTransaction?.transactionFor}</td>
                        <td>997212</td>
                        <td>{receiptTransaction?.modeOfPayment}</td>
                        <td>{receiptTransaction?.paymentDescription}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  } */}


                  {
                    receiptTransaction?.transactionFor === "Locker Allocated" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{(receiptLocker?.entryFees / 1.18 * gst / 100) + (receiptLocker?.lockerRate * gst / 100)}</td>
                        <td>{receiptLocker?.entryFees + (receiptLocker?.lockerRate + (receiptLocker?.lockerRate * gst / 100))}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Deposit Received" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>0</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Additioinal Lock Charge" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Deposit Refunded" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>0</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Renewed With Penalty" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Renewed Without Penalty" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Customer Locker Plan Changed With Penalty" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Customer Locker Plan Changed Without Penalty" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Penalty Charged" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Break Charged" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Access Charged" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18 * gst / 100}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  }
                  {/* {
                    receiptTransaction?.transactionFor !== "Locker Allocated" || receiptTransaction?.transactionFor !== "Locker Deposit Received" || receiptTransaction?.transactionFor !== "Locker Deposit Refunded" || receiptTransaction?.transactionFor !== "Additioinal Lock Charge" ?
                      <tr>
                        <td colSpan={6}>
                          <div className="row">
                            <div className="col-8">
                              <span className="fw-bold">GSTIN No. :</span>{" "}
                              24AEXFS7939H1Z5
                            </div>
                            <div className="col-4 text-end">
                              <span className="fw-bold">Total&nbsp;:&nbsp; </span>{ }
                            </div>
                          </div>
                        </td>
                        <td>{receiptTransaction?.transactionAmount / 1.18}</td>
                        <td>{receiptTransaction?.transactionAmount}</td>
                      </tr> : ""
                  } */}

                  <tr>
                    <td colSpan="6">
                      <div className="col-12">
                        <span className="fw-bold">Bank Name :</span> SHREE DHARTI CO-OP. BANK LTD.
                      </div>
                      <div className="row">


                        <div className="col-7">
                          <span className="fw-bold">Bank A/c. No :</span>{" "}
                          442005101000462
                        </div>
                        <div className="col-5">
                          <span className="fw-bold">IFSC Code :</span>{" "}
                          IBKL01642SD
                        </div>
                      </div>

                    </td>
                    <td colSpan={6}></td>
                  </tr>
                  {
                    receiptTransaction?.transactionFor === "Additioinal Lock Charge" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalAddLockGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Allocated" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalAllocateGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Deposit Received" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(0)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Deposit Refunded" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(0)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Renewed With Penalty" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalLockerRenewedWithPenaltyGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Renewed Without Penalty" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalLockerRenewedWithoutPenaltyGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Customer Locker Plan Changed With Penalty" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalCustomerLockerPlanChangedWithPenaltyGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Customer Locker Plan Changed Without Penalty" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalCustomerLockerPlanChangedWithoutPenaltyGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Penalty Charged" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalLockerPenaltyChargedGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Break Charged" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalLockerBreakChargedGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {
                    receiptTransaction?.transactionFor === "Locker Access Charged" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(totalLockerAccessChargedGST)}`)}{" "}
                          <br />
                          {/* Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} */}
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          {/* Grand Total : {parseInt(totalNetAmount)} */}
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  }
                  {/* {
                    receiptTransaction?.transactionFor !== "Locker Allocated" || receiptTransaction?.transactionFor !== "Locker Deposit Received" || receiptTransaction?.transactionFor !== "Locker Deposit Refunded" || receiptTransaction?.transactionFor !== "Additioinal Lock Charge" ?
                      <tr>
                        <th
                          colSpan="6"
                          className="text-capitalize"
                        >
                          Total GST : {convert(`${parseInt(receiptTransaction?.transactionAmount / 1.18)}`)}{" "}
                          <br />
                          Bill Amount : {convert(`${parseInt(receiptTransaction?.transactionAmount)}`)}{" "}
                        </th>
                        <th
                          colSpan="2"
                          className=""
                        >
                          Grand Total : {parseInt(receiptTransaction?.transactionAmount)}

                        </th>
                      </tr> : ""
                  } */}
                  {/* <tr>
                    <th
                      colSpan="6"
                      className="text-capitalize"
                    >
                      Total GST : {convert(`${parseInt(totalGST)}`)}{" "}
                      <br />
                       Bill Amount : {convert(`${parseInt(totalNetAmount)}`)}{" "} 
                      Bill Amount : {convert(`${parseInt()}`)}{" "}
                    </th>
                    <th
                      colSpan="2"
                      className=""
                    >
                      Grand Total : {parseInt(totalNetAmount)} 
                      Grand Total : {parseInt()}

                    </th>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-8 ">
              <div className="ps-2">
                <h6>Term & Condition</h6>
                <ul style={{ fontSize: '10px' }}>
                  <li className="fst-italic">
                    "Break open change RS. 11000/- + GST change extra."
                  </li>
                  <li className="fst-italic">
                    "Late fees per month Rs.250/- + GST change extra from next month."
                  </li>
                  <li className="fst-italic">
                    "Additional lock available on request with charge of Rs.4000/- + GST charge extra."
                  </li>
                  <li className="fst-italic">
                    "Cheque deposits in A/C are subject to clearing."
                  </li>
                  <li className="fst-italic">
                    "Your given deposit is valid for 9 years."
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-4 position-relative">
              <h6>For, Shreesh Securities </h6>
              <p className="fst-italic mt-4 position-absolute bottom-0 ">(Authorised Signatory) </p>
            </div>
          </div>
        </div>
      </div>
    </div>)
    // console.log(printElement);
    html2pdf().from(ReactDOMServer.renderToStaticMarkup(printElement)).set({ filename: 'Transaction Receipt.pdf' }).save();
  }

  // let date = new Date(lockerDetails?.planEndDate).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
  // const str = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  // console.log("str == ", str);
  let startDate = lockerDetails?.planStartDate?.split('T')[0]
  let endDate = lockerDetails?.planEndDate?.split('T')[0]

  useEffect(() => {
    props.setBreadcrumbItems("Assigned Locker Details", breadcrumbItems)
  })

  useEffect(() => {
    if (id) {
      handleDisplaylockerUpdate(id)
    }
  }, [""])


  const [modeOfPayment, setModeOfPayment] = useState("");
  const [transactionDbId, setTransactionDbId] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [transactionFor, setTransactionFor] = useState("");


  // const [state, setState] = useState(0);

  // const [expandedRows, setExpandedRows] = useState([]);

  // State variable to keep track which row is currently expanded.
  // const [expandState, setExpandState] = useState({});

  // const handleClick = (index, userId) => {
  //   // const currentExpandedRows = expandedRows;
  //   // const isRowExpanded = currentExpandedRows.includes(userId);

  //   // let obj = {};
  //   // isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
  //   // setExpandState(obj);
  //   // setTransactionDbId(userId)

  //   // // If the row is expanded, we are here to hide it. Hence remove
  //   // // it from the state variable. Otherwise add to it.
  //   // const newExpandedRows = isRowExpanded ?
  //   //   currentExpandedRows.filter(id => id !== userId) :
  //   //   currentExpandedRows.concat(userId);

  //   // setExpandedRows(newExpandedRows);
  //   // console.log("newExpandedRows = ", newExpandedRows);
  //   // setModeOfPayment("")
  //   const updatedState = lockerTransaction[index];
  //   // console.log("updatedState ", updatedState);

  //   if (updatedState.other) {
  //     setTransactionDbId("")
  //     delete updatedState.other;
  //     setState((pre) => {
  //       return pre + 1;
  //     });
  //   } else {
  //     // console.log(updatedState.transactionDBId);

  //     updatedState.other = {
  //       description: (
  //         <div className="py-1" style={{ textAlign: "center" }}>
  //           <Label
  //             htmlFor="example-text-input"
  //             className=" col-form-label"
  //             style={{ fontWeight: "600" }}
  //           >
  //             Set Mode of Payment
  //           </Label>
  //           <div className="mx-2" style={{ display: "flex", justifyContent: "center" }}>
  //             <div
  //               className="active"
  //               id="modeOfPayment"
  //               style={{ padding: "0px 5rem" }}
  //             >
  //               <Input
  //                 type="radio"
  //                 name="modeOfPayment"
  //                 value="Cash"
  //                 // defaultChecked={addressType === 1 || addressType === "1"}
  //                 onChange={e => {
  //                   setModeOfPayment(e.target.value)
  //                 }}
  //               />
  //               &nbsp;Cash&nbsp;&nbsp;
  //               <Input
  //                 type="radio"
  //                 name="modeOfPayment"
  //                 value="Online"
  //                 // defaultChecked={addressType === 1 || addressType === "1"}
  //                 onChange={e => {
  //                   setModeOfPayment(e.target.value)
  //                 }}
  //               />
  //               &nbsp;Online&nbsp;&nbsp;
  //               <Input
  //                 type="radio"
  //                 name="modeOfPayment"
  //                 value="Cheque"
  //                 // defaultChecked={addressType === 2 || addressType === "2"}
  //                 onChange={e => {
  //                   setModeOfPayment(e.target.value)
  //                 }}
  //               />
  //               &nbsp;Cheque
  //             </div>
  //             <div>
  //               <Input
  //                 type="submit"
  //                 name="modeOfPayment" onClick={handlePaymentSubmit()} />
  //             </div>
  //           </div>
  //         </div>
  //       ) //or data from api
  //     };

  //     setState((pre) => {
  //       return pre + 1;
  //     });
  //   }
  // };

  // console.log("transactionDbId - ", transactionDbId);

  const submitPay = async (e) => {
    if (modeOfPayment) {
      // console.log("id = ", index);
      console.log("modeOfPayment before = ", transactionDbId, modeOfPayment, paymentDescription);
      try {
        const response = await updateLockerTransactionByIdAPI(transactionDbId, modeOfPayment, paymentDescription)
        // console.log(response);
        if (response.status === 1 || response.status === "1") {
          Swal.fire({
            title: response.message,
            icon: "success",
            timer: 1500
          })
          setPaymentDescription("")
          setModeOfPayment("")
          await getLockerTransaction()
        } else {
          Swal.fire({
            title: response.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handlePaymentSubmit = async (index, transactionFor) => {
    setModeOfPayment("")
    setTransactionFor(transactionFor)
    console.log("setModeOfPayment = ", modeOfPayment);
    setTransactionDbId(index)
    // console.log(" consolededfefrf ", index, modeOfPayment);
    await submitPay()
  }


  return (
    <div>

      <Row>
        <Col>
          <div className="d-flex justify-content-end">
            <div className="goback">
              <Link to="/assignedLockerList" >
                Go Back
              </Link>
            </div>
          </div>

          <Tabs
            value={value}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
            style={{ borderBottom: "1px solid gray" }}
            orientation={smallScreen ? "vertical" : "horizontal"}
            classes={{
              flexContainer: "flex-wrap sm:flex-nowrap",
            }}
            className="tabs"
          >
            <Tab className="top-tab" label="Locker Details" style={{ fontWeight: "600" }} />
            <Tab className="top-tab" label="Locker History" style={{ fontWeight: "600" }} onClick={getLockerHistory} />
            <Tab className="top-tab" label="Locker Transaction & Receipt" style={{ fontWeight: "600" }} onClick={getLockerTransaction} />
            {/* <Tab className="top-tab" label="Locker Action" style={{ fontWeight: "600" }} /> */}

          </Tabs>

          {value === 0 && (
            <Typography className="tabs-content">
              <Row>
                {/* Locker Details */}
                <Card className="mt-3">
                  <CardBody>
                    <Row className="fw-bold view-table">
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Category</h6>
                        <p>{lockerDetails?.lockerName}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Category</h6>
                        <p>{lockerDetails?.lockerCategory}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker No</h6>
                        <p>{lockerDetails?.customerLockerNo}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Key</h6>
                        <p>{lockerDetails?.customerLockerKey}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Size</h6>
                        <p>{lockerDetails?.lockerSize}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Plan Name</h6>
                        <p>{lockerDetails?.rentName}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Plan Duration Days</h6>
                        <p>{lockerDetails?.rentDurationDays}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Plan Start Date</h6>
                        <p>{moment(startDate).format("DD-MM-YYYY")}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Plan End Date</h6>
                        <p>{moment(endDate).format("DD-MM-YYYY")}</p>
                      </Col>

                      {lockerDetails?.referenceBy &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Reference By</h6>
                          <p>{lockerDetails?.referenceBy}</p>
                        </Col>
                      }

                      {lockerDetails?.operatingMandate &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Operating Mandate</h6>
                          <p>{lockerDetails?.operatingMandate}</p>
                        </Col>
                      }

                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Status</h6>
                        <p>{lockerDetails?.lockerStatus}</p>
                      </Col>

                      {/* {
                        lockerDetails?.reason &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Reason</h6>
                          <p>{lockerDetails?.reason}</p>
                        </Col>
                      } */}

                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Status</h6>
                        {lockerDetails?.status ? (
                          <div className="active-btn">Active</div>
                        ) : (
                          <div className="deactive-btn">Inactive</div>
                        )}
                      </Col>
                    </Row>
                    <hr />
                    <Row className="fw-bold view-table">

                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Deposit (Refundable)</h6>
                        <p>{lockerDetails?.lockerDeposit}</p>
                      </Col>

                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Entry Fees (GST 18%)</h6>
                        <p>{lockerDetails?.entryFees}</p>
                      </Col>

                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Locker Rate (GST 18%)</h6>
                        {/* <p>{lockerDetails?.lockerRate + (lockerDetails?.lockerRate * gst) / 100}</p> */}
                        <p>{lockerDetails?.rentPrice + (lockerDetails?.rentPrice * gst) / 100}</p>
                      </Col>

                      {lockerDetails?.penalty > 0 &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Late Fees (GST 18%)</h6>
                          <p>{lockerDetails?.penalty}</p>
                        </Col>
                      }

                      {lockerDetails?.breakCharge > 0 &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Break Charge (GST 18%)</h6>
                          <p>{lockerDetails?.breakCharge}</p>
                        </Col>
                      }

                      {
                        lockerDetails?.additionalLockCharge > 0 &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Additional Lock Charge (GST 18%)</h6>
                          <p>{lockerDetails?.additionalLockCharge}</p>
                        </Col>
                      }



                      {lockerDetails?.rentMinimumCharges && (

                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Rent Minimum Charges</h6>
                          <p>{lockerDetails?.rentMinimumCharges}</p>
                        </Col>
                      )}


                    </Row>
                  </CardBody>
                </Card>

                {/* Primary Holder */}
                <Card>
                  <CardBody>
                    <CardTitle className="fs-4">
                      <b>Primary Holder</b>
                    </CardTitle>
                    <hr />
                    <Row className="fw-bold view-table" style={{}}>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold ">Name</h6>
                        <p>{customerDetails?.fullName}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Occupation</h6>
                        <p>{customerDetails?.occupation}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Email</h6>
                        <p>{customerDetails?.email}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Mobile</h6>
                        <p>{customerDetails?.mobile}</p>
                      </Col>
                      {customerDetails?.telephone &&
                        <Col xl={3} lg={4} md={6} sm={12}>
                          <h6 className="fw-bold">Telephone</h6>
                          <p>{customerDetails?.telephone}</p>
                        </Col>
                      }
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Photo</h6>
                        <p><ImageViewer path={customerDetails?.photo} style={{ width: "5rem", height: "4rem", cursor: "pointer" }} /></p>
                      </Col>
                      {/* <Col xl={3} lg={4} md={6} sm={12}>
                    <h6 className="fw-bold">Primary Holder</h6>
                    {customerDetails?.isPrimaryHolder ? (
                      <div className="active-btn">Yes</div>
                    ) : (
                      <div className="deactive-btn">No</div>
                    )}
                  </Col> */}
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">KYC Status</h6>
                        <p>{customerDetails?.kycStatus}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Status</h6>

                        {customerDetails?.status ? (
                          <div className="active-btn">Active</div>
                        ) : (
                          <div className="deactive-btn">Inactive</div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {/* Sub Holder */}
                {holderDetails?.length > 0 &&
                  <Card>
                    <CardBody>
                      <CardTitle className="fs-4">
                        <b>Sub Holders</b>
                      </CardTitle>
                      {/* <hr /> */}
                      {holderDetails &&
                        holderDetails?.length > 0 &&
                        holderDetails.map((data, i) => (
                          <>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="mt-2 text-capitalize"
                                style={{ backgroundColor: 'rgb(243 243 243)' }}
                              >
                                <Typography className="fw-bold">{data?.fullName}</Typography>
                              </AccordionSummary>

                              <AccordionDetails style={{ borderTop: "1px solid rgb(171 165 165)" }}>
                                <Typography>
                                  <Row className="fw-bold view-table" style={{}}>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold ">Name</h6>
                                      <p>{data.fullName}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Occupation</h6>
                                      <p>{data.occupation}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Email</h6>
                                      <p>{data.email}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Mobile</h6>
                                      <p>{data.mobile}</p>
                                    </Col>
                                    {data.telephone &&
                                      <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Telephone</h6>
                                        <p>{data.telephone}</p>
                                      </Col>
                                    }
                                    {/* <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Primary Holder</h6>
                                      {data.isPrimaryHolder ? (
                                        <div className="active-btn">Yes</div>
                                      ) : (
                                        <div className="deactive-btn">No</div>
                                      )}
                                    </Col> */}
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">KYC Status</h6>
                                      <p>{data.kycStatus}</p>
                                    </Col>
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                      <h6 className="fw-bold">Status</h6>

                                      {data.status ? (
                                        <div className="active-btn">Active</div>
                                      ) : (
                                        <div className="deactive-btn">Inactive</div>
                                      )}
                                    </Col>
                                  </Row>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </>))}
                    </CardBody>
                  </Card>
                }
              </Row>
            </Typography>
          )}

          {value === 1 && (
            <Typography className="tabs-content">
              <Row >
                <TableContainer>
                  <Table>
                    <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                      <TableRow>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          No.
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Action
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Access Charge
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Action Performed By
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Action Performed At
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Remarks
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyLocker &&
                        historyLocker
                          .map((row, index) => (
                            <TableRow key={index} className="tablerow">
                              <TableCell >{index + 1}</TableCell>
                              <TableCell >{row.action}</TableCell>
                              {row.accessCharge ? <TableCell >{row.accessCharge}</TableCell> : <TableCell >0</TableCell>}
                              <TableCell >{row.actionPerformedBy}</TableCell>
                              <TableCell >{row.actionPerformedAt}</TableCell>
                              <TableCell >{row.remarks}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              </Row>
            </Typography>
          )}

          {value === 2 && (
            <Typography className="tabs-content">
              <Row >
                <TableContainer>
                  <Table>
                    <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                      <TableRow>
                        {/* <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >

                        </TableCell> */}
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          No.
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Mode
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Information
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Details
                        </TableCell>

                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Transaction For
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Time
                        </TableCell>
                        <TableCell
                          className="fs-5 fw-bold"
                          style={{ color: "white" }}
                        >
                          Receipt
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lockerTransaction &&
                        lockerTransaction
                          .map((row, index) => (<>
                            {/* <TableRow key={index} className="tablerow" onClick={event => handleClick(index)}> */}
                            <TableRow key={index} className="tablerow" onClick={() => { handlePaymentSubmit(row.transactionDBId, row.transactionFor) }} >
                              {/* <TableCell >{<button onClick={event => handleClick(index, row.transactionDBId)}>
                                {
                                  expandState[row.transactionDBId] ?
                                    'Hide' : 'Show'
                                }</button>}</TableCell> */}
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{index + 1}</TableCell>
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{row.transactionType}</TableCell>
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{row.modeOfPayment}</TableCell>
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{row.paymentDescription}</TableCell>
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{row.transactionAmount}</TableCell>
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{row.transactionDetails}</TableCell>
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{row.transactionFor}</TableCell>
                              {/* <TableCell >{row.transactionDate}</TableCell> */}
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{moment(row.transactionDate).format("DD-MM-YYYY")}</TableCell>
                              <TableCell data-bs-toggle="modal" data-bs-target="#exampleModal">{moment(row.transactionDate).format("hh:mm:ss A")}</TableCell>
                              <TableCell>
                                <Tooltip title="Download">
                                  <IconButton
                                    onClick={() => handleGeneratePdf(row)}
                                  >
                                    <FeatherIcon icon="download" width="18" />
                                  </IconButton>
                                </Tooltip></TableCell>
                            </TableRow >



                            {/* {
                              expandedRows.includes(row.transactionDBId) ?

                                <tr>
                                  <td colspan="9">
                                    <div>
                                      <h5> Set Mode of Payment </h5>
                                      <div>
                                        <>
                                          <Input type="radio" name="modeOfPayment" value="Cash" onChange={e => setModeOfPayment(e.target.value)} /> Cash
                                          <Input type="radio" name="modeOfPayment" value="Online" onChange={e => setModeOfPayment(e.target.value)} /> Online
                                          <Input type="radio" name="modeOfPayment" value="Cheque" onChange={e => setModeOfPayment(e.target.value)} /> Cheque
                                         
                                        </>
                                      </div>
                                      <div><Input type="submit" onSubmit={expandedRows !== "" ? handlePaymentSubmit() : ""}></Input>

                                      </div>
                                    </div>
                                  </td>
                                </tr> : null
                            } */}
                            {/* {
                              row.other ? (
                                <tr>
                                  <td colSpan={9}>{row.other.description}</td>
                                </tr>
                              ) : null
                            } */}


                          </>))}
                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">Set Mode of Payment for - {transactionFor}</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body d-flex" style={{ justifyContent: "space-evenly" }}>
                              <div>
                                <Input type="radio" name="modeOfPayment" value="Cash" onChange={e => setModeOfPayment(e.target.value)} />&nbsp;&nbsp;Cash
                              </div>
                              <div>
                                <Input type="radio" name="modeOfPayment" value="Online" onChange={e => setModeOfPayment(e.target.value)} />&nbsp;&nbsp;Online
                              </div>
                              <div>
                                <Input type="radio" name="modeOfPayment" value="Cheque" onChange={e => setModeOfPayment(e.target.value)} />&nbsp;&nbsp;Cheque
                              </div>
                            </div>
                            <div>
                              <Input type="textarea" name="paymentDescription" placeholder="Enter your informations" onChange={e => setPaymentDescription(e.target.value)} />
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                              <button type="button" class="btn btn-primary" aria-label="Close" onClick={() => { submitPay(transactionDbId) }}>Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TableBody>

                  </Table >

                </TableContainer>
              </Row>
            </Typography>
          )}

        </Col>
      </Row>
    </div >
  )
}

export default connect(null, { setBreadcrumbItems })(OccupiedLockerDetails)


