import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import DataTable from "react-data-table-component"
import "assets/css/datatables.scss"
// import moment from "moment"
import { Card, CardBody, CardTitle, Col, Input, Label, Row } from "reactstrap"
import { getLockerReportAPI } from "service/service"
import { ExportToExcel } from "./ExportToExcel"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import moment from "moment"

// import { DateRangePicker } from "react-date-range"

const LockersReport = props => {
  document.title = "Locker Access Report | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Locker Access Report", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Locker Access Report", breadcrumbItems);
    //eslint-disable-next-line
  }, [""])

  const [lockerData, setLockerData] = useState([])
  const [lockerExcelData, setLockerExcelData] = useState([])


  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handlechangepage = (event, newpage) => {
    // console.log("newpage", newpage);
    setPageNo(newpage + 1);
    // setCurrentPage(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    setPerPage(+event.target.value)
    setPageNo(1);
  }

  const columns = [
    {
      name: "Customer Name",
      selector: row => row.actioneeName,
    },
    {
      name: "Mobile No",
      selector: row => row.actioneeMobile,
    },
    {
      name: "Locker No",
      selector: row => row.lockerNo,
    },
    // {
    //   name: "Locker Key",
    //   selector: row => row.lockerKey,
    // },
    {
      name: "Primary Holder",
      selector: row => row.primaryHolder,
    },
    // {
    //   name: "Primary Holder Mobile",
    //   selector: row => row.primaryHolderMobile,
    // },
    // {
    //   name: "Locker Category",
    //   selector: row => row.lockerCategory,
    // },
    {
      name: "Action Taken",
      selector: row => row.actionTaken,
    },
    {
      name: "Action Date",
      selector: row => row.date,
    },
    {
      name: "Action Time",
      selector: row => moment(row.actionTime).format(
        "hh : mm : ss A"),
    },
  ]

  const customStyles = {
    // rows: {
    //   style: {
    //     minHeight: "72px", // override the row height
    //   },
    // },
    headCells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for head cells
        // paddingRight: "8px",
        fontSize: "15px",
      },
    },
    // cells: {
    //   style: {
    //     paddingLeft: "8px", // override the cell padding for data cells
    //     paddingRight: "8px",
    //   },
    // },
  }

  const handleDisplayReport = async () => {
    const temp = []
    var startDate = document.getElementById("start_date").value
    var endDate = document.getElementById("end_date").value
    // console.log(selectedValue);
    if (startDate.length > 0 && endDate.length > 0) {
      const response = await getLockerReportAPI(startDate, endDate)
      if (response.status === 1 || response.status === "1") {
        // setLockerData(response.data)
        // console.log("response = ", response.data)
        const data = response?.data
        data &&
          data.length > 0 &&
          data.map(item => {
            // console.log("lockers", item.lockers);
            item.lockers &&
              item.lockers.length > 0 &&
              item.lockers.map(lockersCount => {
                // console.log("temp", temp)
                temp.push(lockersCount)
              })
          })
        setLockerData(temp)

        const postData = temp
        const customHeadings = postData.map((item, index) => ({
          "Sr. NO.": index + 1,
          "Customer Name": item.actioneeName,
          "Customer Mobile": item.actioneeMobile,
          "Locker No": item.lockerNo,
          "Locker Key": item.lockerKey,
          "Locker Category": item.lockerCategory,
          "Primary Holder": item.primaryHolder,
          "Primary Holder Mobile No": item.primaryHolderMobile,
          "Action Taken": item.actionTaken,
          "Action Date": item.date,
          "Action Time": item.actionTime,
        }))

        setLockerExcelData(customHeadings)
        // displayLocker()
      } else {
        console.log("Not call")
      }
    }
  }

  // console.log("Lockerdata", lockerData);

  return (
    <>
      {/* Allocated Lockers to Customers */}
      <Card className="mt-3">
        <CardBody>
          <CardTitle
            className="fs-4 fw-bolder d-flex "
            style={{
              borderBottom: "1px solid #e3d0d0",
              justifyContent: "space-between",
            }}
          >
            Locker Access Report
            <ExportToExcel apiData={lockerExcelData} fileName={"LockerAccessList"} />
          </CardTitle>
          <Row>
            <Col lg={4} md={4}>
              <div className="mb-3">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Start Date*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    id="start_date"
                    type="date"
                    name="endDate"
                    required
                    placeholder="Select Start Date"
                    onChange={handleDisplayReport}
                  />
                </div>
              </div>
            </Col>

            <Col lg={4} md={4}>
              <div className="mb-3">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  End Date*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    id="end_date"
                    type="date"
                    name="endDate"
                    required
                    placeholder="Select End Date"
                    onChange={handleDisplayReport}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                className="mt-3"
                columns={columns}
                data={lockerData}
                fixedHeader
                pagination
                customStyles={customStyles}
              ></DataTable>
            </Col>
          </Row>

          {/* <Card>
            <CardBody>
              <TableContainer>
                <Table>
                  <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                    <TableRow>
                      <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Plan Name</TableCell>
                      <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Description</TableCell>
                      <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Rent Price</TableCell>
                      <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Minimum Charge</TableCell>
                      <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Duration In Days</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {console.log(lockerData)}
                    {lockerData && lockerData.map((row) => (
                      <>
                        {console.log(row)}

                        <TableRow key={row._id} className="tablerow" >
                          <TableCell>{row.actioneeName}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.rentPrice}</TableCell>
                          <TableCell>{row.minimumCharge}</TableCell>
                          <TableCell>{row.minimumDurationDay}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 25]}
                  rowsPerPage={perPage}
                  page={pageNo - 1}
                  count={lockerData.length}
                  component="div"
                  onPageChange={handlechangepage}
                  onRowsPerPageChange={handleRowsPerPage}
                >
                </TablePagination>


              </TableContainer>
            </CardBody>
          </Card> */}

        </CardBody >
      </Card >
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(LockersReport)
