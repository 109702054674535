export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

// export const USER_PROFILE = "USER_PROFILE"
// export const UPLOAD_USER_IMG = "UPLOAD_USER_IMG"
// export const REMOVE_USER_IMG = "REMOVE_USER_IMG"

// export const CHANGE_PASSWORD = "CHANGE_PASSWORD"

// export const COMPANY_PROFILE = "COMPANY_PROFILE"
// export const UPLOAD_COMPANY_IMG = "UPLOAD_COMPANY_IMG"
// export const REMOVE_COMPANY_IMG = "REMOVE_COMPANY_IMG"
// export const UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS"

// export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

// // ---------ROLE---------
// export const CREATE_ROLE = "CREATE_ROLE"
export const DISPLAY_ROLE = "DISPLAY_ROLE"
// export const DELETE_ROLE = "DELETE_ROLE"
// export const UPDATE_ROLE = "CREATE_ROLE"

// // ---------ROLE TO RIGHT---------
export const DISPLAY_ROLE_TO_RIGHTS = "DISPLAY_ROLE_TO_RIGHTS"
export const UPDATE_ROLE_TO_RIGHTS = "UPDATE_ROLE_TO_RIGHTS"

// // ---------RIGHTS---------
// export const CREATE_RIGHTS = "CREATE_RIGHTS"
export const DISPLAY_RIGHTS = "DISPLAY_RIGHTS"
// export const DELETE_RIGHTS = "DELETE_RIGHTS"
// export const UPDATE_RIGHTS = "CREATE_RIGHTS"

// // ---------STAFF---------
// export const STAFF_LIST = "STAFF_LIST"
// export const DELETE_STAFF = "DELETE_STAFF"
// export const UPDATE_STAFF = "UPDATE_STAFF"
// export const CREATE_STAFF = "CREATE_STAFF"
// export const GET_STAFF_BY_ID = "GET_STAFF_BY_ID"
// export const UPLOAD_STAFF_IMG = "UPLOAD_STAFF_IMG"
// export const REMOVE_STAFF_IMG = "REMOVE_STAFF_IMG"

// // ---------LOCKER---------
// export const CREATE_LOCKER = "CREATE_LOCKER"
// export const GET_LOCKER_BY_ID = "GET_LOCKER_BY_ID"
// export const LOCKER_LIST = "LOCKER_LIST"
// export const ACTIVE_LOCKER = "ACTIVE_LOCKER"
// export const DEACTIVE_LOCKER = "DEACTIVE_LOCKER"
// export const UPDATE_LOCKER = "CREATE_LOCKER"
// export const DISPLAY_LOCKER = "DISPLAY_LOCKER"
// export const ASSIGN_LOCKER_TO_CUSTOMER = "ASSIGN_LOCKER_TO_CUSTOMER"

// // ---------CUSTOMER--------
// export const CUSTOMER_NOMINEE_IMAGE = "CUSTOMER_NOMINEE_IMAGE";
// export const CUSTOMER_LIST = "CUSTOMER_LIST";
// export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS";
// export const ADD_CUSTOMER_NOMINEE = "ADD_CUSTOMER_NOMINEE";
export const ADD_CUSTOMER_KYC = "ADD_CUSTOMER_KYC";
// export const ACTIVE_CUSTOMER = "ACTIVE_CUSTOMER";
// export const DEACTIVE_CUSTOMER = "DEACTIVE_CUSTOMER";
// export const UPDATE_CUSTOMER = "CREATE_CUSTOMER";
// export const CUSTOMER_EXPIRINIG_LOCKER_LIST = "CUSTOMER_EXPIRINIG_LOCKER_LIST";
export const REMOVE_CUSTOMER_IMG = "REMOVE_CUSTOMER_IMG";
// export const UPLOAD_CUSTOMER_IMG = "UPLOAD_CUSTOMER_IMG";
// export const CHANGE_LOCKER_PLAN = "CHANGE_LOCKER_PLAN";
// export const RENEW_LOCKER_PLAN = "RENEW_LOCKER_PLAN";
// export const OCCUPIED_LOCKER_LIST = "OCCUPIED_LOCKER_LIST";
// export const ACTIVE_CUSTOMER_LOCKER = "ACTIVE_CUSTOMER_LOCKER"

// // ---------RENT---------
// export const RENT_LIST = "RENT_LIST"
// export const ACTIVE_RENT = "ACTIVE_RENT"
// export const DEACTIVE_RENT = "DEACTIVE_RENT"
// export const UPDATE_RENT = "CREATE_RENT"
// export const CREATE_RENT = "CREATE_RENT"

// export const TRANSACTION_LIST = "TRANSACTION_LIST"

// export const OCCUPIED_LOCKER = "OCCUPIED_LOCKER"

// export const LOCKERS_COUNT = "LOCKERS_COUNT"
// export const CUSTOMERS_COUNT = "CUSTOMERS_COUNT"
// export const TRANSCTIONS_COUNT = "TRANSCTIONS_COUNT"
// export const CREATE_TRANSCTIONS = "CREATE_TRANSCTIONS"