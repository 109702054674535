import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getRentByIdAPI } from "service/service";

const RentDetails = (props) => {
    document.title = "Rent Details | Shreesh Securities";
    const breadcrumbItems = [
        { title: "Locker", link: "/dashboard" },
        { title: "Rent List", link: "/rentlist" },
        { title: "Rent Details", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Rent Details', breadcrumbItems);
    })

    const { id } = useParams();
    const [rentDetails, setRentDetails] = useState([]);
    const [rentLockerDetails, setRentLockerDetails] = useState([]);
    const handleDisplayRent = async (id) => {
        try {
            const res = await getRentByIdAPI(id);
            setRentDetails(res.data);
            setRentLockerDetails(res.data.locker);
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id) {
            handleDisplayRent(id);
        }
        //eslint-disable-next-line
    }, [])


    return (
        <div>
            <Row>
                <Col>
                    <div className="d-flex justify-content-end">
                        <div className="goback">
                            <Link to='/rentlist' className="">Go Back</Link>
                        </div>
                    </div>
                    <Card>
                        <CardBody>
                            <CardTitle className="fs-4"><b>View Rent Details</b></CardTitle>
                            <hr />
                            <Row className="fw-bold view-table" style={{}}>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Locker Category</h6>
                                    <p>{rentLockerDetails.name}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold ">Plan Name</h6>
                                    <p>{rentDetails.name}</p>
                                </Col>
                                {rentDetails.description &&
                                    <Col xl={3} lg={4} md={6} sm={12}>
                                        <h6 className="fw-bold">Rent Description</h6>
                                        <p>{rentDetails.description}</p>
                                    </Col>
                                }
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Rent Price</h6>
                                    <p>{rentDetails.rentPrice}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Minimum Charge</h6>
                                    <p>{rentDetails.minimumCharge}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Duration In Days</h6>
                                    <p>{rentDetails.minimumDurationDay}</p>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={12}>
                                    <h6 className="fw-bold">Status</h6>

                                    {rentDetails.status ?

                                        <div className="active-btn">Active</div>
                                        :
                                        <div className="deactive-btn">Inactive</div>
                                    }

                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default connect(null, { setBreadcrumbItems })(RentDetails);
