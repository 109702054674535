import React, { useCallback, useEffect, useRef, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { addCustomerKycAPI, deleteCustomerKycAPI, getCustomerByIdAPI, getCustomerKycAPI, removeCustomerImg, updateCustomerAPI, updateCustomerAddressAPI, updateCustomerNomineeAPI, uploadCustomerImg, uploadNomineeImages } from "service/service"
import ImageViewer from "ImageViewer"
import { Button, Card, CardBody, CardTitle, Col, Input, Row, Table } from "reactstrap";
import dummy from "../../assets/images/users/dummy.jpg"
import user1 from "../../assets/images/users/user-1.jpg";
import Swal from "sweetalert2"
import Webcam from "react-webcam"

const UpdateCustomer = (props) => {

    const { id } = useParams();

    document.title = "Update Customer | Shreesh Securities"

    const breadcrumbItems = [
        { title: "Locker", link: "/dashboard" },
        { title: "Locker List", link: "/customerlist" },
        { title: "Update Customer", link: "#" },
    ]


    const re = /^[A-Za-z\s]+$/;
    const [customerDetails, setCustomerDetails] = useState([])
    const [customerAddress, setCustomerAddress] = useState([])
    const [customerKyc, setCustomerKyc] = useState([])
    const [customerNominee, setCustomerNominee] = useState([])

    const [filePath, setFilePath] = useState("");
    const [addressId, setAddressId] = useState("");
    const [nomineeID, setnomineeID] = useState("");

    const [addressType, setAddressType] = useState(customerAddress.addressType ? customerAddress.addressType : "1")
    const [fullAddress, setFullAddress] = useState(customerAddress.fullAddress ? customerAddress.fullAddress : "")
    const [photo, setPhoto] = useState(customerDetails.photo ? customerDetails.photo : "")
    const [fullName, setFullName] = useState(customerDetails.fullName ? customerDetails.fullName : "")
    const [occupation, setOccupation] = useState(customerDetails.occupation ? customerDetails.occupation : "")
    const [email, setEmail] = useState(customerDetails.email ? customerDetails.email : "")
    const [mobile, setMobile] = useState(customerDetails.mobile ? customerDetails.mobile : "")
    const [telephone, setTelephone] = useState(customerDetails.telephone ? customerDetails.telephone : "")

    const [fullNameNominee, setFullNameNominee] = useState(customerNominee.fullName ? customerNominee.fullName : "")
    const [occupationNominee, setOccupationNominee] = useState(customerNominee.occupation ? customerNominee.occupation : "")
    const [mobileNominee, setMobileNominee] = useState(customerNominee.mobile ? customerNominee.mobile : "")
    const [relationWithCustomer, setRelationWithCustomer] = useState(customerNominee.relationWithCustomer ? customerNominee.relationWithCustomer : "")
    const [addressTypeNominee, setAddressTypeNominee] = useState(customerNominee.addressType ? customerNominee.addressType : "1")
    const [fullAddressNominee, setFullAddressNominee] = useState(customerNominee.fullAddress ? customerNominee.fullAddress : "")

    const [documentType, setdocumentType] = useState("");
    const [docDetail, setdocDetail] = useState("");
    const [isKyc, setIsKyc] = useState(false)

    const [filepathNominee, setFilepathNominee] = useState("");
    const [nomineePhoto, setNomineePhoto] = useState(customerNominee.photo ? customerNominee.photo : "");
    const [file, setFile] = useState("");
    const [nomineeKyc, setNomineeKyc] = useState(customerNominee.kycDoc ? customerNominee.kycDoc : "");

    const [image, setImage] = useState("")
    const [imageView, setImageView] = useState("")

    const [isDeleted, setIsDeleted] = useState(0)

    const [status, setStatus] = useState(1) // 0: no show, 1: show yes, 2: show no.

    const radioHandler = (status) => {
        setStatus(status);
    };

    const handleImage = (e) => {
        e.preventDefault()
        setImage(e.target.files[0])
        setImageView(URL.createObjectURL(e.target.files[0]))
        // console.log(image);
    }

    const CustomerDetails = async () => {
        try {
            const res = await getCustomerByIdAPI(id)

            if (res.status === "1" || res.status === 1) {
                setCustomerDetails(res.data);
                setCustomerAddress(res.data.addresses);
                setCustomerKyc(res.data.kyc);
                setCustomerNominee(res.data.nominees);

                const data = res.data
                console.log(data);
                setFullName(data?.fullName)
                setOccupation(data?.occupation)
                setEmail(data?.email)
                setMobile(data?.mobile)
                setTelephone(data?.telephone)
                setPhoto(data?.photo);

                const cusAddress = res.data.addresses
                setAddressId(cusAddress[0]?.id)
                setAddressType(cusAddress[0]?.addressType)
                setFullAddress(cusAddress[0]?.fullAddress)
                // console.log(cusAddress[0].addressType);

                const cusKyc = res.data.kyc

                const cusNominee = res.data.nominees[0]
                console.log(cusNominee);

                setnomineeID(cusNominee?.id)
                setFullNameNominee(cusNominee?.fullName)
                setOccupationNominee(cusNominee?.occupation)
                setMobileNominee(cusNominee?.mobile)
                setRelationWithCustomer(cusNominee?.relationWithCustomer)
                setAddressTypeNominee(cusNominee?.addressType)
                setFullAddressNominee(cusNominee?.fullAddress)
                setNomineePhoto(cusNominee?.photo)
                setNomineeKyc(cusNominee?.kycDoc)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateCustomerDetails = async (e) => {
        e.preventDefault()
        try {
            const res = await updateCustomerAPI(
                id,
                fullName,
                occupation,
                email,
                mobile,
                telephone
            )
            // console.log(res.data);
            if (res.status === 1 || res.status === "1") {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",
                })
            }
        } catch (error) {

        }
    }

    const updateCustomerAddress = async (e) => {
        e.preventDefault()
        try {
            const res = await updateCustomerAddressAPI(
                addressId,
                addressType,
                fullName,
                mobile,
                fullAddress
            )
            // console.log(res.data);
            if (res.status === 1 || res.status === "1") {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",
                })
            }
        } catch (error) {

        }
    }

    const uploadCustomerImage = async e => {
        e.preventDefault()
        setFilePath(URL.createObjectURL(e.target.files[0]))
        const file = e.target.files[0]

        const response = await uploadCustomerImg(id, file)
        if (response.status === 1 || response.status === "1") {
            setPhoto(response.data.photo)
            // localStorage.setItem("Customer_Image", response.data.photo)
            Swal.fire({
                title: response.message,
                icon: "success",
                timer: 1500
            })
        } else {
            Swal.fire({
                title: response.message,
                icon: "error",
            })
        }
    }

    const removeImage = async e => {

        // const customerId = customerId
        const imagePath = photo
        const response = await removeCustomerImg(id, imagePath)

        if (response.status === "1") {
            // localStorage.removeItem("Customer_Image")
            Swal.fire({
                title: response.message,
                icon: "success",
                timer: 1500
            })
            setPhoto("");
            setFilePath("")
        } else {
            Swal.fire({
                title: response.message,
                icon: "error",
            })
        }
    }

    const uploadNomineeImage = async e => {
        e.preventDefault()
        setFilepathNominee(URL.createObjectURL(e.target.files[0]))
        const file_name = e.target.files[0]

        const res = await uploadNomineeImages(file_name)
        setNomineePhoto(res.data)
        localStorage.setItem("nominee_Photo", res.data)

        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }
    }

    const uploadNomineeKyc = async e => {
        e.preventDefault()
        setFile(URL.createObjectURL(e.target.files[0]))
        const file_name = e.target.files[0]

        const res = await uploadNomineeImages(file_name)
        setNomineeKyc(res.data)
        localStorage.setItem("nominee_kyc", res.data)

        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }
    }

    const NomineeUpdate = async (e) => {
        try {
            const res = await updateCustomerNomineeAPI(
                nomineeID,
                fullNameNominee,
                occupationNominee,
                relationWithCustomer,
                mobileNominee,
                nomineePhoto,
                nomineeKyc,
                fullAddressNominee,
                addressTypeNominee,
            )

            if (res.status === "1" || res.status === 1) {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    timer: 1500
                })
            } else {
                Swal.fire({
                    title: res.message,
                    icon: "error",
                })
            }
        } catch (error) {

        }
    }

    const removeNomineeImage = async e => {
        setFilepathNominee("")
        setNomineePhoto("")
        localStorage.removeItem("nominee_Photo")
    }

    const removeNomineeKyc = async e => {
        setFile("")
        setNomineeKyc("")
        localStorage.removeItem("nominee_kyc")
    }

    const handleGetKyc = async id => {
        const res = await getCustomerKycAPI(id)
        setCustomerKyc(res?.data)
    }

    const handleDeleteOldKyc = async i => {
        const res = await deleteCustomerKycAPI(customerKyc[i].id)
        localStorage.removeItem("customerAddress")
        res.status === "1" && handleGetKyc(id)
        if (res.status === "1" || res.status === 1) {
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }
        setIsDeleted(2)
    }

    const handleSubmitKyc = async (e, i) => {
        e.preventDefault()
        const res = await addCustomerKycAPI(
            id,
            image,
            documentType,
            docDetail
        )
        // dispatch(addCustomerKyc(id, image, documentType, docDetail))
        handleGetKyc(id)
        handleClear()
        if (res.status === "1" || res.status === 1) {
            setIsKyc(true)
            Swal.fire({
                title: res.message,
                icon: "success",
                timer: 1500
            })
            // localStorage.setItem("customerS2", true)
        } else {
            Swal.fire({
                title: res.message,
                icon: "error",
            })
        }
    }

    const handleClear = () => {
        document.getElementById('kycImage').value = null
        setdocumentType("")
        setdocDetail("")
        setImage(null)
        setImageView("")
    }

    const [camImage, setCamImage] = useState("")
    const webcamRef = useRef(null)

    // const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()
        // const imageSrc = window.URL.createObjectURL(new Blob(webcamRef.current.getScreenshot()))

        setCamImage(imageSrc)
        // var blob = dataURItoBlob(imageSrc)
        // setFacingMode(FACING_MODE_USER);
        // console.log("blob", blob);
    }, [webcamRef, setCamImage])

    function dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }


    const uploadCustCameraImage = async e => {
        e.preventDefault()
        const file = dataURItoBlob(camImage)
        const cid = id

        console.log("cam - ", camImage);
        // console.log("cam - ", customerId);
        const response = await uploadCustomerImg(cid, file)
        if (response.status === 1 || response.status === "1") {
            setPhoto(response.data.photo)
            setCamImage("")
            setStatus(1)
            localStorage.setItem("Customer_Image", response.data.photo)
            Swal.fire({
                title: response.message,
                icon: "success",
                timer: 1500
            })
        } else {
            Swal.fire({
                title: response.message,
                icon: "error",
            })
        }
    }

    useEffect(() => {
        props.setBreadcrumbItems("Update Customer", breadcrumbItems)
    })

    useEffect(() => {
        CustomerDetails();
    }, [isDeleted])
    // console.log(customerKyc);

    return (
        <div>
            <div className="d-flex justify-content-end">
                <div className="goback">
                    <Link to="/customerlist" className="">
                        Go Back
                    </Link>
                </div>
            </div>
            {/* --------------Personal Details--------------- */}
            <Card>
                <CardBody>
                    <Row>
                        <Col md={9}>
                            <CardTitle
                                className="fs-4 fw-bolder"
                                style={{ borderBottom: "1px solid #e3d0d0" }}
                            >
                                Customer Personal Details
                            </CardTitle>
                            <Row>
                                <Col md="4" className="mt-3">
                                    <h6 className="fw-bold ">Name</h6>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            name="fullName"
                                            id="fullName"
                                            placeholder="Enter Customer Name"
                                            value={fullName}
                                            onChange={e => {
                                                const { value } = e.target;
                                                if (value === "" || re.test(value)) {
                                                    setFullName(value);
                                                }
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="4" className="mt-3">
                                    <h6 className="fw-bold ">Occupation</h6>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            name="occupation"
                                            id="occupation"
                                            placeholder="Enter Occupation"
                                            value={occupation}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (value === "" || re.test(value)) {
                                                    setOccupation(value);
                                                }
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="4" className="mt-3">
                                    <h6 className="fw-bold ">Email</h6>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Enter Email"
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            value={email}
                                        />
                                    </div>
                                </Col>
                                <Col md="4" className="mt-3">
                                    <h6 className="fw-bold ">Mobile No</h6>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            name="mobile"
                                            id="mobile"
                                            placeholder="Enter Mobile-No"
                                            onChange={(e) => {
                                                const limit = 10
                                                setMobile(e.target.value.slice(0, limit))
                                            }}
                                            value={mobile}
                                        />
                                    </div>
                                </Col>
                                <Col md="4" className="mt-3">
                                    <h6 className="fw-bold ">Telephone</h6>
                                    <div>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            name="telephone"
                                            id="telephone"
                                            placeholder="Enter Telephone-No"
                                            onChange={(e) => { setTelephone(e.target.value) }}
                                            value={telephone}
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <div className="mt-3 d-flex justify-content-start">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-md "
                                    onClick={updateCustomerDetails}
                                >
                                    Update
                                </button>
                            </div>

                        </Col>

                        <Col lg={3} className="position-relative">
                            <CardTitle
                                className="fs-4 fw-bolder"
                                style={{ borderBottom: "1px solid #e3d0d0" }}
                            >
                                Photo
                            </CardTitle>
                            {/* {!photo ? (
                                <div>
                                    <Input
                                        type="file"
                                        name="doc"
                                        id="doc"
                                        required
                                        onChange={uploadCustomerImage}
                                    />
                                    <img
                                        src={filePath ? filePath : user1}
                                        alt="Custome Profile"
                                        style={{ width: "8rem", height: "7rem" }}
                                    />
                                </div>
                            ) : (
                                <div className="mt-1">
                                    <ImageViewer
                                        path={photo}
                                        style={{ width: "8rem", height: "7rem" }}
                                    />
                                    <i
                                        className="fas fa-trash-alt"
                                        style={{
                                            position: "relative",
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                        }}
                                        onClick={removeImage}
                                    ></i>
                                </div>
                            )} */}
                            <div className="py-1">
                                <div className="d-flex">
                                    <div>
                                        <input type="radio" name="release" checked={status === 1} onClick={(e) => radioHandler(1)} style={{ margin: "8px 6px" }} /><span>Photo</span>
                                    </div>
                                    <div style={{ marginLeft: "5rem" }}>
                                        <input type="radio" name="release" checked={status === 2} onClick={(e) => radioHandler(2)} style={{ margin: "8px 6px" }} /><span>Camera</span>
                                    </div>
                                </div>
                                {status === 1 && <div className="py-1">
                                    <div className="py-1">
                                        {!photo ? (
                                            <div>
                                                <Input
                                                    type="file"
                                                    name="doc"
                                                    id="doc"
                                                    required
                                                    onChange={uploadCustomerImage}
                                                />
                                                <img
                                                    src={filePath ? filePath : user1}
                                                    alt="Customer Profile"
                                                    style={{ width: "8rem", height: "7rem" }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="mt-1">
                                                <ImageViewer
                                                    path={photo}
                                                    style={{ width: "8rem", height: "7rem" }}
                                                />
                                                <i
                                                    className="fas fa-trash-alt"
                                                    style={{
                                                        position: "relative",
                                                        cursor: "pointer",
                                                        marginLeft: "5px",
                                                    }}
                                                    onClick={removeImage}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </div>}
                                {status === 2 && <div>
                                    <div className="webcam-container py-1" style={{ textAlign: "center" }}>
                                        <div className="webcam-img">
                                            {camImage == "" ? (
                                                <Webcam
                                                    audio={false}
                                                    // height={200}
                                                    ref={webcamRef}
                                                    screenshotFormat="image/jpeg"
                                                    // width={220}
                                                    width={120}
                                                    height={100}
                                                // videoConstraints={videoConstraints}
                                                />
                                            ) : (
                                                <img src={camImage} alt="" />
                                            )}
                                        </div>
                                        <div style={{ margin: "1rem 0px" }}>
                                            {camImage != "" ? (
                                                <button
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        setCamImage("")
                                                    }}
                                                    className="webcam-btn"
                                                >
                                                    Retake Image
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        capture()
                                                    }}
                                                    className="webcam-btn"
                                                >
                                                    Capture
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="d-flex justify-content-center">
                                {status === 2 &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-md"
                                        onClick={uploadCustCameraImage}
                                    >
                                        Upload Image
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {/* ----------Address--------- */}
            {customerAddress &&
                customerAddress.map(data => (<>
                    <Card>
                        <CardBody>
                            <CardTitle
                                className="fs-4 fw-bolder"
                                style={{ borderBottom: "1px solid #e3d0d0" }}
                            >
                                Customer Address
                            </CardTitle>

                            <Row>
                                <Col md="3">
                                    <h6 className="fw-bold ">Address Type</h6>
                                    <div
                                        className="active"
                                        value={addressType}
                                        id="addressType"
                                    >
                                        <Input
                                            type="radio"
                                            name="addresstype"
                                            value="1"
                                            defaultChecked={addressType === 1 || addressType === "1"}
                                            onChange={e => {
                                                setAddressType(e.target.value)
                                            }}
                                        />
                                        &nbsp;Home&nbsp;&nbsp;
                                        <Input
                                            type="radio"
                                            name="addresstype"
                                            value="2"
                                            defaultChecked={addressType === 2 || addressType === "2"}
                                            onChange={e => {
                                                setAddressType(e.target.value)
                                            }}
                                        />
                                        &nbsp;Office
                                    </div>
                                </Col>
                                <Col md="9">
                                    <h6 className="fw-bold ">Address</h6>
                                    <div>
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            rows={4}
                                            placeholder="Enter Address"
                                            name="fullAddress"
                                            id="fullAddress"
                                            value={fullAddress}
                                            required
                                            onChange={e => {
                                                setFullAddress(e.target.value)
                                            }}
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <div className="mt-3 d-flex justify-content-start">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-md "
                                    onClick={updateCustomerAddress}
                                >
                                    Update
                                </button>
                            </div>


                        </CardBody>
                    </Card >
                </>
                ))}

            {/* --------------Kyc--------------- */}




            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle
                                className="fs-4 fw-bolder"
                                style={{ borderBottom: "1px solid #e3d0d0" }}
                            >
                                Customer KYC
                            </CardTitle>

                            <div>
                                <h5 className="fw-bold py-2 text-decoration-underline" style={{ color: "#335733" }}> Add KYC </h5>

                                <Row>
                                    <Col lg={4} md={6}>
                                        <div>
                                            <h6 className="fw-bold ">Select Document Type</h6>

                                            <div>
                                                <select
                                                    className="form-control"
                                                    id="DocName"
                                                    value={documentType}
                                                    onChange={e => {
                                                        const value = e.target.value
                                                        setdocumentType(value)
                                                    }}
                                                >
                                                    <option>-- SELECT --</option>
                                                    <option value="Aadhar Card">Aadhar Card</option>
                                                    <option value="Pan card">Pan card</option>
                                                    <option value="Voter Id Card">Voter Id Card</option>
                                                    <option value="Driving License">
                                                        Driving License
                                                    </option>
                                                    <option value="Passport">Passport</option>
                                                    <option value="Physical Form">Physical Form</option>
                                                </select>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div>
                                            <h6 className="fw-bold ">Document Detail</h6>

                                            <div>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    id="DocDetail"
                                                    placeholder="XXXXXXXXXXXX"
                                                    name="docDetail"
                                                    value={docDetail}
                                                    onChange={e => {
                                                        setdocDetail(e.target.value)
                                                    }
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg={4} md={12} className="py-1">
                                        <h6 className="fw-bold ">Image</h6>

                                        <div>
                                            {/* {image ? (<>
                                                            <ImageViewer
                                                            path={image}
                                                            style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}
                                                            >
                                                            {" "}
                                                            </ImageViewer>
                                                            <i
                                                            className="fas fa-trash-alt"
                                                            style={{
                                                                position: "absolute",
                                                                cursor: "pointer",
                                                                marginLeft: "5px",
                                                            }}
                                                            onClick={() => removeKycImage()}
                                                            ></i>
                                                        </>) : ( */}

                                            <div>
                                                <Input
                                                    type="file"
                                                    name="doc"
                                                    required
                                                    id="kycImage"
                                                    onChange={handleImage}
                                                    multiple
                                                />
                                                <img
                                                    src={imageView ? imageView : dummy}
                                                    alt=""
                                                    style={{ width: "8rem", height: "5rem", paddingTop: "3px" }}
                                                />
                                            </div>
                                            {/* )} */}

                                        </div>
                                    </Col>

                                    <div className="d-flex gap-2">
                                        <button
                                            type="submit"
                                            className="btn btn-primary w-md"
                                            onClick={handleSubmitKyc}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary w-md"
                                            onClick={handleClear}
                                        >
                                            Clear
                                        </button>
                                    </div>

                                </Row>
                            </div>
                            <hr />
                            {customerKyc.length > 0 && (<>
                                <table>
                                    <tr className="text-center">
                                        <td className="fw-bold ">No.</td>
                                        <td className="fw-bold ">Document Type</td>
                                        <td className="fw-bold ">Doc Detail</td>
                                        <td className="fw-bold ">Image</td>
                                        <td className="fw-bold ">Action</td>
                                    </tr>
                                    {customerKyc &&
                                        customerKyc.map((data, index) => (

                                            <tr className="text-center">
                                                <td>{index + 1}</td>
                                                <td>{data.documentType}</td>
                                                <td>{data.docDetail}</td>
                                                <td style={{ paddingTop: "10px" }}>
                                                    {data.doc ? (
                                                        <ImageViewer
                                                            path={data.doc}
                                                            style={{ width: "7rem", height: "5rem", cursor: 'pointer' }}
                                                        >
                                                            {" "}
                                                        </ImageViewer>
                                                    ) : (
                                                        <ImageViewer
                                                            path={dummy}
                                                            style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                                                        >
                                                            {" "}
                                                        </ImageViewer>
                                                    )}
                                                </td>
                                                <td>

                                                    <Button
                                                        type="submit"
                                                        className="btn btn-primary w-sm "
                                                        onClick={() =>
                                                            handleDeleteOldKyc(index)
                                                        }
                                                    >
                                                        Delete
                                                    </Button>

                                                </td>
                                            </tr>

                                        ))}
                                </table>

                            </>)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* --------------Nominee--------------- */}
            {customerNominee.length > 0 && customerNominee && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle
                                    className="fs-4 fw-bolder"
                                    style={{ borderBottom: "1px solid #e3d0d0" }}
                                >
                                    Customer Nominee
                                </CardTitle>
                                {customerNominee &&
                                    customerNominee.map((data, index) => (<>
                                        <Row>

                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Full Name</h6>
                                                <div>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="fullNameNominee"
                                                        id="fullNameNominee"
                                                        placeholder="Enter Nominee Name"
                                                        required
                                                        value={fullNameNominee}
                                                        onChange={e => {
                                                            const { value } = e.target;
                                                            if (value === "" || re.test(value)) {
                                                                setFullNameNominee(value);
                                                            }
                                                            // setFullName(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Occupation</h6>
                                                <div>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="occupation"
                                                        id="occupation"
                                                        placeholder="Enter Occupation"
                                                        required
                                                        value={occupationNominee}
                                                        onChange={e => {
                                                            const { value } = e.target;
                                                            if (value === "" || re.test(value)) {
                                                                setOccupationNominee(value);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Relation With Customer</h6>
                                                <div>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="relationWithCustomer"
                                                        id="relationWithCustomer"
                                                        placeholder="Enter Relation With Customer"
                                                        required
                                                        value={relationWithCustomer}
                                                        onChange={e => {
                                                            const { value } = e.target;
                                                            if (value === "" || re.test(value)) {
                                                                setRelationWithCustomer(value);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Mobile</h6>
                                                <div>
                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        name="mobile"
                                                        id="mobile"
                                                        placeholder="Enter Mobile-No"
                                                        required
                                                        value={mobileNominee}
                                                        onChange={e => {
                                                            const limit = 10
                                                            setMobileNominee(e.target.value.slice(0, limit))
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Address Type</h6>
                                                <div
                                                    className="active"
                                                    value={addressTypeNominee}
                                                    id="addressTypeNominee"
                                                >
                                                    <Input
                                                        type="radio"
                                                        name="addressTypeNominee"
                                                        value="1"
                                                        defaultChecked={addressTypeNominee === 1 || addressTypeNominee === "1"}
                                                        onChange={e => {
                                                            setAddressTypeNominee(e.target.value)
                                                        }}
                                                    />
                                                    &nbsp;Home&nbsp;&nbsp;
                                                    <Input
                                                        type="radio"
                                                        name="addressTypeNominee"
                                                        value="2"
                                                        defaultChecked={addressTypeNominee === 2 || addressTypeNominee === "2"}
                                                        onChange={e => {
                                                            setAddressTypeNominee(e.target.value)
                                                        }}
                                                    />
                                                    &nbsp;Office
                                                </div>
                                            </Col>
                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Address</h6>
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        rows={4}
                                                        name="fullAddress"
                                                        id="fullAddress"
                                                        placeholder="Enter Address"
                                                        required
                                                        value={fullAddressNominee}
                                                        onChange={e => {
                                                            setFullAddressNominee(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Nominee Photo</h6>
                                                {!nomineePhoto ? (
                                                    <div>
                                                        <Input
                                                            type="file"
                                                            name="photo"
                                                            id="photo"
                                                            required
                                                            onChange={uploadNomineeImage}
                                                        />
                                                        <img
                                                            src={filepathNominee ? filepathNominee : user1}
                                                            alt="company-profile"
                                                            style={{
                                                                width: "8rem",
                                                                height: "7rem",
                                                                marginTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <ImageViewer
                                                            path={nomineePhoto}
                                                            style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                                                        >
                                                            {" "}
                                                        </ImageViewer>
                                                        <span style={{ marginLeft: "20px" }}>
                                                            <i
                                                                className="fas fa-trash-alt"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={removeNomineeImage}
                                                            ></i>
                                                        </span>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col lg="3" md="4" className="mt-3">
                                                <h6 className="fw-bold ">Nominee Document</h6>
                                                {!nomineeKyc ? (
                                                    <div>
                                                        <Input
                                                            type="file"
                                                            name="kycDoc"
                                                            id="kycDoc"
                                                            required
                                                            onChange={uploadNomineeKyc}
                                                        />
                                                        <img
                                                            src={file ? file : dummy}
                                                            alt=""
                                                            style={{
                                                                width: "8rem",
                                                                height: "6rem",
                                                                marginTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <ImageViewer
                                                            path={nomineeKyc}
                                                            style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                                                        >
                                                            {" "}
                                                        </ImageViewer>
                                                        <span style={{ marginLeft: "20px" }}>
                                                            <i
                                                                className="fas fa-trash-alt"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={removeNomineeKyc}
                                                            ></i>
                                                        </span>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <div className="mt-3 d-flex justify-content-start">
                                            <button
                                                type="submit"
                                                className="btn btn-primary w-md"
                                                onClick={NomineeUpdate}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </>
                                    ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}

        </div >
    )
}

export default connect(null, { setBreadcrumbItems })(UpdateCustomer)
