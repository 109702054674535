/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import { getCustomerByIdAPI, getCustomerList, registerCustomerFingerPrintData, searchCustomer } from "service/service"
import Select from "react-select"
import "jquery"
import ImageViewer from "ImageViewer"
import user1 from "../../assets/images/users/user-1.jpg"
import { bioMetricApiKey } from "api/apiClient"
import Swal from "sweetalert2"
import './biometric.css';
import $ from 'jquery'

const FetchFingerPrint = props => {
  document.title = "Authentication | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Register Biometric", link: "#" },
  ]
  useEffect(() => {
    props.setBreadcrumbItems("Authentication", breadcrumbItems)
  })

  const [isCaptured, setCaptured] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [customer, setCustomer] = useState("")
  const [address, setAddress] = useState("");
  const [selectedFinger, setFinger] = useState({ value: "LeftThumb", label: "LeftThumb" });
  // const [selectedFinger, setFinger] = useState({});
  const fingerOptions = useState([
    // Left Arm
    { value: "LeftThumb", label: "LeftThumb" },
    { value: "LeftIndexFinger", label: "LeftIndexFinger" },
    { value: "LeftMiddleFinger", label: "LeftMiddleFinger" },
    { value: "LeftRingFinger", label: "LeftRingFinger" },
    { value: "LeftPinky", label: "LeftPinky" },
    // Right Arm
    { value: "RightThumb", label: "RightThumb" },
    { value: "RightIndexFinger", label: "RightIndexFinger" },
    { value: "RightMiddleFinger", label: "RightMiddleFinger" },
    { value: "RightRingFinger", label: "RightRingFinger" },
    { value: "RightPinky", label: "RightPinky" },
  ])[0];

  localStorage.setItem("selectedFinger", selectedFinger.value);

  console.log("selectedFinger = ", selectedFinger);
  const [customerDetails, setCustomerDetails] = useState([])

  const handleSelect = e => {
    const value = e.value
    console.log('handleSelect called value', value);
    // console.log(value);
    setCustomerId(value);
    window.getServiceTagId();
    localStorage.setItem("bioCustomerId", value)
  }

  
  const handleStatus = e => {
    console.log("handleStatus = ", e);
    setFinger(e);
    localStorage.setItem("selectedFinger", e.value);
  }

  const handleDisplayOldCustomerList = async () => {
    try {
      const res = await searchCustomer()
      setCustomer(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleDisplayCustomerDetail = async customerId => {
    console.log('handleDisplayCustomerDetail called with customerId', customerId);
    try {
      const res = await getCustomerByIdAPI(customerId)
      if (res.status === '1') {
        setCustomerDetails(res?.data)
        setAddress(res?.data?.addresses)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const customerOptions = [
    customer &&
    customer.map(data => {
      return { value: data.id, label: data.fullName }
    }),
  ]

  useEffect(() => {
    handleDisplayOldCustomerList()
  }, [])

  useEffect(() => {
    handleDisplayCustomerDetail(customerId)
  }, [customerId])

  const handleCaptureEvent = async () => {
    setCaptured(false);
    setLoading(true);
    window.capture(bioMetricApiKey, true);
  }

  const handleBiometricError = (event) => {
    console.log('handleBiometricError event', event)
    Swal.fire({
      title: "Finger Print Scanner Isn't Ready, please try after sometime",
      icon: "error",
      timer: 1500,
    });
  };


  const handleCaptureResult = async (event) => {
    let fingerDataInfo = event?.detail ? event.detail : localStorage.getItem("Capture");
    if (!event?.detail) {
      fingerDataInfo = fingerDataInfo ? JSON.parse(fingerDataInfo) : {};
    }
    const fingerSelected = localStorage.getItem("selectedFinger");
    console.log('handleCaptureResult selectedFinger', selectedFinger.value);
    console.log('handleCaptureResult fingerSelected', fingerSelected);
    const pngImageContent = "data:image/png;base64," + fingerDataInfo?.image;
    console.log("selectedFinger.value == ", selectedFinger.value);
    const data = {
      "customerId": customerId ? customerId : localStorage.getItem("bioCustomerId"),
      "template": fingerDataInfo?.template,
      "templateFor": fingerSelected,
      "imageDataUri": pngImageContent,
      "serviceTagId": localStorage.getItem("serviceTagId"),
      "qualityScore": fingerDataInfo?.qualityScore
    };
    setCaptured(true);
    setLoading(false);
    const capturedEle = document.querySelector("#box1");
    capturedEle.style.backgroundImage = `url(${pngImageContent})`;

    console.log("fingerPrint captured data", data);

    const res = await registerCustomerFingerPrintData(data);
    if (res && res.status === '1') {
      localStorage.removeItem("Capture");
      localStorage.removeItem("selectedFinger");
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
        timer: 1500,
      });
    }
  }

  const handleServiceTageResult = async (event) => {
    console.log('get GetServiceTagId result', event);
    let serviceTag = event?.detail ? event.detail : localStorage.getItem("GetServiceTagId");
    if (!event?.detail) {
      serviceTag = serviceTag ? JSON.parse(serviceTag) : {};
    }
    const serviceTagId = serviceTag?.serviceTagId || null;
    localStorage.setItem("serviceTagId", serviceTagId);
  }

  useEffect(() => {
    window.addEventListener('Capture', handleCaptureResult)
    window.addEventListener('GetServiceTagId', handleServiceTageResult)
    return () => {
      window.removeEventListener('Capture', handleCaptureResult);
      window.removeEventListener('GetServiceTagId', handleServiceTageResult);
    }
  }, [])

  useEffect(() => {
    window.addEventListener('Capture-Failed', handleBiometricError)
    window.addEventListener('GetServiceTagId-Failed', handleBiometricError)
    return () => {
      window.removeEventListener('GetServiceTagId-Failed', handleBiometricError);
      window.removeEventListener('Capture-Failed', handleBiometricError);
    }
  }, [])


  return (
    <div className="mt-3">
      <Card style={{ minHeight: "20vmax" }}>
        <CardBody>
          <div className="py-1">
            <Label htmlFor="example-text-input" className=" col-form-label">
              Please select customer*
            </Label>
            <div>
              {customerOptions && customerOptions.length ?
                customerOptions.map((data, i) => (
                  <Select
                    key={`customer_${i}`}
                    maxMenuHeight={250}
                    isSearchable={true}
                    options={data}
                    defaultValue={customerId}
                    onChange={handleSelect}
                  />
                )) : "Loading.."}
            </div>
          </div>
          {customerDetails && customerDetails?.fullName ? (
            <>
              <Row className="mt-5 fw-bold view-table" style={{}}>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Name</h6>
                  <p>{customerDetails?.fullName}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Occupation</h6>
                  <p>{customerDetails?.occupation}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Email</h6>
                  <p>{customerDetails?.email}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Mobile</h6>
                  <p>{customerDetails?.mobile}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Telephone</h6>
                  <p>{customerDetails?.telephone}</p>
                </Col>
                {
                  address && address.length > 0 ?
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Address</h6>
                      {/* {address?.map(item => (
                        <p>{item?.fullAddress}</p>
                      ))} */}
                      <p>{address[0]?.fullAddress}</p>
                    </Col>

                    : ''
                }
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">KYC Status</h6>
                  <p>{customerDetails?.kycStatus}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Photo</h6>
                  {customerDetails?.photo ? (
                    <ImageViewer
                      path={customerDetails?.photo}
                      style={{
                        width: "5rem",
                        height: "3rem",
                        cursor: "pointer",
                      }}
                    ></ImageViewer>
                  ) : (
                    <img
                      src={user1}
                      alt="staff-profile"
                      style={{ width: "5rem", height: "3rem" }}
                    />
                  )}
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Status</h6>

                  {customerDetails?.status ? (
                    <div className="active-btn">Active</div>
                  ) : (
                    <div className="deactive-btn">Inactive</div>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}

          {/* Register Biometric */}
          {customerId ? (
            <>
              <Row style={{ paddingTop: "5rem" }}>
                <Col lg={12}>
                  <Label className="panel-heading">Please select finger for which you want to store data</Label>
                  <div>
                    {fingerOptions && fingerOptions.length ? (
                      <Select
                        maxMenuHeight={250}
                        defaultValue={selectedFinger}
                        isSearchable={true}
                        onChange={handleStatus}
                        options={fingerOptions}
                      />
                    ) : ""}
                  </div>
                </Col>
                <Col lg={12} className="fingerprintBox">
                  <Label htmlFor="example-text-input" className=" col-form-label">
                    Register Your Biometrics Data
                  </Label>
                  <form onSubmit={e => e.preventDefault()}>
                    <Row style={{ paddingTop: "1rem" }}>
                      <Col lg={12} style={{ paddingTop: "1rem" }}>
                        {
                          isLoading ?
                            <div className="scan">
                              <div className="fingerprint"></div>
                              <h3>Scanning...</h3>
                            </div>
                            : ''
                        }
                        <div style={{ display: isLoading ? "none" : "block" }} className="box" id="box1"></div>
                      </Col>
                      <Col lg={12} style={{ paddingTop: "1rem" }}>
                        <Button
                          htmlFor="box1"
                          type="button"
                          style={{ marginLeft: "10px" }}
                          className={isCaptured ? 'btn btn-success' : 'btn btn-primary'}
                          onClick={handleCaptureEvent}
                        >
                          Capture & Save
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(FetchFingerPrint)
