import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import "assets/css/datatables.scss"
import { Card, CardBody } from "reactstrap"
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'

const StaffReport = (props) => {
  document.title = "Staff Report | Shreesh Securities";
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Customer report", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Staff Report', breadcrumbItems);
     //eslint-disable-next-line
  })
  const [page, pagechange] = useState(0)
  const [rowperpage, rowperpagechange] = useState(8);

  const handlechangepage = (event, newpage) => {
    pagechange(newpage)
  }
  const handleRowsPerPage = event => {
    rowperpagechange(+event.target.value)
    pagechange(0)
  }

  return (

    <Card className="mt-3">
      <CardBody>
        <TableContainer>
          <Table>
            <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
              <TableRow>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Full Name</TableCell>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Mobile No</TableCell>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Status</TableCell>
                <TableCell className="fs-5 fw-bold" style={{ color: 'white' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{ display: 'flex', gap: '5px' }}>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <TablePagination
          rowsPerPageOptions={[8, 10, 20, 25]}
          component="div"
          onPageChange={handlechangepage}
          onRowsPerPageChange={handleRowsPerPage}
          rowsPerPage={rowperpage}
          page={page}
          >
          </TablePagination>

        </TableContainer>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(StaffReport);