import React, { useState } from 'react'

import logoDark from "../../assets/images/logo-light.png";
import logoLight from "../../assets/images/logo-light.png";

import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row } from 'reactstrap';

import Swal from "sweetalert2"
import { registerInvitedCustAPI, uploadNomineeImages } from 'service/service';
import dummy from "../../assets/images/users/dummy.jpg"

const Register = props => {
  //meta title
  document.title = "Customer Registration | Shreesh Securities";
  const re = /^[A-Za-z\s]+$/;
  const [fullName, setFullName] = useState("")
  const [occupation, setOccupation] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [telephone, setTelephone] = useState("")
  const [addressType, setAddressType] = useState("1")
  const [fullAddress, setFullAddress] = useState("")

  const [documentType, setdocumentType] = useState("Aadhar Card")
  const [docDetail, setdocDetail] = useState("")

  const [fullNameNominee, setFullNameNominee] = useState("")
  const [occupationNominee, setOccupationNominee] = useState("")
  const [mobileNominee, setMobileNominee] = useState("")
  const [relationWithCustomer, setRelationWithCustomer] = useState("")
  const [addressTypeNominee, setAddressTypeNominee] = useState("1")
  const [fullAddressNominee, setFullAddressNominee] = useState("")

  const [fullNameH, setFullNameH] = useState("")
  const [occupationH, setOccupationH] = useState("")
  const [emailH, setEmailH] = useState("")
  const [mobileH, setMobileH] = useState("")
  const [telephoneH, setTelephoneH] = useState("")

  const [subHolders, setSubHolders] = useState([])


  // ---------primarycustomerkyc---------
  const [image, setImage] = useState("")
  const [imageView, setImageView] = useState("")
  const uploadKycImage = async (e) => {
    // setImage(e.target.files[0])
    setImageView(URL.createObjectURL(e.target.files[0]))
    const file_name = e.target.files[0]

    const res = await uploadNomineeImages(file_name)
    setImage(res.data)
    // localStorage.setItem("nominee_Photo", res.data)

    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
    // console.log(e.target.files[0]);
    // let images = [];

    // for (let i = 0; i < e.target.files.length; i++) {
    //   images.push(URL.createObjectURL(e.target.files[i]));
    // }

    // setImage(e.target.files);
    // setImageView(images);
  }

  const kyc = {
    "documentType": documentType,
    "docDetail": docDetail,
    "doc": image
  }

  const address = {
    "addressType": addressType,
    "fullAddress": fullAddress,
    "personName": fullName,
    "mobile": mobile
  }

  const nominee = {
    "fullName": fullNameNominee,
    "occupation": occupationNominee,
    "relationWithCustomer": relationWithCustomer,
    "mobile": mobileNominee,
    "fullAddress": fullAddressNominee,
    "addressType": addressTypeNominee
  }

  const handleSubmitSubHolder = async (e) => {
    console.log("submit");
    e.preventDefault();
    let holder = {
      "fullName": fullNameH,
      "occupation": occupationH,
      "email": emailH,
      "mobile": mobileH,
      "telephone": telephoneH
    }

    const updatedItems = [...subHolders, holder];
    setSubHolders(updatedItems)
    localStorage.setItem("subHolder", JSON.stringify(updatedItems))
    setFullNameH("")
    setOccupationH("")
    setEmailH("")
    setMobileH("")
    setTelephoneH("")
  }

  // const removeHolders = async (index) => {
  //   setSubHolders(subHolders.filter(function (item) {
  //     return item !== index;
  //   }))
  // }

  const handleSubmit = async (e) => {
    // console.log(fullName, occupation, email, mobile, telephone, address, nominee, subHolders, kyc);

    e.preventDefault();
    const res = await registerInvitedCustAPI(fullName, occupation, email, mobile, telephone, address, nominee, subHolders, kyc);
    // console.log(res);
    if (res.status === 1 || res.status === "1") {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
  }

  return (
    <>
      <div className="account-pages my-1 pt-sm-2">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    {/* <Link to="/" className="d-block auth-logo"> */}
                    {/* <img src={logoDark} alt="" height="110" className="auth-logo-dark" /> */}
                    <img src={logoLight} alt="" height="110" className="auth-logo-light" />
                    {/* </Link> */}
                  </h3>
                  <div className="p-3">

                    <CardTitle className="text-center fs-4 mb-3">
                      <b>Customer Registration</b>{" "}
                    </CardTitle>

                    <Form>
                      {/* --------Customer Personal Details-------- */}
                      <Row>

                        <Col lg={12} className="mb-3">
                          <Label className=" col-form-label">
                            Full Name*
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="text"
                              id="fullName"
                              name="fullName"
                              placeholder="Enter Full Name"
                              value={fullName}
                              onChange={e => {
                                const { value } = e.target;
                                if (value === "" || re.test(value)) {
                                  setFullName(value);
                                }
                              }}
                              required
                            />
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label className=" col-form-label">
                              Occupation*
                            </Label>
                            <div>
                              <Input
                                className="form-control"
                                type="text"
                                id="occupation"
                                name="occupation"
                                placeholder="Enter Occupation"
                                value={occupation}
                                onChange={e => {
                                  const { value } = e.target;
                                  if (value === "" || re.test(value)) {
                                    setOccupation(value);
                                  }
                                }}
                                required
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className=" col-form-label">
                              Email*
                            </Label>
                            <div>
                              <Input
                                className="form-control"
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={e => {
                                  setEmail(e.target.value)
                                }}
                                required
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label className=" col-form-label">
                              Mobile No*
                            </Label>
                            <div>
                              <Input
                                className="form-control"
                                type="number"
                                id="mobile"
                                name="mobile"
                                placeholder="Enter Mobile No"
                                onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                value={mobile}
                                min={10}
                                onChange={e => {
                                  setMobile(e.target.value.slice(0, 10))
                                }}
                                required
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label className=" col-form-label">
                              Telephone No
                            </Label>
                            <div>
                              <Input
                                className="form-control"
                                type="number"
                                id="telephone"
                                name="telephone"
                                onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                placeholder="Enter Telephone"
                                value={telephone}
                                onChange={e => {
                                  setTelephone(e.target.value)
                                }}
                                required
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {/* --------Customer Address-------- */}
                      <Row>
                        <Col md={6}>
                          <div>
                            <div className="py-1">
                              <Label

                                className=" col-form-label"
                              >
                                Address*
                              </Label>
                              <div>
                                <textarea
                                  className="form-control"
                                  type="text"
                                  rows={4}
                                  placeholder="Enter Full Address"
                                  name="fullAddress"
                                  id="fullAddress"
                                  value={fullAddress}
                                  required
                                  onChange={e => {
                                    setFullAddress(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="py-1">
                            <Label
                              className=" col-form-label"
                            >
                              Address Type
                            </Label>
                            <div className="mx-2">
                              <div
                                className="active"
                                value={addressType}
                                id="addressType"
                              >
                                <Input
                                  type="radio"
                                  name="addresstypee"
                                  value="1"
                                  id='Home'
                                  defaultChecked={addressType === 1 || addressType === "1"}
                                  onChange={e => {
                                    setAddressType(e.target.value)
                                  }}
                                />
                                &nbsp;Home&nbsp;&nbsp;
                                <Input
                                  type="radio"
                                  name="addresstypee"
                                  value="2"
                                  id='Office'
                                  defaultChecked={addressType === 2 || addressType === "2"}
                                  onChange={e => {
                                    setAddressType(e.target.value)
                                  }}
                                />
                                &nbsp;Office
                              </div>
                            </div>
                          </div> */}

                        </Col>
                        <Col md={6} >
                          <div className="py-1">
                            <Label
                              className=" col-form-label"
                            >
                              Address Type
                            </Label>
                            <div className="mx-2">
                              <div
                                className="active"
                                value={addressType}
                                id="addressType"
                              >
                                <Input
                                  type="radio"
                                  name="addresstypee"
                                  value="1"
                                  id='Home'
                                  defaultChecked={addressType === 1 || addressType === "1"}
                                  onChange={e => {
                                    setAddressType(e.target.value)
                                  }}
                                />
                                &nbsp;Home&nbsp;&nbsp;
                                <Input
                                  type="radio"
                                  name="addresstypee"
                                  value="2"
                                  id='Office'
                                  defaultChecked={addressType === 2 || addressType === "2"}
                                  onChange={e => {
                                    setAddressType(e.target.value)
                                  }}
                                />
                                &nbsp;Office
                              </div>
                            </div>
                          </div>
                          {/* <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Profile Photo
                          </Label>

                          <div>
                            <Input
                              type="file"
                              name="kycDoc"
                              id="kycDoc"
                              required
                              onChange={uploadCustProfile}
                            />
                            <img
                              src={fileCusProfile ? fileCusProfile : dummy}
                              alt=""
                              style={{
                                width: "8rem",
                                height: "6rem",
                                marginTop: "5px",
                              }}
                            />
                          </div> */}

                        </Col>
                      </Row>

                      <hr />
                      {/* --------Customer KYC-------- */}
                      <div>
                        <CardTitle className="text-center fs-4 mt-4">
                          <b>Primary Holder KYC</b>{" "}
                        </CardTitle>

                        <Row>
                          <Col md={5}>
                            <div>
                              <Label

                                className=" col-form-label"
                              >
                                Document Name*
                              </Label>
                              <div>
                                <Input
                                  className="form-control"
                                  type="text"
                                  id="DocName"
                                  placeholder="XXXXXXXXXXXX"
                                  name="DocName"
                                  value={documentType}
                                  readOnly
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={7}>
                            <div>
                              <Label className=" col-form-label">
                                Enter Aadhar Card Number*
                              </Label>
                              <div>
                                <Input
                                  className="form-control"
                                  type="text"
                                  id="DocDetail"
                                  placeholder="XXXXXXXXXXXX"
                                  name="docDetail"
                                  value={docDetail}
                                  onChange={e => {
                                    setdocDetail(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div className="py-1">
                          <Label className=" col-form-label">Image*</Label>
                          <div>
                            <Input
                              type="file"
                              name="doc"
                              required
                              id="kycImage"
                              onChange={uploadKycImage}
                              multiple
                            />
                            {/* {imageView && (
                              <div>
                                {imageView.map((img, i) => {
                                  return (
                                    <img className="preview" src={img} alt={"image-" + i} key={i} style={{ width: "8rem", height: "5rem" }} />
                                  );
                                })}
                              </div>
                            )} */}
                            {
                              !imageView ?
                                (<img
                                  src={dummy}
                                  alt=""
                                  style={{ width: "8rem", height: "5rem" }}
                                />) : (
                                  < img
                                    src={imageView}
                                    alt=""
                                    style={{ width: "8rem", height: "5rem" }}
                                  />
                                )
                            }
                          </div>
                        </div>

                      </div>

                      <hr />
                      {/* --------Customer Nominee-------- */}
                      <div>
                        <div>
                          <CardTitle className="text-center fs-4">
                            <b>Customer Nominee</b>
                          </CardTitle>

                          <div className="py-1">
                            <Label className=" col-form-label">
                              Nominee Name*
                            </Label>
                            <div>
                              <Input
                                className="form-control"
                                type="text"
                                name="fullNameN"
                                id="fullNameN"
                                placeholder="Enter Nominee Name"
                                required
                                value={fullNameNominee}
                                onChange={e => {
                                  const { value } = e.target;
                                  if (value === "" || re.test(value)) {
                                    setFullNameNominee(value);
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <Row>
                            <Col md={6}>
                              <div className="py-1">
                                <Label className=" col-form-label">
                                  Nominee Ocuupation*
                                </Label>
                                <div>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="occupationN"
                                    id="occupationN"
                                    placeholder="Enter Occupation"
                                    required
                                    value={occupationNominee}
                                    onChange={e => {
                                      const { value } = e.target;
                                      if (value === "" || re.test(value)) {
                                        setOccupationNominee(value);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="py-1">
                                <Label

                                  className=" col-form-label"
                                >
                                  Relation with Customer*
                                </Label>
                                <div>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="relationWithCustomer"
                                    id="relationWithCustomer"
                                    placeholder="Enter Relation With Customer"
                                    required
                                    value={relationWithCustomer}
                                    onChange={e => {
                                      const { value } = e.target;
                                      if (value === "" || re.test(value)) {
                                        setRelationWithCustomer(value);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="py-1">
                            <Label className=" col-form-label">
                              Mobile No*
                            </Label>
                            <div>
                              <Input
                                className="form-control"
                                type="number"
                                name="mobileN"
                                id="mobileN"
                                placeholder="Enter Mobile No"
                                min={10}
                                onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                value={mobileNominee}
                                onChange={e => {
                                  setMobileNominee(e.target.value.slice(0, 10))
                                }}
                                required
                              />
                            </div>
                          </div>

                          <div className="py-1">
                            <Label className=" col-form-label">
                              Address*
                            </Label>
                            <div>
                              <textarea
                                className="form-control"
                                type="text"
                                rows={4}
                                name="fullAddressN"
                                id="fullAddressN"
                                placeholder="Enter Address"
                                required
                                value={fullAddressNominee}
                                onChange={e => {
                                  setFullAddressNominee(e.target.value)
                                }}
                              />
                            </div>
                          </div>

                          <div className="py-1">
                            <Label className=" col-form-label">
                              Address Type
                            </Label>

                            <div className="mx-2">
                              <div
                                className="active"
                                value={addressTypeNominee}
                                id="addressTypeN"
                              >
                                <Input
                                  type="radio"
                                  name="addresstypeN"
                                  value="1"
                                  id="home"
                                  defaultChecked={addressTypeNominee === 1 || addressTypeNominee === "1"}
                                  onChange={e => {
                                    setAddressTypeNominee(e.target.value)
                                  }
                                  }
                                />
                                &nbsp;Home&nbsp;&nbsp;
                                <Input
                                  type="radio"
                                  name="addresstypeN"
                                  value="2"
                                  id="office"
                                  defaultChecked={addressTypeNominee === 2 || addressTypeNominee === "2"}
                                  onChange={e => {
                                    setAddressTypeNominee(e.target.value)
                                  }
                                  }
                                />
                                &nbsp;Office
                              </div>
                            </div>
                          </div>

                        </div>

                        {/* <Row>
                          <Col lg={6}>
                            <div className="py-1">
                              <Label className=" col-form-label">
                                Nominee Profile Photo
                              </Label>

                              <div>
                                <Input
                                  type="file"
                                  name="photo"
                                  id="photo"
                                  required
                                  onChange={uploadNomineeProfile}
                                />
                                <img
                                  src={filepath ? filepath : user1}
                                  alt="company-profile"
                                  style={{
                                    width: "8rem",
                                    height: "7rem",
                                    marginTop: "5px",
                                  }}
                                />
                              </div>

                            </div>
                          </Col>
                          <Col lg={6}>

                            <div className="py-1">
                              <Label className=" col-form-label">
                                Nominee KYC Photo
                              </Label>

                              <div>
                                <Input
                                  type="file"
                                  name="kycDoc"
                                  id="kycDoc"
                                  required
                                  onChange={uploadNomineeKYC}
                                />
                                <img
                                  src={file ? file : dummy}
                                  alt=""
                                  style={{
                                    width: "8rem",
                                    height: "6rem",
                                    marginTop: "5px",
                                  }}
                                />
                              </div>

                            </div>
                          </Col>
                        </Row> */}
                        {/* <div className="mt-4 d-flex justify-content-start">
                          <button
                            type="submit"
                            className="btn btn-primary w-md "
                          // onClick={updateNominee}
                          >
                            Save
                          </button>
                        </div> */}
                      </div>

                      <hr />
                      {/* --------Customer Sub holder-------- */}

                      <div>
                        <CardTitle className="text-center fs-4 mt-4">
                          <b>Customer Sub Holder</b>{" "}
                        </CardTitle>
                        <div className="mb-3">
                          <Label className=" col-form-label">
                            Full Name*
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="text"
                              id="fullNameH"
                              name="fullNameH"
                              placeholder="Enter Full Name"
                              value={fullNameH}
                              onChange={e => {
                                const { value } = e.target;
                                if (value === "" || re.test(value)) {
                                  setFullNameH(value);
                                }
                              }}
                              required
                            />
                          </div>
                        </div>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className=" col-form-label">
                                Occupation*
                              </Label>
                              <div>
                                <Input
                                  className="form-control"
                                  type="text"
                                  id="occupationH"
                                  name="occupationH"
                                  placeholder="Enter Occupation"
                                  value={occupationH}
                                  onChange={e => {
                                    const { value } = e.target;
                                    if (value === "" || re.test(value)) {
                                      setOccupationH(value);
                                    }
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className=" col-form-label">
                                Email*
                              </Label>
                              <div>
                                <Input
                                  className="form-control"
                                  type="email"
                                  id="emailH"
                                  name="emailH"
                                  placeholder="Enter Email"
                                  value={emailH}
                                  onChange={e => {
                                    setEmailH(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className=" col-form-label">
                                Mobile No*
                              </Label>
                              <div>
                                <Input
                                  className="form-control"
                                  type="number"
                                  id="mobileH"
                                  name="mobileH"
                                  placeholder="Enter Mobile No"
                                  onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                  value={mobileH}
                                  min={10}
                                  onChange={e => {
                                    setMobileH(e.target.value.slice(0, 10))
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className=" col-form-label">
                                Telephone No
                              </Label>
                              <div>
                                <Input
                                  className="form-control"
                                  type="number"
                                  id="telephoneH"
                                  name="telephoneH"
                                  onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                  placeholder="Enter Telephone"
                                  value={telephoneH}
                                  onChange={e => {
                                    setTelephoneH(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div className="mt-3 d-flex justify-content-start">
                          <button
                            type="submit"
                            className="btn btn-primary w-md "
                            onClick={handleSubmitSubHolder}
                          >
                            Save
                          </button>
                        </div>
                        {subHolders && subHolders.length > 0 ? <Row>
                          <Col lg={12}>
                            <div className="py-1 table-responsive mt-3">
                              <table
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Sr. No.
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Full Name
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Occupation
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Email
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Mobile No
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Telephone No
                                    </th>
                                    {/* <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Action
                                    </th> */}
                                    {/* <th
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Address
                                    </th> */}
                                  </tr>
                                </thead>
                                {
                                  subHolders && subHolders.length > 0 && subHolders.map((subData, index) => {
                                    return (
                                      <tbody key={index}>
                                        <tr >
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "center",
                                            }}
                                          >
                                            {index + 1}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "center",
                                            }}
                                          >

                                            {subData.fullName}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "center",
                                            }}
                                          >
                                            {subData.occupation}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "center",
                                            }}
                                          >
                                            {subData.email}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "center",
                                            }}
                                          >
                                            {subData.mobile}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "center",
                                            }}
                                          >
                                            {subData.telephone}
                                          </td>
                                          {/* <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "center",
                                            }}
                                          >
                                            <span>
                                              <i
                                                className="fas fa-trash-alt"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  removeHolders(index)
                                                }
                                              ></i>
                                            </span>
                                          </td> */}
                                          {/* <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      rajkot
                                    </td> */}
                                        </tr>
                                      </tbody>
                                    )
                                  })
                                }

                              </table>
                            </div>
                          </Col>
                        </Row> : ""}

                      </div>

                      <hr />
                      <div className="mt-3 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md "
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="my-3 text-center">
                {/* <p>Already have an account ? <Link to="/login" className="text-primary"> Login </Link> </p> */}
                Shreesh Securities © {new Date().getFullYear()} - Managed By : Shreesh Design Studio. All Rights Reserved.
              </div>
            </Col>
          </Row>
        </Container >
      </div >

    </>
  )
}

export default Register
