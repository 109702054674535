import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import user1 from "../../assets/images/users/user-1.jpg"
import {
  getCompanyDetail,
  removeCompanyImg,
  updateCompanyDetails,
  uploadCompanyFile,
} from "service/service"
import Swal from "sweetalert2"
import ImageViewer from "../../ImageViewer"
import { companyId } from "api/apiClient"
// import Loading from "components/Common/Loading"

const CompanyProfile = props => {
  const re = /^[A-Za-z\s]+$/;
  const [comDetail, setComDetail] = useState([])
  const [brandName, setBrandName] = useState(
    comDetail.brandName ? comDetail.brandName : ""
  )
  const [lat, setlat] = useState(comDetail.lat ? comDetail.lat : "")
  const [long, setlong] = useState(comDetail.long ? comDetail.long : "")

  const [contactEmail, setcontactEmail] = useState(
    comDetail.contactEmail ? comDetail.contactEmail : ""
  )
  const [contactNumber, setcontactNumber] = useState(
    comDetail.contactNumber ? comDetail.contactNumber : ""
  )
  const [facebookLink, setfacebookLink] = useState(
    comDetail.facebookLink ? comDetail.facebookLink : ""
  )
  const [googlePlusLink, setgooglePlusLink] = useState(
    comDetail.googlePlusLink ? comDetail.googlePlusLink : ""
  )
  const [instagramLink, setinstagramLink] = useState(
    comDetail.instagramLink ? comDetail.instagramLink : ""
  )
  const [officeAddress, setofficeAddress] = useState(
    comDetail.officeAddress ? comDetail.officeAddress : ""
  )
  const [pinterestLink, setpinterestLink] = useState(
    comDetail.pinterestLink ? comDetail.pinterestLink : ""
  )
  const [salesEmail, setsalesEmail] = useState(
    comDetail.salesEmail ? comDetail.salesEmail : ""
  )
  const [supportEmail, setsupportEmail] = useState(
    comDetail.supportEmail ? comDetail.supportEmail : ""
  )
  const [twitterLink, settwitterLink] = useState(
    comDetail.twitterLink ? comDetail.twitterLink : ""
  )
  const [youtubeLink, setyoutubeLink] = useState(
    comDetail.youtubeLink ? comDetail.youtubeLink : ""
  )
  const [linkedinLink, setlinkedinLink] = useState(
    comDetail.linkedinLink ? comDetail.linkedinLink : ""
  )
  const [brandLogo, setbrandLogo] = useState(
    comDetail.brandLogo ? comDetail.brandLogo : ""
  )

  document.title = "Company Profile | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Company Profile", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Company Profile", breadcrumbItems)
  })

  const removeImage = async () => {

    const imagePath = brandLogo
    const res = await removeCompanyImg(companyId, imagePath)
    if (res.status === "1" || res.status === 1) {
      setbrandLogo("")
      Swal.fire({
        title: res.message,
        icon: "success",
        timer:1000
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
  }

  const uploadCompanyImage = async e => {
    e.preventDefault()
    // setbrandLogo(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0]
    const res = await uploadCompanyFile(companyId, file)
    if (res.status === "1" || res.status === 1) {
      setbrandLogo(res.data.brandLogo)
      Swal.fire({
        title: res.message,
        icon: "success",
      })
    } else {
      setbrandLogo("")
      Swal.fire({
        title: res.message,
        icon: "error",
        timer:1000
      })
    }
    e.target.value = ""
  }

  const handleDisplayCompanyDetail = async () => {
    try {
      const res = await getCompanyDetail(companyId)
      setComDetail(res.data);

      if (res.status === "1" || res.status === 1) {
        const data = res.data
        setBrandName(data.brandName)
        setlat(data.lat)
        setlong(data.long)
        setcontactEmail(data.contactEmail)
        setcontactNumber(data.contactNumber)
        setfacebookLink(data.facebookLink)
        setgooglePlusLink(data.googlePlusLink)
        setinstagramLink(data.instagramLink)
        setofficeAddress(data.officeAddress)
        setpinterestLink(data.pinterestLink)
        setsalesEmail(data.salesEmail)
        setsupportEmail(data.supportEmail)
        settwitterLink(data.twitterLink)
        setyoutubeLink(data.youtubeLink)
        setlinkedinLink(data.linkedinLink)
        setbrandLogo(data.brandLogo)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateCompanyDetail = async () => {
    try {
      const res = await updateCompanyDetails(
        companyId,
        lat,
        long,
        contactEmail,
        contactNumber,
        facebookLink,
        googlePlusLink,
        instagramLink,
        linkedinLink,
        officeAddress,
        pinterestLink,
        salesEmail,
        supportEmail,
        twitterLink,
        youtubeLink,
        brandLogo,
        brandName
      )

      //   handleDisplaylockerList();
      if (res.status === "1" || res.status === 1) {
        // history('/dashboard')
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500,
        })
        // history("/dashboard")
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleDisplayCompanyDetail()
  }, [])


  return (

    <div className="mt-3">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <CardTitle className="fs-4 pb-3">
                        <b>Company Basic Details</b>
                      </CardTitle>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Company Name
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="text"
                            name="brandName"
                            id="brandName"
                            required
                            value={brandName}
                            // onChange={handleChangeName}
                            onChange={e => {
                              setBrandName(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className=" col-form-label fw-bold">
                          Office Address
                        </Label>
                        <div>
                          <textarea
                            className="form-control"
                            type="text"
                            rows={4}
                            name="officeAddress"
                            required
                            value={officeAddress}
                            onChange={e => {
                              setofficeAddress(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Latitude
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="number"
                            name="lat"
                            required
                            value={lat}
                            onChange={e => {
                              setlat(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Longitude
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="number"
                            name="long"
                            required
                            value={long}
                            onChange={e => {
                              setlong(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Company Logo
                        </Label>
                        <div>
                          {!brandLogo ? (
                            <div>
                              <Input
                                type="file"
                                name="doc"
                                id="doc"
                                required
                                onChange={uploadCompanyImage}
                              />
                              {/* <img src={filepath ? filepath : user1} alt="company-profile" style={{ width: '8rem', height: '7rem' }} /> */}
                              <ImageViewer
                                path={user1}
                                style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                              >
                                {" "}
                              </ImageViewer>
                            </div>
                          ) : (
                            <div className="mt-1 ">
                              {/* <FontAwesomeIcon icon={faTrashAlt}  className="removeAvatar"/> */}
                              <ImageViewer
                                path={brandLogo}
                                style={{ width: "8rem", height: "5rem", cursor: 'pointer' }}

                              >
                                {" "}
                              </ImageViewer>
                              <i
                                className="fas fa-trash-alt"
                                style={{
                                  position: "absolute",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() => removeImage()}
                              ></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <CardTitle className="fs-4 pb-3">
                        <b>Company Contact Details</b>
                      </CardTitle>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Contact Email
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="email"
                            name="contactEmail"
                            required
                            value={contactEmail}
                            onChange={e => {
                              setcontactEmail(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Support Email
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="email"
                            name="supportEmail"
                            required
                            value={supportEmail}
                            onChange={e => {
                              setsupportEmail(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Sales Email
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="email"
                            name="salesEmail"
                            required
                            value={salesEmail}
                            onChange={e => {
                              setsalesEmail(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label fw-bold">
                          Contact Number
                        </Label>
                        <div>
                          <Input
                            className="form-control"
                            type="text"
                            name="contactNumber"
                            value={contactNumber}
                            onChange={e => {
                              setcontactNumber(e.target.value)
                            }}
                            // onChange={handleNumChange}
                            required
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={4}>
                                            <CardTitle className="fs-4 pb-3"><b>Company Social Media Details</b></CardTitle>
                                            <div className="mb-3">
                                                <Label className="col-form-label fw-bold" >
                                                    Facebook Link
                                                </Label>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="facebookLink"
                                                        required
                                                        value={facebookLink}
                                                        onChange={e => { setfacebookLink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="col-form-label fw-bold" >
                                                    Instagram Link
                                                </Label>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="instagramLink"
                                                        required
                                                        value={instagramLink}
                                                        onChange={e => { setinstagramLink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="col-form-label fw-bold" >
                                                    Youtube Channel Link
                                                </Label>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="youtubeLink"
                                                        required
                                                        value={youtubeLink}
                                                        onChange={e => { setyoutubeLink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="col-form-label fw-bold" >
                                                    Twitter Link
                                                </Label>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="twitterLink"
                                                        required
                                                        value={twitterLink}
                                                        onChange={e => { settwitterLink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="col-form-label fw-bold" >
                                                    Pinterest Link
                                                </Label>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="pinterestLink"
                                                        required
                                                        value={pinterestLink}
                                                        onChange={e => { setpinterestLink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="col-form-label fw-bold" >
                                                    Linkedin Link
                                                </Label>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="linkedinLink"
                                                        required
                                                        value={linkedinLink}
                                                        onChange={e => { setlinkedinLink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="col-form-label fw-bold" >
                                                    Google Plus Link
                                                </Label>
                                                <div >
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="googlePlusLink"
                                                        required
                                                        value={googlePlusLink}
                                                        onChange={e => { setgooglePlusLink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                        </Col> */}
                    <div>
                      <Button
                        type="button"
                        className="btn btn-primary w-md mx-2"
                        onClick={updateCompanyDetail}
                      >
                        Update
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>

  )
}

export default connect(null, { setBreadcrumbItems })(CompanyProfile)
