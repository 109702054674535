import React, { useEffect, useState } from "react"
import { getLockerHistoryAPI } from "service/service"
import { DateRangePicker } from "react-date-range"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css"

const LockerOperation = () => {
  const [locker, setLocker] = useState([])
  const [allLocker, setAllLocker] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const getLockerHistory = async () => {
    try {
      const res = await getLockerHistoryAPI("A2")
      // setTransaction(res.data)
      setAllLocker(res.data)
    } catch (error) {
      throw error
    }
  }
  const handleSelect = date => {
    console.log(date)
    let filtered = allLocker?.filter(product => {
      // console.log("product - ", product)
      let productDate = new Date(product["actionPerformedAt"])
      return (
        productDate >= date.selection.startDate &&
        productDate <= date.selection.endDate
      )
    })
    setStartDate(date.selection.startDate)
    setEndDate(date.selection.endDate)
    setLocker(filtered)
    console.log("filter", filtered)
  }
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  }
  useEffect(() => {
    getLockerHistory()
     //eslint-disable-next-line
  }, [""])
  return (
    <div className="mt-3">
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Select Date*
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
            />
          </li>
        </ul>
      </div>
      <div>
        <table style={{ marginTop: "2rem" }}>
          <thead>
            <tr>
              <th>Sr. NO.</th>
              <th>Action</th>
              <th>Action Performed By</th>
              <th>Date</th>
            </tr>
          </thead>
          {selectionRange ? (
            <tbody>
              {locker?.map((product, index) => {
                let date = new Date(product["actionPerformedAt"])
                return (
                  <tr key={product["id"]}>
                    <td>{index + 1}</td>
                    <td>{product["action"]}</td>
                    <td>{product["actionPerformedBy"]}</td>
                    <td>{date.toLocaleDateString()}</td>
                  </tr>
                )
              })}
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
    </div>
  )
}

export default LockerOperation
