import React, { useEffect, useState } from "react"

import {
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  addCustomerKycAPI,
  deleteCustomerKycAPI,
  getCustomerByIdAPI,
  getCustomerList,
} from "service/service"

import Swal from "sweetalert2"
// import img1 from '../../assets/images/small/img-1.jpg'
import imgIcon from "../../assets/images/icons8-image-48.png"

const CustomerKYC = ({ id }) => {
 
  const [custID, setCustID] = useState("")
  const [fileView, setFileView] = useState()
  const [filePath, setFilePath] = useState()
  const [oldCustomerId, setOldCustomerId] = useState("")
  const [oldCustomer, setOldCustomer] = useState("")
  const [primaryCustomer, setPrimaryCustomer] = useState("")
  const [documentIdRemove, setDocumentId] = useState("")

  const [documentType, setDocumentType] = useState("")
  const [docDetail, setDocDetail] = useState("")

  const handleDocDetail = e => {
    setDocDetail(e.target.value)
  }
  const handleSelect = e => {
    const value = e.target.value
    setDocumentType(value)
  }

  const handleSetFildOld = async id => {
    const res = await getCustomerByIdAPI(id)
    setDocumentType(res?.data?.kyc?.documentType)
    setDocDetail(res?.data?.kyc?.docDetail)
    setFileView(res?.data?.kyc?.doc)
  }
  const handleDisplayOldCustomerList = async () => {
    try {
      const res = await getCustomerList()
      setOldCustomer(res.data)
      
    } catch (error) {
      console.log(error);
    }
  }
  const handleChange = async e => {
    setFileView(e.target.files[0])
    
    setFilePath(URL.createObjectURL(e.target.files[0]))
  }

  const handleSubmit = async e => {
  
    // setFilePath(e.target.files[0])
    e.preventDefault()
    try {
      const res = await addCustomerKycAPI(
        custID,
        fileView,
        documentType,
        docDetail
      )
      
      if (res.status === 1 || res.status === "1") {
        Swal.fire({
          title: res.message,
          icon: "success",
        })
        setDocumentId(res.data.kyc._id)
        setFileView(res.data.kyc.doc)
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const removeImage = async () => {
    const documentId = documentIdRemove
    const res = await deleteCustomerKycAPI(documentId)
    if (res.status === 1 || res.status === "1") {
      Swal.fire({
        title: res.message,
        icon: "success",
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
  }
  useEffect(() => {
    setCustID(localStorage.getItem("customerID"))
  }, [])

  useEffect(() => {
    setPrimaryCustomer(localStorage.getItem("customerID"))
    handleDisplayOldCustomerList()
    handleSetFildOld(oldCustomerId)
  }, [oldCustomerId])

  useEffect(
    () => {
      handleSetFildOld(id)
    },
    { id }
  )

  // useEffect(() => {
  //   getSubData()
  // }, [subId])

  return (
    <>
      <div>
        <>
          <Row>
            <Col md={5}>
              <div>
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Select Document Type
                </Label>
                <div>
                  <select
                    className="form-control"
                    id="DocName"
                    value={documentType}
                    onChange={handleSelect}
                  >
                    <option>-- SELECT --</option>
                    <option value="Aadhar Card">Aadhar Card</option>
                    <option value="Pan card">Pan card</option>
                    <option value="Voter Id Card">Voter Id Card</option>
                    <option value="Driving Licence">Driving Licence</option>
                  </select>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div>
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Document Detail
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="XXXXXXXXXXXX"
                    name="docDetail"
                    value={docDetail}
                    onChange={handleDocDetail}
                    required
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="py-1">
            <Label htmlFor="example-text-input" className=" col-form-label">
              Image
            </Label>
            <div className="mt-1">
              {!fileView ? (
                <div>
                  <Input
                    type="file"
                    name="doc"
                    id="doc"
                    required
                    onChange={handleChange}
                  />
                  <img
                    src={filePath ? filePath : imgIcon}
                    alt="company-profile"
                    style={{ width: "8rem", height: "7rem" }}
                  />
                </div>
              ) : (
                <div className="mt-1 ">
                  {/* <FontAwesomeIcon icon={faTrashAlt}  className="removeAvatar"/> */}
                  <img
                    crossOrigin="anonymous"
                    src={"http://64.227.143.181:3000/" + fileView}
                    alt=""
                    style={{ width: "8rem", height: "5rem" }}
                  />
                  <i
                    className="fas fa-trash-alt"
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => removeImage()}
                  ></i>
                </div>
              )}
            </div>
          </div>
          <div
            className="mt-5 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <button
              type="submit"
              className="btn btn-primary w-md"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </>
      </div>
    </>
  )
}

export default CustomerKYC
