import React, { useEffect, useState } from 'react';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import CreateCustomer from './createCustomer';
import CustomerAddress from './CustomerAddress';
import CustomerNominee from './CustomerNominee';
// import CustomerKYC from './CustomerKYC';
import CustomerCreationSteps from './CustomerCreationSteps';
// import CustomerSubholder from './CustomerSubholder';
import DynamicAddHolders from './DynamicAddHolders';
// import CustomerSubholder from './CustomerSubholder';

const Index = (props) => {
    document.title = "Create Customer | Shreesh Securities";
    const [page, setPage] = useState("CreateCustomer");

    const nextPage = (page) => {
        setPage(page);
    };
    const breadcrumbItems = [
        { title: "Locker", link: "/dashboard" },
        { title: "Create Customer", link: "#" },
    ]
    // let isSub = localStorage.getItem('isOldCust');
    useEffect(() => {
        props.setBreadcrumbItems('Create Customer', breadcrumbItems);
    })

    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
            case "1":
                let s = localStorage.getItem('customerS1');
                if (s) {
                    setPage("CreateCustomer");
                }
                break;
            case "2":
                let s1 = localStorage.getItem('customerS1');
                let s2 = localStorage.getItem('customerS2');

                if (s1 || s2) {
                    setPage("CustomerAddress");
                }
                break;
            case "3":
                let s3 = localStorage.getItem('customerS3');

                if (s3 === '1' || s3 === 1) {
                    setPage("CustomerSubholder")
                }
                break;
            case "4":
                let s4 = localStorage.getItem('customerS2');
                let s5 = localStorage.getItem('customerS3');
                if (s5 === '1' || s5 === 1) {
                    if (JSON.parse(localStorage.getItem('subHolderId')) && JSON.parse(localStorage.getItem('subHolderId')).length > 0) {
                        setPage("CustomerNominee")
                    }
                }
                if ((s5 === '0' || s5 === 0) && s4) {
                    setPage("CustomerNominee")
                }
                break;

            default:
                setPage("1");
        }
    };
    return (
      <div>
        <CustomerCreationSteps page={page} onPageNumberClick={nextPageNumber} />
        {
          {
            CreateCustomer: <CreateCustomer onButtonClick={nextPage} />,
            CustomerAddress: <CustomerAddress onButtonClick={nextPage} />,
            CustomerSubholder: <DynamicAddHolders onButtonClick={nextPage}/>,
            CustomerNominee: <CustomerNominee onButtonClick={nextPage} />,
          }[page]
        }
      </div>
    )
}

export default connect(null, { setBreadcrumbItems })(Index);