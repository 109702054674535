import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { useNavigate, useParams } from "react-router-dom"
import { addRenewLockerAPI, getOccupiedLockerByIdAPI, getRentByIdAPI } from "service/service"
import { Card, CardBody, CardTitle, Col, Input, Label, Row } from "reactstrap";
import Swal from "sweetalert2"
import { gst } from "api/apiClient"

const RenewLocker = props => {
  document.title = "Locker Renew | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Locker Expire", link: "/lockerExpire" },
    { title: "Renew Locker", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Renew Locker", breadcrumbItems)
  })

  const history = useNavigate();
  const { id } = useParams();
  const [cusLocker, setCusLocker] = useState("");
  const [cusLockerRent, setCusLockerRent] = useState("");
  const [planDate, setPlanDate] = useState(cusLocker.planEndDate ? cusLocker.planEndDate : "")
  // console.log(planDate);
  const [penalty, setPenalty] = useState("");
  const [cusRentId, setRentId] = useState("");
  const [cusLockerNo, setCusLockerNo] = useState(cusLocker.customerLockerNo ? cusLocker.customerLockerNo : "");
  const [cusRentName, setCusRentName] = useState(cusLocker.rentName ? cusLocker.rentName : "");
  const [rentStatus, setRentStatus] = useState("");
  let rentId = ''
  // const occupiedLockerCustomer = async () => {
  //   try {
  //     if (id) {
  //       const res = await getOccupiedLockerByIdAPI(id);
  //       // console.log(res.data.locker);
  //       setCusLocker(res.data.locker);
  //       const cuslockerdata = res.data.locker;
  //       setRentId(cuslockerdata.rentId)
  //       setCusLockerNo(cuslockerdata.customerLockerNo)
  //       setCusRentName(cuslockerdata.rentName)
  //       getRant(res.data.locker.rentId)
  //       // rentId = res.data.locker.rentId
  //       // console.log(rentId);
  //     }
  //   } catch (error) {

  //   }
  // }
  const [calculatedPenalty, setCalculatedPenalty] = useState(0);

  const occupiedLockerCustomer = async () => {
    try {
      if (id) {
        const res = await getOccupiedLockerByIdAPI(id);
        // console.log(res.data.locker);
        setCusLocker(res.data.locker);
        const cuslockerdata = res.data.locker;

        // cuslockerdata.lockerName = "XL"

        // const startDate = new Date("2024-03-21")
        // const lastDate = new Date("2027-03-21")
        const startDate = new Date(cuslockerdata?.planStartDate)
        const lastDate = new Date(cuslockerdata?.planEndDate)

        // year
        const differenceForYear = lastDate - startDate;
        console.log("differenceForYear = ", differenceForYear);
        const countDays = Math.ceil(differenceForYear / (1000 * 60 * 60 * 24));
        console.log("countDays = ", countDays);

        if (countDays > 900) {
          // const currentDateObject = new Date("2027-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              // console.log("penalty = ", penalty);
              // console.log("weeksPastDue = ", weeksPastDue);
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              // console.log("penalty = ", penalty);
              // console.log("weeksPastDue = ", weeksPastDue);
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              // console.log("penalty = ", penalty);
              // console.log("weeksPastDue = ", weeksPastDue);
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              // console.log("penalty = ", penalty);
              // console.log("weeksPastDue = ", weeksPastDue);
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              console.log("penalty = ", penalty);
              console.log("weeksPastDue = ", weeksPastDue);
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
          }
        }
        else if (countDays > 600) {
          // const currentDateObject = new Date("2026-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
          }
        }
        else if (countDays > 300) {
          // const currentDateObject = new Date("2027-04-21");
          const currentDateObject = new Date();
          // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
          const differenceInMilliseconds = currentDateObject - lastDate;
          const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          console.log("differenceInDays = ", differenceInDays);
          const weeksPastDue = Math.floor(differenceInDays / 7);
          console.log("weeksPastDue = ", weeksPastDue);
          const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
          const moreThanOneMonth = differenceInMilliseconds > oneMonthInMilliseconds;
          console.log("moreThanOneMonth = ", moreThanOneMonth);

          let penalty = 0;
          if (moreThanOneMonth) {
            // penalty = (weeksPastDue * 1000);
            // let yearGst = penalty + (penalty * gst) / 100
            // setCalculatedPenalty(yearGst)
            // console.log("Penalty Year = ", yearGst);
            if (cuslockerdata.lockerName === "B") {
              // console.log("innn", cuslockerdata.lockerName);
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "C") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "F") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "L") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
            else if (cuslockerdata.lockerName === "XL") {
              penalty = (weeksPastDue * 250);
              let yearGst = penalty + (penalty * gst) / 100
              setCalculatedPenalty(yearGst)
              console.log("Penalty Year = ", yearGst);
            }
          }
        } else {
          setCalculatedPenalty(0)
        }

        // month
        // const currentDateObject = new Date("2024-03-30");
        const currentDateObject = new Date();
        // const differenceInMilliseconds = Math.abs(currentDateObject - lastDate);
        const differenceInMilliseconds = currentDateObject - lastDate;
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        console.log("differenceInDays = ", differenceInDays);
        if (differenceInDays >= 0) {
          if (cuslockerdata.lockerName === "B") {
            let penalty = 0
            penalty = (500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "C") {
            let penalty = 0
            penalty = (750 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "F") {
            let penalty = 0
            penalty = (1000 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "L") {
            let penalty = 0
            penalty = (1500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }
          else if (cuslockerdata.lockerName === "XL") {
            let penalty = 0
            penalty = (2500 * differenceInDays)
            // penalty = (penalty * 100) / 18
            let dayGst = penalty + (penalty * gst) / 100
            setCalculatedPenalty(dayGst)
            console.log("Penalty Days = ", dayGst);
          }

          // let penalty = 0
          // penalty = (500 * differenceInDays)
          // // penalty = (penalty * 100) / 18
          // let dayGst = penalty + (penalty * gst) / 100
          // setCalculatedPenalty(dayGst)
        }
        setRentId(cuslockerdata.rentId)
        setCusLockerNo(cuslockerdata.customerLockerNo)
        setCusRentName(cuslockerdata.rentName)
        getRant(res.data.locker.rentId)
      } else {
        setCalculatedPenalty(0)
      }
    } catch (error) {

    }
  }
  console.log("calculatedPenalty = ", calculatedPenalty);
  const getRant = async (id) => {
    try {
      if (id) {
        const res = await getRentByIdAPI(id);
        // setRentStatus(false)
        setRentStatus(res.data.status)
        // console.log("rent status = ", res.data.status);
        setCusLockerRent(res.data.rentPrice)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renewLocker = async () => {
    try {
      if (id) {
        const res = await addRenewLockerAPI(id, cusRentId, planDate, calculatedPenalty, gst);
        // console.log(res.data);
        if (res.status === 1 || res.status === "1") {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500,
          })
          history("/lockerExpire")
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }

      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    occupiedLockerCustomer();
    // getRant(rentId);
    //eslint-disable-next-line
  }, [])

  return (
    <div className="mt-3">
      <Row>
        <Col xl={6} lg={8}>
          <Card>
            <CardBody>
              <CardTitle></CardTitle>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Locker No
                </Label>
                <div>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter Address"
                    name="fullAddress"
                    id="fullAddress"
                    value={cusLockerNo}
                    required
                    disabled
                  />
                </div>
              </div>

              <Row>
                {
                  rentStatus ? "" :
                    <div className="py-1 text-align-center">
                      <h5 style={{ color: "red" }}> This plan is Inactive. You can't Renew. </h5>
                    </div>
                }
                <Col md={6}>
                  <div className="py-1">
                    <Label htmlFor="example-text-input" className=" col-form-label">
                      Plan Name
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="text"
                        name="cusRentName"
                        id="cusRentName"
                        value={cusRentName}
                        disabled
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="py-1">
                    <Label htmlFor="example-text-input" className=" col-form-label">
                      Rent
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="text"
                        name="cusRentName"
                        id="cusRentName"
                        value={cusLockerRent}
                        disabled
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Plan Start Date*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    id="planStartDate"
                    type="date"
                    name="planStartDate"
                    required
                    placeholder="Select Plan Start Date"
                    pattern="\d{2}-\d{2}-\d{4}"
                    value={planDate}
                    onChange={e => {
                      setPlanDate(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Late Fees
                </Label>
                <div>
                  <Input
                    className="form-control"
                    id="penalty"
                    type="number"
                    name="penalty"
                    required
                    onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                    value={calculatedPenalty}
                    onChange={e => {
                      setPenalty(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="mt-4 d-flex gap-2">
                {
                  rentStatus ?
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={renewLocker}
                    >
                      Submit
                    </button>
                    : <button
                      type="submit"
                      className="btn btn-primary w-md"
                      style={{ display: "flex", justifyContent: "center" }}
                      disabled
                    >
                      Submit
                    </button>
                }
                <button
                  type="button"
                  className="btn btn-primary w-md"
                  style={{ display: "flex", justifyContent: "center" }}
                  onClick={() => { history("/lockerExpire") }}
                >
                  Cancel
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(RenewLocker)