import React, { useEffect, useState } from "react"
import { Table, Card, CardBody, CardTitle } from "reactstrap"

//Import Images
import { getTransactionList } from "service/service"
import moment from "moment"

// class LatestTransactions extends Component {
const LatestTransactions = () => {

  const [transactions, setTransactions] = useState("")
  const rowPerPage = 5
  const pageNo = 1
  const transactionListFor = async e => {
    try {
      const res = await getTransactionList(rowPerPage, pageNo)
      setTransactions(res.data)

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    transactionListFor()
    // eslint-disable-next-line
  }, [])


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {/* <h4 className="card-title mb-4"></h4> */}
          <CardTitle className="text-center fs-4">
            <b>Latest Transactions</b>{" "}
          </CardTitle>
          <hr />
          <div className="table-responsive">
            <Table className="align-middle table-centered table-vertical table-nowrap">
              <thead>
                <tr>
                  <th>Transaction For</th>
                  <th>Transaction Details</th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions.length > 0 &&
                  transactions.map((transaction, i) => (
                    <tr key={i} >
                      <td>
                        {transaction.transactionFor}
                      </td>
                      {/* <td>{transaction.transactionDetails.split("", 50)}</td> */}
                      <td>{transaction.transactionDetails}</td>
                      <td>
                        {moment(transaction.transactionDate).format(
                          "Do MMMM YYYY"
                        )}
                      </td>
                      <td>
                        {moment(transaction.transactionDate).format("h : mm : ss A")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )

}

export default LatestTransactions
