import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import edit from '../../assets/images/icons/edit.png';
import { useDispatch } from "react-redux";
import { displayRights, displayRole, displayRoleToRights, setBreadcrumbItems, updateRoleToRights } from "../../store/actions";
import { displayRoleToRightsAPI, getUserCommonRightsAPI, searchRoleAPI, updateRoleToRightsAPI } from "service/service";
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";

const RoleToRight = (props) => {
  document.title = "RoleToRight | Shreesh - Locker Management System";

  const [toggleState, setToggleState] = useState(1);
  const [roles, setRoles] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [rights, setRights] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isMasterCheck, setIsMasterCheck] = useState(false);
  const dispatch = useDispatch();
  const userRights = useSelector((state) => state.Profile.userRights);

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Role To Right", link: "#" },
  ];

  const handleDisplayRole = async () => {
    try {
      const res = await searchRoleAPI();
      setRoles(res.data);
      setSelectedValue(document.getElementById('roleName').value);
      dispatch(displayRole(res.data));
    } catch (error) {
      throw error;
    }
  };

  const handleDisplayCommonRights = async () => {
    let temp = [];
    const res = await getUserCommonRightsAPI();
    res.data && res.data.length &&
      res.data.map((data) => {
        temp.push({
          "id": data.id,
          "name": data.name,
          "displayName": data.displayName,
          "read": false,
          "write": false,
          "delete": false,
          "import": false,
          "export": false,
          "isSubChecked": false
        })
      })
    setRights(temp);

    dispatch(displayRights(res.data));
  };

  const handleUserRights = async () => {
    let count = 0;
    let rightsLength = rights.length;
    try {
      let roleId;
      roles &&
        roles.forEach((data) => {
          if (data.name === selectedValue) {
            roleId = data.id;
          }
        });
      const res = await displayRoleToRightsAPI(roleId);
      if (roleId) {

        let userRights = res.data
        let temp = [];
        const resDefault = await getUserCommonRightsAPI();
        resDefault.data && resDefault.data.length &&
        resDefault.data.map((data) => {
            temp.push({
              "id": data.id,
              "name": data.name,
              "displayName": data.displayName,
              "read": false,
              "write": false,
              "delete": false,
              "import": false,
              "export": false,
              "isSubChecked": false
            })
          })
        console.log(temp);
        userRights.length === 0 && setIsChecked(false);

        userRights && userRights.length > 0 && rights && rights.length > 0 &&
          rights.map((data, i) => {
            userRights.map((item, index) => {
              if (item?.rightId === rights[i].id) {
                temp[i].read = userRights[index]?.read
                temp[i].write = userRights[index]?.write
                temp[i].delete = userRights[index]?.delete
                temp[i].import = userRights[index]?.import
                temp[i].export = userRights[index]?.export
                if (userRights[index]?.read && userRights[index]?.write && userRights[index]?.delete && userRights[index]?.import && userRights[index]?.export) {
                  temp[i].isSubChecked = true;
                  count++
                  rightsLength && rightsLength === count ? setIsMasterCheck(true) : setIsMasterCheck(false);
                }
                else {
                  temp[i].isSubChecked = false;
                }
              }
            })
          });
        setRights(temp);
        dispatch(displayRoleToRights(res.data));
      }
    } catch (error) {
      throw error;
    }
  };

  const handleMaterCheck = (data) => {
    setIsChecked(!isChecked);
    setIsMasterCheck(data);
  };

  const handleRightsArray = () => {
    let temp = [...rights];

    if (isMasterCheck) {
      temp && temp.length > 0 &&
        temp.map((data, index) => {
          temp[index] = {
            ...temp[index],
            "read": true,
            "write": true,
            "delete": true,
            "import": true,
            "export": true,
            "isSubChecked": true
          }
        })
      setRights(temp);
    }
    else {
      handleDisplayCommonRights();
    }
  };

  const handleCheckboxChange = (i, check) => {
    let temp = [...rights];
    rights && rights.length &&
      rights.map((data, index) => {
        if (check) {
          temp[i].read = true
          temp[i].write = true
          temp[i].delete = true
          temp[i].import = true
          temp[i].export = true
          temp[i].isSubChecked = true
        }
        else if (!check) {
          temp[i].read = false
          temp[i].write = false
          temp[i].delete = false
          temp[i].import = false
          temp[i].export = false
          temp[i].isSubChecked = false
        }

        if (data.read && data.write && data.delete && data.import && data.export) {
          temp[index].isSubChecked = true;
        }
        else {
          temp[index].isSubChecked = false;
        };
        handleSubCheck(temp);
        setRights(temp);
      });
  };

  const handleSubCheck = (temp) => {
    let count = 0;
    let rightsLength = rights.length;
    temp && temp.length > 0 &&
      temp.map((data, i) => {
        if (temp[i]?.read && temp[i]?.write && temp[i]?.delete && temp[i]?.import && temp[i]?.export) {
          temp[i].isSubChecked = true;
          count++
          rightsLength && rightsLength === count ? setIsMasterCheck(true) : setIsMasterCheck(false);
        }
        else {
          temp[i].isSubChecked = false;
        }
      })
  };

  const handleRead = (index) => {
    let temp = [...rights];
    temp[index].read = !temp[index].read;
    handleSubCheck(temp);
    setRights(temp);
  };

  const handleWrite = (index) => {
    let temp = [...rights];
    temp[index].write = !temp[index].write;
    handleSubCheck(temp);
    setRights(temp);
  };

  const handleDelete = (index) => {
    let temp = [...rights];
    temp[index].delete = !temp[index].delete;
    handleSubCheck(temp);
    setRights(temp);
  };

  const handleImport = (index) => {
    let temp = [...rights];
    temp[index].import = !temp[index].import;
    handleSubCheck(temp);
    setRights(temp);
  };

  const handelExport = (index) => {
    let temp = [...rights];
    temp[index].export = !temp[index].export;
    handleSubCheck(temp);
    setRights(temp);
  };

  const toggleTab = (id) => {
    setToggleState(id);
  };

  const handleUpdate = async () => {
    let roleId;
    let temp = [];

    roles &&
      roles.forEach((data) => {
        if (data.name === selectedValue) {
          roleId = data.id;
        }
      });

    rights && rights.length > 0 &&
      rights.map((data) => {
        temp.push({
          "_id": data.id,
          "name": data.name,
          "read": data.read,
          "write": data.write,
          "delete": data.delete,
          "import": data.import,
          "export": data.export
        })
      })
    const res = await updateRoleToRightsAPI(roleId, temp);
    dispatch(updateRoleToRights(temp));
    if (res.status === 1 || res.status === '1') {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
        timer: 1500
      })
    }
  };

  const handleUpdateClick = (num, name) => {
    toggleTab(num);
    setSelectedValue(name);
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
  };

  useEffect(() => {
    handleUserRights();
    handleDisplayRole();
    handleDisplayCommonRights();
    //eslint-disable-next-line
  }, [selectedValue]);

  useEffect(() => {
    handleRightsArray();
    //eslint-disable-next-line
  }, [isChecked]);

  useEffect(() => {
    props.setBreadcrumbItems('Role To Right', breadcrumbItems);
    //eslint-disable-next-line
  }, [props]);

  return (
    <>
      <div>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle><b>User Rights Management</b></CardTitle>
                <div className="d-flex flex-wrap gap-2">
                  <div>
                    <button type="submit" className=" btn btn-primary" onClick={() => toggleTab(2)}>AllocateRights</button>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary" onClick={() => toggleTab(1)}>ViewAllocateRights</button>
                  </div>
                </div>
                <hr />

                <div className={toggleState === 1 ? "d-block" : "d-none"}>
                  <CardTitle style={{ marginTop: '20px' }}><b>Modify Allocated Rights</b></CardTitle>
                  {roles &&
                    roles.length > 0 &&
                    roles.map((data, index) => (
                      <div className="viewAllocateRights mb-3" key={index}>
                        <div className="d-flex w-100">
                          <div className="w-100 align-self-center">
                            <h5 className="m-0 ">{data.name} Rights Modify</h5>
                          </div>
                          <div>
                            <center>
                              <img src={edit} alt="" title="edit " style={{ height: '30px', cursor: 'pointer' }} onClick={() => handleUpdateClick(2, data.name)} />
                            </center>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className={toggleState === 2 ? "d-block" : "d-none"}>
                  <CardTitle className="mt-3"><b>AllocatedRights</b></CardTitle>
                  <div className='row'>
                    <div className="col-lg-3" >
                      <label htmlFor="roleName" className="col-form-label">
                        <CardTitle>Select User Role*</CardTitle>
                      </label>
                      <div>
                        <select className='form-control' id="roleName" value={selectedValue} onChange={handleSelect}>
                          <option>-- Select Role --</option>
                          {roles &&
                            roles.length > 0 &&
                            roles.map((data, index) => (
                              <option key={index} value={data.name}>{data.name}</option>
                            ))}
                        </select>
                      </div>
                      <div className="" style={{ marginTop: '20px' }}>
                        {userRights && userRights?.role_to_right?.write && <button type="submit" className="btn btn-success" onClick={() => handleUpdate()}>Save</button>}
                        <button type="submit" className="btn btn-primary" style={{ marginLeft: '10px' }}>GoBack</button>
                      </div>
                    </div>

                    <div className="col-lg-9">
                      <label htmlFor="example-text-input" className="col-form-label">
                        <CardTitle>Select Rights</CardTitle>
                      </label>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  name="masterchk"
                                  checked={isMasterCheck}
                                  onChange={(e) => handleMaterCheck(e.target.checked)}
                                />
                              </th>
                              <th>Right Name</th>
                              <th>Read</th>
                              <th>Write</th>
                              <th>Delete</th>
                              <th>Import</th>
                              <th>Export</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rights &&
                              rights.length > 0 &&
                              rights.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type='checkbox'
                                      checked={rights[index].isSubChecked}
                                      onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                    />
                                  </td>
                                  <td>{rights.length > 0 && rights[index].displayName}</td>
                                  <td>
                                    <input
                                      type='checkbox'
                                      checked={
                                        rights.length > 0 && rights[index]?.read ? rights[index].read : false
                                      }
                                      onChange={() => handleRead(index)}
                                      name="chk"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type='checkbox'
                                      name="chk"
                                      checked={rights.length > 0 && rights[index]?.write ? rights[index].write : false}
                                      onChange={() => handleWrite(index)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type='checkbox'
                                      name="chk"
                                      checked={rights.length > 0 && rights[index]?.delete ? rights[index].delete : false}
                                      onChange={() => handleDelete(index)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type='checkbox'
                                      name="chk"
                                      checked={rights.length > 0 && rights[index]?.import ? rights[index].import : false}
                                      onChange={() => handleImport(index)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type='checkbox'
                                      name="chk"
                                      checked={rights.length > 0 && rights[index]?.export ? rights[index].export : false}
                                      onChange={() => handelExport(index)}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default connect(null, { setBreadcrumbItems })(RoleToRight);