import React from 'react';
import { ProgressBar, Step } from "react-step-progress-bar";


const CustomerCreationSteps = ({ page, onPageNumberClick }) => {
    var stepPercentage = 0;
    if (page === "CreateCustomer") {
        stepPercentage = 25;
    } else if (page === "CustomerAddress") {
        stepPercentage = 50;
    } else if (page === "CustomerSubholder") {
      stepPercentage = 75
    } else if (page === "CustomerNominee") {
      stepPercentage = 100
    } else {
      stepPercentage = 0
    }


    return (
        <div>
            <ProgressBar percent={stepPercentage}>
                <Step>
                    {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            onClick={() => onPageNumberClick("1")}
                        >
                            {index + 1}
                          
                        </div>
                    )}
                    
                </Step>
                <Step>
                    {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            onClick={() => onPageNumberClick("2")}
                        >
                            {index + 1}
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            onClick={() => onPageNumberClick("3")}
                        >
                            {index + 1}
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished, index }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            onClick={() => onPageNumberClick("4")}
                        >
                            {index + 1}
                        </div>
                    )}
                </Step>
                
            </ProgressBar>

        </div>
    )
}

export default CustomerCreationSteps
