import React, { useEffect, useState } from "react";
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row, Label, Form } from "reactstrap";
import { createRoleAPI, updateRoleAPI } from "service/service";

import Swal from "sweetalert2"
import UserRole from "components/UserRole/UserRole";
import { useSelector } from "react-redux";

const Role = (props) => {
  const [roleName, setRoleName] = useState('');
  const [userStatus, setUserStatus] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [childId, setChildId] = useState('');
  const userRights = useSelector((state) => state.Profile.userRights);
  const re = /^[A-Za-z\s]+$/;

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Role", link: "#" },
  ]
  // const value = { roleName, userStatus };
  const handleName = (e) => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setRoleName(value);
    }
    // setRoleName(e.target.value);
  }
  const handleStatus = (e) => {
    setUserStatus(e.target.value);
  }
  const handleSubmit = async () => {
    
    try {
      const res = await createRoleAPI(roleName, userStatus);
      localStorage.setItem('createRole', res.status);
      if (res.status === '1' || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          
        })
        handleChildClick('')
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",

        })
      }
    }
    catch (error) {
      throw (error)
    }
  };

  const handleUpdate = async () => {
    
    const roleNameInner = document.getElementById("roleName").value;
    try {
      if (roleNameInner.length > 0) {
        const res = await updateRoleAPI(childId, roleNameInner, userStatus);
        res.status === '1' ? setIsUpdate(false) : setIsUpdate(true)
        handleClean();
        if (res.status === '1' || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
          })
          handleChildClick('')
        }
      }
      else {
        return false;
      }
      // localStorage.setItem('createRole', res.status);
    }
    catch (error) {
      throw (error)
    }
  };

  const handleClean = () => {
    handleChild();
    handleClear();
  }

  const handleChild = () => {
    // setTimeout(() => {
      setIsRefresh(!isRefresh);
    // }, 100);
  }

  const handleUpdateChild = (data) => {
    setChildId(data.id);
    setIsUpdate(data.isUpdateChild);
  };
  const handleChildClick = (value) => {
    setRoleName(value);
    document.getElementById('roleName').value = value
  };

  const handleClear = () => {
    document.getElementById('roleName').value = '';
    setIsUpdate(false);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Role', breadcrumbItems)
  })

  return (
    <>
      <div className="mt-3">
        <div>
          <Card>
            <CardBody>
              <Row>
                {userRights.user_role?.write && <Col xl={3}>
                  <Card style={{ boxShadow: 'none' }}>
                    <CardBody >
                      <CardTitle><b>UserRole Details</b></CardTitle>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <div>
                          <Label htmlFor="example-text-input" className=" col-form-label" >
                            Name*
                          </Label>
                          <div>
                            <input className="form-control" id="roleName" placeholder="Enter Role Name" type="text" value={roleName} onChange={handleName} />
                          </div>
                        </div>
                        <div>
                          <Label htmlFor="example-text-input" className=" col-form-label" >
                            Status
                          </Label>
                          <div className="d-flex flex-wrap gap-2">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="Radio1"
                                value="true"
                                defaultChecked
                                onClick={handleStatus}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                Active
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="Radio2"
                                value="false"
                                onClick={handleStatus}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios2"
                              >
                                Inactive
                              </label>
                            </div>
                          </div>

                        </div>
                        <div>
                          <button className="role-save custom-btn" onClick={isUpdate ? handleUpdate : handleSubmit}>{!isUpdate ? "Save" : "Update"}</button>
                          <button className="role-clear custom-btn" onClick={handleClear}>Clear</button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>}
                <UserRole
                  refresh={isRefresh}
                  dataRecived={handleUpdateChild}
                  onChildClick={handleChildClick}
                />
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

// export default Role
export default connect(null, { setBreadcrumbItems })(Role);