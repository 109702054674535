import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  addCustomerKycAPI,
  deleteCustomerKycAPI,
  getCustomerKycAPI,
  updateCustomerKycAPI,
} from "service/service"
import Swal from "sweetalert2"
import dummy from "../../assets/images/users/dummy.jpg"

const CustomerSubHolderKYC = ({ id, count, oldCustomerId, isSave }) => {
  const [oldKyc, setOldKyc] = useState("")
  const [isDeleted, setIsDeleted] = useState(0)
  const [documentType, setdocumentType] = useState("")
  const [docDetail, setDocDetail] = useState("")
  const [kycId, setKycId] = useState("")

  const subHolders = JSON.parse(localStorage.getItem("subHolderId"))

  const handleDocDetail = e => {
    setDocDetail(e.target.value)
  }

  const handleSelect = e => {
    const value = e.target.value
    setdocumentType(value)
  }

  const [image, setImage] = useState(null)
  const [imageView, setImageView] = useState(null)

  function handleImage(e) {
    setImage(e.target.files[0])
    setImageView(URL.createObjectURL(e.target.files[0]))
  }

  const handleSubmitKyc = async e => {
    e.preventDefault()
    if (kycId) {
      try {
        const res = await updateCustomerKycAPI(
          kycId,
          image,
          documentType,
          docDetail
        )
        // dispatch(addCustomerKyc(customerId, image, documentType, docDetail))
        handleGetKyc()
        handleClear()
        if (res.status === "1" || res.status === 1) {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) { }
    } else {
      const res = await addCustomerKycAPI(
        subHolders[count] ? subHolders[count] : oldCustomerId,
        image,
        documentType,
        docDetail
      )

      handleGetKyc()
      handleClear()
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
        localStorage.setItem("customerS2", true)
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    }
  }
  const handleClear = () => {
    setdocumentType("")
    setDocDetail("")
    setImage("")
    document.getElementById('kycImage').value = null
    setImageView("")
  }

  const handleGetKyc = async () => {
    // console.log(count);
    if (subHolders !== null) {
      const res = await getCustomerKycAPI(subHolders[count])
      setOldKyc(res.data)
    }
  }

  const handleDeleteOldKyc = async i => {
    const res = await deleteCustomerKycAPI(oldKyc[i].id)
    localStorage.removeItem("customerAddress")
    res.status === "1" && handleGetKyc(id)
    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
    setIsDeleted(2)
  }

  const handleUpdateOldKyc = i => {
    setKycId(oldKyc[i].id)
    setDocDetail(oldKyc[i].docDetail)
    setdocumentType(oldKyc[i].documentType)
    setImage(oldKyc[i].doc)
  }

  useEffect(() => {
    handleGetKyc(oldCustomerId ? oldCustomerId : id)
    // eslint-disable-next-line
  }, [id, isDeleted, oldCustomerId, isSave, isDeleted])

  // useEffect(() => {
  //   handleGetKyc(oldCustomerId)
  // }, [isDeleted, oldCustomerId])

  return (
    <div>
      <div className="d-flex justify-content-center">
        <Row className="w-100" style={{ maxWidth: "50rem" }}>
          <Col>

            <Form>
              <CardTitle className="text-center fs-4 mt-4">
                <b>Customer Sub Holder KYC</b>{" "}
              </CardTitle>

              <Row>
                <Col md={5}>
                  <div>
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Select Document Type*
                    </Label>
                    <div>
                      <select
                        className="form-control"
                        id="DocName"
                        value={documentType}
                        onChange={handleSelect}
                      >
                        <option>-- SELECT --</option>
                        <option value="Aadhar Card">Aadhar Card</option>
                        <option value="Pan card">Pan card</option>
                        <option value="Voter Id Card">Voter Id Card</option>
                        <option value="Driving Licence">
                          Driving Licence
                        </option>
                        <option value="Physical Form">Physical Form</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col md={7}>
                  <div>
                    <Label
                      htmlFor="example-text-input"
                      className=" col-form-label"
                    >
                      Document Detail*
                    </Label>
                    <div>
                      <Input
                        className="form-control"
                        type="text"
                        id="DocDetail"
                        placeholder="XXXXXXXXXXXX"
                        name="docDetail"
                        value={docDetail}
                        onChange={handleDocDetail}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="py-1">
                <Label
                  htmlFor="example-text-input"
                  className=" col-form-label"
                >
                  Image*
                </Label>

                <div>
                  <div>
                    <Input
                      type="file"
                      name="doc"
                      id="kycImage"
                      required
                      onChange={handleImage}
                    />
                  </div>
                  <div className="mt-1">
                    <img
                      src={imageView ? imageView : dummy}
                      alt=""
                      style={{ width: "8rem", height: "5rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary w-md "
                  onClick={handleSubmitKyc}
                >
                  {kycId ? "Update" : "Save"}
                </button>
              </div>
              <Row>
                <Col lg={12}>
                  <div className="py-1 table-responsive mt-3">
                    <table
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Document Type
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Document Number
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                      {oldKyc &&
                        oldKyc.length > 0 &&
                        oldKyc.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                {data.documentType}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.docDetail}{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {/* <span>
                                      <i
                                        className="fa fa-edit"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleUpdateOldKyc(index)
                                        }
                                      ></i>
                                    </span> */}
                                <span>
                                  <i
                                    className="fas fa-trash-alt"
                                    style={{
                                      cursor: "pointer", color: "red",
                                      fontSize: "larger"
                                    }}
                                    onClick={() =>
                                      handleDeleteOldKyc(index)
                                    }
                                  ></i>
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                    </table>
                  </div>
                </Col>
              </Row>
            </Form>

          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CustomerSubHolderKYC
