import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { getLockerByIdAPI } from "service/service"
import { Link, useParams } from "react-router-dom"

const LockerDetails = props => {
  document.title = "Locker Details | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Locker List", link: "/lockerlist" },
    { title: "Locker Details", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Locker Details", breadcrumbItems)
  })

  const { id } = useParams()
  const [lockerDetails, setLockerDetails] = useState([])
  const handleDisplaylockerUpdate = async id => {
    try {
      const res = await getLockerByIdAPI(id)
      setLockerDetails(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (id) {
      handleDisplaylockerUpdate(id)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div>
      <Row>
        <Col>
          <div className="d-flex justify-content-end">
            <div className="goback">
              <Link to="/lockerlist" className="">
                Go Back
              </Link>
            </div>
          </div>
          <Card>
            <CardBody>
              <CardTitle className="fs-4">
                <b>View Locker Details</b>
              </CardTitle>
              <hr />
              <Row className="fw-bold view-table" style={{}}>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Locker Category</h6>
                  <p>{lockerDetails.name}</p>
                </Col>
                {lockerDetails.description &&
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <h6 className="fw-bold">Locker Description</h6>
                    <p>{lockerDetails.description}</p>
                  </Col>
                }
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Locker Size</h6>
                  {/* <p>{lockerDetails.lockerSize}</p> */}
                  <p>{lockerDetails.lockerCategory}</p>
                </Col>
                {/* <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Locker Category</h6>
                  <p>{lockerDetails.lockerCategory}</p>
                </Col> */}
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Locker Deposit (Refundable)</h6>
                  <p>{lockerDetails.lockerDeposit}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Status</h6>

                  {lockerDetails.status ? (
                    <div className="active-btn">Active</div>
                  ) : (
                    <div className="deactive-btn">Inactive</div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(LockerDetails)
