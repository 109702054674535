import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter, HashRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

import store from "./store"
import ErrorBoundary from "api/ErrorHandler"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  // <ErrorBoundary>
  //   <Provider store={store}>
  //     <>
  //       <HashRouter>
  //         <App />
  //       </HashRouter>
  //     </>
  //   </Provider>
  // </ErrorBoundary>
  <Provider store={store}>
    <>
      <HashRouter>
        <App />
      </HashRouter>
    </>
  </Provider>
)

serviceWorker.unregister()
