import { Card, CardBody, Input } from "reactstrap"
import { getTransactionCountAPI, getTransactionList, searchTransaction } from "service/service"
import React, { useEffect, useState } from "react"

import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import moment from "moment"
import { useNavigate } from "react-router-dom"

const TransactionList = props => {
  const [transaction, setTransaction] = useState([])
  const [count, setRentCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isRefresh, setIsRefresh] = useState(false)
  const history = useNavigate()

  document.title = "Transaction List | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Transaction", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Transaction", breadcrumbItems)
  })
  const handlechangepage = (event, newpage) => {
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    setPerPage(+event.target.value)
    setPageNo(1);
  }

  const handleDisplayTransactionList = async () => {
    try {
      const res = await getTransactionList(perPage, pageNo)
      setTransaction(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const getRentCount = async () => {
    try {
      const res = await getTransactionCountAPI();
      // console.log("res", res);
      if (res.status === '1' || res.status === 1) {
        setRentCount(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function getCounts() {
      await getRentCount();
    }
    getCounts();
  }, [])

  const handleTransactionView = async i => {
    try {
      history(`/viewTransaction/${i}`)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchTransaction(value)
    setTransaction(res.data)
  }

  useEffect(() => {
    handleDisplayTransactionList()
    // eslint-disable-next-line
  }, [isRefresh, perPage, pageNo])

  return (
    <>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-3 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-3 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Type
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Mode
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Information
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Amount
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction For
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Detail
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Date
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Time
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Transaction Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transaction &&
                  transaction.map((row, index) => (
                    <TableRow key={row._id} className="tablerow">
                      <TableCell className="tablerow">
                        {row.transactionType}
                      </TableCell>
                      <TableCell className="tablerow">
                        {row.modeOfPayment}
                      </TableCell>
                      <TableCell className="tablerow">
                        {row.paymentDescription}
                      </TableCell>
                      <TableCell
                        onClick={() => handleTransactionView(row._id)}
                      >
                        {row.transactionAmount}
                      </TableCell>
                      <TableCell
                        onClick={() => handleTransactionView(row._id)}
                      >
                        {row.transactionFor}
                      </TableCell>
                      <TableCell
                        onClick={() => handleTransactionView(row._id)}
                      >
                        {row.transactionDetails}
                      </TableCell>
                      <TableCell
                        onClick={() => handleTransactionView(row._id)}
                      >
                        {moment(row.transactionDate).format("Do MMMM YYYY")}
                      </TableCell>
                      <TableCell
                        onClick={() => handleTransactionView(row._id)}
                      >
                        {moment(row.transactionDate).format("h : mm : ss A")}
                      </TableCell>
                      <TableCell
                        onClick={() => handleTransactionView(row._id)}
                      >
                        {row.transactionStatus}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              rowsPerPage={perPage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            />
          </TableContainer>
        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(TransactionList)
