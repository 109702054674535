import { axiosClient, axiosClientFormData } from "api/apiClient"

import {
  ActiveCustomerRoute,
  ActiveLockerRoute,
  ActiveRentRoute,
  authLoginRoute,
  changePasswordRoute,
  companyProfileRoute,
  createLockerRoute,
  CreateRentRoute,
  createRightsRoute,
  createRoleRoute,
  createStaffRoute,
  CustomerListRoute,
  DeactiveCustomerRoute,
  DeactiveLockerRoute,
  DeactiveRentRoute,
  deleteRightsRoute,
  deleteRoleRoute,
  deleteStaffRoute,
  displayRightsRoute,
  displayRoleRoute,
  displayRoleToRightsRoute,
  getRentById,
  getUserRights,
  lockerByIdRoute,
  lockerListRoute,
  RentListRoute,
  StaffListRoute,
  updateCompanyDetailsRoute,
  updateLockerRoute,
  UpdateRentRoute,
  updateRightsRoute,
  updateRoleRoute,
  updateRoleToRightsRoute,
  UpdateStaffRoute,
  uploadCompanyImage,
  userProfileRoute,
  createCustomerRoute,
  updateCustomerRoute,
  getCustomerByIdRoute,
  addCustomerAddressRoute,
  addCustomerNomineeRoute,
  removeCompanyImage,
  removeUserImage,
  uploadUserImage,
  uploadStaffImgRoute,
  removeStaffImgRoute,
  getStaffById,
  TransactionListRoute,
  assignLockerToCustomer,
  getExpiringLockersRoute,
  uploadCustomerImage,
  removeCustomerImage,
  getCustomerAddressRoute,
  getCustomerKycRoute,
  deleteCustomerAddressRoute,
  deleteCustomerKycRoute,
  getOccupiedLockerRoute,
  updateCustomerAddressRoute,
  renewLockerRoute,
  searchStaffRoute,
  searchLockerRoute,
  searchRentRoute,
  searchCustomerRoute,
  searchTransactionRoute,
  displayCustomersCountRoute,
  displayLockersCountRoute,
  kycByIdRoute,
  UploadImageRoute,
  createTransactionRoute,
  changeLockerPlanRoute,
  updateCustomerNomineeRoute,
  UpdateCustomerKycStatusRoute,
  occupiedLockerRoute,
  getOccupiedLockerByIdRoute,
  occupiedLockerDeactivateRoute,
  addCustKycRoute,
  occupiedLockerRemoveRoute,
  occupiedLockerBreakRoute,
  occupiedLockerActivateRoute,
  addSubHolderRoute,
  getSubHolderRoute,
  updateCustKycRoute,
  getTransactionByIdRoute,
  getLockerHistoryRoute,
  getCustomerReportRoute,
  getTransactionReportRoute,
  getLockerReportRoute,
  getUnOperatedLockerReportRoute,
  lockerCountRoute,
  rentCountRoute,
  staffCountRoute,
  customerCountRoute,
  transactionCountRoute,
  rightsCountRoute,
  roleCountRoute,
  searchRoleRoute,
  ActiveStaffRoute,
  deactiveStaffRoute,
  searchCustomerLockersByLockerNo,
  fetchCustomerFingerPrint,
  registerCustomerFingerPrint,
  accessCustomerLockerRoute,
  displayDailyLockerAccessCountRoute,
  displayLockerAccessCountRoute,
  sendMessageRoute,
  registerInvitedCustRoute,
  getLockerTransactionByIdRoute,
  addAdditionalLockOnLockerRoute,
  manualAccessLockerRoute,
  closeCustLockerRoute,
  listCustomerNomineeRoute,
  deleteCustomerNomineeRoute,
  updateLockerTransactionByIdRoute,
} from "./backendRoutes";

import axios from "axios"

const serverURL = "https://shreeshdesignstudiollp.com:3001/"
// const serverURL = "http://localhost:3001/"
const baseURL = serverURL + "Development/"

const timeout = 999999
// ------------------AuthLogin--------------------------
export const authLogin = async (userName, password, userGroup) => {
  try {
    const data = {
      username: userName,
      password: password,
      userGroup: userGroup,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(authLoginRoute, data)
    // localStorage.setItem("md5", response.data.data.secretKey)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ----------------Get Lockers Count--------------------
export const displayLockersCount = async () => {
  try {
    const data = []
    // Make the axiosClient request with headers
    const response = await axiosClient.post(displayLockersCountRoute, data)

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ----------------Get Customers Count--------------------
export const displayCustomersCount = async () => {
  try {
    const data = []
    // Make the axiosClient request with headers
    const response = await axiosClient.post(displayCustomersCountRoute, data)

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ----------------Get Transactions Count--------------------
export const displayLockerAccessCountAPI = async () => {
  try {
    const data = []
    // Make the axiosClient request with headers
    const response = await axiosClient.post(displayLockerAccessCountRoute, data)

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ----------------Get Daily Transactions Count--------------------
export const displayDailyLockerAccessCountAPI = async () => {
  try {
    const data = []
    // Make the axiosClient request with headers
    const response = await axiosClient.post(
      displayDailyLockerAccessCountRoute,
      data
    )

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------user Profile--------------------------
export const userProfileAPI = async userId => {
  try {
    const data = {
      userId: localStorage.getItem("loginUserId"),
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(userProfileRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------UPLOAD User Profile Img--------------------------
export const uploadUserImg = async (userId, file_name) => {
  try {
    const formData = new FormData()
    formData.append("userId", userId)
    formData.append("file_name", file_name)

    const response = await axiosClientFormData.post(uploadUserImage, formData)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Remove User Profile Img--------------------------
export const removeUserImg = async (userId, imagePath) => {
  try {
    const data = {
      userId: userId,
      imagePath: imagePath,
    }

    const response = await axiosClient.post(removeUserImage, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Company Details--------------------------
export const getCompanyDetail = async companyId => {
  try {
    const data = {
      companyId: companyId,
    }

    const response = await axiosClient.post(companyProfileRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update Company Details--------------------------
export const updateCompanyDetails = async (
  companyId,
  lat,
  long,
  contactEmail,
  contactNumber,
  facebookLink,
  googlePlusLink,
  instagramLink,
  linkedinLink,
  officeAddress,
  pinterestLink,
  salesEmail,
  supportEmail,
  twitterLink,
  youtubeLink,
  brandLogo,
  brandName
) => {
  try {
    const data = {
      companyId: companyId,
      lat: lat,
      long: long,
      contactEmail: contactEmail,
      contactNumber: contactNumber,
      facebookLink: facebookLink,
      googlePlusLink: googlePlusLink,
      instagramLink: instagramLink,
      linkedinLink: linkedinLink,
      officeAddress: officeAddress,
      pinterestLink: pinterestLink,
      salesEmail: salesEmail,
      supportEmail: supportEmail,
      twitterLink: twitterLink,
      youtubeLink: youtubeLink,
      brandName: brandName,
      brandLogo: brandLogo,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(updateCompanyDetailsRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------UPLOAD Company Profile Img--------------------------
export const uploadCompanyFile = async (companyId, file_name) => {
  try {
    const formData = new FormData()
    formData.append("companyId", companyId)
    formData.append("file_name", file_name)

    const response = await axiosClientFormData.post(
      uploadCompanyImage,
      formData
    )
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Remove Company Profile Img--------------------------
export const removeCompanyImg = async (companyId, imagePath) => {
  try {
    const data = {
      companyId: companyId,
      imagePath: imagePath,
    }

    const response = await axiosClient.post(removeCompanyImage, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Change pass--------------------------
export const changePasswordAPI = async (userId, old_password, new_password) => {
  try {
    const data = {
      userId: localStorage.getItem("user_id"),
      old_password: old_password,
      new_password: new_password,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(changePasswordRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Create Role--------------------------
export const createRoleAPI = async (name, status) => {
  try {
    const data = {
      name: name,
      status: status,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(createRoleRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Display Role--------------------------
export const getRoleList = async (rowperpage, page) => {
  try {
    const data = {
      itemsPerPage: rowperpage,
      pageNo: page,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(displayRoleRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Search Role--------------------------
export const searchRoleAPI = async searchString => {
  try {
    const data = {
      searchString: searchString,
    }

    const response = await axiosClient.post(searchRoleRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Display Role to rights => role--------------------------
export const displayRoleAPI = async (rowperpage, page) => {
  try {
    const data = {
      roleId: localStorage.getItem("roleId"),
      itemsPerPage: rowperpage,
      pageNo: page + 1,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(displayRoleRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Delete Role--------------------------
export const deleteRoleAPI = async roleId => {
  try {
    const data = {
      data: [roleId[0]],
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(deleteRoleRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

export const getRoleCountAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(roleCountRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update Role--------------------------
export const updateRoleAPI = async (id, name, status) => {
  try {
    const data = {
      roleId: id,
      name: name,
      status: status,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(updateRoleRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Create Rights--------------------------
export const createRightsAPI = async (name, displayName, status) => {
  try {
    const data = {
      name: name,
      displayName: displayName,
      status: status,
    }
    // Make the axiosClient request with headers
    const response = await axiosClient.post(createRightsRoute, data)

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Display Rights--------------------------
export const displayRightsAPI = async (rowperpage, page) => {
  try {
    const data = {
      roleId: localStorage.getItem("roleId"),
      itemsPerPage: rowperpage,
      pageNo: page,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(displayRightsRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Delete Rights--------------------------
export const deleteRightsAPI = async roleId => {
  try {
    const data = {
      data: [roleId[0]],
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(deleteRightsRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update RIGHTS--------------------------
export const updateRightsAPI = async (id, name, displayName, status) => {
  try {
    const data = {
      rightId: id,
      name: name,
      displayName: displayName,
      status: status,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(updateRightsRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

export const getRightsCountAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(rightsCountRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Get User Rights--------------------------
export const getRightsList = async (itemsPerPage, pageNo) => {
  try {
    const data = {
      itemsPerPage,
      pageNo,
    }
    // Make the axiosClient request with headers
    const response = await axiosClient.post(getUserRights, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get User Rights--------------------------
export const getUserCommonRightsAPI = async () => {
  try {
    const data = {
      itemsPerPage: 100,
      pageNo: 1,
    }
    // Make the axiosClient request with headers
    const response = await axiosClient.post(getUserRights, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Display Role To Rights--------------------------
export const displayRoleToRightsAPI = async roleId => {
  try {
    const data = {
      roleId: roleId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(displayRoleToRightsRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update Role To Rights--------------------------
export const updateRoleToRightsAPI = async (roleId, roleToRight) => {
  try {
    const data = {
      roleId: roleId,
      rights: roleToRight,
    }
    // Make the axiosClient request with headers
    const response = await axiosClient.post(updateRoleToRightsRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Search Staff--------------------------
export const searchStaff = async searchString => {
  try {
    const data = {
      // itemsPerPage: rowperpage,
      // pageNo: page + 1,
      searchString: searchString,
    }

    const response = await axiosClient.post(searchStaffRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

export const getStaffCountAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(staffCountRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Staff List--------------------------
export const getStaffList = async (rowperpage, page) => {
  try {
    const data = {
      itemsPerPage: rowperpage,
      pageNo: page,
    }
    const response = await axiosClient.post(StaffListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Create Staff--------------------------
export const createStaffAPI = async (
  firstName,
  lastName,
  email,
  mobile,
  image,
  gender,
  address,
  adharCard,
  username,
  password,
  userRoleId,
  status
) => {
  try {
    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mobile,
      image: image,
      gender: gender,
      address: address,
      adharCard: adharCard,
      username: username,
      password: password,
      userRoleId: userRoleId,
      userGroup: "admin_staff",
      status: status,
    }

    const response = await axiosClient.post(createStaffRoute, data)

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------UPLOAD Staff Profile Img--------------------------
export const uploadStaffImages = async (staffId, file_name) => {
  try {
    let accessToken = localStorage.getItem("accessToken")
    let secretKey = localStorage.getItem("secretKey")
    const formData = new FormData()
    formData.append("staffId", staffId)
    formData.append("file_name", file_name)

    // Make the Axios request with headers
    const response = await axios.post(
      baseURL + uploadStaffImgRoute,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-MD5": secretKey,
          "Content-Type": "multipart/form-data",
        },
      },
      timeout
    )
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  // try {
  //   const formData = new FormData()
  //   formData.append("staffId", staffId)
  //   formData.append("file_name", file_name)

  //   const response = await axiosClientFormData.post(
  //     uploadStaffImgRoute,
  //     formData
  //   )
  //   return response?.data
  // } catch (error) {
  //   console.error("Error:", error.message)
  // }
  return null
}

// -----------------Deactive Staff--------------------------
export const deactiveStaffAPI = async staffId => {
  // console.log("bg", staffId);
  try {
    const data = {
      staffId: staffId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(deactiveStaffRoute, data)
    // console.log(response);
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Active Staff--------------------------
export const activeStaffAPI = async staffId => {
  try {
    const data = {
      staffId: staffId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(ActiveStaffRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Remove Staff Profile Img--------------------------
export const removeStaffImg = async (staffId, imagePath) => {
  try {
    const data = {
      staffId: staffId,
      imagePath: imagePath,
    }

    const response = await axiosClient.post(removeStaffImgRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Staff By Id--------------------------
export const getStaffByIdAPI = async id => {
  try {
    const data = {
      staffId: id,
    }
    const response = await axiosClient.post(getStaffById, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Delete Staff--------------------------
export const deleteStaffAPI = async (staffId, status) => {
  try {
    const data = {
      staffId: staffId,
      // status: status,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(deleteStaffRoute, data)

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update Role--------------------------
export const updateStaffAPI = async (
  id,
  firstName,
  lastName,
  email,
  mobile,
  image,
  gender,
  address,
  adharCard,
  username,
  password,
  userRoleId
) => {
  try {
    const data = {
      staffId: id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mobile,
      image: image,
      gender: gender,
      address: address,
      adharCard: adharCard,
      username: username,
      password: password,
      userRoleId: userRoleId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(UpdateStaffRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Search Locker--------------------------
export const searchLocker = async searchString => {
  try {
    const data = {
      // itemsPerPage: rowperpage,
      // pageNo: page + 1,
      searchString: searchString,
    }

    const response = await axiosClient.post(searchLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Create Locker--------------------------
export const createLockerAPI = async (
  lockerName,
  lockerDesc,
  lockerDeposit,
  lockerSize,
  lockerCategory,
  status
) => {
  try {
    const data = {
      name: lockerName,
      description: lockerDesc,
      lockerSize: lockerSize,
      lockerDeposit: lockerDeposit,
      lockerCategory: lockerCategory,
      status: status,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(createLockerRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Assign Locker to Customer--------------------------
export const assignLockerToCustomerAPI = async (
  customerId,
  lockerId,
  rentId,
  key,
  number,
  additionalLockCharge,
  deposit,
  instructions,
  referenceBy,
  entryFees,
  gst,
  primaryHolder,
  holders,
  operatingMandate
) => {
  try {
    const data = {
      customerId: customerId,
      lockerId: lockerId,
      rentId: rentId,
      customerLockerKey: key,
      customerLockerNo: number,
      additionalLockCharge: additionalLockCharge,
      deposit: deposit,
      Instructions: instructions,
      referenceBy: referenceBy,
      entryFees: entryFees,
      gst: gst,
      primaryHolderId: primaryHolder,
      holders: holders,
      operatingMandate: operatingMandate,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(assignLockerToCustomer, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Locker List--------------------------
export const getLockerList = async (rowperpage, page) => {
  try {
    const data = {
      itemsPerPage: rowperpage,
      pageNo: page,
    }
    const response = await axiosClient.post(lockerListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Locker List--------------------------
export const getSearchLockerList = async searchString => {
  try {
    const data = {
      searchString: searchString,
    }
    const response = await axiosClient.post(searchLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Locker List--------------------------
export const getLockerStaticList = async (rowperpage, page) => {
  try {
    const data = {
      itemsPerPage: 50,
      pageNo: 1,
    }
    const response = await axiosClient.post(lockerListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Deactive Locker--------------------------
export const deactiveLockerAPI = async lockerId => {
  try {
    const data = {
      lockerId: lockerId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(DeactiveLockerRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Active Locker--------------------------
export const activeLockerAPI = async lockerId => {
  try {
    const data = {
      lockerId: lockerId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(ActiveLockerRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update Locker--------------------------
export const updateLockerAPI = async (
  lockerId,
  lockerName,
  lockerDesc,
  lockerDeposit,
  lockerSize,
  lockerCategory,
  status
) => {
  try {
    const data = {
      lockerId: lockerId,
      name: lockerName,
      description: lockerDesc,
      // lockerDeposit: lockerDeposit,
      lockerSize: lockerSize,
      lockerCategory: lockerCategory,
      status: status,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(updateLockerRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

export const getCustomerCountAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(customerCountRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Locker By Id--------------------------
export const getLockerByIdAPI = async lockerId => {
  try {
    const data = {
      lockerId: lockerId,
    }
    const response = await axiosClient.post(lockerByIdRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Search Customer--------------------------
export const searchCustomer = async searchString => {
  try {
    const data = {
      // itemsPerPage: rowperpage,
      // pageNo: page + 1,
      searchString: searchString,
    }

    const response = await axiosClient.post(searchCustomerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------UPLOAD Nominee Profile Img--------------------------
export const uploadNomineeImages = async file_name => {
  try {
    const formData = new FormData()
    formData.append("file_name", file_name)

    const response = await axiosClientFormData.post(UploadImageRoute, formData)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Create Customer--------------------------
export const createCustomerAPI = async (
  fullName,
  occupation,
  email,
  mobile,
  telephone
) => {
  try {
    const data = {
      fullName: fullName,
      occupation: occupation,
      email: email,
      mobile: mobile,
      telephone: telephone,
    }
    const response = await axiosClient.post(createCustomerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Assigned Locker Deactive--------------------------
export const assignLockerDeactiveAPI = async (customerLockerId, reason) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      reason: reason,
    }
    const response = await axiosClient.post(occupiedLockerDeactivateRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Create Customer--------------------------
export const getLockerOccupiedList = async () => {
  try {
    const data = []

    const response = await axiosClient.post(occupiedLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update Customer Nominee--------------------------
export const updateCustomerNomineeAPI = async (
  nomineeId,
  fullName,
  occupation,
  relationWithCustomer,
  mobile,
  photo,
  kycDoc,
  fullAddress,
  addressType
) => {
  try {
    const data = {
      nomineeId: nomineeId,
      fullName: fullName,
      occupation: occupation,
      relationWithCustomer: relationWithCustomer,
      mobile: mobile,
      photo: photo,
      kycDoc: kycDoc,
      fullAddress: fullAddress,
      addressType: addressType,
    }
    const response = await axiosClient.post(updateCustomerNomineeRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Update CUSTOMER KYC Status--------------------------
export const updateCustomerKYCStatusById = async (customerId, kycStatus) => {
  try {
    const data = {
      customerId: customerId,
      kycStatus: kycStatus,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(UpdateCustomerKycStatusRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Customer By ID--------------------------
export const getCustomerByIdAPI = async custID => {
  try {
    const data = {
      customerId: custID,
    }
    const response = await axiosClient.post(getCustomerByIdRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Add Customer Address--------------------------
export const addCustomerAddressAPI = async (
  custID,
  addressType,
  fullName,
  mobile,
  fullAddress
) => {
  try {
    const data = {
      customerId: custID,
      addressType: addressType,
      personName: fullName,
      mobile: mobile,
      fullAddress: fullAddress,
    }
    const response = await axiosClient.post(addCustomerAddressRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Update Customer Address--------------------------
export const updateCustomerAddressAPI = async (
  addressId,
  addressType,
  fullName,
  mobile,
  fullAddress
) => {
  try {
    const data = {
      addressId: addressId,
      addressType: addressType,
      personName: fullName,
      mobile: mobile,
      fullAddress: fullAddress,
    }
    const response = await axiosClient.post(updateCustomerAddressRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------GET Customer Address--------------------------
export const getCustomerAddressAPI = async custID => {
  try {
    const data = {
      customerId: custID,
    }
    const response = await axiosClient.post(getCustomerAddressRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------DELETE Customer Address--------------------------
export const deleteCustomerAddressAPI = async addId => {
  try {
    const data = {
      addressId: addId,
    }
    const response = await axiosClient.post(deleteCustomerAddressRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Add Customer Nominee--------------------------
export const addCustomerNomineeAPI = async (
  custID,
  fullName,
  occupation,
  relationWithCustomer,
  mobile,
  fullAddress,
  addressType
) => {
  try {
    const data = {
      customerId: custID,
      fullName: fullName,
      occupation: occupation,
      relationWithCustomer: relationWithCustomer,
      mobile: mobile,
      fullAddress: fullAddress,
      addressType: addressType,
    }
    const response = await axiosClient.post(addCustomerNomineeRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------ADD CUSTOMER KYC--------------------------
export const addCustomerKycAPI = async (
  customerId,
  file_name,
  documentType,
  docDetail
) => {
  try {
    let accessToken = localStorage.getItem("accessToken")
    let secretKey = localStorage.getItem("secretKey")
    const formData = new FormData()
    formData.append("customerId", customerId)
    formData.append("file_name", file_name)
    formData.append("documentType", documentType)
    formData.append("docDetail", docDetail)

    // Make the Axios request with headers
    const response = await axios.post(
      baseURL + addCustKycRoute,
      // baseURL + "Customer/addCustomerKYCDocument",
      formData,
      {
        headers: {
          Authorization: ` Bearer ${accessToken}`,
          "Content-MD5": secretKey,
          "Content-Type": "multipart/form-data",
        },
      },
      timeout
    )
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  // try {
  //   const formData = new FormData()
  //   formData.append("customerId", customerId)
  //   formData.append("file_name", file_name)
  //   formData.append("documentType", documentType)
  //   formData.append("docDetail", docDetail)

  //   const response = await axiosClientFormData.post(addCustKycRoute, formData)
  //   return response?.data
  // } catch (error) {
  //   console.error("Error:", error.message)
  // }
  return null
}
// ------------------GET CUSTOMER KYC--------------------------
export const getCustomerKycAPI = async customerId => {
  try {
    const data = {
      customerId: customerId,
    }
    const response = await axiosClient.post(getCustomerKycRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------GET CUSTOMER KYC--------------------------
export const deleteCustomerKycAPI = async customerId => {
  try {
    const data = {
      documentId: customerId,
    }
    const response = await axiosClient.post(deleteCustomerKycRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
export const updateCustomerKycAPI = async (
  documentId,
  file_name,
  documentType,
  docDetail
) => {
  try {
    const formData = new FormData()
    formData.append("documentId", documentId)
    formData.append("file_name", file_name)
    formData.append("documentType", documentType)
    formData.append("docDetail", docDetail)

    const response = await axiosClient.post(updateCustKycRoute, formData)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Update Customer--------------------------
export const updateCustomerAPI = async (
  custID,
  fullName,
  occupation,
  email,
  mobile,
  telephone
) => {
  try {
    const data = {
      customerId: custID,
      fullName: fullName,
      occupation: occupation,
      email: email,
      mobile: mobile,
      telephone: telephone,
    }
    const response = await axiosClient.post(updateCustomerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Customer List--------------------------
export const getCustomerList = async (rowperpage, page) => {
  try {
    const data = {
      itemsPerPage: rowperpage,
      pageNo: page,
    }
    const response = await axiosClient.post(CustomerListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Customer List--------------------------
export const getSearchCustomerList = async searchString => {
  try {
    const data = {
      searchString: searchString,
    }
    const response = await axiosClient.post(searchCustomerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Customer List--------------------------
export const getCustomerStaticList = async (rowperpage, page) => {
  try {
    const data = {
      itemsPerPage: 100,
      pageNo: 1,
    }
    const response = await axiosClient.post(CustomerListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Get Customer Expiring Locker List--------------------------
export const getCustomerLockerList = async expiryingDay => {
  try {
    const data = {
      expiryingDay: expiryingDay,
    }
    const response = await axiosClient.post(getExpiringLockersRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Deactive CUSTOMER--------------------------
export const deactiveCustomerAPI = async customerId => {
  try {
    const data = {
      customerId: customerId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(DeactiveCustomerRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Active CUSTOMER--------------------------
export const activeCustomerAPI = async customerId => {
  try {
    const data = {
      customerId: customerId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(ActiveCustomerRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------UPLOAD Customer Photo--------------------------
export const uploadCustomerImg = async (customerId, file_name) => {
  try {
    let accessToken = localStorage.getItem("accessToken")
    let secretKey = localStorage.getItem("secretKey")
    const formData = new FormData()
    formData.append("customerId", customerId)
    formData.append("file_name", file_name)

    // Make the Axios request with headers
    const response = await axios.post(
      baseURL + uploadCustomerImage,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-MD5": secretKey,
          "Content-Type": "multipart/form-data",
        },
      },
      timeout
    )
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  // try {
  //   const formData = new FormData()
  //   formData.append("customerId", customerId)
  //   formData.append("file_name", file_name);

  //   const response = await axiosClientFormData.post(
  //     uploadCustomerImage,
  //     formData
  //   )

  //   return response?.data
  // } catch (error) {
  //   console.error("Error:", error.message)
  // }
  return null
}

// ------------------Remove Customer Photo--------------------------
export const removeCustomerImg = async (customerId, imagePath) => {
  try {
    const data = {
      customerId: customerId,
      imagePath: imagePath,
    }

    const response = await axiosClient.post(removeCustomerImage, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Search Rent--------------------------
export const searchRent = async searchString => {
  try {
    const data = {
      // itemsPerPage: rowperpage,
      // pageNo: page,
      searchString: searchString,
    }

    const response = await axiosClient.post(searchRentRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Create Rent--------------------------
export const createRentAPI = async (
  lockerId,
  name,
  description,
  rentPrice,
  minimumCharge,
  minimumDurationDay,
  // status
) => {
  try {
    const data = {
      lockerId: lockerId,
      name: name,
      description: description,
      rentPrice: rentPrice,
      minimumCharge: minimumCharge,
      minimumDurationDay: minimumDurationDay,
      // status: status,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(CreateRentRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Rent List--------------------------
export const getRentList = async (pageNo, itemsPerPage) => {
  try {
    const data = {
      itemsPerPage,
      pageNo,
    }
    const response = await axiosClient.post(RentListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Rent List--------------------------
export const getRentCountAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(rentCountRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Rent List--------------------------
export const getRentStaticList = async (page, rowperpage) => {
  try {
    const data = {
      itemsPerPage: 100,
      pageNo: 1,
    }
    const response = await axiosClient.post(RentListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Rent By Id--------------------------
export const getRentByIdAPI = async id => {
  try {
    const data = {
      rentId: id,
    }
    const response = await axiosClient.post(getRentById, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Update Rent By Id--------------------------
export const updateRentByIdAPI = async (
  rentId,
  lockerId,
  name,
  description,
  rentPrice,
  minimumCharge,
  minimumDurationDay,
  // status
) => {
  try {
    const data = {
      rentId: rentId,
      lockerId: lockerId,
      name: name,
      description: description,
      rentPrice: rentPrice,
      minimumCharge: minimumCharge,
      minimumDurationDay: minimumDurationDay,
      // status: status,
    }
    const response = await axiosClient.post(UpdateRentRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Deactive RENT--------------------------
export const deactiveRentAPI = async rentId => {
  try {
    const data = {
      rentId: rentId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(DeactiveRentRoute, data)

    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}

// -----------------Active RENT--------------------------
export const activeRentAPI = async rentId => {
  try {
    const data = {
      rentId: rentId,
    }

    // Make the axiosClient request with headers
    const response = await axiosClient.post(ActiveRentRoute, data)
    return response?.data
  } catch (error) {
    // Handle error
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Search Transaction--------------------------
export const searchTransaction = async searchString => {
  try {
    const data = {
      // itemsPerPage: rowperpage,
      // pageNo: page + 1,
      searchString: searchString,
    }

    const response = await axiosClient.post(searchTransactionRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Create Transaction--------------------------
export const createTransaction = async (
  customerId,
  lockerId,
  rentId,
  transactionType,
  transactionAmount,
  transactionFor,
  transactionDetails
) => {
  try {
    const data = {
      customerId: customerId,
      lockerId: lockerId,
      rentId: rentId,
      transactionType: transactionType,
      transactionAmount: transactionAmount,
      transactionFor: transactionFor,
      transactionDetails: transactionDetails,
    }

    const response = await axiosClient.post(createTransactionRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
export const getTransactionCountAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(transactionCountRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
export const getTransactionList = async (rowperpage, page) => {
  try {
    const data = {
      itemsPerPage: rowperpage,
      pageNo: page,
    }
    const response = await axiosClient.post(TransactionListRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

export const getTransactionById = async transactionId => {
  try {
    const data = {
      transactionId: transactionId,
    }
    const response = await axiosClient.post(getTransactionByIdRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

export const getOccupiedLocker = async (searchString) => {
  try {
    const data = {
      "searchString": searchString
    }
    const response = await axiosClient.post(getOccupiedLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

export const searchCustomerLockerByLockerNo = async (searchString) => {
  try {
    const data = { searchString };
    const response = await axiosClient.post(searchCustomerLockersByLockerNo, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

export const getLockerCountAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(lockerCountRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ---------------Get Occupied Locker ById-------------------
export const getOccupiedLockerByIdAPI = async customerLockerId => {
  try {
    const data = {
      customerLockerId: customerLockerId,
    }

    const response = await axiosClient.post(getOccupiedLockerByIdRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Change Locker PLan--------------------------
export const changeLockerPlanAPI = async (
  customerLockerId,
  rentId,
  customerLockerKey,
  customerLockerNo,
  planStartDate,
  // entryFees,
  penalty,
  gst
) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      rentId: rentId,
      customerLockerKey: customerLockerKey,
      customerLockerNo: customerLockerNo,
      planStartDate: planStartDate,
      // entryFees: entryFees,
      penalty: penalty,
      gst: gst
    }
    const response = await axiosClient.post(changeLockerPlanRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Renew Locker--------------------------
export const addRenewLockerAPI = async (
  customerLockerId,
  rentId,
  planStartDate,
  penalty,
  gst
) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      rentId: rentId,
      planStartDate: planStartDate,
      penalty: penalty,
      gst: gst
    }
    const response = await axiosClient.post(renewLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------ Update KYC status -----------
export const getKYCByIdAPI = async customerId => {
  try {
    const data = {
      customerId: customerId,
    }
    const response = await axiosClient.post(kycByIdRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Assigned Locker Active--------------------------
export const assignLockerActiveAPI = async (customerLockerId, reason) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      reason: reason,
    }
    const response = await axiosClient.post(occupiedLockerActivateRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Assigned Locker Remove--------------------------
export const assignLockerRemoveAPI = async (customerLockerId, reason, penalty) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      reason: reason,
      penalty: penalty
    }
    const response = await axiosClient.post(occupiedLockerRemoveRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Assigned Locker Break--------------------------
export const assignLockerBreakAPI = async (customerLockerId, reason, breakCharge) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      reason: reason,
      breakCharge: breakCharge
    }
    const response = await axiosClient.post(occupiedLockerBreakRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Add Customer SubHolder Address--------------------------
export const addCustomerSubHolderAPI = async (primaryHolderId, holders) => {
  try {
    const data = {
      primaryHolderId: primaryHolderId,
      holders: holders,
    }
    const response = await axiosClient.post(addSubHolderRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Add Customer SubHolder Address--------------------------
export const accessCustomerLockerAPI = async (customerLockerId, customerId, accessCharge) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      customerId: customerId,
      accessCharge: accessCharge
    }
    const response = await axiosClient.post(accessCustomerLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Get Customer SubHolder Address--------------------------
export const getCustomerSubHolderAPI = async primaryHolderId => {
  try {
    const data = {
      primaryHolderId: primaryHolderId,
    }
    const response = await axiosClient.post(getSubHolderRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Locker History--------------------------
export const getLockerHistoryAPI = async customerLockerNo => {
  try {
    const data = {
      customerLockerNo: customerLockerNo,
    }
    const response = await axiosClient.post(getLockerHistoryRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Customer Report--------------------------
export const getCustomerReportAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(getCustomerReportRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Transaction Report--------------------------
export const getTransactionReportAPI = async (startDate, endDate) => {
  try {
    const data = {
      startDate: startDate,
      endDate: endDate,
    }
    const response = await axiosClient.post(getTransactionReportRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get Transaction Report--------------------------
export const getLockerReportAPI = async (startDate, endDate) => {
  try {
    const data = {
      startDate: startDate,
      endDate: endDate,
    }
    const response = await axiosClient.post(getLockerReportRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get UnOperated Lockers Report--------------------------
export const getUnOperatedLockerReportAPI = async () => {
  try {
    const data = []
    const response = await axiosClient.post(
      getUnOperatedLockerReportRoute,
      data
    )
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get UnOperated Lockers Report--------------------------
export const registerCustomerFingerPrintData = async (data) => {
  try {
    const response = await axiosClient.post(
      registerCustomerFingerPrint,
      data
    )
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------Get UnOperated Lockers Report--------------------------
export const fetchCustomerFingerPrintData = async (data) => {
  try {
    const response = await axiosClient.post(
      fetchCustomerFingerPrint,
      data
    );
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ------------------list Customer Nominee--------------------------
export const listCustomerNomineeAPI = async (customerId) => {
  try {
    const data = {
      customerId: customerId
    }
    const response = await axiosClient.post(listCustomerNomineeRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------get Customer Nominee--------------------------
export const deleteCustomerNomineeAPI = async (nomineeId) => {
  try {
    const data = {
      nomineeId: nomineeId
    }
    const response = await axiosClient.post(deleteCustomerNomineeRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}


// ---------------Get Locker Transaction By customerLockerId-------------------
export const getLockerTransactionByIdAPI = async customerLockerId => {
  try {
    const data = {
      customerLockerId: customerLockerId,
    }

    const response = await axiosClient.post(getLockerTransactionByIdRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}


// ---------------Get Locker Transaction By customerLockerId-------------------
export const addAdditionalLockOnLockerAPI = async (customerLockerId, additionalLockCharge) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      additionalLockCharge: additionalLockCharge
    }

    const response = await axiosClient.post(addAdditionalLockOnLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ---------------Manual Locker Access-------------------
export const manualLockerAccessAPI = async (customerLockerId, customerId, accessCharge, remarks) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      customerId: customerId,
      accessCharge: accessCharge,
      remarks: remarks
    }

    const response = await axiosClient.post(manualAccessLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

// ---------------Close Customer Locker With Penalty-------------------
export const closeCustLockerAPI = async (customerLockerId, reason, penalty) => {
  try {
    const data = {
      customerLockerId: customerLockerId,
      reason: reason,
      penalty: penalty,
    }

    const response = await axiosClient.post(closeCustLockerRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}


// ---------------Close Customer Locker With Penalty-------------------
export const updateLockerTransactionByIdAPI = async (transactionDBId, modeOfPayment, paymentDescription) => {
  try {
    const data = {
      transactionDBId: transactionDBId,
      modeOfPayment: modeOfPayment,
      paymentDescription: paymentDescription
    }

    const response = await axiosClient.post(updateLockerTransactionByIdRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}


// ------------------Send Message to Customer--------------------------
export const sendMessageAPI = async (mobiles, message) => {
  try {
    const data = {
      mobiles: mobiles,
      message: message,
    }
    const response = await axiosClient.post(sendMessageRoute, data)
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}
// ------------------Send Message to Customer--------------------------
export const registerInvitedCustAPI = async (fullName, occupation, email, mobile, telephone, address, nominee, holders, kyc) => {
  try {
    const data = {
      fullName: fullName,
      occupation: occupation,
      email: email,
      mobile: mobile,
      telephone: telephone,
      address: address,
      nominee: nominee,
      holders: holders,
      kyc: kyc
    }
    const response = await axiosClient.post(registerInvitedCustRoute, data)
    // console.log(response.message);
    return response?.data
  } catch (error) {
    console.error("Error:", error.message)
  }
  return null
}

