import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import {
  activeLockerAPI,
  deactiveLockerAPI,
  getLockerCountAPI,
  getLockerList,
  searchLocker,
} from "service/service"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
// import $ from "jquery"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"

import { Card, CardBody, Input } from "reactstrap"

const Lockerlist = props => {
  const [isRefresh, setIsRefresh] = useState(false)
  const history = useNavigate()
  const [locker, setLocker] = useState([]);
  const userRights = useSelector(state => state.Profile.userRights)

  const [count, setLockerCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);

  document.title = "Locker Category List | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Locker Category List", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Locker Category List", breadcrumbItems)
  })

  const handlechangepage = (event, newpage) => {
    // console.log("newpage", newpage);
    setPageNo(newpage + 1);
  }

  const handleRowsPerPage = (event) => {
    setPerPage(+event.target.value)
    setPageNo(1);
  }

  const handleDisplaylockerList = async () => {
    try {
      const res = await getLockerList(perPage, pageNo)
      setLocker(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleLockerCount = async () => {
    try {
      const res = await getLockerCountAPI();
      // console.log("res", res);
      if (res.status === '1' || res.status === 1) {
        setLockerCount(res.data);
      }
    }
    catch (error) {
      throw (error)
    }
  };

  const handleDeactiveLocker = async i => {
    try {
      // const deactiveid = locker[i].id
      const res = await deactiveLockerAPI(i)
      setIsRefresh(!isRefresh)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleActiveLocker = async i => {
    try {
      // const activeid = locker[i].id
      const res = await activeLockerAPI(i)
      setIsRefresh(!isRefresh)
      if (res.status === "1" || res.status === 1) {
        Swal.fire({
          title: res.message,
          icon: "success",
          timer: 1500
        })
      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateLocker = async i => {
    try {
      history(`/updateLocker/${i}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewLocker = async i => {
    try {
      history(`/viewLocker/${i}`)
    } catch (error) {
      console.log(error)
    }
  }
  const handleSearch = async e => {
    let value = e.target.value
    const res = await searchLocker(value)
    setLocker(res.data)
  }

  useEffect(() => {
    async function getCounts() {
      await handleLockerCount();
    }
    getCounts();
  }, [])

  useEffect(() => {
    handleDisplaylockerList();
    //eslint-disable-next-line
  }, [isRefresh, perPage, pageNo])
  return (
    <>
      <div className="d-flex justify-content-end position-relative">
        <div className="input-group w-25 searchBox">
          <span
            className="fa fa-search mb-2 input-group-text"
            id="basic-addon1"
            style={{ height: "auto" }}
          ></span>
          <Input
            type="search"
            className="mb-2 d-flex form-control"
            onChange={handleSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(77 80 86)" }}>
                <TableRow >
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    Locker Category
                  </TableCell>
                  {/* <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Description
                  </TableCell> */}
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    Locker Size
                  </TableCell>
                  {/* <TableCell
                    className="fs-5 fw-bold"
                    style={{ color: "white" }}
                  >
                    Locker Category
                  </TableCell> */}
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    Locker Deposit (Refundable)
                  </TableCell>
                  <TableCell
                    className="fs-5 fw-bold w-25"
                    style={{ color: "white" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locker &&
                  locker.map((row, index) => (
                    <TableRow key={row._id} className="tablerow">
                      <TableCell onClick={() => handleViewLocker(row._id)}>
                        {row.name}
                      </TableCell>
                      {/* <TableCell onClick={() => handleViewLocker(row._id)}>
                        {row.description}
                      </TableCell> */}
                      <TableCell onClick={() => handleViewLocker(row._id)}>
                        {/* {row.lockerSize} */}
                        {row.lockerCategory}
                      </TableCell>
                      {/* <TableCell onClick={() => handleViewLocker(row._id)}>
                        {row.lockerCategory}
                      </TableCell> */}
                      <TableCell onClick={() => handleViewLocker(row._id)}>
                        {row.lockerDeposit}
                      </TableCell>
                      <TableCell >
                        <div style={{ display: "flex", gap: "5px" }}>
                          {userRights?.locker?.write && (
                            <div
                              className="edit-btn"
                              onClick={() => handleUpdateLocker(row._id)}
                            >
                              Edit
                            </div>
                          )}
                          {userRights?.locker?.write && row.status ? (
                            <div
                              className="active-btn"
                              onClick={() => handleDeactiveLocker(row._id)}
                            >
                              Active
                            </div>
                          ) : (
                            <div
                              className="deactive-btn"
                              onClick={() => handleActiveLocker(row._id)}
                            >
                              Inactive
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              rowsPerPage={perPage}
              page={pageNo - 1}
              count={count}
              component="div"
              onPageChange={handlechangepage}
              onRowsPerPageChange={handleRowsPerPage}
            >
            </TablePagination>
          </TableContainer>


        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Lockerlist)
