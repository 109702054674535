import axios from "axios"

let isLoggedIn = localStorage.getItem("isLoggedIn")
let loginUserId = localStorage.getItem("loginUserId")
let accessToken = localStorage.getItem("accessToken")
let refreshToken = localStorage.getItem("refreshToken")
let username = localStorage.getItem("username")
let expiryTime = localStorage.getItem("expiryTime")
let secretKey = localStorage.getItem("secretKey")

const serverURL = "https://shreeshdesignstudiollp.com:3001/"
// const serverURL = "http://localhost:3001/"
const baseURL = serverURL + "Development/"

const bioMetricApiKey = "U8OTw4HCu3MKwoPDimPCgsK7KkJywpPCssKpSwpbe8KhS3LCqVvCscKRwoPCqcKiwpHCmlrCm1s7w4oTczrDksKiCgt6wqI7SsKZejvDgcK6KsK5eWJawqN6esKBwqFyw5PCi3vCgcKjwpLCg8KRwqNrIsKZW8KZwoPCqsKjEsKqQwvDk8OJwpnCgWrCscKrMsK5O8OSworCm8OBc2p6wqJTwpLDkhJSwqrCgnt6UsKiwqnCo8KDU8KDwrNbwrF7woljUkvCs8KLw4kzQsOSecKRKsKhwooiM8KLe8ODWxtjU8KaMkrCijvCuVkqwqMrwpIjYnnCq8KrSsKJwqsregobecKRQ2rCmSpKwpMawrnCgwoiw5LCkTvCqXoiw4sbakMyMzLDicKZwpFbegvCsULCunJywpJzKiJDc8KCGmILWWLDi8KJwpI7wptywrHCsiLCgSLCusKzw4ILwpHCscK7wrnCsTPDijI7w4LCucKLWWPCsWrCmypaQlJrQ8ODCzrCinrCiyLCoipzwrtaw4NTe8KZw5JLaxrCucKrwrIKI8KrwrPCgsOKwpLCqVPCq8OSw4vCoSvCmcODwokTU8KCw4HCsUtrGsKra8OSG0pqelMSwqnDgSPCkXpTS8KJwpILakLCscKyUxLDgnl7woPCusOSwrkSOmvDksKzwpnCiSpKazJLwrN7E1rDg8Kywrpaw4vDgWrCgzvCgsKDw5JTSsOSwoMyM8OJw4oKGjrCqsKzKyMSeyPCqsKBw4HDk8Kyw4pqwqvCg8KJwrsie8OTwrPDiUIjwqrCgltaa8K6w4nCo8KJwpnCosKyw4kTwrLCkQrCm8OBYsOKG8KDWxsbK1I6w4nDgTtSw4LCuWLCscKDw5JLO8ODw4pZwosKwoHDgXtLw4pzwovCkyPCihvDisKRMnIyYzPDk0NTwpvDinPCs8KqwoHDksOJwqEKOivDkwtCwrLCmXs6wrN7wqISwpHCqSrCq8KKw5JLC8KiwpHCsxMqwpnDi8OBMnJKw5JzwoEyC8KTe8KhSsKiw5LCs8OJOlLDkwsqUjvCssKBwrI7YwsLasKLwrnDi8OLWnnDi8KbK8Kxw4LCg8KBK0tSQ8OLwpMzwoLDknt7a2saW3nCoSPCmgt7c8KCw5I7ahoqSmPCk8KzwoNzwoJKecKpwrlrIkJ5e0J7w4Eyw4rCgsKJGsODwrLDicK5OsK6WzJqwoEbY8OKW8KZIsOBCmrCssKDwqrDicKrK8Kpw5NCIsKRwotzczJyYsK7woLCkxPCg8KZwoHCi1vDkzvDqcOpwoPDgjtbwqPCssKSWsKbwpMTwroqwoPDicKranvCisOKwprCisOpw6ljBUFBWWs=";

const timeout = 999999
const headers = {
  Authorization: `Bearer ${accessToken}`,
  "Content-MD5": secretKey,
  "Content-Type": "application/json",
}

const formDataHeaders = {
  Authorization: `Bearer ${accessToken}`,
  "Content-MD5": secretKey,
  "Content-Type": "multipart/form-data",
}

const axiosClient = axios.create({
  baseURL,
  headers,
  timeout,
})

const axiosClientFormData = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    "Content-MD5": secretKey,
    "Content-Type": "multipart/form-data",
  },
  timeout,
})

const axiosImageViewerClient = axios.create({ baseURL: serverURL })

const companyId = "61b026f3385d3f22bfeef327"

const gst = 18

const generateImagePath = path => {
  // console.log("path", path)

  if (path && (path.includes("http") || path.includes("https"))) {
    return path
  }
  if (
    [
      "Customer",
      "Staff",
      "User",
      "Locker",
      "Rent",
      "Admin",
      "Company",
    ].includes(path?.split("/")[0])
  ) {
    return `${serverURL}${path}`
  }
  return path
}

let dt = Date.now()
let tokenDT = new Date(expiryTime).getTime()
let expiredInMin = (tokenDT - dt) / 1000 / 60
// console.log("expiredInMin", expiredInMin)

const refreshOldToken = async () => {
  refreshToken = localStorage.getItem("refreshToken")
  username = localStorage.getItem("username")
  const payload = {
    username,
    refreshToken,
  }
  // console.log("payload", payload)
  try {
    const result = await axiosClient.post(
      "Admin/getAccessTokenByRefreshToken",
      payload
    )
    // console.log("result.data", result?.data)

    if (result && result.data) {
      const response = result.data
      if (response.status === "1" || response.status === 1) {
        const data = response.data
        // console.log("updated token details", data)
        // Update the Authorization header with the new token
        localStorage.setItem("isLoggedIn", true)
        localStorage.setItem("accessToken", data.accessToken)
        localStorage.setItem("refreshToken", data.refreshToken)
        localStorage.setItem("expiryTime", data.expiryTime)
        localStorage.setItem("secretKey", data.secretKey)
      }
    }
  } catch (error) {
    // Handle token refresh failure (e.g., redirect to login)
    console.error("Token refresh failed: error", error)
    // Redirect to login or handle in a way that makes sense for your application
  }
}

const isTokenExpiring = async () => {
  try {
    expiryTime = localStorage.getItem("expiryTime")
    dt = Date.now()
    tokenDT = new Date(expiryTime).getTime()
    expiredInMin = (tokenDT - dt) / 1000 / 60
    // console.log("expiredInMin", expiredInMin)
    if (expiredInMin < 5) {
      await refreshOldToken()
    }
  } catch (error) {
    // Handle token refresh failure (e.g., redirect to login)
    console.error("Token refresh failed: error", error)
  }
}

axiosClient.interceptors.request.use(
  async config => {
    accessToken = localStorage.getItem("accessToken")
    secretKey = localStorage.getItem("secretKey")
    if (accessToken && secretKey) {
      config.headers.Authorization = `Bearer ${accessToken}`
      config.headers["Content-MD5"] = secretKey
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


const interval = 60000 // 1 minute = 60 seconds = 60000 Milli Seconds
setInterval(async () => {
  await isTokenExpiring()
}, interval)

export {
  axiosClient,
  axiosImageViewerClient,
  axiosClientFormData,
  generateImagePath,
  companyId,
  gst,
  bioMetricApiKey
}
