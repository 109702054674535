import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  addCustomerNomineeAPI,
  deleteCustomerNomineeAPI,
  listCustomerNomineeAPI,
  updateCustomerNomineeAPI,
  uploadNomineeImages,
} from "service/service"
import Swal from "sweetalert2"
import user1 from "../../assets/images/users/user-1.jpg"
import dummy from "../../assets/images/users/dummy.jpg"
import { useNavigate } from "react-router-dom"
import ImageViewer from "ImageViewer"

const CustomerNominee = ({ onButtonClick }) => {

  const re = /^[A-Za-z\s]+$/;
  let isDisplay = JSON.parse(localStorage.getItem("customerS3"))
  const customerID = localStorage.getItem('customerID')

  const [fullName, setFullName] = useState("")
  const [occupation, setOccupation] = useState("")
  const [mobile, setMobile] = useState("")
  const [relationWithCustomer, setRelationWithCustomer] = useState("")
  const [addressType, setAddressType] = useState("1")
  const [fullAddress, setFullAddress] = useState("")
  const [custID, setCustID] = useState("")
  const [nominee, setNominee] = useState([])
  const [isRefresh, setIsRefresh] = useState(false)
  const [oldNominee, setOldNominee] = useState(localStorage.getItem("nomineeId"))


  const history = useNavigate()
  // const [nomineeId, setNomineeId] = useState("")


  const value = {
    fullName,
    occupation,
    relationWithCustomer,
    mobile,
    fullAddress,
    addressType,
  }

  const handleSetFild = (id) => {
    if (id) {
      nominee && nominee.length > 0 && nominee.map((item, i) => {
        if (item.id === id) {
          setFullName(item?.fullName)
          setOccupation(item?.occupation)
          setMobile(item?.mobile)
          setRelationWithCustomer(item?.relationWithCustomer)
          setAddressType(item?.addressType)
          setFullAddress(item?.fullAddress)
          setNomineePhoto(item?.photo)
          setNomineeKyc(item?.kycDoc)
          setOldNominee(true)
        }
      })
    }
  }

  const handleFullName = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setFullName(value);
    }
    // setFullName(e.target.value)
  }

  const handleOccupation = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setOccupation(value);
    }
  }

  const handleMobileNo = e => {
    const limit = 10
    setMobile(e.target.value.slice(0, limit))
  }

  const handlerelationWithCustomer = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setRelationWithCustomer(value);
    }
  }

  const handleFullAddress = e => {
    setFullAddress(e.target.value)
  }

  const handleAddressType = e => {
    setAddressType(e.target.value)
  }

  const handleGetNominee = async () => {
    const data = await listCustomerNomineeAPI(customerID)
    setNominee(data.data)
  }
  const handleUpdateNominee = (id) => {
    handleSetFild(id)
    setOldNominee(id)
  }
  const handleDeleteNominee = async (id) => {
    deleteCustomerNomineeAPI(id)
    Swal.fire({
      title: "Customer nominee deleted successfully",
      icon: "success",
      timer: 1500
    })
    setIsRefresh(!isRefresh)
    await handleGetNominee()
  }

  const handleClear = () => {
    setFullName('')
    setOccupation('')
    setMobile('')
    setRelationWithCustomer('')
    setFullAddress('')
    setNomineePhoto(null)
    setNomineeKyc(null)
    setOldNominee(undefined)
  }
  const handleAddNew = (e) => {
    e.preventDefault()
    handleClear()
    setOldNominee(undefined)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (custID) {
      // const nominee_kyc = localStorage.getItem("nominee_kyc")
      // const nominee_Photo = localStorage.getItem("nominee_Photo")
      if (oldNominee && oldNominee !== "undefined") {
        try {
          const res = await updateCustomerNomineeAPI(
            oldNominee,
            fullName,
            occupation,
            relationWithCustomer,
            mobile,
            nomineePhoto,
            nomineeKyc,
            // nominee_Photo,
            // nominee_kyc,
            fullAddress,
            addressType,
          )

          if (res.status === "1" || res.status === 1) {
            Swal.fire({
              title: res.message,
              icon: "success",
              timer: 1500
            })
            handleSetFild()
            handleClear()
            setIsRefresh(!isRefresh)
            localStorage.setItem("customerS3", true)
          } else {
            Swal.fire({
              title: res.message,
              icon: "error",
            })
          }
        } catch (error) {
          throw error
        }
      } else {
        try {
          const res = await addCustomerNomineeAPI(
            custID,
            fullName,
            occupation,
            relationWithCustomer,
            mobile,
            fullAddress,
            addressType
          )

          if (res.status === "1" || res.status === 1) {
            Swal.fire({
              title: res.message,
              icon: "success",
              timer: 1500
            })
            await handleGetNominee()
            handleSetFild()
            handleClear()
            localStorage.setItem("customerS3", true)
          } else {
            Swal.fire({
              title: res.message,
              icon: "error",
            })
          }
        } catch (error) {
          throw error
        }
      }
    }
  }

  const [filepath, setFilepath] = useState()
  const [nomineePhoto, setNomineePhoto] = useState("")
  const uploadNomineeImage = async e => {
    e.preventDefault()
    setFilepath(URL.createObjectURL(e.target.files[0]))
    const file_name = e.target.files[0]

    const res = await uploadNomineeImages(file_name)
    setNomineePhoto(res.data)
    localStorage.setItem("nominee_Photo", res.data)

    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
  }

  const [file, setFile] = useState()
  const [nomineeKyc, setNomineeKyc] = useState("")
  // console.log(nomineeKyc);
  const uploadNomineeKyc = async e => {
    e.preventDefault()
    setFile(URL.createObjectURL(e.target.files[0]))
    const file_name = e.target.files[0]

    const res = await uploadNomineeImages(file_name)
    setNomineeKyc(res.data)
    localStorage.setItem("nominee_kyc", res.data)

    if (res.status === "1" || res.status === 1) {
      Swal.fire({
        title: res.message,
        icon: "success",
        timer: 1500
      })
    } else {
      Swal.fire({
        title: res.message,
        icon: "error",
      })
    }
  }

  const updateNominee = async (e, id) => {
    e.preventDefault()
    if (custID) {
      // const nominee_kyc = localStorage.getItem("nominee_kyc")
      // const nominee_Photo = localStorage.getItem("nominee_Photo")
      if (oldNominee && oldNominee !== "undefined") {
        try {
          const res = await updateCustomerNomineeAPI(
            oldNominee,
            fullName,
            occupation,
            relationWithCustomer,
            mobile,
            nomineePhoto,
            nomineeKyc,
            // nominee_Photo,
            // nominee_kyc,
            fullAddress,
            addressType,
          )

          if (res.status === "1" || res.status === 1) {
            Swal.fire({
              title: res.message,
              icon: "success",
              timer: 1500
            })
            // handleSetFild()
            setFilepath("")
            setFile("")
            handleClear()
            setIsRefresh(!isRefresh)
            // localStorage.setItem("customerS3", true)
          } else {
            Swal.fire({
              title: res.message,
              icon: "error",
            })
          }
        } catch (error) {
          throw error
        }
      }
    }
    // e.preventDefault()
    // if (id) {
    //   const nominee_kyc = localStorage.getItem("nominee_kyc")
    //   const nominee_Photo = localStorage.getItem("nominee_Photo")
    //   nominee && nominee.length > 0 && nominee.map((item, i) => {
    //     if (item.id === id) {
    //       console.log("item.id = ", item.id);
    //       try {
    //         const res = updateCustomerNomineeAPI(
    //           // localStorage.getItem("nomineeId"),
    //           item.id,
    //           item.fullName,
    //           item.occupation,
    //           item.relationWithCustomer,
    //           item.mobile,
    //           nominee_Photo,
    //           nominee_kyc,
    //           // nomineePhoto,
    //           // nomineeKyc,
    //           item.fullAddress,
    //           item.addressType
    //         )

    //         if (res.status === 1 || res.status === "1") {
    //           Swal.fire({
    //             title: res.message,
    //             icon: "success",
    //             timer: 1500,
    //           })
    //           localStorage.removeItem("nominee_Photo")
    //           localStorage.removeItem("nominee_Kyc")
    //           // history(`/customerlist`)
    //         } else {
    //           Swal.fire({
    //             title: res.message,
    //             icon: "error",
    //             timer: 1500,
    //           })
    //         }
    //       } catch (error) {
    //         throw error
    //       }
    //     }
    //   })
    // }
    // try {
    //   const data = localStorage.getItem("customerNominee")
    //   const res = await updateCustomerNomineeAPI(
    //     localStorage.getItem("nomineeId"),
    //     data.fullName,
    //     data.occupation,
    //     data.relationWithCustomer,
    //     data.mobile,
    //     nomineePhoto,
    //     nomineeKyc,
    //     data.fullAddress,
    //     data.addressType
    //   )

    //   if (res.status === 1 || res.status === "1") {
    //     Swal.fire({
    //       title: res.message,
    //       icon: "success",
    //       timer: 1500,
    //     })
    //     // history(`/customerlist`)
    //   } else {
    //     Swal.fire({
    //       title: res.message,
    //       icon: "error",
    //       timer: 1500,
    //     })
    //   }
    // } catch (error) {
    //   throw error
    // }
  }

  const handleNext = async e => {
    e.preventDefault()
    history(`/customerlist`)
    localStorage.removeItem("nominee_Photo")
    localStorage.removeItem("nominee_kyc")
    localStorage.removeItem("customerID")
    localStorage.removeItem("nomineeId")
    localStorage.removeItem("customerNominee")
    localStorage.removeItem("Customer_Image")
    localStorage.removeItem("customerAddressId")
    localStorage.removeItem("customerAddress")
    localStorage.removeItem("subHolderId")
    localStorage.removeItem("customerS3")
    localStorage.removeItem("customer")
    localStorage.removeItem("customerS1")
    localStorage.removeItem("customerS2")
  }

  const removeImage = async e => {
    setFilepath("")
    setNomineePhoto("")
    localStorage.removeItem("nominee_Photo")
  }
  const removeKyc = async e => {
    setFile("")
    setNomineeKyc("")
    localStorage.removeItem("nominee_kyc")
  }

  useEffect(() => {
    handleSetFild()
  }, [isDisplay])

  useEffect(() => {
    setCustID(localStorage.getItem("customerID"))
    handleGetNominee()
  }, [])
  useEffect(() => {
    handleGetNominee()
  }, [isRefresh])

  // useEffect(()=>{
  //   handleSetFild()
  // },[handleUpdateNominee])
  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="mt-5 w-100" style={{ maxWidth: "50rem" }}>
          <Col>
            <Card>
              <CardBody>
                <Form>
                  <div>
                    <CardTitle className="text-center fs-4">
                      <b>Customer Nominee</b>
                    </CardTitle>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Nominee Name
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          name="fullName"
                          id="fullName"
                          placeholder="Enter Nominee Name"
                          required
                          value={fullName}
                          onChange={handleFullName}
                        />
                      </div>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="py-1">
                          <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Nominee Occupation
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="text"
                              name="occupation"
                              id="occupation"
                              placeholder="Enter Occupation"
                              required
                              value={occupation}
                              onChange={handleOccupation}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="py-1">
                          <Label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Relation with Customer
                          </Label>
                          <div>
                            <Input
                              className="form-control"
                              type="text"
                              name="relationWithCustomer"
                              id="relationWithCustomer"
                              placeholder="Enter Relation With Customer"
                              required
                              value={relationWithCustomer}
                              onChange={handlerelationWithCustomer}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Mobile-no
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="number"
                          name="mobile"
                          id="mobile"
                          placeholder="Enter Mobile-No"
                          min={10}
                          onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                          value={mobile}
                          onChange={handleMobileNo}
                          required
                        />
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Address
                      </Label>
                      <div>
                        <textarea
                          className="form-control"
                          type="text"
                          rows={4}
                          name="fullAddress"
                          id="fullAddress"
                          placeholder="Enter Address"
                          required
                          value={fullAddress}
                          onChange={handleFullAddress}
                        />
                      </div>
                    </div>

                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Address Type
                      </Label>

                      <div className="mx-2">
                        <div
                          className="active"
                          value={addressType}
                          id="addressType"
                        >
                          <Input
                            type="radio"
                            name="addresstype"
                            value="1"
                            id="home"
                            defaultChecked={addressType === 1 || addressType === "1"}
                            onChange={handleAddressType}
                            style={{ transform: "scale(1.3)" }}
                          />
                          &nbsp;&nbsp;Home&nbsp;&nbsp;
                          <Input
                            type="radio"
                            name="addresstype"
                            value="2"
                            id="office"
                            defaultChecked={addressType === 2 || addressType === "2"}
                            onChange={handleAddressType}
                            style={{ transform: "scale(1.3)" }}
                          />
                          &nbsp;&nbsp;Office
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary w-md"
                        onClick={handleAddNew}>
                        Add New
                      </button>&nbsp;&nbsp;&nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary w-md"
                        onClick={handleSubmit}
                      >
                        {oldNominee && oldNominee !== 'undefined'
                          ? "Update"
                          : "Save"}
                      </button>
                    </div>
                  </div>

                  <hr />
                  <Row>
                    <Col lg={6}>
                      <CardTitle>
                        <b>Nominee Profile Photo</b>
                      </CardTitle>

                      <div className="py-1">
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Image
                        </Label>
                        {!nomineePhoto ? (
                          <div>
                            <Input
                              type="file"
                              name="photo"
                              id="photo"
                              required
                              onChange={uploadNomineeImage}
                            />
                            <img
                              src={filepath ? filepath : user1}
                              alt="company-profile"
                              style={{
                                width: "8rem",
                                height: "7rem",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <ImageViewer
                              path={nomineePhoto}
                              style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                            >
                              {" "}
                            </ImageViewer>
                            <span style={{ marginLeft: "20px" }}>
                              <i
                                className="fas fa-trash-alt"
                                style={{
                                  cursor: "pointer", color: "red",
                                  fontSize: "larger"
                                }}
                                onClick={removeImage}
                              ></i>
                            </span>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <CardTitle>
                        <b>Nominee KYC Photo</b>
                      </CardTitle>

                      <div className="py-1">
                        <Label
                          htmlFor="example-text-input"
                          className=" col-form-label"
                        >
                          Image
                        </Label>
                        {!nomineeKyc ? (
                          <div>
                            <Input
                              type="file"
                              name="kycDoc"
                              id="kycDoc"
                              required
                              onChange={uploadNomineeKyc}
                            />
                            <img
                              src={file ? file : dummy}
                              alt=""
                              style={{
                                width: "8rem",
                                height: "6rem",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <ImageViewer
                              path={nomineeKyc}
                              style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                            >
                              {" "}
                            </ImageViewer>
                            <span style={{ marginLeft: "20px" }}>
                              <i
                                className="fas fa-trash-alt"
                                style={{
                                  cursor: "pointer", color: "red",
                                  fontSize: "larger"
                                }}
                                onClick={removeKyc}
                              ></i>
                            </span>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4 d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary w-md "
                      onClick={updateNominee}
                    >
                      Save
                    </button>
                  </div>
                  <Row>
                    <Col lg={12}>
                      <div className="py-1 table-responsive mt-3">
                        <table
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Nominee Name
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Nominee Relation
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Nominee Address
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Nominee Mobile
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          {nominee &&
                            nominee.length > 0 &&
                            nominee.map((data, index) => {
                              return (
                                <tbody key={index}>
                                  <tr >
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      {data.fullName}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      {data.relationWithCustomer}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {data.fullAddress}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {data.mobile}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      {/* <span>
                                        <i
                                          className="fa fa-edit"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleUpdateOldKyc(index)
                                          }
                                        ></i>
                                      </span> */}
                                      <span className="d-flex gap-2" style={{ justifyContent: 'center' }}>
                                        <i className="fas fa-file-signature" style={{ cursor: 'pointer' }} onClick={() => handleUpdateNominee(data.id)}></i>
                                        <i className="fas fa-grip-lines-vertical"></i>
                                        <i className="fas fa-trash" style={{ cursor: 'pointer', color: "red" }} onClick={() => handleDeleteNominee(data.id)}></i>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}

                        </table>
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4 d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary w-md "
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default CustomerNominee
