import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { logoutUser } from "../../store/actions";
import Swal from 'sweetalert2'

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const logout=logoutUser(history);
  
    dispatch(logout);
    // localStorage.removeItem('token');
    // localStorage.removeItem('ref_token');
    // localStorage.removeItem('userName');
    // localStorage.removeItem('user_id');
    // localStorage.removeItem('authUser');
    // localStorage.removeItem('md5');
    localStorage.clear();
    if (logout.type === "LOGOUT_USER") {
      Swal.fire({
        title: "Logout SuccessFully",
        icon: "success",
        timer: 1500
      })
      history("/login")
    }

  }, [dispatch, history]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);