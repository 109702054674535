import React, { useEffect, useState } from "react"
import {
  setBreadcrumbItems,
} from "../../store/actions"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  createCustomerAPI,
  getCustomerByIdAPI,
  getCustomerSubHolderAPI,
  getSearchCustomerList,
  updateCustomerAPI,
} from "service/service"

import Swal from "sweetalert2"
import Select from "react-select"
// import Loading from "components/Common/Loading"
// import FeatherIcon from "feather-icons-react"

const options = ["Primary holder only", "Add sub holder"]

const CreateCustomer = ({ onButtonClick }, props) => {
  document.title = "Create Customer | Shreesh Securities"

  const re = /^[A-Za-z\s]+$/;

  const [fullName, setFullName] = useState("")
  const [occupation, setOccupation] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [telephone, setTelephone] = useState("")
  const [custID, setCustID] = useState("")
  const [oldCustomerId, setOldCustomerId] = useState("")
  const [oldCustomer, setOldCustomer] = useState("")
  const [selected, setSelected] = useState(0)
  const [isChanged, setIsChanged] = useState(false)
  let isDisplay = JSON.parse(localStorage.getItem("customerS1"))

  // const [selectedOptions, setSelectedOptions] = useState();

  const value = {
    fullName,
    occupation,
    email,
    mobile,
    telephone,
  }

  const handleSetFild = () => {
    let data = JSON.parse(localStorage.getItem("customer"))
    setFullName(data?.fullName)
    setOccupation(data?.occupation)
    setEmail(data?.email)
    setMobile(data?.mobile)
    setTelephone(data?.telephone)
  }

  const handleDisplayOldCustomerList = async () => {
    try {
      const res = await getSearchCustomerList()
      setOldCustomer(res.data)

    } catch (error) {
      console.log(error);
    }
  }

  const handleFullName = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setIsChanged(true)
      setFullName(value);
    }
    // setIsChanged(true)
    // setFullName(e.target.value)
  }

  const handleOccupation = e => {
    const { value } = e.target;
    if (value === "" || re.test(value)) {
      setIsChanged(true)
      setOccupation(value);
    }
    // setOccupation(e.target.value)
  }

  const handleEmail = e => {
    setIsChanged(true)
    setEmail(e.target.value)
  }

  const handleMobileNo = e => {
    setMobile(e.target.value.slice(0, 10))
    setIsChanged(true)
  }

  const handleTelephoneNo = e => {
    setIsChanged(true)
    setTelephone(e.target.value)
  }

  const handleOnChange = i => {
    setIsChanged(true)
    setSelected(prev => (i === prev ? null : i))
    localStorage.setItem("customerS3", i === 1 ? 1 : 0)
  }

  const handleSelect = e => {
    const value = e.value
    setOldCustomerId(value)
    localStorage.setItem("customerID", e.value)
  }
  // Not add holders
  // const handleSetFildOld = async id => {
  //   const res = await getCustomerByIdAPI(id)
  //   localStorage.setItem("isOldCust", true)
  //   res?.data?.nominees?.length > 0 && localStorage.setItem("nomineeId", res?.data?.nominees && res?.data?.nominees[0]?.id)
  //   res?.data?.nominees?.length > 0 && localStorage.setItem("customerNominee", res?.data?.nominees && JSON.stringify({
  //     "fullName": res?.data?.nominees[0]?.fullName,
  //     "occupation": res?.data?.nominees[0]?.occupation,
  //     "relationWithCustomer": res?.data?.nominees[0]?.relationWithCustomer,
  //     "mobile": res?.data?.nominees[0]?.mobile,
  //     "fullAddress": res?.data?.nominees[0]?.fullAddress,
  //     "addressType": res?.data?.nominees[0]?.addressType
  //   }))
  //   res?.data?.nominees?.length > 0 && localStorage.setItem("nominee_Photo", (res?.data?.nominees[0]?.photo !== null ? res?.data?.nominees[0]?.photo : ''))
  //   res?.data?.nominees?.length > 0 && localStorage.setItem("nominee_kyc", (res?.data?.nominees[0]?.kycDoc !== null ? res?.data?.nominees[0]?.kycDoc : ''))
  //   res?.data?.photo && localStorage.setItem("Customer_Image", res?.data && res?.data?.photo)
  //   res?.data?.addresses?.length > 0 && localStorage.setItem("customerAddressId", res?.data?.addresses && res?.data?.addresses[0]?.id)
  //   res?.data?.addresses?.length > 0 && localStorage.setItem("customerAddress", res?.data?.addresses && JSON.stringify({
  //     "fullAddress": res?.data?.addresses[0]?.fullAddress,
  //     "addressType": res?.data?.addresses[0]?.addressType
  //   }))
  //   setFullName(res?.data?.fullName)
  //   setOccupation(res?.data?.occupation)
  //   setEmail(res?.data?.email)
  //   setMobile(res?.data?.mobile)
  //   setTelephone(res?.data?.telephone)
  // }
  // add holders 1/4/24
  const handleSetFildOld = async id => {
    const res = await getCustomerByIdAPI(id)
    let tempHolder = []
    const holders = await getCustomerSubHolderAPI(id)
    localStorage.setItem("isOldCust", true)
    res?.data?.nominees?.length > 0 && localStorage.setItem("nomineeId", res?.data?.nominees && res?.data?.nominees[0]?.id)
    res?.data?.nominees?.length > 0 && localStorage.setItem("customerNominee", res?.data?.nominees && JSON.stringify({
      "fullName": res?.data?.nominees[0]?.fullName,
      "occupation": res?.data?.nominees[0]?.occupation,
      "relationWithCustomer": res?.data?.nominees[0]?.relationWithCustomer,
      "mobile": res?.data?.nominees[0]?.mobile,
      "fullAddress": res?.data?.nominees[0]?.fullAddress,
      "addressType": res?.data?.nominees[0]?.addressType
    }))
    res?.data?.nominees?.length > 0 && localStorage.setItem("nominee_Photo", (res?.data?.nominees[0]?.photo !== null ? res?.data?.nominees[0]?.photo : ''))
    res?.data?.nominees?.length > 0 && localStorage.setItem("nominee_kyc", (res?.data?.nominees[0]?.kycDoc !== null ? res?.data?.nominees[0]?.kycDoc : ''))
    res?.data?.photo && localStorage.setItem("Customer_Image", res?.data && res?.data?.photo)
    res?.data?.addresses?.length > 0 && localStorage.setItem("customerAddressId", res?.data?.addresses && res?.data?.addresses[0]?.id)
    res?.data?.addresses?.length > 0 && localStorage.setItem("customerAddress", res?.data?.addresses && JSON.stringify({
      "fullAddress": res?.data?.addresses[0]?.fullAddress,
      "addressType": res?.data?.addresses[0]?.addressType
    }))
    holders?.data[0]?.holders?.length > 0 &&
      holders?.data[0]?.holders.map((data) => {
        tempHolder.push(data.id)
      })

    // tempHolder && tempHolder.length > 0 && localStorage.setItem("subHolderId", tempHolder)
    tempHolder && tempHolder.length > 0 && localStorage.setItem("subHolderId", JSON.stringify(tempHolder))
    console.log(res.data);
    setFullName(res?.data?.fullName)
    setOccupation(res?.data?.occupation)
    setEmail(res?.data?.email)
    setMobile(res?.data?.mobile)
    setTelephone(res?.data?.telephone)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (custID || oldCustomerId) {
      if (isChanged) {
        const fullName = document.getElementById("fullName").value
        const occupation = document.getElementById("occupation").value
        const email = document.getElementById("email").value
        const mobile = document.getElementById("mobile").value
        const telephone = document.getElementById("telephone").value
        let temp = { fullName, occupation, email, mobile, telephone }
        try {
          const res = await updateCustomerAPI(
            oldCustomerId ? oldCustomerId : custID,
            fullName,
            occupation,
            email,
            mobile,
            telephone
          )


          if (res.status === 1 || res.status === "1") {
            Swal.fire({
              title: res.message,
              icon: "success",
              timer: 1500
            })
            onButtonClick("CustomerAddress")
            localStorage.setItem("customerID", res.data.id)
            handleSetFild()
            localStorage.setItem("customer", JSON.stringify(temp))
            localStorage.setItem("customerS1", true)
            localStorage.setItem("customerS2", true)
            localStorage.setItem("customerS3", selected)
          } else {
            Swal.fire({
              title: res.message,
              icon: "error",
            })
          }
        } catch (error) {
          console.log(error);
        }
      }
      else {
        const fullName = document.getElementById("fullName").value
        const occupation = document.getElementById("occupation").value
        const email = document.getElementById("email").value
        const mobile = document.getElementById("mobile").value
        const telephone = document.getElementById("telephone").value
        let temp = { fullName, occupation, email, mobile, telephone }
        localStorage.setItem("customer", JSON.stringify(temp))
        localStorage.setItem("customerS1", true)
        localStorage.setItem("customerS2", true)
        localStorage.setItem("customerS3", selected)

        const res = await getCustomerByIdAPI(localStorage.getItem('customerID'))
        localStorage.setItem("isOldCust", true)
        res?.data?.nominees?.length > 0 ? localStorage.setItem("nomineeId", res?.data?.nominees && res?.data?.nominees[0]?.id) : localStorage.setItem("nomineeId", '')
        res?.data?.nominees?.length > 0 ? localStorage.setItem("customerNominee", res?.data?.nominees && JSON.stringify({
          "fullName": res?.data?.nominees[0]?.fullName,
          "occupation": res?.data?.nominees[0]?.occupation,
          "relationWithCustomer": res?.data?.nominees[0]?.relationWithCustomer,
          "mobile": res?.data?.nominees[0]?.mobile,
          "fullAddress": res?.data?.nominees[0]?.fullAddress,
          "addressType": res?.data?.nominees[0]?.addressType
        })) : localStorage.setItem("customerNominee", '')
        res?.data?.nominees?.length > 0 && localStorage.setItem("nominee_Photo", (res?.data?.nominees[0]?.photo !== null ? res?.data?.nominees[0]?.photo : ''))
        res?.data?.nominees?.length > 0 && localStorage.setItem("nominee_kyc", (res?.data?.nominees[0]?.kycDoc !== null ? res?.data?.nominees[0]?.kycDoc : ''))
        onButtonClick("CustomerAddress")
      }
    } else {

      try {
        const res = await createCustomerAPI(
          fullName,
          occupation,
          email,
          mobile,
          telephone
        )
        if (res.status === 1 || res.status === "1") {
          Swal.fire({
            title: res.message,
            icon: "success",
            timer: 1500
          })
          onButtonClick("CustomerAddress")
          localStorage.setItem("customerID", res.data.id)
          localStorage.setItem("customer", JSON.stringify(value))
          // handleSetFild()
          localStorage.setItem("customerS1", true)
        } else {
          Swal.fire({
            title: res.message,
            icon: "error",
          })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    setCustID(localStorage.getItem("customerID"))
    setSelected(Number(localStorage.getItem("customerS3")))
  }, [])

  useEffect(() => {
    handleSetFild()
  }, [isDisplay])

  useEffect(() => {
    handleDisplayOldCustomerList()
    handleSetFildOld(oldCustomerId)
    //eslint-disable-next-line
  }, [oldCustomerId])

  const customeroptions = [
    oldCustomer &&
    oldCustomer.map(data => {
      return { value: data.id, label: data.fullName }
    }),
  ]

  return (
    <div className="d-flex justify-content-center">
      <Row className="mt-5 w-100" style={{ maxWidth: "50rem" }}>
        <Col>
          <Card>
            <CardBody>
              <Form>
                <CardTitle className="text-center fs-4">
                  <b>Primary Customer Personal Details</b>{" "}
                </CardTitle>
                <div className="py-1">
                  <Label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Please select if customer already added
                  </Label>
                  <div>
                    {customeroptions &&
                      customeroptions.map((data, i) => (
                        <Select key={"cus" + i} options={data} onChange={handleSelect} placeholder={fullName} />
                      ))}
                  </div>
                </div>
                <div className="py-1">
                  <Label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Customer Name*
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder="Enter Customer Name"
                      required
                      onChange={handleFullName}
                      value={fullName}
                    />
                  </div>
                </div>
                <div className="py-1">
                  <Label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Occupation*
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      type="text"
                      name="occupation"
                      id="occupation"
                      placeholder="Enter Occupation"
                      required
                      onChange={handleOccupation}
                      value={occupation}
                    />
                  </div>
                </div>
                <div className="py-1">
                  <Label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Email*
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Email"
                      required
                      onChange={handleEmail}
                      value={email}
                    />
                  </div>
                </div>

                <Row>
                  <Col lg={6}>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Mobile-No*
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="number"
                          name="mobile"
                          id="mobile"
                          min={10}
                          placeholder="Enter Mobile-No"
                          onChange={handleMobileNo}
                          onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                          value={mobile}
                          required
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="py-1">
                      <Label
                        htmlFor="example-text-input"
                        className=" col-form-label"
                      >
                        Telephone-No
                      </Label>
                      <div>
                        <Input
                          className="form-control"
                          type="number"
                          name="telephone"
                          id="telephone"
                          placeholder="Enter Telephone-No"
                          onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                          onChange={handleTelephoneNo}
                          value={telephone}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="py-1 mt-3">
                  {options.map((name, i) => (
                    <Label key={"i" + i} style={{ marginRight: "8px" }}>
                      <Input
                        type="checkbox"
                        checked={i === selected}
                        onChange={() => handleOnChange(i)}
                        style={{ marginRight: "6px", transform: "scale(1.3)" }}
                      />
                      {name}
                    </Label>
                  ))}
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary w-md "
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(CreateCustomer)
