/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import { accessCustomerLockerAPI, fetchCustomerFingerPrintData, manualLockerAccessAPI, searchCustomer, searchCustomerLockerByLockerNo } from "service/service"
import Select from "react-select"
import { bioMetricApiKey, gst } from "api/apiClient"
import Swal from "sweetalert2"
import ImageViewer from "ImageViewer"
import user1 from "../../assets/images/users/user-1.jpg"
import './biometric.css';
import { useNavigate } from "react-router-dom";
import { Input, Tabs, Typography } from "@mui/material"
import Tab from '@mui/material/Tab';

const AuthenticateFingerPrint = props => {
  document.title = "Authentication | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Authenticate Biometric", link: "#" },
  ]
  useEffect(() => {
    props.setBreadcrumbItems("Authentication", breadcrumbItems)
  })

  const navigate = useNavigate()

  const [value, setValue] = useState(0);

  const [isLoading, setLoading] = useState(false);
  const searchString = useState("")[0];
  const [customerId, setCustomerId] = useState("")
  const [customerDetails, setCustomerDetails] = useState({})
  const [templates, setTemplates] = useState([])
  const [address, setAddress] = useState("");
  const [isMatched, setMatch] = useState('');
  const [selectedLocker, setLocker] = useState({ value: '', label: 'SELECT' });
  const [Lockers, setLockers] = useState([]);
  const [LockerDetail, setLockerDetail] = useState(null);
  const [selectedFinger, setFinger] = useState({ value: "LeftThumb", label: "LeftThumb" });
  const fingerOptions = useState([
    // Left Arm
    { value: "LeftThumb", label: "LeftThumb" },
    { value: "LeftIndexFinger", label: "LeftIndexFinger" },
    { value: "LeftMiddleFinger", label: "LeftMiddleFinger" },
    { value: "LeftRingFinger", label: "LeftRingFinger" },
    { value: "LeftPinky", label: "LeftPinky" },
    // Right Arm
    { value: "RightThumb", label: "RightThumb" },
    { value: "RightIndexFinger", label: "RightIndexFinger" },
    { value: "RightMiddleFinger", label: "RightMiddleFinger" },
    { value: "RightRingFinger", label: "RightRingFinger" },
    { value: "RightPinky", label: "RightPinky" },
  ])[0];
  localStorage.setItem("selectedFinger", selectedFinger.value);

  const [lockerCategory, setLockerCategory] = useState("")
  const [lockerStatus, setLockerStatus] = useState("")

  const handleDisplayOldLockerList = async () => {
    try {
      const res = await searchCustomerLockerByLockerNo(searchString)
      // console.log(res.data);
      setLockers(res.data)
    } catch (error) {
      throw error
    }
  }

  const fetchFingerPrintData = async (customerId) => {
    const result = [];
    try {
      const data = { customerId };
      const res = await fetchCustomerFingerPrintData(data);
      if (res.status === '1') {
        if (res.data.length) {
          res.data.forEach(data => {
            const { customerId, template, templateFor } = data;
            result.push({ customerId, template, templateFor });
          });
          console.log("fetch", result);
          return result;
        }
      }
    } catch (error) {
      console.log("Api error", error);
    }
    return result;
  }

  const fetchAllUsersTemplates = async (customers) => {
    const allTempls = [];
    for (const customerId of customers) {
      const data = await fetchFingerPrintData(customerId);
      console.log("data", data)
      if (data && data.length) {
        data.forEach(item => {
          allTempls.push(item);
        });
      }
    }
    console.log('total founded templates lengtth', allTempls.length);
    return allTempls;
  };

  const setLockerTemplates = (event) => {
    setCustomerId('');
    setLoading(false);
    setCustomerDetails({});
    setAddress([])
    setMatch(false);
    setLockerDetail(null)
    const capturedEle = document.querySelector("#box1");
    if (capturedEle) {
      capturedEle.style.backgroundImage = 'none';
    }
    localStorage.removeItem("isMatched");
    localStorage.removeItem("GetServiceTagId");
    localStorage.removeItem("Compare");
    localStorage.removeItem("Capture");
    localStorage.removeItem(`customer-template-${customerId}`);
    localStorage.setItem('customerTemplates', JSON.stringify([]));
    setLocker(event);
    const locker = Lockers.find(l => l._id.toString() === event?.value.toString());
    // console.log("locker = ", locker);
    setLockerDetail(locker);
    const customers = [];
    if (locker) {
      // console.log(locker);
      // console.log(locker?.lockerStatus);
      if (locker?.lockerStatus === "Freezed") {
        setLockerStatus(locker?.lockerStatus)
        Swal.fire({
          title: `${locker.customerLockerNo} is Freezed. </br> You are not able to access your locker.`,
          icon: "warning"
        })
      }
      else if (locker?.lockerStatus === "Breaked") {
        setLockerStatus(locker?.lockerStatus)
        Swal.fire({
          title: `${locker.customerLockerNo} is Breaked. </br> You are not able to access your locker.`,
          icon: "warning"
        })
      }
      else {
        setLockerStatus(locker?.lockerStatus)
        setLockerCategory(locker?.locker?.lockerCategory)
        customers.push(locker?.primaryHolder?._id);
        locker?.holders.map(c => customers.push(c._id));
        console.log('primary holder + total holders counts', customers.length);
        fetchAllUsersTemplates(customers).then(data => {
          console.log('fetchAllUsersTemplates result data.length', data.length);
          localStorage.setItem('locker', JSON.stringify(locker))
          localStorage.setItem(`${locker.customerLockerNo}-templates`, JSON.stringify(data));
        }).catch(err => {
          console.log('fetchAllUsersTemplates result data', err)
        });
      }
    }
  }

  const handleSelect = e => {
    setLockerTemplates(e);
    window.getServiceTagId();
  }

  const handleStatus = e => {
    console.log("finger value", e);
    setFinger(e)
    localStorage.setItem("selectedFinger", e.value);
  }

  const onAuthenticate = async () => {
    console.log("handleCaptureEvent called");
    setLoading(true);
    window.capture(bioMetricApiKey, true);
  }

  const handleAuthrised = () => {
    let locker = localStorage.getItem('locker');
    let customerId;
    let currentTemplate;
    let customerInfo = localStorage.getItem('customerInfo');

    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
    }
    let customerTemplate = [];
    if (locker) {
      locker = JSON.parse(locker);
      // console.log("handleAuthrised = ", locker._id);
      customerTemplate = localStorage.getItem(`${locker.customerLockerNo}-templates`);
      if (customerTemplate) {
        customerTemplate = JSON.parse(customerTemplate);
      }
      customerId = localStorage.getItem(`${locker?.customerLockerNo}-customerId`);
      if (customerId) {
        currentTemplate = localStorage.getItem(`customer-template-${customerId}`);
        if (currentTemplate) {
          currentTemplate = JSON.parse(currentTemplate);
        }
      }
    }
    let lock = localStorage.getItem('customerInfo');
    let custInfo = JSON.parse(lock);
    // console.log("lock", lock);
    // console.log("custInfo", custInfo);
    let imageData = custInfo?.photo
    let name = custInfo.fullName
    let email = custInfo.email
    const serverURL = "https://shreeshdesignstudiollp.com:3001/"
    Swal.fire({
      title: `You are authorized for lockerNo '${locker.customerLockerNo}'`,
      html: `
      <label>Name: ${name}</label></br>
      <label>Email: ${email}</label></br>
      <img src=${serverURL + imageData} crossOrigin="anonymous" width="200" height="150"></img>
    `,
      icon: "success",
    });
    localStorage.removeItem("GetServiceTagId");
    localStorage.removeItem("isMatched");
    localStorage.removeItem("Compare");
    localStorage.removeItem("Capture");
    // localStorage.removeItem("locker");
    localStorage.removeItem(`${locker?.customerLockerNo}-customerId`);
    localStorage.removeItem(`${locker.customerLockerNo}-templates`);
    localStorage.removeItem(`customer-template-${customerId}`);

    localStorage.removeItem("selectedFinger");
    handleAccessCustomerDetail()
    // navigate(`/customerDetailsTest/${locker._id}`);
    navigate(`/viewAssignedLocker/${locker._id}`);
    return;
  }

  const handleNotAuthorised = (noTempalte = 0) => {
    let locker = localStorage.getItem('locker');
    let customerId;
    let currentTemplate;
    let customerInfo = localStorage.getItem('customerInfo');
    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
    }
    let customerTemplate = [];
    if (locker) {
      locker = JSON.parse(locker);
      customerTemplate = localStorage.getItem(`${locker.customerLockerNo}-templates`);
      if (customerTemplate) {
        customerTemplate = JSON.parse(customerTemplate);
      }
      customerId = localStorage.getItem(`${locker?.customerLockerNo}-customerId`);
      if (customerId) {
        currentTemplate = localStorage.getItem(`customer-template-${customerId}`);
        if (currentTemplate) {
          currentTemplate = JSON.parse(currentTemplate);
        }
      }
    }
    const capturedEle = document.querySelector("#box1");
    if (capturedEle) {
      capturedEle.style.backgroundImage = 'none';
    }

    if (!noTempalte) {
      Swal.fire({
        title: `You are not authorized for lockerNo '${locker?.customerLockerNo}'`,
        icon: "error",
      });
    } else {
      Swal.fire({
        title: `No fingerprint data found for any user for lockerNo '${locker?.customerLockerNo}'`,
        icon: "error",
      });
    }
    setLockerTemplates(selectedLocker);
    return;
  }

  const wait = async (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

  const compareTempaltes = async (iteration) => {
    console.log("compareTempaltes called with iteration", iteration);
    let fingerDataInfo = localStorage.getItem("Capture");
    if (fingerDataInfo) {
      fingerDataInfo = JSON.parse(fingerDataInfo);
    }
    const selectedFinger = localStorage.getItem("selectedFinger");
    console.log('compareTempaltes fingerDataInfo', fingerDataInfo);
    if (fingerDataInfo) {

      const pngImageContent = "data:image/png;base64," + fingerDataInfo?.image;
      console.log('selectedFinger', selectedFinger);
      const templateToCheck = fingerDataInfo?.template;
      const capturedEle = document.querySelector("#box1");
      capturedEle.style.backgroundImage = `url(${pngImageContent})`;
      let locker = localStorage.getItem('locker');
      let customerId;
      let currentTemplate;
      let customerInfo;
      let customerTemplate = [];
      let isMatched = localStorage.getItem('isMatched');
      let tempData;
      if (locker) {
        locker = JSON.parse(locker);
        customerTemplate = localStorage.getItem(`${locker.customerLockerNo}-templates`);
        if (customerTemplate) {
          customerTemplate = JSON.parse(customerTemplate);
          tempData = customerTemplate.find(t => t.templateFor === selectedFinger);
          if (!tempData) {
            return handleNotAuthorised();
          }
          customerId = tempData?.customerId;
        }

        if (customerId) {
          currentTemplate = localStorage.getItem(`customer-template-${customerId}`);
          if (currentTemplate) {
            currentTemplate = JSON.parse(currentTemplate);
          }

        }
      }
      console.log('customerTemplate.length', customerTemplate.length);
      isMatched = localStorage.getItem('isMatched');
      if (!customerTemplate.length && !isMatched) {
        return handleNotAuthorised();
      }
      await wait(500);
     
      const { template, templateFor } = tempData;
      console.log(`Comparing captured finger print data with ${customerId}`)
      localStorage.setItem(`customer-template-${customerId}`, JSON.stringify(tempData));
      localStorage.setItem(`${locker?.customerLockerNo}-customerId`, tempData.customerId);
      if (locker.primaryHolder._id.toString() === customerId) {
        customerInfo = locker?.primaryHolder;
      }
      let cust = locker?.holders?.find(c => c._id.toString() === customerId.toString());
      if (cust) {
        customerInfo = cust;
      }
      if (customerInfo) {
        localStorage.setItem('customerInfo', JSON.stringify(customerInfo));
      }
      const templateToCompare = template;
      const isTemplateMatch = templateFor === selectedFinger;
      console.log('Line::438, isTemplateMatch', isTemplateMatch)
      if (!isTemplateMatch) {
        return isMatched;
      };
      await wait(500);
      if (isMatched !== 'true' || isMatched !== true) {
        console.log('BEFORE COMPARE customerTemplate.length', customerTemplate.length)
        // customerTemplate.splice(0, 1);
        // console.log('tempData', tempData);
        customerTemplate && localStorage.setItem("AccessCustID", JSON.stringify(customerTemplate))
        console.log('customerTemplate', customerTemplate);
        localStorage.setItem(`${locker?.customerLockerNo}-templates`, JSON.stringify(customerTemplate));
        await wait(500);
        window.compare(bioMetricApiKey, templateToCheck, templateToCompare);
        console.log('AFTER COMPARE customerTemplate.length', customerTemplate.length);
        await wait(500);
      }
    }
  }


  const handleBiometricError = (event) => {
    console.log('handleBiometricError event', event)
    Swal.fire({
      title: "Finger Print Scanner Isn't Ready, please try after sometime",
      icon: "error",
      timer: 1500,
    });
  };

  const handleCaptureResult = async (event, iteration = 1) => {
    console.log('handleCaptureResult called iteration', iteration);
    console.log('get Capture result', event);
    let fingerDataInfo = event?.detail ? event.detail : localStorage.getItem("Capture");
    if (!event?.detail) {
      fingerDataInfo = fingerDataInfo ? JSON.parse(fingerDataInfo) : null;
    }

    if (fingerDataInfo) {
      const pngImageContent = "data:image/png;base64," + fingerDataInfo?.image;
      console.log('selectedFinger', selectedFinger);
      setLoading(false);
      const capturedEle = document.querySelector("#box1");
      capturedEle.style.backgroundImage = `url(${pngImageContent})`;
      let locker = localStorage.getItem('locker');
      let customerTemplate = [];
      let isMatched = localStorage.getItem('isMatched');
      if (locker) {
        locker = JSON.parse(locker);
        customerTemplate = localStorage.getItem(`${locker.customerLockerNo}-templates`);
        if (customerTemplate) {
          customerTemplate = JSON.parse(customerTemplate);
        }
      }
      console.log('BEFORE COMPARE customerTemplate.length', customerTemplate.length)
      console.log('isMatched', isMatched);
      console.log('customerTemplate.length', customerTemplate.length);
      if (!customerTemplate.length) {
        return handleNotAuthorised(1)
      }
      return await compareTempaltes(0);
    }
  };

  const handleCompareResult = async (event) => {
    console.log('get Compare result LINE:503 handleCompareResult', event);
    let compareInfo = event?.detail ? event.detail : localStorage.getItem("Compare");
    if (!event?.detail) {
      compareInfo = compareInfo ? JSON.parse(compareInfo) : null;
    }
    console.log('compareInfo', compareInfo);
    // localStorage.getItem(`customer-template-${customerId}`)
    let locker = localStorage.getItem('locker');
    let customerId;
    let currentTemplate;
    let customerInfo;
    let customerTemplate = [];
    if (locker) {
      locker = JSON.parse(locker);
      customerTemplate = localStorage.getItem(`${locker.customerLockerNo}-templates`);
      if (customerTemplate) {
        customerTemplate = JSON.parse(customerTemplate);
      }
      customerId = localStorage.getItem(`${locker?.customerLockerNo}-customerId`);
      if (customerId) {
        currentTemplate = localStorage.getItem(`customer-template-${customerId}`);
        if (currentTemplate) {
          currentTemplate = JSON.parse(currentTemplate);
        }
        if (locker.primaryHolder._id.toString() === customerId) {
          customerInfo = locker?.primaryHolder;
        }
        let cust = locker?.holders?.find(c => c._id.toString() === customerId.toString());
        if (cust) {
          customerInfo = cust;
        }
      }
    }
    console.log("customer matching with current template data", customerInfo)
    if (compareInfo) {

      if (compareInfo?.matchScore <= 0) {
        await wait(500);
        setMatch('false');
        localStorage.setItem('isMatched', 'false');
        return;
      }

      if (compareInfo?.matchScore > 0) {
        setMatch('true');
        localStorage.setItem('isMatched', 'true');
        setCustomerDetails(customerInfo);
        await wait(500);
        return;
      }
    }
  };

  const handleMatchedResult = async (event) => {
    console.log('handleMatchedResult LINE:557 result', event);
    let isMatched = event?.detail ? event.detail : localStorage.getItem("isMatched");
    console.log('isMatched', isMatched);
    let locker = localStorage.getItem('locker');
    let customerId;
    let currentTemplate;
    let customerInfo;
    let customerTemplate = [];
    if (locker) {
      locker = JSON.parse(locker);
      customerTemplate = localStorage.getItem(`${locker.customerLockerNo}-templates`);
      if (customerTemplate) {
        customerTemplate = JSON.parse(customerTemplate);
      }
      customerId = localStorage.getItem(`${locker?.customerLockerNo}-customerId`);
      if (customerId) {
        currentTemplate = localStorage.getItem(`customer-template-${customerId}`);
        if (currentTemplate) {
          currentTemplate = JSON.parse(currentTemplate);
        }
        if (locker.primaryHolder._id.toString() === customerId) {
          customerInfo = locker?.primaryHolder;
        }
        let cust = locker?.holders?.find(c => c._id.toString() === customerId.toString());
        if (cust) {
          customerInfo = cust;
        }
      }
    }
    if (!locker?._id) {
      console.log('locker?._id NOT FOUND', locker?._id);
      return;
    }
    if ((isMatched === 'true' || isMatched === true) && locker) {
      // setMatch('true');
      await wait(150);
      setCustomerDetails(customerInfo);
      if (customerInfo?.fullName) {
        return handleAuthrised();
      }
    } else {
      console.log("else case")
      return await compareTempaltes(0);
    }
  };

  const handleServiceTageResult = async (event) => {
    console.log('get GetServiceTagId result', event);
    let serviceTag = event?.detail ? event.detail : localStorage.getItem("GetServiceTagId");
    if (!event?.detail) {
      serviceTag = serviceTag ? JSON.parse(serviceTag) : {};
    }
    const serviceTagId = serviceTag?.serviceTagId || null;
    localStorage.setItem("serviceTagId", serviceTagId);
  }

  useEffect(() => {
    window.addEventListener('Compare', handleCompareResult)
    window.addEventListener('Compare-Matched', handleMatchedResult)
    window.addEventListener('Capture', handleCaptureResult)
    window.addEventListener('GetServiceTagId', handleServiceTageResult)
    return () => {
      window.removeEventListener('Compare', handleCompareResult)
      window.removeEventListener('Capture', handleCaptureResult);
      window.removeEventListener('GetServiceTagId', handleServiceTageResult);
      window.removeEventListener('Compare-Matched', handleMatchedResult);
    }
  })

  useEffect(() => {
    window.addEventListener('Compare-Failed', handleBiometricError)
    window.addEventListener('Capture-Failed', handleBiometricError)
    window.addEventListener('GetServiceTagId-Failed', handleBiometricError)
    return () => {
      window.removeEventListener('Compare-Failed', handleBiometricError)
      window.removeEventListener('GetServiceTagId-Failed', handleBiometricError);
      window.removeEventListener('Capture-Failed', handleBiometricError);
    }
  })


  useEffect(() => {
    handleDisplayOldLockerList()
  }, [])



  const LockerOptions = [
    Lockers &&
    Lockers.map(data => {
      return { value: data._id, label: data.customerLockerNo }
    }),
  ];

  console.log('LockerOptions', LockerOptions);


  const [accessCharge, setAccessCharge] = useState("")

  const handleAccessCharge = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked) {
      console.log("checked = ", e.target.checked);
      setAccessCharge(2000)
    } else {
      setAccessCharge("")
    }
  }

  const totalCharge = accessCharge > 0 ? accessCharge + (accessCharge * gst) / 100 : "0"
  // console.log(totalCharge);

  const handleAccessCustomerDetail = async () => {
    console.log("Access");
    try {
      const cust = JSON.parse(localStorage.getItem("customerInfo"))
      const locker = JSON.parse((localStorage.getItem("locker")))
      // const custId = JSON.parse(localStorage.getItem('AccessCustID'))[0].customerId
      const custId = cust._id
      const custLockerId = locker._id
      console.log("custId = ", custId);
      console.log("custLockerId = ", custLockerId._id);

      const res = await accessCustomerLockerAPI(custLockerId, custId, totalCharge);
      if (res.status === 1 || res.status === "1") {
        localStorage.removeItem("locker");
      }

      // console.log(res);
      // setCustomerDetail(res.data);
      // setCustomerLockerDetail(res.data.locker)
    } catch (error) {
      console.log(error);
    }
  }

  const [customer, setCustomer] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [lockerSelected, setSelectedLocker] = useState("")
  const [remarks, setRemarks] = useState("")

  const handleDisplayOldCustomerList = async () => {
    setLockerCategory("")
    try {
      const res = await searchCustomer()
      setCustomer(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectCustomer = (e) => {
    setSelectedCustomer(e.value)
  }

  const handleSelectLocker = (e) => {
    console.log("locoek = ", e.value);
    setSelectedLocker(e.value)
    const locker = Lockers.find(l => l._id.toString() === e?.value.toString());
    if (locker) {
      // console.log(locker);
      localStorage.setItem("locker", JSON.stringify(locker))
      setLockerCategory(locker?.locker?.lockerCategory)
    }
  }

  const CustomerOptions = [
    customer &&
    customer.map(data => {
      return { value: data._id, label: data.fullName }
    }),
  ];

  const manualLockerAccess = async () => {
    // console.log("Locker ID = ", lockerSelected);
    // console.log("Customer ID = ", selectedCustomer);
    // console.log("Remarks = ", remarks);
    const locker = JSON.parse((localStorage.getItem("locker")))
    // console.log("locker.lockerStatus = ", locker.lockerStatus);
    let customerLockerId = lockerSelected
    let customerId = selectedCustomer

    try {
      if (locker.lockerStatus === "Freezed") {
        Swal.fire({
          title: `${locker.customerLockerNo} is Freezed. </br> You are not able to access your locker.`,
          icon: "warning"
        })
      }
      else if (locker.lockerStatus === "Breaked") {
        Swal.fire({
          title: `${locker.customerLockerNo} is Breaked. </br> You are not able to access your locker.`,
          icon: "warning"
        })
      } else {
        const response = await manualLockerAccessAPI(customerLockerId, customerId, totalCharge, remarks)
        if (response.status === 1 || response.status === "1") {
          let imageData = response?.data.photo
          let name = response?.data.fullName
          let email = response?.data.email
          const serverURL = "https://shreeshdesignstudiollp.com:3001/"
          Swal.fire({
            title: response.message,
            html: `
      <label>Name: ${name}</label></br>
      <label>Email: ${email}</label></br>
      <img src=${serverURL + imageData} crossOrigin="anonymous" width="200" height="150"></img>
    `,
            icon: "success",
          })
          // navigate(`/viewAssignedLocker/${customerLockerId}`);
        } else {
          Swal.fire({
            title: response.message,
            icon: "error"
          })
        }
      }
      // const response = await manualLockerAccessAPI(customerLockerId, customerId, totalCharge, remarks)
      // if (response.status === 1 || response.status === "1") {
      //   Swal.fire({
      //     title: response.message,
      //     icon: "success"
      //   })
      //   navigate(`/viewAssignedLocker/${customerLockerId}`);
      // } else {
      //   Swal.fire({
      //     title: response.message,
      //     icon: "error"
      //   })
      // }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="mt-3">

      <Tabs
        value={value}
        textColor="primary"
        indicatorColor="primary"
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        style={{ borderBottom: "1px solid gray" }}
        // orientation={smallScreen ? "vertical" : "horizontal"}
        classes={{
          flexContainer: "flex-wrap sm:flex-nowrap",
        }}
        className="tabs"
      >
        <Tab className="top-tab" label="Authenticate With Fingerprint" style={{ fontWeight: "600" }} />
        <Tab className="top-tab" label="Manually Authenticate" style={{ fontWeight: "600" }} onClick={handleDisplayOldCustomerList} />

      </Tabs>

      {value === 0 && (
        <Typography className="tabs-content">
          <Row>
            {/* <Col lg={12} style={{ paddingTop: "1rem" }} className="fingerprintBox">
              <form onSubmit={e => e.preventDefault()}>
                <div className="d-flex justify-content-center">
                  <div>
                    <div className="form-group">
                      {
                        isLoading ?
                          <div className="scan">
                            <div className="fingerprint"></div>
                            <h3>Scanning...</h3>
                          </div>
                          : ''
                      }
                      <div style={{ display: isLoading ? "none" : "block" }} className="box" id="box1"></div>
                    </div>

                    <center className="pt-1 ">
                      <Button
                        htmlFor="box1"
                        type="button"
                        className={isMatched === 'true' || isMatched === true ? 'btn btn-success' : 'btn btn-primary'}
                        onClick={onAuthenticate}
                      >
                        Capture & Authenticate
                      </Button>
                    </center>
                  </div>
                </div>
              </form>
            </Col> */}
            <Card style={{ minHeight: "20vmax" }}>
              <CardBody>
                <div className="py-1">
                  <Label htmlFor="example-text-input" className=" col-form-label">
                    Select Locker No*
                  </Label>
                  <div>
                    {LockerOptions && LockerOptions.length ?
                      LockerOptions.map((data, i) => (
                        <Select
                          key={`customer_locker_${i}`}
                          maxMenuHeight={250}
                          isSearchable={true}
                          defaultValue={selectedLocker}
                          onChange={handleSelect}
                          options={data}
                        />
                      )) : ""
                    }
                  </div>
                </div>


                {/* Authenticate Biometric */}
                {selectedLocker.value && (!customerDetails || !customerDetails?._id) ?
                  <>
                    <Row style={{ paddingTop: "2rem" }}>
                      <Col lg={6}>
                        <Label className="panel-heading">Please select finger for which you want to verify biometric data</Label>
                        <div>
                          {fingerOptions && fingerOptions.length ? (
                            <Select
                              maxMenuHeight={250}
                              defaultValue={selectedFinger}
                              isSearchable={true}
                              onChange={handleStatus}
                              options={fingerOptions}
                            />
                          ) : ""}
                        </div>
                      </Col>
                      <Col lg={6}>
                        {
                          lockerCategory === "Large" || lockerCategory === "Extra Large" ? (
                            <div className="panel-heading">
                              <Label>
                                24*7 Locker Access Charge 2000 + GST (18%)
                              </Label>
                              <div className="d-flex">
                                <input
                                  type="checkbox"
                                  // value={index}
                                  onChange={handleAccessCharge}
                                  style={{ cursor: "pointer", width: "15px" }}
                                />
                                <span style={{ marginLeft: "1rem" }}>If you want to pay <b>Access Charge</b> please select checkbox</span>
                              </div>

                              {/* <Input
                          className="form-control"
                          type="text"
                          name="accessCharge"
                          id="accessCharge"
                          required
                          readOnly
                          value={lockerAccessCharge}
                        /> */}
                            </div>
                          ) : ""
                        }

                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} style={{ paddingTop: "1rem" }} className="fingerprintBox">
                        <Label htmlFor="example-text-input" className=" col-form-label">
                          Authenticate Your Biometrics Data
                        </Label>
                        <form onSubmit={e => e.preventDefault()}>
                          <Row style={{ paddingTop: "1rem" }}>
                            <Col lg={12} style={{ paddingTop: "1rem" }}>
                              <div className="form-group">
                                {
                                  isLoading ?
                                    <div className="scan">
                                      <div className="fingerprint"></div>
                                      <h3>Scanning...</h3>
                                    </div>
                                    : ''
                                }
                                <div style={{ display: isLoading ? "none" : "block" }} className="box" id="box1"></div>
                              </div>
                            </Col>
                            <Col lg={12} style={{ paddingTop: "1rem" }}>
                              {lockerStatus === "Freezed" || lockerStatus === "Breaked" ?
                                <Button
                                  htmlFor="box1"
                                  type="button"
                                  className={isMatched === 'true' || isMatched === true ? 'btn btn-success' : 'btn btn-primary'}
                                  // onClick={onAuthenticate}
                                  disabled
                                >
                                  Capture & Authenticate
                                </Button>
                                : <Button
                                  htmlFor="box1"
                                  type="button"
                                  className={isMatched === 'true' || isMatched === true ? 'btn btn-success' : 'btn btn-primary'}
                                  onClick={onAuthenticate}
                                >
                                  Capture & Authenticate
                                </Button>
                              }
                            </Col>
                          </Row>
                        </form>
                      </Col>
                    </Row>
                  </>
                  : ''}

                {/* Authenticated Customer */}
                {!isLoading && customerDetails && customerDetails?._id ? (
                  <>
                    <Row className="mt-5 fw-bold view-table" style={{}}>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold ">Name</h6>
                        <p>{customerDetails?.fullName}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Occupation</h6>
                        <p>{customerDetails?.occupation}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Email</h6>
                        <p>{customerDetails?.email}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Mobile</h6>
                        <p>{customerDetails?.mobile}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Telephone</h6>
                        <p>{customerDetails?.telephone}</p>
                      </Col>
                      {
                        address && address.length ?
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <h6 className="fw-bold">Address</h6>
                            {address?.map(item => (
                              <p>{item?.fullAddress}</p>
                            ))}
                          </Col> : ''
                      }
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Primary Holder</h6>
                        {customerDetails?.isPrimaryHolder ? (
                          <div className="active-btn">Yes</div>
                        ) : (
                          <div className="deactive-btn">No</div>
                        )}
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">KYC Status</h6>
                        <p>{customerDetails?.kycStatus}</p>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Photo</h6>
                        {customerDetails?.photo ? (
                          <ImageViewer
                            path={customerDetails?.photo}
                            style={{
                              width: "5rem",
                              height: "3rem",
                              cursor: "pointer",
                            }}
                          ></ImageViewer>
                        ) : (
                          <img
                            src={user1}
                            alt="staff-profile"
                            style={{ width: "5rem", height: "3rem" }}
                          />
                        )}
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12}>
                        <h6 className="fw-bold">Status</h6>

                        {customerDetails?.status ? (
                          <div className="active-btn">Active</div>
                        ) : (
                          <div className="deactive-btn">Inactive</div>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Row>
        </Typography>
      )}

      {value === 1 && (
        <Typography className="tabs-content">

          {/* <Col lg={12} style={{ paddingTop: "1rem" }} className="fingerprintBox">
              <Label htmlFor="example-text-input" className=" col-form-label">
                Authenticate Your Biometrics Data
              </Label>
            </Col> */}

          <Card>
            <CardBody>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Select Locker No*
                </Label>
                <div>
                  {LockerOptions && LockerOptions.length ?
                    LockerOptions.map((data, i) => (
                      <Select
                        // key={`customer_locker_${i}`}
                        maxMenuHeight={250}
                        isSearchable={true}
                        defaultValue={lockerSelected}
                        onChange={handleSelectLocker}
                        options={data}
                      />
                    )) : ""
                  }
                </div>
              </div>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Select Customer*
                </Label>
                <div>
                  {CustomerOptions && CustomerOptions.length ?
                    CustomerOptions.map((data, i) => (
                      <Select
                        // key={`customer_locker_${i}`}
                        maxMenuHeight={250}
                        isSearchable={true}
                        defaultValue={selectedCustomer}
                        onChange={handleSelectCustomer}
                        options={data}
                      />
                    )) : ""
                  }
                </div>
              </div>
              <div className="py-1">
                <Label htmlFor="example-text-input" className=" col-form-label">
                  Remarks*
                </Label>
                <div>
                  <Input
                    className="form-control"
                    id="remarks"
                    type="text"
                    name="remarks"
                    required
                    placeholder="Enter Remarks"
                    value={remarks}
                    onChange={e => {
                      setRemarks(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="py-1">
                {
                  lockerCategory === "Large" || lockerCategory === "Extra Large" ? (
                    <div className="panel-heading">
                      <Label>
                        24*7 Locker Access Charge 2000 + GST (18%)
                      </Label>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          // value={index}
                          onChange={handleAccessCharge}
                          style={{ cursor: "pointer", width: "15px" }}
                        />
                        <span style={{ marginLeft: "1rem" }}>If you want to pay <b>Access Charge</b> please select checkbox</span>
                      </div>
                    </div>
                  ) : ""
                }
              </div>
              <div className="mt-4 d-flex gap-2">
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  style={{ display: "flex", justifyContent: "center", fontSize: "16px" }}
                  onClick={manualLockerAccess}
                >
                  Submit
                </button>
              </div>
            </CardBody>
          </Card>

        </Typography>
      )
      }


      {/* <Card style={{ minHeight: "20vmax" }}>
        <CardBody> */}
      {/* <div className="py-1">
            <Label htmlFor="example-text-input" className=" col-form-label">
              Select Locker No
            </Label>
            <div>
              {LockerOptions && LockerOptions.length ?
                LockerOptions.map((data, i) => (
                  <Select
                    key={`customer_locker_${i}`}
                    maxMenuHeight={250}
                    isSearchable={true}
                    defaultValue={selectedLocker}
                    onChange={handleSelect}
                    options={data}
                  />
                )) : ""
              }
            </div>
          </div> */}


      {/* Authenticate Biometric */}
      {/* {selectedLocker.value && (!customerDetails || !customerDetails?._id) ?
            <> */}
      {/* <Row style={{ paddingTop: "2rem" }}>
                <Col lg={6}>
                  <Label className="panel-heading">Please select finger for which you want to verify biometric data</Label>
                  <div>
                    {fingerOptions && fingerOptions.length ? (
                      <Select
                        maxMenuHeight={250}
                        defaultValue={selectedFinger}
                        isSearchable={true}
                        onChange={handleStatus}
                        options={fingerOptions}
                      />
                    ) : ""}
                  </div>
                </Col>
                <Col lg={6}>
                  {
                    lockerCategory === "Large" ? (
                      <div className="panel-heading">
                        <Label>
                          24*7 Locker Access Charge 2000 + GST (18%)
                        </Label>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            // value={index}
                            onChange={handleAccessCharge}
                            style={{ cursor: "pointer", width: "15px" }}
                          />
                          <span style={{ marginLeft: "1rem" }}>If you required <b>Access Charge</b> please select checkbox</span>
                        </div>

                        <Input
                          className="form-control"
                          type="text"
                          name="accessCharge"
                          id="accessCharge"
                          required
                          readOnly
                          value={lockerAccessCharge}
                        />
                      </div>
                    ) : ""
                  }

                </Col>
              </Row> */}
      {/* <Row>
                <Col lg={12} style={{ paddingTop: "1rem" }} className="fingerprintBox">
                  <Label htmlFor="example-text-input" className=" col-form-label">
                    Authenticate Your Biometrics Data
                  </Label>
                  <form onSubmit={e => e.preventDefault()}>
                    <Row style={{ paddingTop: "1rem" }}>
                      <Col lg={12} style={{ paddingTop: "1rem" }}>
                        <div className="form-group">
                          {
                            isLoading ?
                              <div className="scan">
                                <div className="fingerprint"></div>
                                <h3>Scanning...</h3>
                              </div>
                              : ''
                          }
                          <div style={{ display: isLoading ? "none" : "block" }} className="box" id="box1"></div>
                        </div>
                      </Col>
                      <Col lg={12} style={{ paddingTop: "1rem" }}>
                        <Button
                          htmlFor="box1"
                          type="button"
                          className={isMatched === 'true' || isMatched === true ? 'btn btn-success' : 'btn btn-primary'}
                          onClick={onAuthenticate}
                        >
                          Capture & Authenticate
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row> */}
      {/* </>
            : ''} */}

      {/* Authenticated Customer */}
      {/* {!isLoading && customerDetails && customerDetails?._id ? (
            <>
              <Row className="mt-5 fw-bold view-table" style={{}}>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Name</h6>
                  <p>{customerDetails?.fullName}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Occupation</h6>
                  <p>{customerDetails?.occupation}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Email</h6>
                  <p>{customerDetails?.email}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Mobile</h6>
                  <p>{customerDetails?.mobile}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Telephone</h6>
                  <p>{customerDetails?.telephone}</p>
                </Col>
                {
                  address && address.length ?
                    <Col xl={3} lg={4} md={6} sm={12}>
                      <h6 className="fw-bold">Address</h6>
                      {address?.map(item => (
                        <p>{item?.fullAddress}</p>
                      ))}
                    </Col> : ''
                }
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Primary Holder</h6>
                  {customerDetails?.isPrimaryHolder ? (
                    <div className="active-btn">Yes</div>
                  ) : (
                    <div className="deactive-btn">No</div>
                  )}
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">KYC Status</h6>
                  <p>{customerDetails?.kycStatus}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Photo</h6>
                  {customerDetails?.photo ? (
                    <ImageViewer
                      path={customerDetails?.photo}
                      style={{
                        width: "5rem",
                        height: "3rem",
                        cursor: "pointer",
                      }}
                    ></ImageViewer>
                  ) : (
                    <img
                      src={user1}
                      alt="staff-profile"
                      style={{ width: "5rem", height: "3rem" }}
                    />
                  )}
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Status</h6>

                  {customerDetails?.status ? (
                    <div className="active-btn">Active</div>
                  ) : (
                    <div className="deactive-btn">Inactive</div>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            ""
          )} */}
      {/* </CardBody>
      </Card> */}
    </div >
  )
}

export default connect(null, { setBreadcrumbItems })(AuthenticateFingerPrint)
