import React, { useEffect, useState } from "react";
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Input, Label, Row } from "reactstrap";
import { ExportToExcel } from "./ExportToExcel";
import { getTransactionReportAPI } from "service/service";
import DataTable from "react-data-table-component";

const TransactionsReport = (props) => {

  document.title = "Transaction Report | Shreesh Securities"

  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Transactions report", link: "#" },
  ]

  const [graphData, setGraphData] = useState([]);
  const [dataTransaction, setDataTransaction] = useState([]);

  const getNewData = async () => {
    var startDate = document.getElementById("start_date").value;
    var endDate = document.getElementById("end_date").value;

    if (startDate.length > 0 && endDate.length > 0) {
      const response = await getTransactionReportAPI(startDate, endDate);
      if (response.status === 1 || response.status === "1") {
        setGraphData(response.data);
        const postData = response.data;
        const customHeadings = postData.map((item, index) => ({
          "Sr. No.": index + 1,
          "Date of the day": item.date,
          "Entry Fees of the day": item.entryFee,
          "Deposit Credit of the day": item.depositCredit,
          "Deposit Refund of the day": item.depositRefund,
          "Rent of the day": item.rent,
          "Penalty of the day": item.penalty,
          "GST of the day": item.gst,
          "Total": item.entryFee + item.depositCredit + item.depositRefund + item.rent + item.penalty + item.gst,
        }))
        setDataTransaction(customHeadings)
      } else {
        console.log("Not call");
      }
    }
  }
  // console.log(graphData);
  const columns = [
    {
      name: "Date",
      selector: row => row.date,
    },
    {
      name: "Deposit Credit",
      selector: row => row.depositCredit,
    },
    {
      name: "Deposit Refund",
      selector: row => row.depositRefund,
    },
    {
      name: "Entry Fee",
      selector: row => row.entryFee,
    },
    {
      name: "Late Fee",
      selector: row => row.penalty,
    },
    {
      name: "Rent",
      selector: row => row.rent,
    },
    {
      name: "GST",
      selector: row => row.gst,
    },
    {
      name: "Total",
      selector: row => row.entryFee + row.depositCredit + row.depositRefund + row.rent + row.penalty + row.gst,
    }
  ]

  const customStyles = {
    headCells: {
      style: {
        // paddingLeft: "8px", // override the cell padding for head cells
        // paddingRight: "8px",
        fontSize: "15px",
      },
    },
  }

  useEffect(() => {
    props.setBreadcrumbItems("Transactions Report", breadcrumbItems);
  })

  return (
    <>
      <Card className="mt-3">
        <CardBody>
          <CardTitle
            className="fs-4 fw-bolder d-flex "
            style={{
              borderBottom: "1px solid #e3d0d0",
              justifyContent: "space-between",
            }}
          >
            Transactions Report
            <ExportToExcel
              apiData={dataTransaction}
              fileName={"Transactions"}
            />
          </CardTitle>

          <div className="page-title-box date">
            <Row>
              <Col lg={4} md={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Start Date*
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      id="start_date"
                      type="date"
                      name="startDate"
                      required
                      placeholder="Select Start Date"
                      onChange={getNewData}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    End Date*
                  </Label>
                  <div>
                    <Input
                      className="form-control"
                      id="end_date"
                      type="date"
                      name="endDate"
                      required
                      placeholder="Select End Date"
                      onChange={getNewData}
                    />
                  </div>
                </div>
              </Col>
            </Row>

          </div>
          <DataTable
            className="mt-3"
            columns={columns}
            data={graphData}
            fixedHeader
            pagination
            customStyles={customStyles}
          ></DataTable>
        </CardBody>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(TransactionsReport)