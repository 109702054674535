import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { Link, useParams } from "react-router-dom"
import {
  getCustomerByIdAPI,
  getKYCByIdAPI,
  getTransactionList,
} from "service/service"
import ImageViewer from "ImageViewer"
import dummy from "../../assets/images/users/dummy.jpg"
import user1 from "../../assets/images/users/user-1.jpg"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { gst } from "api/apiClient"
import { Typography } from "@mui/material"

const CustomerDetails = props => {
  document.title = "Customer Details | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Customer List", link: "/customerlist" },
    { title: "Customer Details", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Customer Details", breadcrumbItems)
  })

  // const [kycId, setKycId] = useState()
  // const [kycDetails, setKycDetails] = useState([]);

  // const [imageModal, setImageModal] = useState(false);
  // const [imageData, setImagedata] = useState([]);

  // const getData =(img)=>{
  //   let tempData=[img];
  //   setImagedata(item =>[1,...tempData])
  //   return setImageModal(true)
  // }

  const handleDisplayKYCUpdate = async id => {
    try {
      await getKYCByIdAPI(id)
      // // setKycDetails(res.data)
      // res.data.map(data => {
      //   // setKycId(data.customerId)
      //   // setKycStatus(data.customer.kycStatus)
      // })
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (id) {
      handleDisplayKYCUpdate(id)
    }
    //eslint-disable-next-line
  }, [])

  const { id } = useParams()
  const [customerDetails, setCustomerDetails] = useState([])
  const [customerLocker, setCustomerLocker] = useState([])
  const [customerAddress, setCustomerAddress] = useState([])
  const [customerKyc, setCustomerKyc] = useState([])
  const [customerNominee, setCustomerNominee] = useState([])
  const [transaction, setTransaction] = useState([])
  const [historyTr, setHistoryTr] = useState([])
  const [lockeIdA, setLockerIdA] = useState("")

  const handleDisplayCustomer = async id => {
    try {
      const res = await getCustomerByIdAPI(id)
      setCustomerDetails(res.data)
      setCustomerLocker(res.data.locker)
      setCustomerAddress(res.data.addresses)
      setCustomerKyc(res.data.kyc)
      setCustomerNominee(res.data.nominees)
    } catch (error) {
      console.log(error);
    }
  }

  const handleDisplaylockerHistory = async () => {
    try {
      const res = await getTransactionList()
      setTransaction(res.data)
    } catch (error) {
      console.log(error);
    }
  }

  const displayLocker = async () => {
    const temp = []
    transaction &&
      transaction.length > 0 &&
      transaction.map(data => {
        if (data.lockerId === lockeIdA) {

          temp.push(data)
        }
      })
    setHistoryTr(temp)

  }
  // const mainUrl = "https://shreeshdesignstudiollp.com:3001/"
  useEffect(() => {
    if (id) {
      handleDisplayCustomer(id)
      displayLocker()
    }
    // handleDisplayCustomerSubholders()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleDisplaylockerHistory()
  }, [])

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className="goback">
          <Link to="/customerlist" className="">
            Go Back
          </Link>
        </div>
      </div>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle
                className="fs-4 fw-bolder"
                style={{ borderBottom: "1px solid #e3d0d0" }}
              >
                Customer Personal Details
              </CardTitle>
              <Row>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Name</h6>
                  <p>{customerDetails?.fullName}</p>
                </Col>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Occupation</h6>
                  <p>{customerDetails?.occupation}</p>
                </Col>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Email</h6>
                  <p>{customerDetails?.email}</p>
                </Col>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Mobile No</h6>
                  <p>{customerDetails?.mobile}</p>
                </Col>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Telephone</h6>
                  <p>{customerDetails?.telephone}</p>
                </Col>

                <Col lg="3" md="4">
                  <h6 className="fw-bold ">KYC Status</h6>
                  <p style={{ color: customerDetails?.kycStatus === "Verified" ? "green" : "red", fontWeight: customerDetails?.kycStatus === "Verified" ? "500" : "" }}>{customerDetails?.kycStatus}</p>
                </Col>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Status</h6>
                  <p>
                    {customerDetails?.status ? (
                      <div className="active-btn">Active</div>
                    ) : (
                      <div className="deactive-btn">Inactive</div>
                    )}
                  </p>
                </Col>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Address</h6>
                  <p>{customerAddress[0]?.fullAddress}</p>
                </Col>
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Address Type</h6>
                  <p>
                    {customerAddress[0]?.addressType && customerAddress[0]?.addressType == "1"
                      ? "Home"
                      : "Office"}
                  </p>
                </Col>
                {/* {customerAddress &&
                  customerAddress.map(data => (
                    <>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Address</h6>
                        <p>{data.fullAddress}</p>
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Address Type</h6>
                        <p>
                          {data.addressType && data.addressType == "1"
                            ? "Home"
                            : "Office"}
                        </p>
                      </Col>
                    </>
                  ))} */}
                <Col lg="3" md="4">
                  <h6 className="fw-bold ">Photo</h6>
                  <p>

                    {customerDetails?.photo ? (
                      <>
                        <ImageViewer
                          path={customerDetails?.photo}
                          style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                        >
                          {" "}
                        </ImageViewer>
                      </>
                    ) : (
                      <ImageViewer
                        path={user1}
                        style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                      >
                        {" "}
                      </ImageViewer>
                    )}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {customerKyc.length > 0 && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle
                  className="fs-4 fw-bolder"
                  style={{ borderBottom: "1px solid #e3d0d0" }}
                >
                  Customer KYC
                </CardTitle>
                {customerKyc &&
                  customerKyc.map((data, index) => (
                    <Row>
                      <Col lg="1" md="1">
                        <h6 className="fw-bold ">No.</h6>
                        <p>{index + 1}</p>
                      </Col>
                      <Col lg="3" md="3">
                        <h6 className="fw-bold ">Document Type</h6>
                        <p>{data.documentType}</p>
                      </Col>
                      <Col lg="4" md="4">
                        <h6 className="fw-bold ">Doc Detail</h6>
                        <p>{data.docDetail}</p>
                      </Col>
                      <Col lg="3" md="3">
                        <h6 className="fw-bold ">Image</h6>
                        <p>
                          {data.doc ? (
                            <ImageViewer
                              path={data.doc}
                              style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                            >
                              {" "}
                            </ImageViewer>
                          ) : (
                            <ImageViewer
                              path={dummy}
                              style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                            >
                              {" "}
                            </ImageViewer>
                          )}
                        </p>
                      </Col>
                    </Row>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {customerNominee.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle
                  className="fs-4 fw-bolder"
                  style={{ borderBottom: "1px solid #e3d0d0" }}
                >
                  Customer Nominee
                </CardTitle>
                {customerNominee &&
                  customerNominee.map((data, index) => (
                    <Row>
                      {/* <Col lg="3" md="4">
                        <h6 className="fw-bold ">No.</h6>
                        <p>{index + 1}</p>
                      </Col> */}
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Full Name</h6>
                        <p>{data.fullName}</p>
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Occupation</h6>
                        <p>{data.occupation}</p>
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Relation With Customer</h6>
                        <p>{data.relationWithCustomer}</p>
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Mobile</h6>
                        <p>{data.mobile}</p>
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Address</h6>
                        <p>{data.fullAddress}</p>
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Address Type</h6>
                        <p>{data.addressType == "1"
                          ? "Home"
                          : "Office"}</p>
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Nominee Photo</h6>
                        {data.photo ? (
                          <ImageViewer
                            path={data.photo}
                            style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                          >
                            {" "}
                          </ImageViewer>
                        ) : (
                          <ImageViewer
                            path={dummy}
                            style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                          >
                            {" "}
                          </ImageViewer>
                        )}
                      </Col>
                      <Col lg="3" md="4">
                        <h6 className="fw-bold ">Nominee Document</h6>
                        {data.kycDoc ? (
                          <ImageViewer
                            path={data.kycDoc}
                            style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                          >
                            {" "}
                          </ImageViewer>
                        ) : (
                          <ImageViewer
                            path={dummy}
                            style={{ width: "6rem", height: "5rem", cursor: 'pointer' }}
                          >
                            {" "}
                          </ImageViewer>
                        )}
                      </Col>
                    </Row>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {customerLocker.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle
                  className="fs-4 fw-bolder"
                  style={{ borderBottom: "1px solid #e3d0d0" }}
                >
                  Customer Locker
                </CardTitle>
                {customerLocker &&
                  customerLocker.map(data => (
                    <>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className="mt-2 text-capitalize"
                          style={{ backgroundColor: 'rgb(243 243 243)' }}
                        >
                          <Typography className="fw-bold">{data?.customerLockerNo}</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ borderTop: "1px solid rgb(171 165 165)" }}>
                          <Typography>
                            <Row className="fw-bold view-table" style={{}}>
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Locker No</h6>
                                <p>{data.customerLockerNo}</p>
                              </Col>
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Locker Key</h6>
                                <p>{data.customerLockerKey}</p>
                              </Col>
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Locker Rate (GST 18%)</h6>
                                <p>{data.lockerRate + (data.lockerRate * gst / 100)}</p>
                              </Col>
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Operating Mandate</h6>
                                <p>{data.operatingMandate}</p>
                              </Col>
                              {
                                data.reason ?
                                  <Col lg="3" md="4">
                                    <h6 className="fw-bold ">Reason</h6>
                                    <p>{data.reason}</p>
                                  </Col>
                                  : ""
                              }
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Late Fees (GST 18%)</h6>
                                <p>{data.penalty}</p>
                              </Col>
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Additional Lock Charge (GST 18%)</h6>
                                <p>{data.additionalLockCharge}</p>
                              </Col>
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Break Charge (GST 18%)</h6>
                                <p>{data.breakCharge}</p>
                              </Col>
                              <Col lg="3" md="4">
                                <h6 className="fw-bold ">Locker Status</h6>
                                <p>{data.lockerStatus}</p>
                              </Col>
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(CustomerDetails)
