import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { getTransactionById } from "service/service"
import moment from "moment"

const TransactionDetails = props => {
  document.title = "Transaction Details | Shreesh Securities"
  const breadcrumbItems = [
    { title: "Locker", link: "/dashboard" },
    { title: "Transaction List", link: "/transactionList" },
    { title: "Transaction Details", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Transaction Details", breadcrumbItems)
  })

  const { id } = useParams()
  const [transactionDetails, setTransactionDetails] = useState([])
  const handleDisplayTransaction = async id => {
    try {
      const res = await getTransactionById(id)
      setTransactionDetails(res.data)
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    if (id) {
      handleDisplayTransaction(id)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div>
      <Row>
        <Col>
          <div className="d-flex justify-content-end">
            <div className="goback">
              <Link to="/transactionList" className="">
                Go Back
              </Link>
            </div>
          </div>
          <Card>
            <CardBody>
              <CardTitle className="fs-4">
                <b>View Transaction Details</b>
              </CardTitle>
              <hr />
              <Row className="fw-bold view-table" style={{}}>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold ">Transaction Amount</h6>
                  <p>{transactionDetails.transactionAmount}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction Details</h6>
                  <p>{transactionDetails.transactionDetails}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction For</h6>
                  <p>{transactionDetails.transactionFor}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction Type</h6>
                  <p>{transactionDetails.transactionType}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction Mode</h6>
                  <p>{transactionDetails.modeOfPayment}</p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction Information</h6>
                  <p>{transactionDetails.paymentDescription}</p>
                </Col>

                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction Date</h6>
                  <p>
                    {moment(transactionDetails.transactionDate).format(
                      "Do MMMM YYYY"
                    )}
                  </p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction Time</h6>
                  <p>
                    {moment(transactionDetails.transactionDate).format(
                      "h:mm:s"
                    )}
                  </p>
                </Col>
                <Col xl={3} lg={4} md={6} sm={12}>
                  <h6 className="fw-bold">Transaction Status</h6>
                  <p>{transactionDetails.transactionStatus}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(TransactionDetails)
